import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export const Colors = ({
	colorOptions,
	handleOptionSelection,
	requiredOption,
}) => {
	const sortedItems = colorOptions?.items?.sort((a, b) => a.order - b.order) || [];
	const [selectedColor, setColor] = useState({});
	const [isRequired, setIsRequired] = useState(false);
	const queryParams = new URLSearchParams(location.search);
	const handleColorSelection = (data) => {
		if (selectedColor && selectedColor.colorId === data.colorId) {
			setColor({});
		} else {
			setColor(data);
		}
		handleOptionSelection(data, colorOptions.type);
	};

	useEffect(() => {
		const item = requiredOption && requiredOption.find((obj) => obj.id === colorOptions.id);
		if (item && item !== undefined) {
			setIsRequired(true);
		} else {
			setIsRequired(false);
		}
	}, [requiredOption]);

	useEffect(() => {
		if (sortedItems.length == 1 && !queryParams.get('cartid')) {
			setColor(sortedItems[0]);
		}
	}, [sortedItems])

	return (
		<div className="product-dimensions-wrap">
			<div className="prod-dim-inner">
				<p className="product-dimension-title">
					<span style={{ 'display': 'inline-block', 'padding': '0 10px 0 0' }}>
						{colorOptions?.title.trim() + (colorOptions.isRequired ? ' *' : '')}
					</span>
					<span className="product-dimension-light">
						{selectedColor && selectedColor?.color && selectedColor?.color?.name}
					</span>


					{isRequired ? (
						<span className="product-required-label-wrap">
							<span className="product-required-label">Selection Required</span>
						</span>
					) : null}
				</p>
				<div className="product-dimension-color-wrap product-details-color-wrap">
					{sortedItems &&
						sortedItems.map((item) => (
							<div key={item?.color?.id} className="color-dots">
								<span>
									<input
										type="radio"
										className="radio-btn"
										name={item?.color?.name}
										id={item?.color?.id}
										data-action="color"
										aria-label={item?.color?.name }
										style={{ backgroundColor: item?.color?.hex }}
										checked={
											selectedColor &&
											selectedColor.color &&
											selectedColor.color?.id === item.color?.id
										}
										onClick={() => handleColorSelection(item)}
									/>
									{item?.color?.colorImage ? (
										<label className="color-dots large color-select" htmlFor={item?.color?.id}>
										<div className="color-dots-img-wrap">
											<img
												src={item?.color?.colorImage?.previewsrc}
												style={{ height: '100%' }}
                                                alt="Color dot"
												/>
									</div>
										</label>
									) : (
										<label
											className="color-dots large color-select"
											style={{ backgroundColor: item?.color?.hex }}
											htmlFor={item?.color?.id}
										></label>
									)}
								</span>
							</div>
						))}
				</div>
			</div>
		</div>
	);
};
Colors.propTypes = {
	colorOptions: PropTypes.object,
	handleOptionSelection: PropTypes.func,
	requiredOption: PropTypes.array,
};
