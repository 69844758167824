import React, { useState, useEffect } from 'react';
import '../Admin.css';
import { useDispatch } from 'react-redux';
import { resetSaveStatus, loadManufacturers } from '../../../store/manufacturer-reducer';
import { loadColorGroups } from '../../../store/colors-reducer';
import { useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { EditManufacturerColors } from "./editManufacturerColors";
import EditProducts from "./editProducts";
import EditManufacturerName from "./editManufacturerName"
import EditShippingNote from './editShippingNote';
import AdminError from '../../../components/admin/admin-error';
import { updateTitle } from '../../../helpers/titleHelper';

function EditManufacturers() {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState('products');
    const { manufacturerId, openTab } = useParams();
    const manufacturers = useSelector((state) => state.manufacturers.list.data);
    const colorGroups = useSelector((state) => state.colors.colorGroups);

    const manufacturer = manufacturers?.find((m) => m.id == manufacturerId);
    const currentName = manufacturer ? manufacturer.name : '';
    const currentNotes = manufacturer ? manufacturer.shippingNote : '';
    const saveStatus = useSelector((state) => state.manufacturers.saveStatus);
    const saveError = useSelector((state) => state.manufacturers.saveError);
    const [error, setError] = useState('');
    const [loaded, setLoaded] = useState(false);
    updateTitle('Admin', 'Manufacturer - ' + currentName);

    useEffect(() => {
        if (openTab !== undefined) setActiveTab(openTab);
    }, []);

    useEffect(() => {
        if (!manufacturers) {
            dispatch(loadManufacturers());
        }
    }, [manufacturers, colorGroups, dispatch]);

    useEffect(() => {
        if (manufacturerId !== null) {
            dispatch(loadColorGroups(manufacturerId));

        }
    }, [manufacturerId, colorGroups, dispatch]);

    useEffect(() => {
        if (saveStatus === 'succeeded') {
            dispatch(loadManufacturers());
            setTimeout(() => {
                dispatch(resetSaveStatus());
            }, 3000);
        } else if (saveStatus === 'failed' && loaded == true) {
            setError(saveError);
        } else if (saveStatus === 'failed' && loaded == false) {
            resetSaveStatus();
        }

        setLoaded(true);
    }, [saveStatus, dispatch]);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        window.history.replaceState(null, '/admin/manufacturers-products/edit/' + manufacturerId + '/' + tab, '/admin/manufacturers-products/edit/' + manufacturerId + '/' + tab);
    }

    if (!manufacturer) {
        return (
            <div className="admin-page-wrap">
                <div className="admin-top-bar-wrap">
                    <div className="admin-top-bar-left">
                        <button className="admin-top-bar-back">Back</button>
                    </div>
                </div>
                <div className="admin-page-content">
                    <h2 className="admin-page-heading">Manufacturer does not exist.</h2>
                    <Link className="admin-page-link" to="/admin/manufacturers">Back to Manufacturers</Link>
                </div>
            </div>);
    }
    return (
        <div className="admin-page-wrap">
            <div className="admin-top-bar-wrap">
                <div className="admin-top-bar-left">
                    <Link className="admin-top-bar-back" to="/admin/manufacturers">Back</Link>
                </div>
            </div>
            <div className="admin-page-content">
                <h1 className="admin-page-heading">{currentName}</h1>
                <div className="admin-tab-wrap">
                    <button className={`admin-tab ${activeTab === 'products' ? 'active' : ''}`} onClick={() => handleTabChange('products')} id="tab1" role="tab" aria-controls="product-tab" aria-selected="true">Products</button>
                    <button className={`admin-tab ${activeTab === 'colors' ? 'active' : ''}`} id="tab2" onClick={() => handleTabChange('colors')} role="tab" aria-controls="color-tab" aria-selected="true">Manufacturer Colors</button>
                    <button className={`admin-tab ${activeTab === 'notes' ? 'active' : ''}`} id="tab3" role="tab" onClick={() => handleTabChange('notes')} aria-controls="shipping-tab" aria-selected="true">Shipping &amp; Install Note</button>
                    <button className={`admin-tab ${activeTab === 'name' ? 'active' : ''}`} id="tab4" role="tab" aria-controls="name-tab" onClick={() => handleTabChange('name')} aria-selected="true">Manufacturer Name</button>
                </div>
                <div className="product-tab" id="product-tab" role="tabpanel" aria-labelledby="tab1">

                    {saveStatus === 'succeeded' && (
                        <div className="success-message">
                            Manufacturer Information has been updated
                        </div>
                    )}
                    <AdminError message={error} onClose={() => { setError(''); }}></AdminError>
                    {activeTab === 'notes' && <EditShippingNote manufacturerId={manufacturerId} currentNotes={currentNotes} currentName={currentName} />}
                    {activeTab === 'name' && <EditManufacturerName manufacturerId={manufacturerId} currentNotes={currentNotes} currentName={currentName} />}
                    {activeTab === 'colors' && <EditManufacturerColors />}
                    {activeTab === 'products' && <EditProducts />}
                </div>
            </div>
        </div>
    );
}

export default EditManufacturers;