import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateQuote } from '../../api/cartapi.js';
import { useNavigate } from 'react-router-dom';
import { controlBannerWithButtonAndMessage, hideBanner } from '../../store/layout-banner-reducer.js';
import { getImpersonationCheck, getImpersonationDetails } from '../../api/cartapi.js';
export const Banner = () => {
	const showBanner = useSelector((state) => state.layoutBanner.isVisible);
	const message = useSelector((state) => state.layoutBanner.message);
	const showBtn = useSelector((state) => state.layoutBanner.isButtonShown);
	const impersonation = getImpersonationDetails();
	const showTypicalBtn = useSelector((state) => state.layoutBanner.isTypicalButtonShow);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const handleSaveQuote = () => {
		const quoteId = localStorage.getItem('editQuoteId');
		updateQuote().then((response) => {
			if (response.data.success == true) {
				localStorage.setItem('editQuoteId', null);
				localStorage.removeItem('editQuoteId');
				localStorage.setItem('editQuoteId_Edit', null);
				dispatch(hideBanner());
				navigate('/my-quote/' + quoteId);
			}
		});
	}

	const disableImpersonationMode = () =>{
			const {originalURL} = getImpersonationDetails();
		dispatch(controlBannerWithButtonAndMessage({ isImpersonating:false,userImpersonatingID:'',userImpersonatingName:'' }))
			sessionStorage.removeItem('impersonationDetails');
			navigate(originalURL);
		
	}

	const typicalProductDetails = JSON.parse(localStorage.getItem('editTypicalLayout')) || [];
	const handleCancelEditing = () => {
		localStorage.removeItem('typicallayout');
		dispatch(hideBanner());
		navigate('/admin/districts/edit/' + typicalProductDetails.districtId + '/typical-layout/' + typicalProductDetails.id);
	}

	if (!showBanner) return null;
	return (
		<div className="message-box message-wrap">
			<div className="message-wrap-inner">
				<div className="message-item-msg">{message}</div>
				<div className="message-item-btn">

					{showBtn ? (
						<button className="message-btn" onClick={() => getImpersonationCheck() ? disableImpersonationMode() : handleSaveQuote()}>
							<span className="btn-outline">{impersonation?.isImpersonating ? 'Save & Exit Edit Mode' : 'Disable Edit Mode'}</span>
						</button>
					) : showTypicalBtn ? (
						<button className="message-btn" onClick={() => handleCancelEditing()}>
							<span className="btn-outline">Cancel Editing</span>
						</button>
					) : (
						''
					)}

				</div>
			</div>
		</div>
	);
};
