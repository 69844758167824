import React from 'react';
import Scrollbars from "react-custom-scrollbars-2";
import LayoutTypeItem from './layout-type-item';
import { useSelector } from 'react-redux';
export default function GetCombinedMenu() {
    const roomTypeList = useSelector((state) => state.categories.roomType);

    function closeMenu(e) {
        e.preventDefault();
        document.querySelector('html').classList.remove('product-nav-open');
    }

    return (
        <div className="nav-link-dropdown" id="productroomTypes">
            <div className="nav-overlay-product">
                <div className="nav-overlay-inner">
                    <div className="nav-overlay-content-inner scrollbar-outer">
                        <a href="#" onClick={closeMenu} className="nav-overlay-close"><span className="nav-overlay-close-icon"><span className="material-symbols-rounded">close</span></span></a>
                        <Scrollbars
                            autoHeight
                            autoHeightMin={400}
                            autoHeightMax={800}
                            renderTrackHorizontal={props => <div {...props} className="track-horizontal" />}
                            renderTrackVertical={props => <div {...props} className="track-vertical" />}
                            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" />}
                            renderThumbVertical={props => <div {...props} className="thumb-vertical" />}
                            renderView={props => <div {...props} className="view" />}>
                            <div className="nav-overlay-center home-room-overlay">
                                <div className="nav-drop-layout-wrap">
                                    <a href="#" className="type-back-link" onClick={closeMenu}><span className="material-symbols-rounded">arrow_back_ios</span>Back</a>
                                    <div className="nav-block-wrap">
                                        <p className="nav-drop-title">Browse by Room Type</p>
                                        {roomTypeList && roomTypeList.map((roomtype, index) => {
                                            return (
                                                <LayoutTypeItem
                                                    imagePath={roomtype.imagePath}
                                                    imageAlt={roomtype.imageAlt}
                                                    name={roomtype.name}
                                                    url={roomtype.url}
                                                    key={index}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </Scrollbars>
                    </div>
                </div>
            </div>
        </div>
    );
}
