import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './survey.css';
import { updateTitle } from '../../helpers/titleHelper';
import { getSurveyList } from '../../store/survey/actions';
import Link from '../../assets/link.svg'
export default function SurveyDetail() {
    const dispatch = useDispatch();
    updateTitle('Surveys');
    const districtId = localStorage.getItem('districtId') ?? 0;
    const { surveyList } = useSelector(state => state.surveyList);

    const [filter] = useState({ sort: 4, districtId: districtId });

    useEffect(() => {
        dispatch(getSurveyList(filter));
    }, [filter]);

    const surveyActiveList = surveyList?.filter(x => x.isActive === false);

    return (
        <div className="contact-wrapper">
            <div className="default-center">
                <div className="page-header-wrap survey-header-title">
                    <h1 className="product-main-title">Surveys</h1>
                </div>
                <div className="survey-blocks-wrapper">
                    <div className="survey-blocks-wrap">

                        {surveyActiveList && surveyActiveList.length > 0 ? (
                            surveyActiveList.map(item =>
                                <div className="survey-item" key={'survey_detail_' + item.id}>
                                    <h3>{item.title}</h3>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: item.description,
                                        }}
                                    ></span>
                                    <a href={item.links}
                                        className="default-cta power"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        Take Survey
                                        <img src={Link} alt="link" />
                                    </a>
                                </div>
                            )
                        ) : ""}


                    </div>
                </div>
            </div>
        </div>
    );
}
