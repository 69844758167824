import React from 'react';
import PropTypes from 'prop-types';

function AdminDefaultInputText({ id, value, placeholder, label, onChange, inputType }) {

  return (
    <div className="admin-default-input-text-wrap">
      <label
        className="admin-default-label"
        htmlFor={id}
      >
        {label}
      </label>
      <input
              className="admin-default-input-text"
              type={inputType}
              id={id}
              defaultValue={value}
              placeholder={placeholder}
              autoComplete="new-password"
              onChange={(e) => { onChange(e.target.value); } }
      />
    </div>
  );
}

AdminDefaultInputText.propTypes = {
  id: PropTypes.string,
  value: PropTypes.node,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  inputType: PropTypes.string
}

AdminDefaultInputText.defaultProps = {
  inputType: 'text'
}

export default AdminDefaultInputText;