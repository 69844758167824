import React, { useEffect, useRef } from 'react';
import AdminError from './admin-error.js';
import PropTypes from 'prop-types';

export function AdminModal({
	modalTitle,
	show,
	onClose,
	onSave,
	children,
	showDelete,
	adminModalClass,
	displayError,
	onHideError,
	saveButtonText
}) {
	const inputRef = useRef(null);

	const handleModalClose = (e) => {
		if (onClose) onClose(e);
	};
	const handleSave = () => {
		if (onSave) onSave();
	};

	useEffect(() => {
		if(show) {
			const handleEnterKey = (e) => {
				if(e.key === 'Enter' || e.keyCode === 13) {
					setTimeout(() => {
						handleSave();
					}, 50); 
				}
			};
			if (inputRef.current) {
				inputRef.current.focus();
			}
			window.addEventListener('keydown', handleEnterKey);
			return () => {
				window.removeEventListener('keydown', handleEnterKey);
			};
		}
	}, [show, onSave]);


	if (!show) return null;
	return (
		<div className="admin-modal-overlay">
			<div className={"admin-modal admin-add-role admin-user-width " + adminModalClass}>
				<div className="admin-modal-scroll">
				<button className="admin-modal-close" onClick={handleModalClose}>
					<span className="material-symbols-rounded">close</span>
				</button>
				<h2 className="admin-modal-heading">{modalTitle}</h2>
				<AdminError message={displayError} onClose={onHideError}></AdminError>
				{children}
				<div className="admin-modal-button-wrap">
					<button className="admin-modal-button" onClick={onClose}>
						Cancel
					</button>
					{showDelete && (
						<button className="admin-modal-button red">Delete</button>
					)}
					<button className="admin-modal-button power" onClick={handleSave}>
						{saveButtonText ?? "Save Changes"}
					</button>
					</div>
				</div>
			</div>
		</div>
	);
}

AdminModal.propTypes = {
	children: PropTypes.node,
	modalTitle: PropTypes.string,
	show: PropTypes.bool,
	onClose: PropTypes.func,
	onSave: PropTypes.func,
	showDelete: PropTypes.bool,
	adminModalClass: PropTypes.string,
	displayError: PropTypes.string,
	onHideError: PropTypes.func,
	saveButtonText: PropTypes.string
};
