import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DistrictSiteUsers from './districtAccountManager';
import { AddDistrictModal } from './addDistrictModal';
import { AddEditDistrictEmailsModal } from './addDistrictEmailModal';
import { getAllDistrictEmail, editDistrictEmail, addDistrictsEmail, deleteDistrictEmailById } from '../../../api/districtapi';
import Loader from '../../../components/Loader';

function DistrictDetails({ district, onSaveDistrict }) {
    const [showEditModal, setShowEditModal] = useState(false);

    useEffect(() => {

    }, []);

    return (
        <div className="admin-new-section">
            <div className="admin-section">
                <div className="admin-section-title-bar">
                    <p className="admin-section-headline">Branding</p>
                    <div className="admin-section-title-action-wrap">
                        <button className="admin-button" onClick={() => setShowEditModal(true)}>
                            <span className="admin-button-text">Edit Branding</span>
                        </button>
                    </div>
                </div>
                <AddDistrictModal
                    title='Edit District Branding'
                    show={showEditModal}
                    onClose={() => setShowEditModal(false)}
                    handleAddDistrict={onSaveDistrict}
                    model={{
                        id: district.id,
                        name: district.name,
                        hexcolor: district.hexColor,
                        path: district.path,
                        mediaItemId: district.mediaItemId,
                        schoolLogo: district.schoolLogo
                    }}
                />
            </div>
            <div className="admin-section-row">
                <div className="admin-section-cell">
                    <p className="admin-default-label">School District Name</p>
                    <p className="admin-default-uneditable">{district.name}</p>
                </div>
                <div className="admin-section-cell">
                    <p className="admin-default-label">URL Path</p>
                    <p className="admin-default-uneditable">/{district.path}</p>
                </div>
            </div>
            <div className="admin-section-row">
                <div className="admin-section-cell">
                    <p className="admin-default-label">Main School District Color</p>
                    <div className="admin-modal-color-picker-preview" style={{ background: district.hexColor }}></div>
                </div>
                <div className="admin-section-cell">
                    <p className="admin-default-label">District Logo</p>
                    {district.schoolLogo && <img src={district.schoolLogo.previewsrc} alt={district.name + " Logo"} className="admin-modal-school-logo" />}
                    <input type="hidden" value={district.id} />
                </div>
            </div>
        </div>
    )
}
DistrictDetails.propTypes = {
    district: PropTypes.object.isRequired,
    onSaveDistrict: PropTypes.func
}

function DistrictEmails({ district }) {
    const defaultEmailObj = { id: 0, domain: '', districtid: district.id }

    const [showAddModal, setShowAddModal] = useState(false);
    const [districtEmailList, setDistrictEmailList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [emailModel, setEmailModel] = useState(defaultEmailObj);


    const loadEmails = () => {
        setLoading(true);
        getAllDistrictEmail(district.id).then((response) => {
            if (response.data.success) setDistrictEmailList(response.data.data);
            setLoading(false);
        });
    }

    useEffect(() => {
        if (district.id !== null)
            loadEmails();
    }, [district]);

    const handleAddEmailDistrict = () => {
        setEmailModel(defaultEmailObj);
        setShowAddModal(true);
    }

    const handleEditTrigger = (id, domain, districtid) => {
        setEmailModel({ id: id, domain: domain, districtid: districtid });
        setShowAddModal(true);
    }

    const handleEditDistrictEmail = (districtEmailData) => {
        if (districtEmailData != undefined && districtEmailData != null && districtEmailData.id > 0) {
            editDistrictEmail({ options: { data: districtEmailData } })
                .then((response) => {
                    if (response.data.success) loadEmails();
                    else alert(response.data.message);
                });
        }
        else {
            //add email
            addDistrictsEmail({ options: { data: districtEmailData } })
                .then((response) => {
                    if (response.data.success) loadEmails();
                    else alert(response.data.message);
                });
        }
    }

    const handleDeleteEmailDistrict = (districtData) => {
        deleteDistrictEmailById(districtData)
            .then((response) => {
                if (response.data.success) loadEmails();
            });
    }

    return (
        <>
            <div className="admin-section">
                <AddEditDistrictEmailsModal
                    show={showAddModal}
                    onClose={() => setShowAddModal(false)}
                    handleAddDistrictEmail={handleEditDistrictEmail}
                    modelEmail={emailModel}
                />
                <div className="admin-section-title-bar">
                    <p className="admin-section-headline">Email Domain</p>
                    <div className="admin-section-title-action-wrap">
                        <button className="admin-button" onClick={() => handleAddEmailDistrict(true)}>
                            <span className="admin-button-text">Add Email Domain</span>
                            <span className="material-symbols-rounded" aria-hidden="true">add</span>
                        </button>
                    </div>
                </div>
                <div className="admin-table-wrap">
                    <table className="admin-table">
                        <tbody>
                            <tr>
                                <th className="admin-table-name-cell">URL</th>
                                <th className="admin-table-action-cell">&nbsp;</th>
                                <th className="admin-table-action-cell">&nbsp;</th>
                            </tr>
                            {districtEmailList && districtEmailList.length > 0 ? (
                                districtEmailList.map(districtemail =>
                                    <tr key={districtemail.id}>
                                        <td className="admin-table-name-cell">{districtemail.domain}</td>
                                        <td className="admin-table-action-cell admin-table-branding">
                                            <button className="admin-table-action" onClick={() => handleEditTrigger(districtemail.id, districtemail.domain, district.id)}>
                                                <span className="material-symbols-rounded">edit</span>
                                            </button>
                                        </td>
                                        <td className="admin-table-action-cell admin-table-branding">
                                            <button className="admin-table-action" key={districtemail.id}
                                                onClick={() => handleDeleteEmailDistrict(districtemail.id)}>
                                                <span className="material-symbols-rounded">delete</span>
                                            </button>
                                        </td>
                                    </tr>
                                )) : (
                                loading ? <tr><td colSpan="2"><Loader /></td></tr> : <></>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
DistrictEmails.propTypes = {
    district: PropTypes.object.isRequired
}


export default function DistrictSettings({ district, onDistrictEdit }) {
    useEffect(() => {
    }, [])
    return (
        <div className="admin-tab-content" id="settings-tab" role="tabpanel" aria-labelledby="tab1">
            <DistrictEmails district={district} />
            <DistrictDetails district={district} onSaveDistrict={onDistrictEdit} />
            {(!district.isDistrictUser || district.isDistrictAdmin === true || district.isDistrictManager == true) &&
                <DistrictSiteUsers />
            }
        </div>
    );
}

DistrictSettings.propTypes = {
    district: PropTypes.object.isRequired,
    onDistrictEdit: PropTypes.func
}