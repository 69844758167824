import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
    getDistrictSiteUsers,
    submitDistrictSiteUser,
    editDistrictSiteUser,
    deleteDistrictSiteUser
} from '../api/districtsiteuserapi';

const slice = createSlice({
    name: "districtSiteUsers",
    initialState: {
        districtSiteUsers: [],
        isLoading: false,
        isImpersonation:false,
        userImpersonatingID:'',
        userImpersonatingName:'',
        originalURL:'',
        canImpersonate:false
    },
    reducers: {
        siteUserRequested: (districtSiteUsers) => {
            districtSiteUsers.isLoading = true;
        },
      
        siteUserReceived: (districtSiteUsers, action) => {
            districtSiteUsers.districtSiteUsers = action.payload;
            districtSiteUsers.isLoading = false;
        },
        siteUserAdded: (districtSiteUsers, action) => {
            districtSiteUsers.districtSiteUsers.push(action.payload);
        },
        siteUserEdited: (districtSiteUsers, action) => {
            const index = districtSiteUsers.districtSiteUsers.findIndex(siteUser => siteUser.siteUserId === action.payload.data.siteUserId);
            if (index >= 0) {
                districtSiteUsers.districtSiteUsers[index] = action.payload.data;
            }
        },

        siteUserDeleted: (districtSiteUsers, action) => {
            const index = districtSiteUsers.districtSiteUsers.findIndex(siteUser => siteUser.siteUserId === action.payload.siteUserId);
            districtSiteUsers.districtSiteUsers.splice(index, 1);
        },

        saveStarted: (state) => {
            state.saveStatus = 'saving';
        },
        saveSucceeded: (state) => {
            state.saveStatus = 'succeeded';
        },
        saveFailed: (state, action) => {
            state.saveStatus = 'failed';
            state.saveError = action.payload;
        },
        resetSaveStatus: (state) => {
            state.saveStatus = 'idle';
        },
        handleImpersonation:(state, action) => {
            state.isImpersonation = action.payload.isImpersonating;
            state.userImpersonatingID = action.payload.userImpersonatingID;
            state.userImpersonatingName = action.payload.userImpersonatingName;
            state.originalURL = action.payload.originalURL; 
        },
        setCanImpersonate:(state, action) => {
            state.canImpersonate = action.payload
        }
    },
});

export const {
    siteUserRequested,
    siteUserReceived,
    siteUserAdded,
    siteUserEdited,
    siteUserDeleted,
    saveStarted,
    saveSucceeded,
    saveFailed,
    resetSaveStatus,
    handleImpersonation
} = slice.actions;
export default slice.reducer;

// Action Creators

export const loadSiteUserAccount = (districtId) => async (dispatch) => {
    dispatch(siteUserRequested());
    try {
        const response = await getDistrictSiteUsers(districtId);
        const accountManager = response.data.data;
        dispatch(siteUserReceived(accountManager));
    } catch (error) {
        console.error("There was an error loading site user:", error);
    }
};

export const getAllSiteUsersList = createSelector(
    state => state.districtSiteUsers.districtSiteUsers,
    districtSiteUsers => districtSiteUsers
);

export const addNewSiteUsers = (siteUserData) => async (dispatch) => {
    dispatch(saveStarted());

    try {
        const response = await submitDistrictSiteUser(siteUserData);
        if (!response.data.success) {
            dispatch(saveFailed(response.data.message));
        }
        else {
            dispatch(siteUserAdded(response.data.data));
            dispatch(saveSucceeded());
        }
    } catch (error) {
        console.error("There was an error adding the site user:", error);
        dispatch(saveFailed(error.toString()));
    }
};

export const editDistrictSiteUserDetail = (siteUserData) => async (dispatch) => {
    dispatch(saveStarted());

    try {
        const response = await editDistrictSiteUser(siteUserData);
        if(response.data.success){
            dispatch(siteUserEdited(response.data));
            dispatch(saveSucceeded());
        }else{
            dispatch(saveFailed(response.data.message));
        }
   
    } catch (error) {
        dispatch(saveFailed(error.message));
        console.error("There was an error editing the color group:", error);
    }
};

export const deleteExistingSiteUser = (districtId,siteUserId) => async (dispatch) => {
    dispatch(saveStarted());

    try {
        const response = await deleteDistrictSiteUser(districtId, siteUserId);
        if(response.data.success){

        dispatch(siteUserDeleted({ districtId, siteUserId }));
        dispatch(saveSucceeded());}
        else{
            dispatch(saveFailed(response.data.message));
        }

    } catch (error) {
        console.error("There was an error deleting the category:", error);
        dispatch(saveFailed(error.toString()));

    }
};
