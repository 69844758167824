import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import "./typical.css";
import Slider from "react-slick";
import { TypicalProductDetail } from "../../components/common/Typical-product-detail";
import TypicalProductImageSlider from '../../components/common/TypicalProductImageSlider';
import { getById, getIdBySlug, addDistrictUserTypicalLayout } from '../../api/typicallayoutsapi';
import { calculateFinalUnitPrice, formatUSD, isVimeoVideoExists, processUrl } from '../../helpers/formatterHelper';
import { loadAllItems } from '../../store/myproject-reducer';
import { updateTitle } from '../../helpers/titleHelper';
import nextArrow from '../../assets/arrow_next.svg';
import prevArrow from '../../assets/arrow_prev.svg';
import { getVimeoToken } from '../../api/productapi';
import PlayIcon from '../../assets/play_arrow.png'


export default function TypicalLayout() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const districtId = localStorage.getItem('districtId');
    const { slug } = useParams();
    const [layoutId, setLayoutId] = useState(null);
    const [layout, setLayout] = useState();
    const [descriptionList, setDescriptionList] = useState([]);
    const [estimatedCost, setEstimatedCost] = useState(0);
    const [productQty, setProductQty] = useState(0);
    const [showAddButton, setShowAddButton] = useState(true);
    let editingTypicalLayout = localStorage.getItem('typicallayout') == true;
    let geteditQuoteId = localStorage.getItem('editQuoteId');
    /*const [isLightboxOpen, setIsLightboxOpen] = useState(false);*/
    //const [lightboxImage, setLightboxImage] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [videoUrl, setVideoUrl] = useState("");
    const [type, setType] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [processedItems, setProcessedItems] = useState([]);
    const [vimeoToken, setVimeoToken] = useState("");


    const openVideo = (mediaItem, index) => {
        console.log("This is my media Item",mediaItem)
        setOpenModal(!openModal)
        setPreviewImage(mediaItem?.previewsrc);
        setType(mediaItem?.type);
        if (mediaItem?.type == 1) {


            const mediaData = processUrl(mediaItem?.videoSrc);

            let videoUrl = '';
            if (mediaItem.videoType === 0) {
                videoUrl = `https://www.youtube.com/embed/${mediaData.id}?rel=0&autoplay=1`
            }
            else {
                if (mediaData.type === "vimeo") {
                    videoUrl = `https://player.vimeo.com/video/${mediaData.id}?title=0&byline=0&portrait=0&${mediaData.urlval ? `${mediaData.urlval}&` : ''}autoplay=true`;
                }
                if (mediaData.type === "htmlVideo") {
                    videoUrl = mediaItem?.videoSrc;
                }
            }


            setVideoUrl(videoUrl);
        }
        setCurrentIndex(index);
    }


    useEffect(() => {
        getVimeoToken().then((response) => {
            setVimeoToken(response.data);
        });
    }, []);



    //useEffect(() => {
    //    const processItems = async () => {
    //        const itemsWithVideoCheck = await Promise.all(
    //            layout.filter(item => !item.mediaItem?.videoThumbnailUrl?.includes("/static/media/")).map(async (item) => {
    //                console.log("item:", item);
    //                if (item.mediaItem?.videoType === 1) {
    //                    const videoExists = await isVimeoVideoExists(item.mediaItem?.videoId, vimeoToken);
    //                    return { ...item, videoExists };
    //                }
    //                return item;
    //            })
    //        );
    //        let data = itemsWithVideoCheck.filter(x => (x.videoExists === true && x.mediaItem?.videoType === 1) || x.mediaItem?.videoType === 0 || x.mediaItem?.type === 0);
    //        console.log("data:", data);
    //        setProcessedItems(itemsWithVideoCheck.filter(x => (x.videoExists === true && x.mediaItem?.videoType === 1) || x.mediaItem?.videoType === 0 || x.mediaItem?.type === 0));
    //    };

    //    if (layout && layout.length > 0) {
    //        processItems();
    //    }
    //}, [layout]);


    const processItems = async (data) => {
        console.log("item:", data);
        const itemsWithVideoCheck = await Promise.all(
            data.mediaItems.filter(item => !item.mediaItem?.videoThumbnailUrl?.includes("/static/media/")).map(async (item) => {
               
                if (item.mediaItem?.videoType === 1) {
                    const videoExists = await isVimeoVideoExists(item.mediaItem?.videoId, vimeoToken);
                    return { ...item, videoExists };
                }
                return item;
            })
        );
        let data1 = itemsWithVideoCheck.filter(x => (x.videoExists === true && x.mediaItem?.videoType === 1) || x.mediaItem?.videoType === 0 || x.mediaItem?.type === 0);
        console.log("data:", data1);
        setProcessedItems(itemsWithVideoCheck.filter(x => (x.videoExists === true && x.mediaItem?.videoType === 1) || x.mediaItem?.videoType === 0 || x.mediaItem?.type === 0));
    };

    if (layout && layout.length > 0) {
        processItems();
    }



    const nextSlide = () => {
        const newIndex = currentIndex === processedItems.length - 1 ? 0 : currentIndex + 1;
        const media = processedItems[newIndex];
        if (media) {
            setPreviewImage(media.mediaItem.previewsrc);
            setType(media.mediaItem.type);
            if (media.mediaItem.type == 1) {

                const mediaData = processUrl(media.mediaItem?.videoSrc);


                let videoUrl = '';
                if (media.mediaItem.videoType === 0) {
                    videoUrl = `https://www.youtube.com/embed/${mediaData.id}?rel=0&autoplay=1`
                }
                else {
                    if (mediaData.type === "vimeo") {
                        videoUrl = `https://player.vimeo.com/video/${mediaData.id}?title=0&byline=0&portrait=0&${mediaData.urlval ? `${mediaData.urlval}&` : ''}autoplay=true`;
                    }
                    if (mediaData.type === "htmlVideo") {
                        videoUrl = media.mediaItem?.videoSrc;
                    }
                }
                setVideoUrl(videoUrl);
            }
        }
        setCurrentIndex(newIndex);
    };

    const prevSlide = () => {
        const newIndex = currentIndex === 0 ? processedItems.length - 1 : currentIndex - 1;
        const media = processedItems[newIndex];
        if (media) {
            setPreviewImage(media.mediaItem.previewsrc);
            setType(media.mediaItem.type);
            if (media.mediaItem.type == 1) {
                const mediaData = processUrl(media.mediaItem?.videoSrc);


                let videoUrl = '';
                if (media.mediaItem.videoType === 0) {
                    videoUrl = `https://www.youtube.com/embed/${mediaData.id}?rel=0&autoplay=1`
                }
                else {
                    if (mediaData.type === "vimeo") {
                        videoUrl = `https://player.vimeo.com/video/${mediaData.id}?title=0&byline=0&portrait=0&${mediaData.urlval ? `${mediaData.urlval}&` : ''}autoplay=true`;
                    }
                    if (mediaData.type === "htmlVideo") {
                        videoUrl = media.mediaItem?.videoSrc;
                    }
                }
                setVideoUrl(videoUrl);
            }
        }
        setCurrentIndex(newIndex);
    }





    const settings = {
        infinite: true,
        speed: 500,
        initialSlide: 0,
        dots: false,
        arrows: true,
        // slidesToShow: 1.825,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "20px",
        variableWidth: true,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    dots: false,
                    arrows: true,
                    autoplay: true,
                    cssEase: "linear",
                    autoplaySpeed: 2000,
                },
            },
            {
                breakpoint: 900,
                settings: {
                    dots: false,
                    cssEase: "linear",
                    arrows: true,
                    autoplay: true,
                    autoplaySpeed: 2000,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    arrows: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    cssEase: "linear",
                    variableWidth: false,
                },
            },

        ],
    };

    useEffect(() => {
        if (slug != null && slug != '') {
            getIdBySlug(slug).then(response => {
                if (response.data.success == true) {
                    setLayoutId(response.data.data);
                } else {
                    if (parseInt(slug) != 'NaN') {
                        setLayoutId(parseInt(slug));
                    }
                }
            });
        }
    }, [slug]);

    const fetchData = () => {
        if (layoutId == null) { return; }
        getById(layoutId).then(response => {
            if (response.data.success == true) {
                var responseData = response.data.data;
                setLayout(responseData);
                updateTitle('Typical Layout', responseData.title);
                if (responseData.description != undefined && responseData.description != null && responseData.description != '') {
                    var getdescriptionlist = JSON.parse(responseData.description);
                    setDescriptionList(getdescriptionlist);
                }
                setQtyData(responseData.products);
                processItems(responseData);
            }
        });
    };

    useEffect(() => {
        fetchData();
    }, [layoutId]);

    const handleQuantityChange = (quantityData) => {
        const updatedProducts = layout.products.map(product => {
            if (product.id === quantityData.id) {
                return {
                    ...product,
                    quantity: quantityData.quantity
                };
            }
            return product;
        });

        setLayout(prevLayout => ({
            ...prevLayout,
            products: updatedProducts
        }));
        setQtyData(updatedProducts);
    };

    const typicalProductAdded = (productAdded) => {
        const updatedProducts = layout.products.map(product => {
            if (product.id === productAdded.id) {
                return {
                    ...product,
                    isAdded: productAdded.isAdded
                };
            }
            return product;
        });

        setLayout(prevLayout => ({
            ...prevLayout,
            products: updatedProducts
        }));
        setQtyData(updatedProducts);
    };

    const restoreDefultTypicalLayout = () => {
        fetchData();
    };

    const setQtyData = (products) => {
        const newEstimatedCost = products.reduce(
            (sum, productItem) => {
                if (productItem.isAdded) {
                    return sum + calculateFinalUnitPrice(productItem.product) * productItem.quantity;
                }
                return sum;
            },
            0
        )
        setEstimatedCost(newEstimatedCost);

        const totalQuantityAdded = products.reduce(
            (total, product) => {
                // Only include the quantity if the product is added
                if (product.isAdded) {
                    return total + product.quantity;
                }
                return total;
            },
            0
        );
        setProductQty(totalQuantityAdded);
    };


    const handleDistrictUserTypicalLayoutSave = () => {
        setShowAddButton(false);
        const selectedProducts = layout.products
            .filter(item => item.isAdded)
            .map(item => {
                const optionItemIds = item.options?.flatMap(option =>
                    option.optionItems.map(optionItem => optionItem.productOptionItemId)
                );

                return {
                    productId: item.product.id,
                    quantity: item.quantity,
                    optionItemIds: optionItemIds
                };
            });

        const cartTypicalLayoutModel = {
            districtId: ((geteditQuoteId != null && geteditQuoteId > 0) ? 0 : districtId ),
            typicalLayoutId: layoutId,
            products: selectedProducts,
            quoteId: (geteditQuoteId != null && geteditQuoteId > 0 ? geteditQuoteId:0)
        };

        addDistrictUserTypicalLayout(cartTypicalLayoutModel).then(response => {
            if (response.data.success === true) {
                dispatch(loadAllItems());
                navigate('/my-project');
            } else {
                setShowAddButton(true);
            }
        });
    };

    const handlCategory = (cat) => {
        navigate('/typical-layouts/category/' + cat.slug);
    }

    if (!layout) {
        return (<></>);
    }

    //const openLightbox = (layout) => {
    //    setLightboxImage(layout);
    //    setIsLightboxOpen(true);
    //};




    //const closeLightbox = () => {
    //    setIsLightboxOpen(false);
    //};


    return (
        <section>
            <div className="default-outer-wrapper typical-page">
                <div className="default-center">
                    <div className="page-header-wrap">
                        <div className="product-breadcrumbs-wrap">
                            <p>
                                <Link className="breadcrumb" to="/home">Home </Link>
                                &nbsp;/&nbsp;
                                <Link className="breadcrumb" to="/typical-layouts">Typical Layouts</Link>
                                &nbsp;/&nbsp;
                                <a className="breadcrumb category-name" onClick={() => handlCategory(layout.category)}>{layout.category.name}</a>
                            </p>
                        </div>
                        <div className="product-main-title-wrap">
                            <h1 className="product-main-title">{layout?.title}</h1>
                        </div>
                    </div>

                    <div className="page-section">
                        <div className="support-tips-wrap">

                            {descriptionList && descriptionList.map((desc, index) => (
                                <div className="support-tip-item" key={index}>
                                    {/*<div className="support-tip-icon">
                                        <span className="material-symbols-rounded">{desc?.icon}</span>
                                    </div>*/}
                                    <div className="support-tip-content">
                                        <p className="support-tip-desc">
                                            <span className="support-tip-title">{desc?.title}</span>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: desc.description,
                                                }}
                                            ></p>
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {layout.mediaItems.length > 0 &&
                        <div className="page-section slider-section">
                            <div className="room-slider-wrap">
                                <Slider {...settings}>
                                    {layout.mediaItems
                                        .filter(p => !p.mediaItem?.previewsrc?.includes("/static/media/")) // Filter out items with previewsrc containing "/static/media/"
                                        .map((p, index) => (
                                            <div onClick={() => openVideo(p.mediaItem, index)} key={p.mediaItem.id}>
                                                {p.mediaItem?.type == 1 && (
                                                    <div className="play-icon-button">
                                                        <img src={PlayIcon} alt="play" />
                                                    </div>

                                                )}
                                                <TypicalProductImageSlider
                                                    photo={p.mediaItem?.previewsrc}
                                                    photoAlt="test"
                                                />
                                            </div>

                                        ))}
                                </Slider>
                            </div>
                            {/*{*/}
                            {/*    isLightboxOpen && (*/}
                            {/*        <div className="lightbox" onClick={closeLightbox}>*/}
                            {/*            <span className="close">&times;</span>*/}
                            {/*            <img className="lightbox-content"  alt="test" />*/}
                            {/*        </div>*/}
                            {/*    )*/}
                            {/*}*/}
                        </div>
                    }
                    <div className="page-section typical-layout-product">
                        <div className="table-layout-wrap typical-table-layout-wrap">
                            <div className="my-product-title-row">
                                <div className="my-product-title-coll">&nbsp;</div>
                                <div className="my-product-title-coll product-details-title">Product Details</div>
                                <div className="my-product-title-coll product-quantity">Quantity</div>
                                <div className="my-product-title-coll">
                                    Unit Price
                                </div>
                                <div className="my-product-title-coll">
                                    Total

                                </div>
                                <div className="my-product-title-coll ">&nbsp;</div>
                                <div className="my-product-title-coll ">&nbsp;</div>
                            </div>

                            {layout.products
                                .filter(p => !p?.photo?.includes("/static/media/"))
                                .map((item, index) => (
                                   
                                    <React.Fragment key={index}>
                                        <TypicalProductDetail
                                            typicalDetail={item}
                                            handleTypicalQuantityChange={handleQuantityChange}
                                            handTypicalProdcutAdded={typicalProductAdded}
                                        />
                                    </React.Fragment>
                                ))}

                        </div>
                    </div>

                    <div className="page-section">
                        <div className="subtotal-wrap">
                            <div className="subtotal-inner">
                                <a href="javascript:;" onClick={restoreDefultTypicalLayout} className="subtotal-simple-link">
                                    Restore to Default
                                </a>
                                <div className="subtotal-cart-wrap typical-subtotal-wrap">
                                    <div className="subtotal-cart-item">
                                        <div className="subtotal-cart-row">
                                            <p className="subtotal-cart-title">Total Items:</p>
                                            <p className="subtotal-cart-desc">{productQty}</p>
                                        </div>
                                        <div className="subtotal-cart-row">
                                            <p className="subtotal-cart-title">Subtotal:</p>
                                            <p className="subtotal-cart-desc">{formatUSD(estimatedCost)}</p>
                                        </div>
                                    </div>
                                    <div className="subtotal-cart-item subtotal-buttons-wrap">
                                        {showAddButton && !editingTypicalLayout &&
                                            <a href="javascript:;" onClick={handleDistrictUserTypicalLayoutSave} className="add-cart-cta typical-add-button">
                                                Add to My Project
                                            </a>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                openModal && (
                    <div className="video-modal">
                        <div className="close-modal" onClick={openVideo}>
                            <span className="material-symbols-rounded">
                                close
                            </span>
                        </div>
                        <div className="video-event-wrap news-video">
                            {type == 0 && (<img src={previewImage} alt="video" className="video-box" />)}
                            {type == 1 && (
                                <iframe
                                    width="560"
                                    height="315"
                                    src={videoUrl}
                                    title=""
                                    allow="autoplay;"
                                    frameBorder="0"
                                    allowFullScreen
                                >
                                </iframe>
                            )}

                        </div>
                        {processedItems.length >0 && (
                            <div className="video-arrows-wrap">
                                <div className="arrow-modal prev" onClick={prevSlide}>
                                    <span className="arrow-wrap">
                                        <img src={prevArrow} alt="prev item" />
                                    </span>
                                </div>
                                <div className="arrow-modal next" onClick={nextSlide}>
                                    <span className="arrow-wrap">
                                        <img src={nextArrow} alt="next item" />
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                )
            }
        </section>
    );
}
