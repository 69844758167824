import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../../pages/cart/Cart.css';
import { Quantity } from './Quantity';
import CartButton from './CartButton';
import {
    removeItem,
    moveItemToWishlistAction,
    updateMyProjectQuantity,
    updateProductTag
} from '../../store/myproject-reducer';
import ProductImage from './ProductImage';
import { loadAllWishlistItems } from '../../store/mywishlist-reducer';
import { formatUSD, calculateFinalUnitPrice, formatProductCode } from '../../helpers/formatterHelper'
import { getImpersonationCheck } from '../../api/cartapi';
const MyProduct = ({ product, buttons, canEdit, canViewProductDetail, isTypicalLayout }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [tag, setTag] = useState(product.tag || '');

    const handleRemoveFromProject = () => {
        dispatch(removeItem(product.cartId));
    };
    const handleMoveToWishList = () => {
        dispatch(moveItemToWishlistAction(product.cartId));
        dispatch(loadAllWishlistItems());
    };

    const handleQuantityChange = (event) => {
        const selectedQuantity = parseInt(event.target.value, 10);
        dispatch(
            updateMyProjectQuantity({
                cartId: product.cartId,
                quantity: selectedQuantity,
            })
        );
    };
    const goToProduct = (product, cartId) => {
        if (canViewProductDetail) {
            if (isTypicalLayout === true) localStorage.setItem('isTypicallayoutProduct', true);
            else localStorage.removeItem("isTypicallayoutProduct");

            navigate('/products/' + product.slug + (cartId ? '?cartid=' + cartId : ''), { state: { from: 'results' } });
        }
    };

    const handleUpdateTag = (e) => {
        if (window.tagTimer != null) {
            clearInterval(window.tagTimer);
        }
        var t = e.target.value;
        setTag(t);
        var p = product;
        window.tagTimer = setTimeout(() => {
            dispatch(updateProductTag({ cartId: p.cartId, tag: t }));
        }, 2000);
    }

    if (!product) {
        return <h1 className="loading-data">Loading...</h1>;
    }
    return (
        <>
            <div className="my-products--table-row normal-metaPro">
                <div className="my-products--coll cart-img" onClick={() => goToProduct(product, product.cartId)}>
                    <div className="cart-grid-col cart-image-area">
                        <div className="cart-grid-image-wrap">
                            {product && product.photo ? (
                                <ProductImage
                                    photo={product.photo}
                                    photoAlt={product.photoAlt ?? product.details.name}
                                />
                            ) : (
                                <span className="errorImage">Image Coming Soon</span>
                            )}
                        </div>
                    </div>
                </div>
                
                <div className="my-products--coll cart-detail cart-grid-col product-description-title" onClick={() => goToProduct(product, product.cartId)}>
                    <div className="cart-grid-desc-wrap">
                        <p className="cart-desc-title bold-metaPro">
                            {product && product.details && product.details.name}
                        </p>
                        <p className="cart-desc-content">
                            {product && product.details && formatProductCode(product)}
                        </p>
                        {product.options &&
                            product.options.length > 0 &&
                            product.options.filter(x => x.type !== 2).map((option) => (
                                <React.Fragment key={option.id}>
                                    <p className="cart-desc-options">{option.title}&nbsp;:&nbsp;
                                        {option.items &&
                                            option.items.map((item) => (
                                                <React.Fragment key={item.id}>
                                                    {option.type === 2 ? item.color.name : item.title}
                                                </React.Fragment>
                                            ))}
                                    </p>
                                </React.Fragment>
                            ))}
                        {product.options &&
                            product.options.length > 0 &&
                            product.options.filter(x => x.type === 2).map((option) => (
                                <React.Fragment key={option?.id}>
                                    <p className="cart-desc-color">{option?.title}:&nbsp;
                                        {option.items.map((item) => {
                                            return (
                                                <span key={item?.color?.id}>
                                                    <span className="color-dots large color-select"
                                                        style={
                                                            item?.color?.colorImage
                                                                ? {
                                                                    backgroundImage: `url(${item.color?.colorImage?.previewsrc})`,
                                                                }
                                                                : { backgroundColor: item?.color?.hex }
                                                        }
                                                        htmlFor={item?.color?.id}
                                                    ></span>
                                                    <span style={{ 'marginLeft': '1.2rem' }}>{item?.color?.name}</span>
                                                </span>);
                                        })}
                                    </p>
                                </React.Fragment>
                            ))}
                    </div>
                </div>
                {localStorage.typicallayout != 'true' ?
                    canEdit ?
                    <div className="my-products--coll tag-box">
                        <input type="text" placeholder="Enter tag" aria-label="Tag" value={tag} onInput={handleUpdateTag} />
                        </div>
                        : <div className="my-products--coll">{tag}</div> : <></>
                }
                <div className="my-products--coll cart-quantity">
                    {canEdit ?
                        <Quantity
                            cartId={product.cartId}
                            currentQuantity={product.quantity}
                            setQuantity={handleQuantityChange}
                        /> : product.quantity}
                </div>
                <div className="my-products--coll cart-price " colSpan="2">
                    <div className="cart-grid-header-wrap price-label">
                        <div className="cart-grid-header-title">Unit Price</div>
                        <div className="cart-grid-header-title header-right">Total</div>
                    </div>
                    <div className="cart-total-wrap">
                        <div className="cart-datawrap">
                            <div className="cart-grid-total-price-wrap">
                                <div className="cart-grid-inner">
                                    <p className="mobile-price-label">Unit Price</p>
                                    <p className="cart-grid-total left-unit-price">
                                        {product?.details && formatUSD(calculateFinalUnitPrice(product))}
                                    </p>
                                </div>
                            </div>

                            <div className="cart-grid-unit-price-wrap">
                                <div className="cart-grid-inner">
                                    <p className="mobile-price-label">Total Price</p>
                                    <p className="cart-grid-total">
                                        {formatUSD(
                                            calculateFinalUnitPrice(product) * product.quantity
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="cart-grid-unit-buttons">
                            {buttons.wishlist && (!getImpersonationCheck() && localStorage.typicallayout !== 'true') ?
                                <CartButton message={'Move to Wishlist'} action={handleMoveToWishList} />
                                : <></>}
                            {buttons.delete ? <CartButton message={'Delete'} action={handleRemoveFromProject} cellClass={'delete-cell'} /> : <></>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default MyProduct;

MyProduct.propTypes = {
    product: PropTypes.object.isRequired,
    buttons: PropTypes.object,
    canEdit: PropTypes.bool,
    canViewProductDetail: PropTypes.bool,
    isTypicalLayout: PropTypes.bool,
};

MyProduct.defaultProps = {
    buttons: { wishlist: true, delete: true },
    canEdit: true
}

