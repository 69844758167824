import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ColorModal } from './colorModal';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ColorGroupModal } from './colorGroupModal';
import { ColorGroupSection } from './colorGroupSection';
import {
	loadColorGroups,
	resetSaveStatus,
} from '../../../store/colors-reducer';

export function EditManufacturerColors() {
	const dispatch = useDispatch();
	const { manufacturerId } = useParams();
	const [showAddColorModal, setShowAddColorModal] = useState(false);
	const [showAddColorGroupModal, setShowAddColorGroupModal] = useState(false);
	const colorGroups = useSelector(
		(state) => state.colors.colorGroupsWithColors
	);
	const saveStatus = useSelector((state) => state.colors.saveStatus);
	const saveError = useSelector((state) => state.colors.saveError);

	const [colorGroupId, setColorGroupId] = useState(null);
	const [showEditColorModal, setShowEditColorModal] = useState(false);
	const [editingColor, setEditingColor] = useState(null);
	const [showEditColorGroupModal, setShowEditColorGroupModal] = useState(false);
	const [existingGroup, setEditingColorGroup] = useState(null);
	const [showDeleteColorGroupModal, setShowDeleteColorGroupModal] =
		useState(false);

	const openAddColorModal = (id) => {
		setColorGroupId(id);
		setShowAddColorModal(true);
	};
	const openEditColorModal = (color) => {
		setEditingColor(color);
		setShowEditColorModal(true);
	};
	const openEditColorGroupModal = (colorGroup) => {
		setEditingColorGroup(colorGroup);
		setShowEditColorGroupModal(true);
	};
	const openDeleteColorGroupModal = (colorGroup) => {
		setEditingColorGroup(colorGroup);
		setShowDeleteColorGroupModal(true);
	};


	const handleColorModalClose = () => {
		dispatch(resetSaveStatus());
		setShowEditColorModal(false);
		setShowAddColorModal(false);
	}

	const handleGroupModalClose = () => {
		dispatch(resetSaveStatus());
		setShowDeleteColorGroupModal(false);
		setShowAddColorGroupModal(false);
		setShowEditColorGroupModal(false);
	}
	
	useEffect(() => {
		if (saveStatus === 'succeeded') {
			dispatch(loadColorGroups(manufacturerId));
			setTimeout(() => {
				dispatch(resetSaveStatus());
			}, 3000);
		}
	}, [saveStatus, dispatch]);

	return (
		<div>
			<div
				className="admin-tab-content active"
				id="color-tab"
				role="tabpanel"
				aria-labelledby="tab2"
			>
				<div className="admin-tab-top-action-wrap">
					<button
						className="admin-tab-top-button power"
						onClick={() => setShowAddColorGroupModal(true)}
					>
						<span className="admin-tab-top-button-text">Add Color Group</span>
						<span className="material-symbols-rounded" aria-hidden="true">
							add
						</span>
					</button>
				</div>

				{showAddColorModal && (
					<ColorModal
						show={showAddColorModal}
						onClose={handleColorModalClose}
						colorGroupId={colorGroupId}
						saveErrorMessage={saveError}
						saveStatus={saveStatus}
					/>
				)}
				{showEditColorModal && (
					<ColorModal
						show={showEditColorModal}
						onClose={handleColorModalClose}
						colorGroupId={editingColor ? editingColor.ColorGroupId : null}
						isEditMode={true}
						existingColor={editingColor}
						saveErrorMessage={saveError}
						saveStatus={saveStatus}

					/>
				)}

				{showAddColorGroupModal && (
					<ColorGroupModal
						onClose={handleGroupModalClose}
						manufacturerId={manufacturerId}
						saveErrorMessage={saveError}
						saveStatus={saveStatus}

					/>
				)}
				{showEditColorGroupModal && (
					<ColorGroupModal
						onClose={handleGroupModalClose}
						manufacturerId={manufacturerId}
						isEditMode={true}
						existingGroup={existingGroup}
						saveErrorMessage={saveError}
						saveStatus={saveStatus}

					/>
				)}
				{showDeleteColorGroupModal && (
					<ColorGroupModal
						onClose={handleGroupModalClose}
						manufacturerId={manufacturerId}
						isDeleteMode={true}
						existingGroup={existingGroup}
						saveErrorMessage={saveError}
						saveStatus={saveStatus}

					/>
				)}

				{colorGroups && (
					<ColorGroupSection
						colorGroups={colorGroups}
						openAddColorModal={openAddColorModal}
						openEditColorModal={openEditColorModal}
						openEditColorGroupModal={openEditColorGroupModal}
						openDeleteColorGroupModal={openDeleteColorGroupModal}
						loadColorGroups={() => dispatch(loadColorGroups(manufacturerId))}
					/>
				)}
			</div>
		</div>
	);
}
