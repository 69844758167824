import React, { useState } from 'react';
import HomeLayout from '../../layouts/HomeLayout';
import TabImg from '../../assets/tab-img.png'
import BrandLeft from '../../assets/brand-right.png'
import BrandRight from '../../assets/brand-left.png'
import Procurement from '../../assets/procurement-img.png'
import CheckCircle from '../../assets/check-circle.png'
import Standardize from '../../assets/icons/standardize.svg'
import Login from '../../assets/icons/login.svg'
import Track from '../../assets/icons/track.svg'
import Shop from '../../assets/icons/shop.svg'
import ArrowIcon from '../../assets/icons/arrow-icon.svg'
import Streamline from '../../assets/streamline.svg'
import RightBG from '../../assets/right-bg.png'
import LeftBG from '../../assets/left-bg.svg'
import RequestDemo from '../../components/requestDemo/requestDemo';
import TopBG from '../../assets/mo-brand-logos.svg'
import BottomBG from '../../assets/mo-brand-logos-bottom.svg'
import { updateTitle,  updateMetaDescription } from '../../helpers/titleHelper';
import './main.css';

export default function Main() {
    updateTitle('Comprehensive Facility Management Solutions');
    updateMetaDescription("Explore Facility Standard's innovative solutions for efficient facility management. Discover how our services can enhance operational efficiency and safety. Learn more now!");
    const [getFeature, setFeature] = useState(null);
    const [getRequest, setRequest] = useState('');

    const featureclick = (index) => {
        setFeature(index === getFeature ? null : index);
    };

    const requestDemo = () => {
        setRequest(!getRequest);
    };

    return (
        <HomeLayout isMainPage={true}>
            <>
                {
                    getRequest &&
                    <RequestDemo onClose={() => { setRequest(false); }}></RequestDemo>
                }
                <section className="banner-wrapper">
                    <div className="main-container main-hero-section">
                        <div className="banner-heading-container">
                            <div>
                                <a href="#" className="standard-btn">Introducing Facility Standard</a>
                            </div>
                            <div className="banner-title">
                                <h1 className="main-banner-title">
                                    Revolutionizing<br /> Facility Management
                                    <span>
                                        with Seamless Procurement Solutions
                                    </span>
                                </h1>
                                <p className="banner-discription">
                                    Unlock the power of advanced facility management technology through Facility Standard.
                                </p>
                            </div>

                            <div>
                                <button className="request-btn" onClick={requestDemo}>Contact</button>
                            </div>
                        </div>
                        <div className="tab-img-container">
                            <img src={TabImg} className="tab-img" alt="Facility Standard facility management" />
                        </div>
                    </div>
                </section>
                <div className="bg-section relative">
                    <div className="body-right-bg absolute">
                        <img src={RightBG} alt="background" className="w-100" />
                    </div>
                    {/*Features*/}
                    <section className="features-section">
                        <div className="main-container d-flex">
                            <div className="features-title-wrapper">
                                <p className="subheadline-btn black-text">Features</p>
                                <p className="fe-headline">
                                    Powerful Solutions<br /> Made Easy
                                </p>
                                <p className="features-title-discription">
                                    Save time and money with our custom portal. Select products you
                                    need at approved contract pricing, ensuring easy purchasing experience,
                                    standardization and compliance.
                                </p>
                            </div>
                            <div className="features-card-wrapper">
                                <div className={`features-card ${getFeature === 1 ? 'expandFeature' : ''}`}
                                    onClick={() => featureclick(1)}
                                >
                                    <div className="feature-card-inner-wrapper">
                                        <div className="arrow-circle">
                                            <img src={ArrowIcon} alt="arrow" />
                                        </div>
                                        <div className="feature-img">
                                            <img src={Streamline} alt="stramline" />
                                        </div>
                                        <p className="feature-point">01</p>
                                        <p className="feature-card-title">Improve Efficiency and Tranparency</p>
                                        <p className="feature-discription">
                                            Digitize and automate manual paper-based processes for enhanced procurement and
                                            finance efficiency and decision-making.
                                        </p>
                                    </div>
                                </div>
                                <div className={`features-card ${getFeature === 2 ? 'expandFeature' : ''}`}
                                    onClick={() => featureclick(2)}
                                >
                                    <div className="feature-card-inner-wrapper">
                                        <div className="arrow-circle">
                                            <img src={ArrowIcon} alt="arrow" />
                                        </div>
                                        <div className="feature-img">
                                            <img src={Streamline} alt="stramline" />
                                        </div>
                                        <p className="feature-point">02</p>
                                        <p className="feature-card-title">Generate Hard Dollar Savings</p>
                                        <p className="feature-discription">
                                            Enjoy volume discount pricing through long-term contracts based on your
                                            facility plan.Leverage pre-negotiatedapproved contracts that have undergone
                                            the (RFP) process.
                                        </p>
                                    </div>
                                </div>
                                <div className={`features-card ${getFeature === 3 ? 'expandFeature' : ''}`}
                                    onClick={() => featureclick(3)}
                                >
                                    <div className="feature-card-inner-wrapper">
                                        <div className="arrow-circle">
                                            <img src={ArrowIcon} alt="arrow" />
                                        </div>
                                        <div className="feature-img">
                                            <img src={Streamline} alt="stramline" />
                                        </div>
                                        <p className="feature-point">03</p>
                                        <p className="feature-card-title">Streamline Sourcing Process</p>
                                        <p className="feature-discription">
                                            Adopt facility-wide standards and collaborate across multiple locations. Provide
                                            a cost-effective, all-in-one shopping experience for your stakeholders.
                                        </p>
                                    </div>
                                </div>
                                <div className={`features-card ${getFeature === 4 ? 'expandFeature' : ''}`}
                                    onClick={() => featureclick(4)}
                                >
                                    <div className="feature-card-inner-wrapper">
                                        <div className="arrow-circle">
                                            <img src={ArrowIcon} alt="arrow" />
                                        </div>
                                        <div className="feature-img">
                                            <img src={Streamline} alt="stramline" />
                                        </div>
                                        <p className="feature-point">04</p>
                                        <p className="feature-card-title">Enhance Compliance and Reporting</p>
                                        <p className="feature-discription">
                                            Ensure strict adherence to pre-negotiated public contracts, run compliance
                                            reports with a single click, and monitor supplier adherence to contracts and
                                            pricing.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/*Choose Facility Standard*/}
                    <section className="choose-section">
                        <div className="main-container d-flex">
                            <div className="standard-title-wrapper">
                                <p className="subheadline-btn">
                                    Superior Service
                                </p>
                                <p className="fe-headline">
                                    Why Choose Facility Standard
                                </p>
                            </div>
                            <div className="standard-point-wrapper">
                                <div className="standard-point">
                                    <img src={CheckCircle} alt="check" className="standard-bullet-point" />
                                    <p className="standard-point-title">Simplified Procurement Process</p>
                                    <p className="standard-point-dis">
                                        Create a consumer-like, one-stop marketplace for products you need to run your
                                        organization. Trusted suppliers, competitively awarded contracts, and simplified
                                        transactions ensure cost savings and compliance.
                                    </p>
                                </div>
                                <div className="standard-point">
                                    <img src={CheckCircle} alt="check" className="standard-bullet-point" />
                                    <p className="standard-point-title">Efficient Competition Requirements</p>
                                    <p className="standard-point-dis">
                                        Meet competition requirements effortlessly. Facility Standard simplifies how you
                                        connect, contract, and transact, through the use of approved contracts keeping
                                        your organization efficient and focused on its mission.
                                    </p>
                                </div>
                                <div className="standard-point">
                                    <img src={CheckCircle} alt="check" className="standard-bullet-point" />
                                    <p className="standard-point-title">Better Control and Visibility</p>
                                    <p className="standard-point-dis">
                                        Gain control and visibility into your FF&E spending with our digitized and automated processes.
                                        Ensure transparency and accountability to stakeholders, auditors, and taxpayers with easy
                                        access to spending data, manufacturer list prices and discounts.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/*User-Friendly Features*/}
                    <section className="user-friendly-section">
                        <div className="main-container d-flex">
                            <div className="user-friendly-title-wrapper">
                                <p className="subheadline-btn black-text">
                                    User-Friendly Features
                                </p>
                                <p className="fe-headline">
                                    Fueling Transformative Partnerships
                                </p>
                                <p className="user-friendly-discription">
                                    Choose Facility Standard for a transformative approach to facility management,
                                    where simplicity meets efficiency, and your organization thrives.
                                </p>
                            </div>
                            <div className="user-friendly-wrapper">
                                <div className="user-friendly-card-wrapper">
                                    <div className="user-card">
                                        <div className="card-box">
                                            <div className="card-icon">
                                                <img src={Standardize} alt="list icon Standardize" />
                                            </div>
                                            <p className="card-title">Standardize</p>
                                        </div>
                                        <p className="card-discription">
                                            Create standards for products across your facilities.
                                        </p>
                                    </div>
                                    <div className="user-card">
                                        <div className="card-box">
                                            <div className="card-icon">
                                                <img src={Login} alt="Lock icon Login" />
                                            </div>
                                            <p className="card-title">Login</p>
                                        </div>
                                        <p className="card-discription">
                                            Users simply log into your individualized marketplace and search
                                            for what they need.
                                        </p>
                                    </div>
                                    <div className="user-card">
                                        <div className="card-box">
                                            <div className="card-icon">
                                                <img src={Track} alt="Location icon Track" />
                                            </div>
                                            <p className="card-title">Track</p>
                                        </div>
                                        <p className="card-discription">
                                            Easily access and track approvals and purchase orders in the Facility Standard
                                            portal. Create easy reports for compliance and auditing.
                                        </p>
                                    </div>
                                    <div className="user-card">
                                        <div className="card-box">
                                            <div className="card-icon">
                                                <img src={Shop} alt="Cart icon Shop" />
                                            </div>
                                            <p className="card-title">Shop</p>
                                        </div>
                                        <p className="card-discription">
                                            Explore a marketplace of top-quality, pre-approved manufacturers with
                                            a single cart.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="body-left-bg absolute">
                        <img src={LeftBG} alt="background" className="w-100" />
                    </div>
                </div>

                {/*brand headline*/}
                <section className="brand-wrapper d-flex">
                    <div className="brand-img-box">
                        <img src={BrandRight} alt="brand logo" className="desktop-brand-logo" />
                        <img src={TopBG} alt="brand logo" className="mo-brand-logo" />
                    </div>

                    <div className="brand-box">
                        <div className="subheadline-btn">Business Relationships</div>
                        <p className="brand-headline fe-headline">Fueling Transformative Partnerships</p>
                        <p className="brand-discription">
                            Facility Standard fosters thriving human connections, supporting long-term,
                            mutually beneficial relationships for buyers, sellers, and manufacturer partners.
                            Together, we drive shared value and unlock growth for all.
                        </p>
                    </div>
                    <div className="brand-img-box">
                        <img src={BrandLeft} alt="brand logo" className="desktop-brand-logo" />
                        <img src={BottomBG} alt="brand logo" className="mo-brand-logo" />
                    </div>
                </section>

                {/*Procurement section*/}
                <section className="procurement-wrapper">
                    <div className="main-container procurement-row d-flex ">
                        <div className="w-50">
                            <img src={Procurement} alt="Facility Standard portal example" className="w-100" />
                        </div>
                        <div className="w-50">
                            <p className="pro-headline fe-headline">
                                Try Portal Live
                            </p>
                            <p className="pro-discription">
                                Experience the joy of standardizing your facilities.
                            </p>
                            <div style={{ "padding": "10px 0", "float":"left"}}>
                                <a href="/demo" className="solid-primary-btn" style={{'padding':'15px 50px'} }>Try It Now</a>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        </HomeLayout>
    );
}