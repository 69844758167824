

export const ProductItem = {
    id: 0,
    productLineId: 0,
    isDeleted: false,
    details: {
        name: '',
        mfgPartNumber: '',
        freedomCode: '',
        price: 0.00,
        shortDescription: '',
        description: '',
        specifications: '',
        contractDiscount: 0.00,
        netPrice: 0.00
    },
    media: [],
    documents: [],
    categories: [],
    options: []
}