import { bindActionCreators } from "@reduxjs/toolkit";
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getEmailAuth } from '../../store/auth/actions';
import { useNavigate } from 'react-router-dom';
import { updateTitle } from '../../helpers/titleHelper';

export default function EmailAuth() {
    updateTitle('Authenticate Account');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { hash } = useParams();
    const getEmail = bindActionCreators(getEmailAuth, dispatch);
    const [message, setMessage] = useState("");
    useEffect(() => {
        const fetchData = async () => {
            if (hash && hash !== undefined) {
                let data = {
                    EmailAuthToken: hash
                };
                try {
                    const result = await getEmail({ data: data }).unwrap();
                    if (result.success) {
                        if (result.data.isAdmin) navigate('/admin');
                        else navigate("/home");
                    } else {
                        console.error("Error:");
                        setMessage(result.message)
                    }
                } catch (error) {
                    console.error("Error:", error);
                }
            }
        };

        fetchData();

        return () => { };

    }, []);

    return (
        <>
            {message ? (
                message
            ) : (
                <div>
                    Loading Token
                </div>
            )
            }
        </>
    );
}