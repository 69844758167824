import React, { useState, useEffect } from 'react';
import {
	getAllDistrictProducts,
	getAllDistrictProductOptionItems,
	getAllManufacturerWithProducts,
	addProduct,
	removeProduct,
	addProductLine,
	removeProductLine,
	addProductOptionItems
} from '../../../../api/districtproductsapi';
import { getProduct } from '../../../../api/productapi';
import { AdminModal } from '../../../../components/admin/admin-modal';
import PropTypes from 'prop-types';
const ManufacturerList = ({
	districtId,
	manufacturers,
	fetchProductDetails,
	handleSelection,
	selectedProducts,
	handleBulkSelection,
	selectedAllProductOptions
}) => {
	return (
		<div>
			{manufacturers &&
				manufacturers.map((manufacturer) => {
					return (
						<div key={manufacturer.id} className="admin-product-checkbox-section">
							<h2 className="admin-section-headline">{manufacturer.name}</h2>
							<div className="admin-column-wrap allow-wrapping">
								{manufacturer.productLines.filter(a => a.products.length > 0).map((productLine) => {
									var hasAllProducts = productLine.products.filter(a => selectedProducts.filter(b => a.id == b).length > 0).length == productLine.products.length;
									return (
										<div key={productLine.id} className="admin-column">
											<p className="admin-section-small-headline">
												{productLine.name}
											</p>
											<label
												className="admin-inline-label"
												htmlFor={`bulk-${productLine.id}`}
											>
												<input
													className="admin-inline-checkbox"
													id={`bulk-${productLine.id}`}
													type="checkbox"
													checked={hasAllProducts}
													onChange={() => {
														if (!hasAllProducts) {
															addProductLine(districtId, productLine.id)
														} else {
															removeProductLine(districtId, productLine.id)
														}
														handleBulkSelection(
															productLine.id,
															productLine.products,
															!hasAllProducts
														);
													}}
												/>
												{/*<span className="checkmark"></span>*/}
												<span className="admin-inline-label-text">
													{hasAllProducts
														? 'Clear All'
														: 'Select All'}
												</span>
											</label>
											<div
												style={{
													width: '100%',
													height: '100%',
													border: '0.50px #D9D9D9 solid',
												}}
											></div>

											{productLine.products &&
												productLine.products.map((product) => {
													const productOptionItems = product.optionItems.length;
													const selectedOptionItems = product.optionItems && product.optionItems.length > 0 ? selectedAllProductOptions.filter(a => product.optionItems.filter(b => b == a).length > 0).length : 0;
													const selected = selectedProducts.filter(a => a == product.id).length > 0;
													return (
														<span className={'admin-inline-label' + (productOptionItems > selectedOptionItems && selectedOptionItems > 0 ? ' ind-dash' : '')} key={product.id}>
															<input
																className="admin-inline-checkbox"
																id={product.id}
																type="checkbox"
																checked={selected}
																value={product.name}
																onChange={handleSelection}
																onClick={(e) => e.stopPropagation()}
															/>
															{/*<span className="checkmark"></span>*/}
															<span className="admin-inline-label-text product-label"
																onClick={() => fetchProductDetails(product.id)}
															>
																{product.productTitle}
																{productOptionItems > 0 && selected ?
																	<span className="total-items">({selectedOptionItems}/{productOptionItems})</span>
																	: <></>
																}
															</span>
														</span>
													)
												})}
										</div>
									)
								})}
							</div>
						</div>
					)
				})}
		</div>
	);
};

ManufacturerList.propTypes = {
	districtId: PropTypes.number,
	manufacturers: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
			productLines: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.number.isRequired,
					name: PropTypes.string.isRequired,
					products: PropTypes.arrayOf(
						PropTypes.shape({
							id: PropTypes.number.isRequired,
							productTitle: PropTypes.string.isRequired,
							name: PropTypes.string,
						})
					),
				})
			),
		})
	),
	fetchProductDetails: PropTypes.func.isRequired,
	handleSelection: PropTypes.func.isRequired,
	selectedProducts: PropTypes.array.isRequired,
	handleBulkSelection: PropTypes.func,
	selectedAllProductOptions: PropTypes.array
};

export default function DistrictProducts({ districtId }) {
	const [manufacturers, setManufacturers] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [selectedProducts, setSelectedProducts] = useState([]);
	const [viewProduct, setViewProduct] = useState({});
	const [showModal, setShowModal] = useState(false);
	const [selectedProductOptions, setSelectedProductOptions] = useState([]);
	const [selectedAllProductOptions, setSelectedAllProductOptions] = useState([]);

	useEffect(() => {
		getAllDistrictProducts(districtId)
			.then((response) => {
				const selectedProductIds = response.data.data.map(
					(product) => {
						return product.productId
					}
				);
				setSelectedProducts(selectedProductIds);

				getAllDistrictProductOptionItems(districtId)
					.then((response2) => {
						setSelectedAllProductOptions(response2.data.data);
					})
					.catch((err) => {
						console.error('Error fetching district product option items:', err);
						setError(err);
					});
				
			})
			.catch((err) => {
				console.error('Error fetching district products:', err);
				setError(err);
			});

		getAllManufacturerWithProducts()
			.then((response) => {
				setManufacturers(response.data.data);
			})
			.catch((err) => {
				console.error('Error fetching manufacturers:', err);
				setError(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [districtId]);

	const fetchProductDetails = (productId) => {
		getProduct(productId)
			.then((response) => {
				let product = response.data.data;
				setViewProduct(product);
				var options = product.options ? product.options.filter(a => a.items != null && a.items.length > 0).map(a => a.items) : [];
				var optionItems = [];
				options.forEach(a => optionItems.push(...a));
				var selectedItems = selectedAllProductOptions.filter(a => optionItems.filter(b => b.id == a).length > 0);
				setSelectedProductOptions(selectedItems);
				if (selectedProducts.filter(a => a == productId).length == 0) {
					addProduct(districtId, productId);
					setSelectedProducts((prev) => [...prev, productId]);
				}
				setShowModal(true);
			})
			.catch((err) => {
				console.error('Error fetching district products:', err);
				setError(err);
			});
	};
	const onClose = () => {
		setShowModal(false);
	};
	const handleViewedProductSave = () => {
		addProductOptionItems(districtId, selectedProductOptions.filter(a => a != null));
		setShowModal(false);
	};

	const handleSelection = (event, bulkUpdates = []) => {
		if (bulkUpdates.length) {
			setSelectedProducts(bulkUpdates);
		} else {
			const productId = parseInt(event.target.id);
			if (selectedProducts.includes(productId)) {
				removeProduct(districtId, productId);
				setSelectedProducts((prev) => prev.filter((id) => id !== productId));
			} else {
				addProduct(districtId, productId);
				setSelectedProducts((prev) => [...prev, productId]);
			}
		}
	};

	const handleBulkSelection = (productLineId, products, shouldSelect) => {
		if (shouldSelect) {
			const newSelections = products.map((product) => product.id);
			//select product options where no options for a given product were previously selected
			var productLine = [...manufacturers.map(a => a.productLines).reduce((a, b) => [...a, ...b])].filter(a => a.id == productLineId)[0];
			var optionItems = productLine.products ? productLine.products.filter(a => (a.optionItems && a.optionItems.length > 0 &&
				a.optionItems.filter(b => selectedAllProductOptions.filter(c => c == b).length > 0).length == 0)
				|| !a.optionItems || a.optionItems.length == 0
			).map(a => a.optionItems) : [];
			if (optionItems.length > 0) {
				var options = [...optionItems.reduce((a, b) => [...a, ...b])];
				handleBulkOptionsSelection(options, true);
			}
			//select products
			handleSelection(null, [...selectedProducts, ...newSelections]);
		} else {
			const idsToRemove = products.map((product) => product.id);
			const updatedSelections = selectedProducts.filter(
				(id) => !idsToRemove.includes(id)
			);
			setSelectedProducts(updatedSelections);
		}
	};

	const handleProductOptionsSelection = (event) => {
		const itemId = parseInt(event.target.id.replace('optionitem', ''));
		if (selectedProductOptions.includes(itemId)) {
			setSelectedProductOptions((prev) =>
				prev.filter((id) => id !== itemId)
			);
			setSelectedAllProductOptions((prev) =>
				prev.filter((id) => id !== itemId)
			);

		} else {
			setSelectedProductOptions((prev) => [...prev, itemId]);
			setSelectedAllProductOptions((prev) => [...prev, itemId]);
		}
	};

	const handleBulkOptionsSelection = (itemIds, shouldSelect) => {
		setSelectedProductOptions(selectedProductOptions.filter((id) => !itemIds.includes(id)));
		setSelectedAllProductOptions((prev) =>
			prev.filter((id) => itemIds.filter(a => a == id).length == 0)
		);
		if (shouldSelect) {
			setSelectedProductOptions((prev) => [...prev, ...itemIds]);
			setSelectedAllProductOptions((prev) => [...prev, ...itemIds]);
		}
	};

	if (loading) return <p>Loading...</p>;
	if (error) return <p>Error: {error.message}</p>;
	return (
		<div className="admin-tab-content">
			<ManufacturerList
				districtId={Number(districtId)}
				manufacturers={manufacturers}
				fetchProductDetails={fetchProductDetails}
				handleSelection={handleSelection}
				selectedProducts={selectedProducts}
				handleBulkSelection={handleBulkSelection}
				selectedAllProductOptions={selectedAllProductOptions }
			/>
			<AdminModal
				show={showModal}
				onClose={onClose}
				onSave={handleViewedProductSave}
				modalTitle={viewProduct.details ? viewProduct.details.name : ''}
				adminModalClass="large grow"
			>
				{viewProduct &&
				viewProduct.options &&
				viewProduct.options.length === 0 ? (
					<div className="admin-modal-row full">
						<div className="admin-modal-cell full">
							<p>No options selected.</p>
						</div>
					</div>
				) : (
					<div className="admin-modal-row full">
						<div className="admin-modal-cell full">
							<div className="admin-column-wrap allow-wrapping">
								{viewProduct &&
									viewProduct.options &&
										viewProduct.options.filter(a => a.items && a.items.length > 0).map((option) => {
											const selectedAll = selectedAllProductOptions.filter(a => option.items.filter(b => b.id == a).length > 0).length == option.items.length;
											return (
												<div key={option.id} className="admin-column">
													<p className="admin-section-small-headline">
														{option.title}
													</p>
													<label
														className="admin-inline-label"
														htmlFor={`bulk-${option.id}`}
													>
														<input
															className="admin-inline-checkbox"
															id={`bulk-${option.id}`}
															type="checkbox"
															checked={selectedAll}
															onChange={() =>
																handleBulkOptionsSelection(
																	option.items.map((item) => item.id),
																	!selectedAll
																)
															}
														/>
														<span className="admin-inline-label-text">
															{selectedAll
																? 'Clear All'
																: 'Select All'}
														</span>
													</label>
													<div
														style={{
															width: '100%',
															height: '100%',
															border: '0.50px #D9D9D9 solid',
														}}
													></div>
													{option.items &&
														option.items.map((item) => (
															
															<label
																className="admin-inline-label"
																htmlFor={'optionitem' + item.id}
																key={'optionitem' + item.id}
															>
																<input
																	className="admin-inline-checkbox"
																	id={'optionitem' + item.id}
																	type="checkbox"
																	value={item.name}
																	checked={selectedAllProductOptions.includes(item.id)}
																	onChange={handleProductOptionsSelection}
																/>
																<span className="admin-inline-label-text">
																	{option.type == 2 && item.color ? item.color.name : item.title}
																</span>
															</label>
														))}
												</div>
											)
										})}
							</div>
						</div>
					</div>
				)}
			</AdminModal>
		</div>
	);
}

DistrictProducts.propTypes = {
	districtId: PropTypes.string,
};
