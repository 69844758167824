import api from '../utils/api';

const getDistrictId = () => {
    return localStorage.getItem('districtId');
};

export const getAll = () => {
    return api({
        url: '/api/district-user/wishlist/get-all/' + getDistrictId(),
        method: 'GET'
    });
};

export const add = (productId, quantity, optionItemIds) => {
    return api.post('/api/district-user/wishlist/add',
        {
            districtId: getDistrictId(),
            productId: productId,
            quantity: quantity,
            optionItemIds: optionItemIds,
        });
};

export const update = (cartId, quantity) => {
    return api.post('/api/district-user/wishlist/update',
        {
            districtId: getDistrictId(),
            cartId: cartId,
            quantity: quantity
        });
};

export const remove = (cartId) => {
    return api.post('/api/district-user/wishlist/remove',
        {
            districtId: getDistrictId(),
            cartId: cartId
        });
};

export const moveToCart = (cartId) => {
    var quoteId = localStorage.getItem('editQuoteId');
    return api.post('/api/district-user/wishlist/moveto-cart',
        {
            districtId: quoteId != null && quoteId > 0 ? 0 : getDistrictId(),
            cartId: cartId
        });
};