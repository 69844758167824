import AdminLayout from '../layouts/AdminLayout.jsx';
import Admin from '../pages/admin/Admin.js';
import EditManufacturers from '../pages/admin/manufacturers/EditManufacturers.js';
import { Manufacturers } from '../pages/admin/manufacturers/manufacturers';
import { Products } from '../pages/admin/products/products';
import { Districts } from '../pages/admin/districts/districts';
import EditDistrictDetail from '../pages/admin/districts/districtdetails';
import { Categories } from '../pages/admin/categories/Categories';
import { UserIndex, UserEdit } from '../pages/admin/users';
import QuotesPage from '../pages/admin/quotes';
import { AddEditTypicalLayout } from '../pages/admin/typicallayout/addEditTypicalLayouts.js';
import { AddEditSurvey } from '../pages/admin/survey/addEditSurvey.js';
import { Reports } from '../pages/admin/reports';


export const adminRoutes = [
	// Admin Pages
	{
		path: '/admin',
		element: Admin,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/admin/manufacturers',
		element: Manufacturers,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/admin/districts',
		element: Districts,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/admin/manufacturers-products/edit',
		element: EditManufacturers,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/admin/manufacturers-products/edit/:manufacturerId/:openTab?',
		element: EditManufacturers,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/admin/products/:manufacturerId/create',
		element: Products,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/admin/products/:manufacturerId/edit/:productId/:versionDate?',
		element: Products,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/admin/districts/edit/:districtId/:openTab?/:detailsId?',
		element: EditDistrictDetail,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/admin/categories/:openTab?',
		element: Categories,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/admin/users',
		element: UserIndex,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/admin/users/add',
		element: UserEdit,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/admin/users/edit/:userId',
		element: UserEdit,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/admin/quotes',
		element: QuotesPage,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/admin/reports',
		element: Reports,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/admin/districts/edit/:districtId/typical-layout/create',
		element: AddEditTypicalLayout,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/admin/districts/edit/:districtId/typical-layout/:typicalid',
		element: AddEditTypicalLayout,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/admin/districts/edit/:districtId/survey/create',
		element: AddEditSurvey,
		layout: AdminLayout,
		adminPage: true
	},
	{
		path: '/admin/districts/edit/:districtId/survey/:surveyId',
		element: AddEditSurvey,
		layout: AdminLayout,
		adminPage: true
	},
]