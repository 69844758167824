import React, { useEffect } from 'react';
import './contact.css';
import Banner from '../../components/common/ShowBannerDetails';
import { updateTitle } from '../../helpers/titleHelper';
import { loadSiteUserAccount, getAllSiteUsersList } from '../../store/district-account-reducer';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';


export default function Contact() {
    updateTitle('Contact Us');
    const dispatch = useDispatch();
    const districtId = localStorage.getItem('districtId');
    const districtSiteUser = useSelector(getAllSiteUsersList);
    useEffect(() => {
        if (districtId !== null) {
            dispatch(loadSiteUserAccount(districtId));
        }
    }, [districtId, dispatch]);

    return (
        <div className="contact-wrapper">
            <Banner/>
            <div className="default-center">
                <div className="page-header-wrap">
                    <h1 className="product-main-title">Contact</h1>
                </div>
                <div className="contact-blocks-wrapper">
                    <div className="contact-blocks-wrap">
                         <div className="contact-blocks">
                            <p className="contact-title">Facility Standard</p>
                            <p>4000 Washington Kansas City, MO 64111</p>
                            <a href="tel:+816-768-6179">(816) 768-6179</a>
                        </div>
                    </div>
                    <div className="contact-blocks-wrap">
                        {districtSiteUser && districtSiteUser.length > 0 ? (
                            districtSiteUser.map((districtuser, index) => (
                                <div className="contact-blocks" key={index}>
                                    <p className="contact-title">{districtuser?.siteUsers?.fullName}</p>
                                    <p>{districtuser.role}</p>
                                    {districtuser?.siteUsers?.phone && (
                                        <a href={`tel:${districtuser.siteUsers.phone}`}>
                                            {districtuser.siteUsers.phone}
                                        </a>
                                    )}
                                    <a href={`mailto:${districtuser?.siteUsers?.email}`} className="contact-email">{districtuser?.siteUsers?.email}</a>
                                </div>
                            ))
                        ) : (<></>)}
                    </div>
                </div>
            </div>
            </div>
    );
}
