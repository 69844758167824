import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import AdminTextInput from '../../../components/admin/admin-text-input.js';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import CustomerEditor from '@ckeditor/ckeditor5-build-classic';
import { saveSurvey, editSurvey } from '../../../store/survey/actions.js';
import { useDispatch } from 'react-redux'; //useSelector
import { getById } from '../../../api/surveyapi';
import AdminError from '../../../components/admin/admin-error';

const ckEditorConfig = {
    toolbar: [
        'Bold',
        'Italic'
    ],
};
export function AddEditSurvey() {
    const { districtId } = useParams();
    const { surveyId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const defaultObj = { id : 0, isActive: false, title: '', description: '', links: '' };
    const [surveyItem, setSurveyItem] = useState(defaultObj);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [error, setError] = useState('');
    const [errorLink, setErrorLink] = useState('');
    const [validation, setValidation] = useState({
        title: true,
        links: true
    });
    const handleSurveySave = () => {
        let isValid = true;
        const validate = {
            title: true,
            links: true
        };

        let data = {
            Id: surveyItem .id,
            Title: surveyItem.title,
            Description: surveyItem.description,
            Links: surveyItem.links,
            IsActive: surveyItem.isActive,
            DistrictId: districtId
        }
        if (!data.Title || data.Title.trim() === '') {
            validate.title = false;
            isValid = false;
        }
     
        if (!data.Links || data.Links.trim() === '') {
            validate.links = false;
            isValid = false;
        } else {
            const urlRegex = /^https:\/\/.+/i;
            if (!urlRegex.test(data.Links)) {
                validate.links = false;
                isValid = false;
                setErrorLink('Please enter a valid URL starting with "https://".');
            }
        }

        setValidation({ ...validation, ...validate });
        if (!isValid) {
            return;
        } 
        if (data.Id > 0) {
            dispatch(editSurvey(data));
        } else {
            dispatch(saveSurvey(data));
        }    
        navigate('/admin/districts/edit/' + districtId + '/surveys')
    };

    useEffect(() => {
        if (surveyId != undefined && surveyId > 0) {
            getById(surveyId).then(response => {
                if (response.data.success === true) {
                    let surveyResponse = response.data.data;
                    setSurveyItem(surveyResponse);
                }
            });
        }
    }, [surveyId]);

    return (
        <div className="admin-page-wrap">
            <div className="admin-top-bar-wrap">
                <div className="admin-top-bar-left">
                    <Link
                        className="admin-top-bar-back"
                        to={`/admin/districts/edit/${districtId}/surveys`}
                    >
                        Back
                    </Link>
                </div>
            </div>
            <div className="admin-page-content">

                <h1 className="admin-page-heading">Add / Edit Survey</h1>

                <AdminError
                    message={error}
                    onClose={() => {
                        setError('');
                    }}
                ></AdminError>

                <div className="admin-modal-row full">
                    <div className="admin-modal-cell">
                        <label className="admin-radio-label">
                            <input
                                className="admin-radio"
                                type="radio"
                                id="active"
                                name="status"
                                checked={!surveyItem.isActive}
                                onChange={() => {
                                    setSurveyItem({
                                        ...surveyItem,
                                        isActive: false
                                    });
                                }}
                            />
                            Active
                        </label>
                    </div>
                    <div className="admin-modal-cell">
                        <label>
                            <input
                                className="admin-radio"
                                type="radio"
                                id="inactive"
                                name="status"
                                checked={surveyItem.isActive}
                                onChange={() => {
                                    setSurveyItem({
                                        ...surveyItem,
                                        isActive: true
                                    });
                                }}
                            />
                            InActive
                        </label>
                    </div>

                </div>

                <div className="admin-section">
                    <div className="admin-section-row">
                        <div className="admin-section-cell wide">
                            <AdminTextInput
                                label="Title*"
                                id="title"
                                value={surveyItem.title ?? ''}
                                onChange={(value) => {
                                    setSurveyItem({
                                        ...surveyItem, title: value
                                    });
                                    setValidation({ ...validation, title: value != '' });
                                }}
                                validated={validation.title}
                            />
                        </div>
                    </div>
                </div>
                <div className="admin-section-row">
                    <div
                        className="admin-section-cell extra-wide"
                    >
                        <label className="admin-default-label">
                            Description
                        </label>
                        <CKEditor
                            editor={CustomerEditor}
                            config={ckEditorConfig}
                            data={surveyItem.description || ''}
                            onChange={(event, editor) => {
                                const data = editor.getData();                              
                                if (!isInitialLoad) {
                                    setSurveyItem({
                                        ...surveyItem,
                                        description: data
                                    });
                                }
                                setIsInitialLoad(false);
                            }}
                        />
                    </div>
                </div>
                
                <div className="admin-section">
                    <div className="admin-section-row">
                        <div className="admin-section-cell full">
                            <AdminTextInput
                                label="Link*"
                                id="links"
                                value={surveyItem.links || ''}
                                onChange={(value) => {
                                    setSurveyItem({
                                        ...surveyItem,
                                        links: value
                                    });
                                    setValidation({ ...validation, links: value != '' });
                                }}    
                                validated={validation.links}
                            />
                            {validation.links === false && errorLink!= '' ?
                                <p className="validation-error-message">{errorLink}</p>
                                :
                               ''
                            }
                        </div>
                    </div>
                </div>

                <div className="admin-floating-button-wrap">
                    <button
                        className="admin-floating-button"
                        onClick={() => navigate('/admin/districts/edit/' + districtId + '/surveys')}
                    >
                        Cancel
                    </button>
                    <button
                        className="admin-floating-button power"
                        onClick={handleSurveySave}
                    >
                        Save Changes
                    </button>
                </div>
            </div>
        </div>
    );
}

