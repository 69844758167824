import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

const TypicalProductImage = ({ photo, photoAlt, styles }) => {
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        if (photo) {
            const image = new Image();
            image.src = photo;
            image.onload = () => {
                setHasError(false);
            };
            image.onerror = () => {
                setHasError(true);
            };
        } else {
            setHasError(true);
        }
    }, [photo]);

    return (
        <div className={'prod-grid-image-wrap'}>
            {hasError ? (
                <span className="errorImage">Image Coming Soon</span>
            ) : (
                <img src={photo} alt={photoAlt} className={styles} />
            )}
        </div>
    );
};

TypicalProductImage.propTypes = {
    photo: PropTypes.string,
    photoAlt: PropTypes.string,
    styles: PropTypes.string
};

export default TypicalProductImage;