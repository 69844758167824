import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import './typicalindex.css';
//components
import ProductImage from '../../components/common/ProductImage';
//api
import { getByDistrictId } from '../../api/typicallayoutsapi';
import { allCategories, getBySlug } from '../../api/categoriesapi';
//helpers
import { checkForDistrictId, isDemoDistrict, getDistrictPath } from '../../helpers/checkForDistrictId';
import { updateTitle, updateMetaDescription } from '../../helpers/titleHelper';

export default function TypicalLayouts() {
	const { slug } = useParams();
	const navigate = useNavigate();
	const [filter, setFilter] = useState({
		start: 1,
		orderby: 1,
		length: 100,
		categoryIds: []
	});
	const [categories, setCategories] = useState();
	const [typicalLayouts, setTypicalLayouts] = useState({ total: -1, results: [] });
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [scroll, setScroll] = useState({
		hasScroll: false,
		foundBottom: false,
		totalTypicalLayouts: 0,
	});
	const filterRef = React.useRef(filter);
	const scrollRef = React.useRef(scroll);
	const [isFetching, setIsFetching] = useState(false);

	useEffect(() => {
		if (slug != null && slug != '') {
			getBySlug(slug, 1).then(response => { //type = 1 (typical layouts category group)
				if (response.data.success == true) {
					setFilter({ ...filter, categoryIds: [response.data.data.id] });
				}
			});
		}
	}, [slug]);

	useEffect(() => {
		if (categories && categories.length > 0) { return; }
		
		allCategories('', 1)
			.then((cats) => {
				setCategories(cats.data.data[0]);
			})
			.catch((err) => {
				setError('Error fetching categories: ' + err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	});

	useEffect(() => {
		checkForDistrictId(navigate); //redirect to admin/districts if user is not logged into district
		let districtID = localStorage.getItem('districtId');
		if (localStorage.typicallayout === 'true') {
			let typicalDetailList = JSON.parse(localStorage.getItem('editTypicalLayout')) || [];
			districtID = typicalDetailList.districtId;
		}
		let getProductfiltervalue = localStorage.getItem('productfiltervalue');

		let orderValue = getProductfiltervalue != undefined && getProductfiltervalue != null && getProductfiltervalue != '' ? getProductfiltervalue : '1';
		
		filterRef.orderby = orderValue;
		filterRef.categoryIds = filter.categoryIds;
		if (slug != null && slug != '' && filter.categoryIds.length == 0) { return; }
		getByDistrictId(
			districtID,
			filter
		)
			.then((response) => {
				if (response.data.success == true) {
					var p = response.data.data;
					var prods = {
						total: 0,
						results:
							typicalLayouts.results.length > 0
								? [...typicalLayouts.results, ...p]
								: p,
					};
					setIsFetching(false);
					setTypicalLayouts({ ...prods });

					if (filter.start === 1) {
						document.body.scrollTo({ top: 0, behavior: 'smooth' });
					}
				}
			})
			.catch((err) => {
				setError('Error fetching district products: ' + err);
			});
	}, [filter]);

	useEffect(() => {
		if (typicalLayouts && typicalLayouts.results) {
			let plen = typicalLayouts.results.filter((a) => a.details).length;
			if (plen > scroll.totalTypicalLayouts) {
				setScroll({ ...scroll, foundBottom: false, totalTypicalLayouts: plen });
				scrollRef.foundBottom = false;
				scrollRef.totalTypicalLayouts = plen;
			}
		}
	}, [typicalLayouts]);

	const fetchMoreTypicalLayouts = () => {
		if (filter.start < typicalLayouts.total && !isFetching) {
			setIsFetching(true);
			setFilter((prev) => ({ ...prev, start: prev.start + prev.length }));
		}
	};

	const loadCategory = (cat) => {
		setTypicalLayouts({ total: -1, results: [] });
		setFilter({ ...filter, categoryIds: [cat.id] });
		history.pushState(null, 'Typical Layouts - ' + cat.name, "/typical-layouts/category/" + cat.slug + (isDemoDistrict() ? '?org=' + getDistrictPath() : ''));
	}

	const goToTypicalLayout = (product) => {	
		var cat = categories.flat(1).filter(a => a.id == product.categoryId)[0];
		navigate(`/typical-layout/${cat.slug}/${product.slug}` + (isDemoDistrict() ? '?org=' + getDistrictPath() : ''), { state: { from: 'results' } });		
	};

	const CategoryList = () => {
		updateTitle('Typical Facility Layouts');
		updateMetaDescription("Explore Facility Standard's typical facility layouts designed for optimal efficiency and safety. Discover innovative designs that meet your facility's needs. Learn more!");
		return <div id="content" className="typical-layout-main-wrapper">
			<div className="default-outer-wrapper">
				<div className="default-center">
					<div className="page-header-wrap">
						<div className="product-breadcrumbs-wrap">
							<p>
								<Link className="breadcrumb" to="/home">Home </Link>
								&nbsp;/&nbsp;
								<span className="breadcrumb">Typical Layouts</span>
							</p>
						</div>

						<div className="product-main-title-wrap">
							<h1 className="product-main-title">Typical Layouts</h1>
						</div>
					</div>
					<div className="nav-block-wrap">
					{categories.map((item) => {
						return (
							<a key={'cat_' + item.id} href="javascript:" onClick={() => { loadCategory(item); }} className="nav-block-item">
								<span className="nav-block-img-wrap">
								{item.photo && item.photo !== '' && <img src={item.photo} alt={item.name} className="nav-block-cover-thumb" />}
								</span>
								<span className="nav-block-content">
									<span className="nav-block-title">{item.name}</span>
								</span>
							</a>
						);
					})}
					</div>
				</div>
			</div>
		</div>
	};

	const handleBackToCategories = () => {
		setFilter({ ...filter, categoryIds: [] });
		navigate('/typical-layouts' + (isDemoDistrict() ? '?org=' + getDistrictPath() : ''));
	}

	const ProductList = () => {
		var category = categories.filter(a => a.id == filter.categoryIds[0])[0];
		if (category == null || category.name == null) { return <></>; }
		updateTitle('Typical Layouts - ' + category.name);
		return (
			<div id="content" className="typical-layout-main-wrapper">
				<div className="default-outer-wrapper">
					<div className="default-center">
						<div className="page-header-wrap">
							<div className="product-breadcrumbs-wrap">
								<p>
									<Link className="breadcrumb" to="/home">Home </Link>
									&nbsp;/&nbsp;
									<a className="breadcrumb" onClick={handleBackToCategories}>Typical Layouts</a>
									&nbsp;/&nbsp;
									<span className="breadcrumb">{category.name}</span>
								</p>
							</div>

							<div className="product-main-title-wrap">
								<h1 className="product-main-title">{category.name}</h1>
							</div>
						</div>

						<div className="product-inner-item">
							<div className="product-gallery-inner">
								{typicalLayouts && typicalLayouts.results && typicalLayouts.results.length > 0 ? (
									typicalLayouts.results
										.filter(p => !p.mediaItems?.mediaItem?.previewsrc?.includes("/static/media/")) // Filter out items with previewsrc containing "/static/media/"
										.sort((a, b) => a.title.localeCompare(b.title))
										.map((p) => (
											<div key={p.id} className="product-gallery-item" onClick={() => goToTypicalLayout(p)}>
												<span className={'product-item-image favorite'}>
													<ProductImage
														photo={p.mediaItems[0]?.mediaItem?.previewsrc}
														photoAlt={p.title}
														styles="cover-thumb"
													/>
												</span>
												<span className="product-item-content">
													<span className="product-title-amount-wrap">
														<span className="product-item-title">{p.title}</span>
													</span>
												</span>

											</div>
										))
								) : typicalLayouts.total == 0 ? (
									<div className="no-results">No Results Found</div>
									) : <div className="no-results">Loading...</div>}
								<div className="product-detail-loader-spinner">
									{isFetching ? <div className="spinner"></div> : ''}
								</div>
							</div>
							<ObserverTarget onIntersect={fetchMoreTypicalLayouts} />
						</div>
					</div>
				</div>
			</div>
		);
	};

	if (error) {
		return <h1>{error}</h1>;
	}
	if (isLoading) {
		return <div className="loading">Loading...</div>;
	}

	if (filter.categoryIds.length == 0) {
		return CategoryList();
	} else {
		return ProductList();
	}
}

function ObserverTarget({ onIntersect }) {
	const ref = React.useRef();

	React.useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				const firstEntry = entries[0];
				if (firstEntry.isIntersecting) {
					onIntersect();
				}
			},
			{ threshold: 1.0, rootMargin: '0px' }
		);

		const currentRef = ref.current;
		if (currentRef) {
			observer.observe(currentRef);
		}

		return () => {
			if (currentRef) {
				observer.unobserve(currentRef);
			}
		};
	}, [onIntersect]);

	return <div ref={ref}></div>;
}

ObserverTarget.propTypes = {
	onIntersect: PropTypes.func.isRequired,
};
