import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import intlTelInput from 'intl-tel-input';
import { formatPhoneNumber } from '../../helpers/formatterHelper';
import { validatePhone } from '../../helpers/validationHelper';
import '../../styles/intlTelInput.css';

export default function AdminPhone({
    id, value, label, loaded, onChange, onValidation, isRequired, requiredText, validated
}) {
    const [showError, setShowError] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const phoneInputRef = useRef(null);
    const [phoneNumber, setPhone] = useState(value);

    if (onValidation == null) { onValidation = () => { return true; }; }

    useEffect(() => {
        if (loaded == true && isLoading == true) {
            const iti = intlTelInput(phoneInputRef.current, {
                initialCountry: 'us',
                separateDialCode: true,
                customPlaceholder: () => { return '555-555-5555'; },
                utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js"
            });
            iti.setNumber(formatPhoneNumber(value));
            setPhone(formatPhoneNumber(value));

            phoneInputRef.current.addEventListener('input', () => {
                setPhone(formatPhoneNumber(iti.getNumber()));
            });

            phoneInputRef.current.addEventListener('countrychange', () => {
                setPhone(formatPhoneNumber(iti.getNumber()));
            });
            setLoading(false);
            return () => {
                iti && iti.destroy();
            };
        }
    }, [loaded]);

    useEffect(() => {
        if (onChange) {
            onChange(phoneNumber);
        }
        var valid = phoneNumber == '' ? true : validatePhone(phoneNumber);
        setShowError(!valid);
        if (onValidation) {
            onValidation(valid);
        }
    }, [phoneNumber]);

    return (
        <div className={"admin-default-input-text-wrap" + (showError || validated === false ? " input-validation-error" : "")}>
            <label
                className="admin-default-label"
                htmlFor={id}
            >
                {label}{isRequired ? "*" : ""}
            </label>
            <input id={id} className="admin-default-input-text" ref={phoneInputRef} type="tel" autoComplete="off" />

            {showError || validated === false ? <span className="validation-error-message">{requiredText}</span> : <></>}
        </div>
    );
}

AdminPhone.propTypes = {
    id: PropTypes.string,
    value: PropTypes.node,
    label: PropTypes.string,
    loaded: PropTypes.bool,
    onChange: PropTypes.func,
    onValidation: PropTypes.func,
    isRequired: PropTypes.bool,
    requiredText: PropTypes.string,
    validated: PropTypes.bool
}

AdminPhone.defaultProps = {
    isRequired: false,
    validated: true
}
