import api from '../utils/api';

export const saveSurvey = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/survey/add`,
        method: "POST",
        data: data,
        ...options,
    });
}
export const editSurvey = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/survey/edit`,
        method: "POST",
        data: data,
        ...options,
    });
}

export const deleteSurvey = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/survey/delete/${data}`,
        method: "DELETE",
        data: data,
        ...options,
    });
}

export const getSurveyList = (config = {}) => {
    const { data, options } = config;
    return api({
        url: `api/survey/get-all-survey`,
        method: "POST",
        data: data,
        ...options,
    });
}
export const getById = (id) => {
    return api({
        url: '/api/survey/get/' + id,
        method: 'GET',
    });
}