import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import NavMenu from '../components/navigation/nav-menu';
import Footer from '../components/footer';
import { Banner } from '../components/common/Banner';
import { useDispatch, useSelector } from 'react-redux';
import {  controlBannerWithButtonAndMessage } from '../store/layout-banner-reducer';
import { loadAllItems } from '../store/myproject-reducer';
import { loadAllWishlistItems } from '../store/mywishlist-reducer';
import ScrollToTop from '../components/common/ScrollToTop';

export default function DefaultLayout({ children }) {
	const dispatch = useDispatch();
	const isImpersonating = useSelector(
		(state) => state.districtSiteUsers.isImpersonation
	);
	const isBannerVisible = useSelector(
		(state) => state.layoutBanner.isBannerVisible
	);

	useEffect(() => {
		let isTypicallayoutAUI = (localStorage.typicallayout == 'true' ? true : false);
		dispatch(loadAllItems(isTypicallayoutAUI));
		dispatch(loadAllWishlistItems());
	}, []);

	useEffect(() => {
		const storedImpersonationDetails = sessionStorage.getItem(
			'impersonationDetails'
		);
			if (storedImpersonationDetails) {
				const item = JSON.parse(storedImpersonationDetails);
				dispatch(controlBannerWithButtonAndMessage({message:item.message,isVisible:true,isButtonShown:true}));
			}
		
	}, [isBannerVisible, isImpersonating]);
	return (
		<div className="page-content">
			<ScrollToTop />
			<Banner />
			<NavMenu />
			{children}
			<Footer />
		</div>
	);
}

DefaultLayout.propTypes = {
	children: PropTypes.any,
};
