import api from '../utils/api';

export const getImpersonationCheck = () => !!sessionStorage.getItem('impersonationDetails');

export const getImpersonationDetails = () => {
    const data = sessionStorage.getItem('impersonationDetails');
    return JSON.parse(data);
}

const getDistrictId = () => {
    var quoteId = localStorage.getItem('editQuoteId');
    if (quoteId != null && quoteId > 0) { return 0; }
    return localStorage.getItem('districtId');
};


export const getAll = () => {
    var quoteId = localStorage.getItem('editQuoteId');
    let userId;
    if (getImpersonationCheck()) {
        const impersonationDetails = getImpersonationDetails();
        userId = impersonationDetails && impersonationDetails.userImpersonatingID;
    }

    if (quoteId === 'undefined') quoteId = null;
    return api.post('/api/district-user/cart/get-all',
        {
            districtId: getDistrictId(),
            quoteId: quoteId ?? 0,
            userId: userId
        });
};

export const add = (productId, quantity, optionItemIds) => {
    let userId;
    if (getImpersonationCheck()) {
        const impersonationDetails = getImpersonationDetails();
        userId = impersonationDetails && impersonationDetails.userImpersonatingID;
    }
    return api.post('/api/district-user/cart/add',
        {
            districtId: getDistrictId(),
            productId: productId,
            quantity: quantity,
            optionItemIds: optionItemIds,
            userId: userId
        });
};

export const getAllProduct = (productListLocal) => {
    return api.post('/api/typical-layouts/get-all-product', productListLocal);
};

export const update = (cartId, quantity) => {
    let userId;
    if (getImpersonationCheck()) {
        const impersonationDetails = getImpersonationDetails();
        userId = impersonationDetails && impersonationDetails.userImpersonatingID;
    }
    return api.post('/api/district-user/cart/update',
        {
            districtId: getDistrictId(),
            cartId: cartId,
            quantity: quantity,
            userId: userId
        });
};

export const updateTag = (cartId, tag) => {
    let userId;
    if (getImpersonationCheck()) {
        const impersonationDetails = getImpersonationDetails();
        userId = impersonationDetails && impersonationDetails.userImpersonatingID;
    }
    return api.post('/api/district-user/cart/update-tag',
        {
            districtId: getDistrictId(),
            cartId: cartId,
            tag: tag,
            userId: userId
        });
};

export const remove = (cartId) => {
    let userId;
    if (getImpersonationCheck()) {
        const impersonationDetails = getImpersonationDetails();
        userId = impersonationDetails && impersonationDetails.userImpersonatingID;
    }
    return api.post('/api/district-user/cart/remove',
        {
            districtId: getDistrictId(),
            cartId: cartId,
            userId: userId
        });
};

export const complete = () => {
    let userId;
    if (getImpersonationCheck()) {
        const impersonationDetails = getImpersonationDetails();
        userId = impersonationDetails && impersonationDetails.userImpersonatingID;
    }
    return api.post('/api/district-user/cart/complete',
        {
            districtId: getDistrictId(),
            userId: userId
        });
};

export const updateQuote = () => {
    return api.post('/api/district-user/cart/update-quote',
        {
            quoteId: localStorage.getItem('editQuoteId')
        });
};

export const moveToWishlist = (cartId) => {
    return api.post('/api/district-user/cart/moveto-wishlist',
        {
            districtId: getDistrictId(),
            cartId: cartId
        });
};

///////////////////
// Typical Layouts

export const getTypicalLayouts = () => {
    var quoteId = localStorage.getItem('editQuoteId');
    let userId;
    if (getImpersonationCheck()) {
        const impersonationDetails = getImpersonationDetails();
        userId = impersonationDetails && impersonationDetails.userImpersonatingID;
    }

    if (quoteId === 'undefined') quoteId = null;
    return api.post('/api/district-user/cart/get-typical-layouts',
        {
            districtId: getDistrictId(),
            quoteId: quoteId ?? 0,
            userId: userId
        });
};

export const updateTypicalLayoutLabel = (id, label) => {
    var quoteId = localStorage.getItem('editQuoteId');
    let userId;
    if (getImpersonationCheck()) {
        const impersonationDetails = getImpersonationDetails();
        userId = impersonationDetails && impersonationDetails.userImpersonatingID;
    }

    if (quoteId === 'undefined') quoteId = null;
    return api.post('/api/district-user/cart/update-typical-layout',
        {
            districtId: getDistrictId(),
            quoteId: quoteId ?? 0,
            userId: userId,
            id: id,
            label: label
        });
};

export const updateTypicalLayoutQuantity = (id, quantity) => {
    var quoteId = localStorage.getItem('editQuoteId');
    let userId;
    if (getImpersonationCheck()) {
        const impersonationDetails = getImpersonationDetails();
        userId = impersonationDetails && impersonationDetails.userImpersonatingID;
    }

    if (quoteId === 'undefined') quoteId = null;
    return api.post('/api/district-user/cart/update-typical-layout',
        {
            districtId: getDistrictId(),
            quoteId: quoteId ?? 0,
            userId: userId,
            id: id,
            quantity: quantity
        });
};

export const removeTypicalLayout = (id, editquoteId, type) => {
    let quoteId = localStorage.getItem('editQuoteId');
    if (type == 'Quote_Delete') {
        quoteId = editquoteId;
    }
    let userId;
    if (getImpersonationCheck()) {
        const impersonationDetails = getImpersonationDetails();
        userId = impersonationDetails && impersonationDetails.userImpersonatingID;
    }

    if (quoteId === 'undefined') quoteId = null;
    return api.post('/api/district-user/cart/remove-typical-layout',
        {
            districtId: getDistrictId(),
            quoteId: quoteId ?? 0,
            userId: userId,
            id: id
        });
};

//////////////////////////////////////////////////////////////////////////////////////////////////
// Cart Label

export const updateUserCartLabel = (label) => {
    var quoteId = localStorage.getItem('editQuoteId');
    let userId = null;
    if (getImpersonationCheck()) {
        const impersonationDetails = getImpersonationDetails();
        userId = impersonationDetails && impersonationDetails.userImpersonatingID;
    }

    if (quoteId === 'undefined') quoteId = null;
    return api.post('/api/district-user/cart/update-cart-label',
        {
            districtId: getDistrictId(),
            quoteId: quoteId ?? 0,
            userId: userId,
            label: label
        });
};
export const GetUserCartLabel = () => {
    var quoteId = localStorage.getItem('editQuoteId');
    let userId = null;
    if (getImpersonationCheck()) {
        const impersonationDetails = getImpersonationDetails();
        userId = impersonationDetails && impersonationDetails.userImpersonatingID;
    }

    if (quoteId === 'undefined') quoteId = null;
    return api.post('/api/district-user/cart/get-cart-label',
        {
            districtId: getDistrictId(),
            quoteId: quoteId ?? 0,
            userId: userId
        });
};

//////////////////////////////////////////////////////////////////////////////////////////////////
// Cart Notes

export const updateUserCartNotes = (notes) => {
    var quoteId = localStorage.getItem('editQuoteId');
    let userId = null;
    if (getImpersonationCheck()) {
        const impersonationDetails = getImpersonationDetails();
        userId = impersonationDetails && impersonationDetails.userImpersonatingID;
    }

    if (quoteId === 'undefined') quoteId = null;
    return api.post('/api/district-user/cart/update-cart-notes',
        {
            districtId: getDistrictId(),
            quoteId: quoteId ?? 0,
            userId: userId,
            ...notes
        });
};
export const GetUserCartNotes = () => {
    var quoteId = localStorage.getItem('editQuoteId');
    let userId = null;
    if (getImpersonationCheck()) {
        const impersonationDetails = getImpersonationDetails();
        userId = impersonationDetails && impersonationDetails.userImpersonatingID;
    }

    if (quoteId === 'undefined') quoteId = null;
    return api.post('/api/district-user/cart/get-cart-notes',
        {
            districtId: getDistrictId(),
            quoteId: quoteId ?? 0,
            userId: userId
        });
};
