import api from '../utils/api';

export const getDistrictSiteUsers = (districtid) => {
    return api({
        url: '/api/districtaccount/get-all/' + (districtid ?? ''),
        method: 'GET',
    });
}

export const submitDistrictSiteUser = (districtSiteUser) => {
    let url = '/api/districtaccount/add';
    return api.post(url, districtSiteUser);
}

export const editDistrictSiteUser = (districtSiteUser) => {
    let url = '/api/districtaccount/edit';
    return api.post(url, districtSiteUser);
}

export const deleteDistrictSiteUser = (districtId, siteUserId) => {
    return api({
        url: `/api/districtaccount/delete/${districtId}/${siteUserId}`,
        method: 'DELETE',
    });
};

export const getUsers = () => {
    return api({
        url: '/api/adminusers/get-all',
        method: 'GET',
    })
}

export const getAllUsers = () => {
    return api({
        url: '/api/adminusers/get-all-users-list',
        method: 'GET',
    })
}