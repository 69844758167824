import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import { localstore } from '../utils/localForageStorage';
import logger from 'redux-logger';

import authReducer from './auth';
import manufacturersReducer from './manufacturer-reducer';
import loadingReducer from './loading-reducer'
import districtsReducer from './districts-reducer';
import categoriesReducer from './categories-reducer';
import colorsReducer from './colors-reducer';
import districtAccountReducer from './district-account-reducer';
import layoutBannerReducer from './layout-banner-reducer';
import myProjectReducer from './myproject-reducer';
import myWishlistReducer from './mywishlist-reducer';
import surveyReducer from './survey';
import districtReducer from './district-reducer';
const authPersistConfig = {
	key: 'root',
	storage: localstore
}

const rootReducer = combineReducers({
	auth: persistReducer(authPersistConfig, authReducer),
	manufacturers: manufacturersReducer,
	loading: loadingReducer,
	districts: districtsReducer,
	categories: categoriesReducer,
	colors: colorsReducer,
	districtSiteUsers: districtAccountReducer,
	layoutBanner:layoutBannerReducer,
	myProject:myProjectReducer,
	myWishlist: myWishlistReducer,
	surveyList: surveyReducer,
	district: districtReducer
})

export const store = configureStore({
    reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			immutableCheck: true,
			serializableCheck: false,
		}).concat(logger),
	devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store)
