import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import District from '../pages/district';
import AccessDenied from '../pages/account/accessdenied';
//import Main from '../pages/main/main.jsx';

import { accountRoutes } from './Accountrouter';
import { appRoutes } from './Approuter';
import { adminRoutes } from './Adminrouter';

import PropTypes from 'prop-types';
import { getDistrictByPath } from '../api/districtapi';
import { bindActionCreators } from "@reduxjs/toolkit";
import { demoUserLogin } from '../store/auth/actions';

function Element({ element, layout, secure, adminPage }) {
	const { isAuthenticated, isAdmin } = useSelector((state) => state.auth);
	const Component = element;
	const Layout = layout;
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	useEffect(() => {
		if (adminPage && !isAdmin) {
			navigate('/account/login');
		}
	}, []);

	useEffect(() => {
		if (secure && !isAuthenticated) {
			//user is not authenticated
			if (window.location.toString().indexOf('?org=') > 0) {
				//check for demo district and authenticate anonymous user if needed
				let params = new URL(document.location.toString()).searchParams;
				let org = params.get("org");
				if (org != null && org != '') {
					getDistrictByPath(org).then(response => {
						if (response.status == 200 && response.data.success == true) {
							var district = response.data.data;
							if (district != null) {
								if (district.isDemo == true) {
									localStorage.setItem('district', JSON.stringify(district));
									localStorage.setItem('districtId', district.id);

									//authenticate user
									if (localStorage.getItem('demoUserId') == 'undefined') {
										localStorage.setItem('demoUserId', '');
									}
									const login = bindActionCreators(demoUserLogin, dispatch);
									login({ districtId: district.id }).unwrap().then(result => {
										if (result.data && result.data.success == true) {
											//navigate(window.location.pathname, { replace: true }); //redirect demo user to product page (without org querystring)
										} else {
											navigate('/account/login', { state: { from: location } });
										}
									});
								} else {
									//navigate(window.location.pathname, { replace: true }); //redirect demo user to product page (without org querystring)
								}
							} else {
								navigate('/account/login', { state: { from: location } });
							}
						} else {
							navigate('/account/login', { state: { from: location } });
						}
					});
				} else {
					navigate('/account/login', { state: { from: location } });
				}
			}
			else navigate('/account/login', { state: { from: location } });
		}
	}, [isAuthenticated]);

	if (adminPage && !isAdmin) return null;
	else if (secure && !isAuthenticated) return null;
	else {

		//make sure demo district URLs contain ?org=
		var districtJson = localStorage.getItem('district');
		if (typeof districtJson == 'string') {
			var district = JSON.parse(districtJson);
			if (district.isDemo == true) {
				if (window.location.href.indexOf('?org=' + district.path) < 0) {
					history.replaceState(null, null, window.location.pathname + '?org=' + district.path);
				}
			}
		}

		if (Layout) {
			return (
				<Layout>
					<Component />
				</Layout>
			);
		} else {
			return (
				<Component />
			);
		}
	}
}

Element.propTypes = {
	element: PropTypes.any,
	layout: PropTypes.any,
	secure: PropTypes.bool,
	adminPage: PropTypes.bool
};

Element.defaultProps = {
	secure: true,
	adminPage: false
}


export const createRoutes = (routesData) =>
	routesData.map((route) => {
		if (route.routes?.length) {
			const indexRoute = route.routes.find((r) => r.index);
			return (
				<Route
					key={route.path}
					path={route.path}
					element={<Element {...route} />}
					index={route.index}
				>
					{!!indexRoute && (
						<Route index element={<Element {...route} />} />
					)}
					{createRoutes(route.routes)}
				</Route>
			);
		}
		return (
			<Route
				key={route.path}
				path={route.path}
				element={<Element {...route} />}
				index={route.index}
			/>
		);
	});

export default function AppRouter() {
	return (
		<Routes>
			{createRoutes(accountRoutes)}
			{createRoutes(appRoutes)}
			{createRoutes(adminRoutes)}

			<Route
				path='access-denied'
				element={<AccessDenied />}
			/>
			<Route
				path='*'
				element={<District />}
			/>
    </Routes>);
}
