import { format } from 'date-fns';
export const formatDateTimeForUserTimeZone = (utcDateTimeString) => {
    try {
        const formatOfDate = "MM/dd/yyyy @ hh:mm a";
        const dateParsed = getLocalDateTime(utcDateTimeString);
        const formatedDate = format(dateParsed, formatOfDate);
        return formatedDate;
    } catch (error) {
        console.error(error);
        return "";
    }
};

export const formatDateForUserTimeZone = (utcDateTimeString, formatOfDate) => {
    try {
        const dateParsed = getLocalDateTime(utcDateTimeString);
        const formatedDate = format(dateParsed, formatOfDate);
        return formatedDate;
    } catch (error) {
        console.error(error);
        return "";
    }
};

export const DateTimeForUserTimeZone = (utcDateTimeString) => {
    try {
        const formatOfDate = "MM/dd/yyyy, hh:mm:ss a";
        const dateParsed = getLocalDateTime(utcDateTimeString);
        const formatedDate = format(dateParsed, formatOfDate);
        return formatedDate;
    } catch (error) {
        console.error(error);
        return "";
    }
};


function getLocalDateTime(utcDateTimeString) {
    const utcDateTime = new Date(utcDateTimeString);

    // Get the local date and time
    const localDateTime = new Date(utcDateTime);
    const offset = localDateTime.getTimezoneOffset();
    localDateTime.setMinutes(localDateTime.getMinutes() - offset);

    return localDateTime;
}