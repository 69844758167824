import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Loader from '../../../components/Loader.js';
import { getUsers, deleteUser } from '../../../api/adminuserapi.js'
import { DateTimeForUserTimeZone } from '../../../helpers/DateTimeFormatter';
import { updateTitle } from '../../../helpers/titleHelper';

export default function UserIndexPage() {
    updateTitle('Admin', 'Users');
    const navigate = useNavigate();
    const [loadingData, setLoadingData] = useState(true);
    const [users, setUsers] = useState([]);
    const [showDelete, setShowDelete] = useState(null);
    const [deleteError, setDeleteError] = useState(null);
    useEffect(() => {
        getUsers()
            .then((response) => {
                if (response.data.success)
                    setUsers(response.data.data)
                setLoadingData(false);
            });
    }, [loadingData])

    const handleConfirmRemoveUser = () => {
        deleteUser(showDelete.id).then(response => {
            if (response.data.success == true) {
                setShowDelete(null);
                setLoadingData(true);
            } else {
                setDeleteError('Error: could not delete the user');
            }
        });
    };

    const handleDeleteSiteUser = (email, id) => {
        setShowDelete({ email: email, id: id });
    }

    return (
        <div className="admin-page-wrap">
            {showDelete != null &&
                <div className="admin-modal-overlay">
                    <div className="admin-modal">
                        <button className="admin-modal-close" onClick={() => { setShowDelete(null); }}>
                            <span className="material-symbols-rounded">close</span>
                        </button>
                        <h2 className="admin-modal-heading">Remove User</h2>
                        {deleteError != null &&
                            <div className="validation-error-message" style={{ 'padding': '5px 0' }}>{deleteError}</div>    
                        }
                        <div className="admin-form-cell">
                            <p className="admin-default-input-text-wrap">
                                Are you sure you want to remove the admin account for {showDelete.email}?
                            </p>
                        </div>
                        <div className="admin-modal-button-wrap">
                            <button className="admin-modal-button" onClick={() => { setShowDelete(null); }}>
                                Cancel
                            </button>
                            <button className="admin-form-button power red" onClick={handleConfirmRemoveUser}>
                                Remove User
                            </button>
                        </div>
                    </div>
                </div>
            }
            <div className="admin-top-bar-wrap">
                <div className="admin-top-bar-left">
                    <Link className="admin-top-bar-back" to="/admin/">
                        Back
                    </Link>
                </div>

            </div>
            <div className="admin-page-content">
                <div className="admin-page-title-wrap">
                    <h1 className="admin-page-heading">Admin Users</h1>
                    <button className="admin-button power"
                        onClick={() => navigate('/admin/users/add')}
                    >Add User</button>
                </div>
                {loadingData ? <Loader /> : 
            
                    <table className="admin-table">
                        <thead>
                            <tr><th className="admin-table-name-cell">Name</th>
                                <th className="admin-table-name-cell">Email</th>
                                <th className="admin-table-name-cell">Phone</th>
                                <th className="admin-table-name-cell">Last Login</th>
                                <th className="admin-table-action-cell">&nbsp;</th>

                            </tr>
                        </thead>
                        <tbody>
                            {users.length > 0 && users.map((user, idx) =>
                                <tr key={'user_' + idx}>
                                    <td>{user.fullName}</td>
                                    <td>{user.email}</td>
                                    <td>{user.phone}</td>
                                    <td>{user.lastLogin == null ? "--" : DateTimeForUserTimeZone(user.lastLogin)}</td>


                                    <td className="admin-table-action-cell">
                                        <button className="admin-table-action" onClick={() => navigate('/admin/users/edit/' + user.id)} >
                                            <span className="material-symbols-rounded">edit</span>
                                        </button>
                                    </td>
                                    <td className="admin-table-action-cell account-manager">
                                        <button className="admin-table-action"
                                            onClick={() => handleDeleteSiteUser(user.email, user.id)}>
                                            <span className="material-symbols-rounded">delete</span>
                                        </button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
            }
            </div>
        </div>
    );
}