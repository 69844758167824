import React, {  } from 'react';
import PropTypes from 'prop-types';

export default function LayoutTypeItem({ imagePath, imageAlt, name, url }) {
    return (
        <a href={url} className="nav-block-item">
        <span className="nav-block-img-wrap">
          {imagePath && imagePath !== '' && <img src={imagePath} alt={(imageAlt !== '' ? imageAlt : name+" image")} className="nav-block-cover-thumb" />}
            </span>
            <span className="nav-block-content">
                <span className="nav-block-title">{name}</span>
            </span>
        </a>
    );
}

LayoutTypeItem.propTypes = {
    imagePath: PropTypes.any,
    imageAlt: PropTypes.any,
    name: PropTypes.any,
    url: PropTypes.any
};