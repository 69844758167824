import React, { useEffect } from 'react';
import { updateTitle } from '../../helpers/titleHelper';


export default function AccessDenied() {
    updateTitle('Access Denied');
  useEffect(() => {
  }, [])

  return (<>
    <p>Access Denied</p>
  </>)
}
