import React from 'react';
import PropTypes from 'prop-types';

export function Quantity({ currentQuantity, setQuantity, maxQuantity }) {
    const quantity = 1000;
    const length = maxQuantity != null ? (quantity > maxQuantity ? maxQuantity : quantity) : quantity;
    return (
        <div className="cart-grid-select-wrap prod-shipping-select">
            <div className="cart-select-wrapper arrow-button">
                <select
                    value={currentQuantity}
                    className="cart-grid-select cart-select-arrow"
                    onClick={(e) => { e.stopPropagation(); }}
                    onChange={setQuantity}
                >
                    {Array.from({ length: length }, (_, index) => (
                        <option key={index + 1} value={index + 1}>{index + 1}</option>
                    ))}
                </select>
            </div>
        </div>
    );
}
Quantity.propTypes = {
    currentQuantity: PropTypes.number,
    setQuantity: PropTypes.func,
    maxQuantity: PropTypes.number
};