import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { loadDistricts, getDistricts } from "../../../store/districts-reducer";
import { showLoading, hideLoading, delayedHideLoading } from '../../../store/loading-reducer';
export function TypicalDistrictCopyModal({ show, onClose, handleTypicalCopyDistrict, model }) {
    const [formData, setFormData] = useState({});
    const dispatch = useDispatch();
    const districts = useSelector(getDistricts);
    const [validationError, setValidationError] = useState(false);

    useEffect(() => {
        setFormData(model);
        dispatch(showLoading());
        dispatch(loadDistricts())
            .then(() => {
                dispatch(delayedHideLoading());
            })
            .catch(() => {
                dispatch(hideLoading());
            });

    }, [dispatch, show]);


    const handleOnClose = () => {
        setValidationError(false); 
        setFormData({});
        onClose(); // Close the modal           
    };

    const handleSave = () => {
        if (formData.typicalLayoutId && formData.districtIds.length > 0) {
            handleTypicalCopyDistrict(formData);
            setFormData({})
            onClose(); // Close the modal  
        }
        else {
            setValidationError(true); 
        }
    };

    const handleSelection = (e) => {
        const districtId = parseInt(e.target.id);
        const isChecked = e.target.checked;
        if (isChecked) {
            setValidationError(false); 
            setFormData(prevFormData => ({
                ...prevFormData,
                districtIds: [...prevFormData.districtIds, districtId]
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                districtIds: prevFormData.districtIds.filter(id => id !== districtId)
            }));
        }
    };

    if (!show) {
        return null;
    }

    return (
        <div className="admin-modal-overlay">
            <div className="admin-modal admin-add-role">
                <div className="admin-modal-scroll">
                    <button className="admin-modal-close" onClick={handleOnClose}>
                        <span className="material-symbols-rounded">close</span>
                    </button>
                    <h2 className="admin-modal-heading">Districts</h2>
                    <div className="admin-modal-row">
                        <div className="admin-modal-cell">
                            <label className="admin-default-label" htmlFor="email-domain">Select Districts</label>
                            {districts.data && districts.data.length > 0 ? (
                                districts.data.map((district) => (
                                    <label key={district.id} className="admin-inline-label">
                                        <input
                                            className="admin-inline-checkbox"
                                            id={district.id}
                                            type="checkbox"
                                            value={district.id}
                                            onChange={handleSelection}
                                        />
                                        <span className="admin-inline-label-text">{district.name}</span>
                                    </label>

                                ))
                            ) : (
                                <div className="admin-tall-list-item">
                                    <p className="admin-tall-list-name">There are not any active Districts.</p>
                                </div>
                            )}
                        </div>
                    </div>
                    {validationError && (
                        <div className="admin-validation-error">Please select at least one district.</div>
                    )}
                    <div className="admin-modal-button-wrap">
                        <button className="admin-modal-button" onClick={handleOnClose}>Cancel</button>
                        <button className="admin-modal-button power" onClick={handleSave}>Save Changes</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

TypicalDistrictCopyModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    handleTypicalCopyDistrict: PropTypes.func.isRequired,
    model: PropTypes.object
};
