import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Loader from '../Loader';
import * as productApi from '../../api/productapi.js';

function ProductSelector({ selectedId, productLines, onChange, id }) {
  return (
      <select className="admin-select" id={id}
          onChange={onChange}
          defaultValue={selectedId || 0}
      >
      <option value="0">Select a Product Line</option>
      {productLines.map((productLine) =>
      (<option
        key={productLine.id}
        value={productLine.id}
      >{productLine.name}</option>)
      )}
    </select>
  )
}
ProductSelector.propTypes = {
  id: PropTypes.string,
  selectedId: PropTypes.number,
  productLines: PropTypes.array,
  onChange: PropTypes.func
}

export default function ProductLineSelect({ manufacturerId, selectedId, onChange, id }) {
  const [loading, setLoading] = React.useState(true);
  const [productLines, setProductLines] = React.useState([]);

    const getItems = async () => {
        const result = await productApi.getProductLines(manufacturerId);
        if (result.data.success) {
            setLoading(false);
            setProductLines(result.data.data);
        }
    }

  useEffect(() => {
        if (selectedId !== null)
        getItems()
            .catch(console.error);
    }, [selectedId]);

  return (
    <>
      {loading ? <Loader /> : <ProductSelector
        id={id}
        selectedId={selectedId}
        productLines={productLines}
        onChange={onChange}
      />}
    </>
  )
}

ProductLineSelect.propTypes = {
  id: PropTypes.string,
  manufacturerId: PropTypes.number,
  selectedId: PropTypes.number,
    onChange: PropTypes.func
}