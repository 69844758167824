import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
export default function AdminError({ message, onClose }) {
	const [error, setError] = useState({ message: '', show: false });

	const handleDisplayError = (err) => {
		setError({ message: err, show: true });
	}

	const handleHideError = () => {
		setError({ message: '', show: false });
		if (onClose) { onClose(); }
	}

	useEffect(() => {
		if (message != null && message != '' && message != error.message) {
			handleDisplayError(message);
		} else if ((message == null || message == '') && error.show == true) {
			handleHideError();
		}
	}, [message]);

	return (
		<>
			{error.show == true ?
				<div className="internal-error">
					{error.message}
					<span className="close-button" onClick={handleHideError}>
						<span className="material-symbols-rounded">close</span>
					</span>
				</div> : <></>
			}
		</>);
}

AdminError.propTypes = {
	message: PropTypes.string,
	onClose: PropTypes.func
}