import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export const ProductDimension = ({ dimension, handleOptionSelection, requiredOption }) => {
	const sortedItems = dimension?.items?.sort((a, b) => a.order - b.order) || [];
	const queryParams = new URLSearchParams(location.search);
	const [selectedDimension, setSelectedDimension] = useState({});
	const handleDimensionSelection = (option) => {
		if (selectedDimension && selectedDimension.id === option.id) {
			setSelectedDimension({});
		} else {
			setSelectedDimension(option);
		}
		handleOptionSelection(option, dimension.type);

	};

	const [isRequired, setIsRequired] = useState(false);
	useEffect(() => {
		const item = requiredOption && requiredOption.find(obj => obj.id === dimension.id);		
		if (item && item !== undefined) {
			setIsRequired(true);
		} else {
			setIsRequired(false);
		}
	}, [requiredOption]);

	useEffect(() => {
		if (sortedItems.length == 1 && !queryParams.get('cartid')) {
			setSelectedDimension(sortedItems[0]);
		}
	}, [sortedItems])


	return (
		<div className="product-dimensions-wrap">
			<div className="product-dimension-item">
				<p className="product-dimension-title">
					{dimension.title + (dimension.isRequired ? ' *' : '')}

					{isRequired ? <div className="product-required-label-wrap">
						<span className="product-required-label">Selection Required</span>
					</div> : null}
				</p>
				<div className="product-dimension-number-wrap">
					{sortedItems && sortedItems.map((option) => {
						return (
							<div
								key={option.id}
								onClick={() => handleDimensionSelection(option)}
							>
								<input
									type="radio"
									className="radio-btn"
									name={option.title}
									id={option.id}
									data-action="productwidth"
									aria-label={option.title}
									checked={
										selectedDimension && selectedDimension.id === option.id
									}
									onChange={() => {}} 
								/>
								<label
									className="product-dimension-number label "
									htmlFor="a-opt"
								>
									<span>{option.title}</span>
								</label>
							</div>
						);
					})}

				</div>
				
				
			</div>
		
		</div>
	);
};

ProductDimension.propTypes = {
	dimension: PropTypes.object,
	handleOptionSelection: PropTypes.func,
	requiredOption: PropTypes.array
	
};
