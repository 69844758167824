import { createSlice } from '@reduxjs/toolkit';
import {
    update, getAll, add, remove, complete, moveToWishlist, getAllProduct,
    getTypicalLayouts, updateTypicalLayoutLabel, updateTypicalLayoutQuantity,
    removeTypicalLayout, updateTag
} from '../api/cartapi'
import { addItemToWishList } from './mywishlist-reducer';
const myProjectSlice = createSlice({
    name: 'MyProject',
    initialState: {
        data: {
            items: [],
            totalQuantity: 0,
        },
        saveStatus: 'initial', // 'saving', 'succeeded', 'failed', 'idle
        saveError: null,
    },
    reducers: {
        loadAllProducts(state, action) {
            const newItems = action.payload;
            state.data.items = [];
            state.data.totalQuantity = 0;
            if (newItems) {
                newItems.map((item) => {
                    state.data.items.push(item);
                    state.data.totalQuantity += item.quantity;
                })
            }
        },
        loadAllTypicalLayouts(state, action) {
            const elems = action.payload;
            state.data.typicalLayouts = elems;
        },
        addItemToMyProject(state, action) {
            const newItem = action.payload;
            const existingItemIndex = state.data.items.findIndex(
                item => item.cartId === newItem.cartId
            );
            if (existingItemIndex >= 0) {
                state.data.items[existingItemIndex].quantity += newItem.quantity;
            } else {
                state.data.items.push(newItem);
            }
            state.data.totalQuantity += newItem.quantity;
        },
        removeItemFromMyProject(state, action) {
            const cartIdToRemove = action.payload;

            const itemToRemove = state.data.items.find(item => item.cartId === cartIdToRemove);
            if (itemToRemove) {
                state.data.totalQuantity -= itemToRemove.quantity;
            }

            state.data.items = state.data.items.filter(item => item.cartId !== cartIdToRemove);
        },
        updateItemQuantityInMyProject(state, action) {
            const { cartId, quantity } = action.payload;
            const existingItemIndex = state.data.items.findIndex(item => item.cartId === cartId);
            if (existingItemIndex >= 0) {
                const item = state.data.items[existingItemIndex];
                state.data.totalQuantity += (quantity - item.quantity);
                item.quantity = quantity;
            }
        },
        saveStarted: (state) => {
            state.saveStatus = 'saving';
        },
        saveSucceeded: (state) => {
            state.saveStatus = 'succeeded';
        },
        saveFailed: (state, action) => {
            state.saveStatus = 'failed';
            state.saveError = action.payload;
        },
        resetSaveStatus: (state) => {
            state.saveStatus = 'idle';
        }
    },
});

export const {
    addItemToMyProject,
    removeItemFromMyProject,
    updateItemQuantityInMyProject,
    removeItemFromWishList,
    updateItemQuantityInWishList,
    saveStarted,
	saveSucceeded,
	saveFailed,
    loadAllProducts,
    loadAllTypicalLayouts
} = myProjectSlice.actions;

export default myProjectSlice.reducer;

export const loadAllItems = (typicalLayout) => async (dispatch) => {
    try {
        //for Typical Layout ViewDetail Start
        let response = '';
        if (typicalLayout === true) {
            //editing typical layout
            let productDetailList = JSON.parse(localStorage.getItem('editTypicalLayout')) || [];
            if (productDetailList != '' && productDetailList != null) {
                response = await getAllProduct(productDetailList.products);
            }
        }
        else if (typicalLayout != undefined && typicalLayout === "typicalProductDetailView")
        {
            let productViewDetail = JSON.parse(localStorage.getItem('typicalLayoutProductDetail')) || [];
            if (productViewDetail != '' && productViewDetail != null) {
                response = await getAllProduct(productViewDetail);
            }
        }
        else {
            //get cart items
            response = await getAll();
        }
        if (response.data.success) {
            dispatch(loadAllProducts(response.data.data));

            //get info about typical layouts in the user's cart
            if (typicalLayout !== true) {
                response = await getTypicalLayouts();
                if (response.data.success) {
                    dispatch(loadAllTypicalLayouts(response.data.data));
                } else {
                    dispatch(saveFailed(response.data.message));
                }
            }
        } else {
            dispatch(saveFailed(response.data.message));
        }
    } catch (error) {
        dispatch(saveFailed(error.message));
        console.error('Error loading all items:', error);
    }
};

function findCartId(productsList, productId, optionItemIds) {
    let filterSortedOptionItemIds = optionItemIds?.map(x => x.productOptionItemId).slice().sort();
    for (let product of productsList) {
        let filterSortedProductOptionItemIds = product?.optionItems?.map(x => x.productOptionItemId).slice().sort();
        if (product.productId === productId && JSON.stringify(filterSortedOptionItemIds) === JSON.stringify(filterSortedProductOptionItemIds)) {
            return product.cartId;
        }
    }
    return 0;
}

export const addNewItem = (productDetails) => async (dispatch) => {
    dispatch(saveStarted());
    const { productId, quantity, optionItemIds, userId, cartId } = productDetails;
    const typicalLayout = localStorage.getItem('typicallayout');
    if (typicalLayout === 'true')
    {
        try
        {
            let typicalDetailList = JSON.parse(localStorage.getItem('editTypicalLayout')) || [];
            let typicalProductIdList = typicalDetailList?.products?.filter(x => x.productId == productId);
            let existProductCartId = findCartId(typicalProductIdList, productDetails.productId, productDetails?.optionItems)
            if (typicalProductIdList != null && typicalProductIdList != undefined && typicalProductIdList && (existProductCartId ?? 0) > 0) {
                let typicalCartList = typicalDetailList?.products?.find(x => x.cartId == existProductCartId);        
                if (typicalCartList.isDeleted == true) {
                    typicalCartList.isDeleted = false;
                    typicalCartList.quantity = productDetails?.quantity;
                }
                else {
                    typicalCartList.quantity += productDetails?.quantity;
                }
                localStorage.setItem('editTypicalLayout', JSON.stringify(typicalDetailList));
            }
            else {
                let maxCartId = typicalDetailList?.products.reduce((max, item) => {
                    return item.cartId > max ? item.cartId : max;
                }, 0);

                if (maxCartId == 0 || maxCartId == null || maxCartId == undefined) {
                    maxCartId = 0;
                }
                maxCartId = maxCartId + 1;
                productDetails.cartId = maxCartId;
                typicalDetailList.products.push(productDetails);
                localStorage.setItem('editTypicalLayout', JSON.stringify(typicalDetailList));
            }
            dispatch(loadAllItems(true));
            dispatch(saveSucceeded());
        }
        catch (error) {
            dispatch(saveFailed(error.message));
            console.error('Error adding new item:', error);
        }
    }
    else {
        try {
            const response = await add(productId, quantity, optionItemIds, userId, cartId);
            if (response.data.success) {
                dispatch(loadAllItems());
                dispatch(saveSucceeded());
            } else {
                dispatch(saveFailed(response.data.message));
            }
        } catch (error) {
            dispatch(saveFailed(error.message));
            console.error('Error adding new item:', error);
        }
    }
};

export const removeItem = (cartId, userId) => async (dispatch) => {
    dispatch(saveStarted());
    try {

        if (localStorage.typicallayout === 'true') {
            let typicalDetailList = JSON.parse(localStorage.getItem('editTypicalLayout')) || [];

            let findProduct = typicalDetailList.products.filter(product => product.cartId === cartId && product.id > 0);
            if (findProduct != null && findProduct.length >0) {
                let updatedProducts = [...typicalDetailList.products];
                let findProductIndex = updatedProducts.findIndex(product => product.cartId === cartId && product.id > 0);
                if (findProductIndex !== -1) {
                    updatedProducts[findProductIndex].isDeleted = true;
                }
                localStorage.setItem('editTypicalLayout', JSON.stringify(typicalDetailList));
            }
            else {
                let updatedProducts = typicalDetailList.products.filter(product => product.cartId !== cartId);
                typicalDetailList.products = updatedProducts;
                localStorage.setItem('editTypicalLayout', JSON.stringify(typicalDetailList));
            }

            dispatch(loadAllItems(true));
            dispatch(saveSucceeded());
        }
        else {
            const response = await remove(cartId, userId);
            if (response.data.success) {
                dispatch(loadAllItems());
                dispatch(saveSucceeded());
            } else {
                dispatch(saveFailed(response.data.message));
            }
        }
    } catch (error) {
        dispatch(saveFailed(error.message));
        console.error('Error removing item:', error);
    }
};

export const completeCart = (navigate) => async (dispatch) => {
    dispatch(saveStarted());
    try {
        if (localStorage.typicallayout == 'true') {
            let typicalDetailList = JSON.parse(localStorage.getItem('editTypicalLayout')) || [];
            let districtId = typicalDetailList.districtId;
            if (typicalDetailList?.id > 0) {
                let typicalLayoutId = typicalDetailList?.id;
                navigate(`/admin/districts/edit/${districtId}/typical-layout/${typicalLayoutId}`);
                typicalDetailList.IsEditCompleteCart = true;
                localStorage.setItem('editTypicalLayout', JSON.stringify(typicalDetailList));   
                localStorage.removeItem("typicallayout");
            } else {
                navigate(`/admin/districts/edit/${districtId}/typical-layout/create`);
                dispatch(loadAllItems(true));
            }
            localStorage.removeItem("typicallayout");                      
        }
        else      
        {
            const response = await complete();
            if (response.data.success) {
                dispatch(loadAllProducts())
                dispatch(saveSucceeded());
                localStorage.removeItem('demoUserId');
                navigate('/confirmation');
            }
            else {
                dispatch(saveFailed(response.data.message));
            }
        }

    } catch (error) {
        dispatch(saveFailed(error.message));
        console.error('Error completing cart:', error);
    }
};

export const moveItemToWishlistAction = (cartId) => async (dispatch) => {
    try {
        const response = await moveToWishlist(cartId);
        if (response.data.success) {
            dispatch(loadAllItems());
            dispatch(addItemToWishList(response.data.data));
            dispatch(saveSucceeded());
        }
    } catch (error) {
        dispatch(saveFailed(error.message));
        console.error('Error moving item to wishlist:', error);
    }
};

export const updateMyProjectQuantity = (productQuantity) => async (dispatch) => {
    dispatch(saveStarted());
    const { cartId, quantity, userId } = productQuantity;
    try {
        if (localStorage.typicallayout === 'true') {
            let typicalDetailList = JSON.parse(localStorage.getItem('editTypicalLayout')) || [];
            let typicalCartList = typicalDetailList?.products?.find(x => x.cartId == productQuantity.cartId)
            typicalCartList.quantity = productQuantity?.quantity;
            localStorage.setItem('editTypicalLayout', JSON.stringify(typicalDetailList));
            dispatch(loadAllItems(true));
            dispatch(saveSucceeded());
        } else {
            const response = await update(cartId, quantity, userId);
            if (response.data.success) {
                dispatch(loadAllItems());
                dispatch(saveSucceeded());
            } else {
                dispatch(saveFailed(response.data.message));
            }
        }
    } catch (error) {
        dispatch(saveFailed(error.message));
        console.error('There was an error editing the My Project:', error);
    }
};



export const updateProductTag = (productTag) => async (dispatch) => {
    dispatch(saveStarted());
    const { cartId, tag, userId } = productTag;
    try {
        if (localStorage.typicallayout != 'true') {
            const response = await updateTag(cartId, tag, userId);
            if (response.data.success) {
                dispatch(loadAllItems());
                dispatch(saveSucceeded());
            } else {
                dispatch(saveFailed(response.data.message));
            }
        }
    } catch (error) {
        dispatch(saveFailed(error.message));
        console.error('There was an error editing the My Project:', error);
    }
};

export const updateMyTypicalLayoutLabel = (id, label) => async (dispatch) => {
    dispatch(saveStarted());
    try {
        var response = await updateTypicalLayoutLabel(id, label);
        if (response.data.success) {
            //get info about typical layouts in the user's cart
            response = await getTypicalLayouts();
            if (response.data.success) {
                dispatch(loadAllTypicalLayouts(response.data.data));
                dispatch(saveSucceeded());
            } else {
                dispatch(saveFailed(response.data.message));
            }
        } else {
            dispatch(saveFailed(response.data.message));
        }
    } catch (error) {
        dispatch(saveFailed(error.message));
        console.error('There was an error editing the typical layout label', error);
    }
}

export const updateMyTypicalLayoutQuantity = (id, quantity) => async (dispatch) => {
    dispatch(saveStarted());
    try {
        var response = await updateTypicalLayoutQuantity(id, quantity);
        if (response.data.success) {
            //get info about typical layouts in the user's cart
            response = await getTypicalLayouts();
            if (response.data.success) {
                dispatch(loadAllTypicalLayouts(response.data.data));
                dispatch(saveSucceeded());
            } else {
                dispatch(saveFailed(response.data.message));
            }
        } else {
            dispatch(saveFailed(response.data.message));
        }
    } catch (error) {
        dispatch(saveFailed(error.message));
        console.error('There was an error editing the typical layout quantity', error);
    }
}

export const removeMyTypicalLayout = (id, type, quoteId) => async (dispatch) => {
    dispatch(saveStarted());
    try {
        var response = await removeTypicalLayout(id, quoteId, type);
        if (response.data.success) {
            //get user cart after removing typical layout
            dispatch(loadAllItems());
        } else {
            dispatch(saveFailed(response.data.message));
        }
    } catch (error) {
        dispatch(saveFailed(error.message));
        console.error('There was an error removing the typical layout', error);
    }
}
