import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Loader from '../../../components/Loader';
import { getByDistrictId, deleteTypicalLayoutById, copyTypicalToOtherDistrict, copyToDistrict } from '../../../api/typicallayoutsapi';
import { calculateFinalUnitPrice, formatUSD } from '../../../helpers/formatterHelper';
import { DeleteConfrimationModal } from '../../../components/admin/DeleteConfirmationModal'
import { TypicalDistrictCopyModal } from './CopyTypicalToDistricts';
import { TypicalDistrictsCopyModal } from './CopyTypicalFromDistricts';


export default function DistrictTypicalLayouts() {
    const navigate = useNavigate();
    const [layouts, setLayouts] = useState(null);
    const [loading, setLoading] = useState(true);
    const { districtId } = useParams();
    const [filter, setFilter] = useState({ orderby: 2, categoryIds: [] });
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [typicalModal, setTypicalModal] = useState({});
    const [showTypicalCopyModal, setShowTypicalCopyModal] = useState(false);
    const defaultEmailObj = { typicalLayoutId: 0, districtIds: [] }
    const [showCopyModal, setCopyModal] = useState(false);

    useEffect(() => {
        getByDistrictId(districtId, filter).then(response => {
            if (response.data.success === true) {
                setLayouts(response.data.data);
            }
            setLoading(false);
        });
    }, [districtId, filter]);

    const handleEditLayout = (id) => {
        navigate('/admin/districts/edit/' + districtId + '/typical-layout/' + id)
    };

    const handleRemoveLayout = (id, title) => {
        setTypicalModal({ id: id, title: title });
        setShowDeleteModal(true);
    };

    const handleDeleteEvent = (item) => {
        deleteTypicalLayoutById(item.id).then(response => {
            if (response.data.success === true) {
                getByDistrictId(districtId, filter).then(response => {
                    if (response.data.success === true) {
                        setLayouts(response.data.data);
                    }
                    setLoading(false);
                });
            }
        });
    }

    const handleCopyLayout = (id) => {
        defaultEmailObj.typicalLayoutId = id;
        setTypicalModal(defaultEmailObj);
        setShowTypicalCopyModal(true);
    };

    const handleCopyTypicalModal = (item) => {
        copyTypicalToOtherDistrict(item.typicalLayoutId, item.districtIds).then(response => {
            if (response.data.success === true) {
                getByDistrictId(districtId, filter).then(response => {
                    if (response.data.success === true) {
                        setLayouts(response.data.data);
                    }
                    setLoading(false);
                });
            }
        });
    }

    const handleCopiedTypicalLayout = (ids) => {
        setCopyModal(false);
        setLoading(true);
        copyToDistrict(districtId, ids).then(response => {
            if (response.data.success == true) {
                getByDistrictId(districtId, filter).then(response => {
                    if (response.data.success === true) {
                        setLayouts(response.data.data);
                    }
                    setLoading(false);
                });
            }
        });
    };

    return (
        <>
            <DeleteConfrimationModal
                show={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                handleDeleteEvent={handleDeleteEvent}
                model={typicalModal}
            />

            <TypicalDistrictCopyModal
                show={showTypicalCopyModal}
                onClose={() => setShowTypicalCopyModal(false)}
                handleTypicalCopyDistrict={handleCopyTypicalModal}
                model={typicalModal}
            />

            {showCopyModal &&
                <TypicalDistrictsCopyModal
                onClose={() => setCopyModal(false)}
                onCopy={handleCopiedTypicalLayout }
                />
            }

            <div className="admin-tab-content">
                <div className="admin-row">
                    <button className="admin-button" onClick={() => {
                        navigate(`/admin/districts/edit/${districtId}/typical-layout/create`);
                        localStorage.removeItem("editTypicalLayout");
                        localStorage.removeItem("typicallayout");
                    }}>
                        <span className="admin-button-text">Add New Typical Layout</span>
                        <span className="admin-button-icon material-symbols-rounded" aria-hidden="true">add</span>
                    </button>
                    <div style={{ 'display':'inline-block', 'padding':'0 0 0 20px' }}>
                        <button className="admin-button" onClick={() => setCopyModal(true)}>
                            <span className="admin-button-text">Add Typical Layout By District</span>
                            <span className="admin-button-icon material-symbols-rounded" aria-hidden="true">add</span>
                        </button>
                    </div>
                </div>
                <div className="admin-table-wrap" style={{'padding-top':'20px'} }>
                    {loading ? (
                        <Loader />
                    ) : (
                        <table className="admin-table">
                            <thead>
                                <tr>
                                    <th onClick={() => setFilter({ ...filter, orderby: filter.orderby == 0 ? 1 : 0 })} className={filter.orderby == 0 ? 'admin-table-name-cell sortable sorted-dn' : (filter.orderby == 1 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>Category</th>
                                    <th onClick={() => setFilter({ ...filter, orderby: filter.orderby == 2 ? 3 : 2 })} className={filter.orderby == 2 ? 'admin-table-name-cell sortable sorted-dn' : (filter.orderby == 3 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>Title</th>
                                    <th className="admin-table-name-cell">Total Products</th>
                                    <th className="admin-table-name-cell">Cost</th>
                                    <th className="admin-table-action-cell">&nbsp;</th>
                                    <th className="admin-table-action-cell">&nbsp;</th>
                                    <th className="admin-table-action-cell">&nbsp;</th>
                                    <th className="admin-table-action-cell">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {layouts && layouts.map(layout => (
                                    <tr key={'layout_' + layout.id}>
                                        <td>{layout.category ? layout.category.name : 'N/A'}</td>
                                        <td>{layout.title}</td>
                                        <td>
                                            {layout.products
                                                ? layout.products.reduce((accumulator, product) => {
                                                    if (!product.isDeleted) {
                                                        accumulator += product.quantity || 0;
                                                    }
                                                    return accumulator;
                                                }, 0)
                                                : 0}
                                        </td>

                                        <td>{formatUSD(layout.products ? layout.products.reduce((sum, a) => sum + calculateFinalUnitPrice(a.product) * a.quantity, 0) : 0)}</td>
                                        <td className="admin-table-name-cell nowrap"><Link to={'/typical-layout/' + layout.id}>View Layout</Link></td>
                                        <td className="table-action-cell">
                                            <button
                                                className="admin-table-action"
                                                data-id={layout.id}
                                                onClick={(e) => handleEditLayout(e.target.dataset.id)}
                                                title="edit"
                                            >
                                                <span className="material-symbols-rounded">edit</span>
                                            </button>
                                        </td>
                                        <td className="table-action-cell">
                                            <button
                                                className="admin-table-action"
                                                data-id={layout.id}
                                                onClick={(e) => handleRemoveLayout(e.target.dataset.id, layout.title)}
                                                title="delete"
                                            >
                                                <span className="material-symbols-rounded">delete</span>
                                            </button>
                                        </td>
                                        <td className="table-action-cell">
                                            <button
                                                className="admin-table-action"
                                                data-id={layout.id}
                                                onClick={(e) => handleCopyLayout(e.target.dataset.id)}
                                                title="copy"
                                            >
                                                <span className="material-symbols-rounded">content_copy</span>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </>
    );
}