import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import DragDropContainer from "../../../components/DragAndDropContainer";
import { AdminModal } from '../../../components/admin/admin-modal';
import ProductOptionItems from './productOptions_ItemsModal';

const productTypes = [
  { id: 0, name: 'Height', isVisible: true },
  { id: 1, name: 'Width', isVisible: false },
  { id: 2, name: 'Color', isVisible: true },
  { id: 3, name: 'Option - Select Multiple', isVisible: true },
  { id: 4, name: 'Option - Select Single', isVisible: true },
]

export const defaultProductOption = {
  id: 0,
  colorGroupId: null,
  title: '',
  type: 0,
  order: 0,
  isRequired: true,
  items: []
}

export const defaultProductOptionItem = {
  id: 0,
  title: '',
  description: '',
  code: '',
  colorId: null,
  upCharge: null,
  order: 0,
  mediaItemId: null,
  mediaItem: null
}

export function ProductOptions({ product, onSave }) {
  const [productGroups, setProductGroups] = useState(productTypes);
  const [productOptions, setProductOptions] = useState(product.options ?? []);
  const [showTypeModal, setShowTypeModal] = useState(false);
  const [modalItemsType, setModalItemsType] = useState(null);
  const [showOptionModal, setShowOptionModal] = useState(false);
  const [editProductIndex, setEditProductIndex] = useState(null);
  const [editProductOption, setEditProductOption] = useState(Object.assign({}, defaultProductOption));

  useEffect(() => {
    if (product.options.length > 0) {
      setProductOptions(product.options.sort((a, b) => a.order - b.order))
    } else setProductOptions([]);
    updateAvailableTypes();
  }, [product]);

  const updateAvailableTypes = () => {
    let currentOptions = [...productGroups];
    if (productOptions.filter(x => x.type === 0).length > 0) {
      currentOptions.filter(x => x.id === 0)[0].isVisible = false;
    }
    if (productOptions.filter(x => x.type === 1).length > 0) {
      currentOptions.filter(x => x.id === 1)[0].isVisible = false;
    }
    setProductGroups(currentOptions);
  }

  const handleDeleteItem = (setIndex) => {
    let updatedItems = [...productOptions];
    updatedItems.splice(setIndex, 1);
    updatedItems.map((item, idx) => {
      item.order = idx;
      return item;
    });
    setProductOptions(updatedItems);
    if (onSave) onSave(updatedItems);
  } 

  const handleEditItem = (setIndex) => {
    setModalItemsType(productOptions[setIndex].type);
    setEditProductIndex(setIndex);
    setEditProductOption(productOptions[setIndex]);
    setShowOptionModal(true);
    }
  

    const renderOptionItem = (productOption, setIndex) => (
        <>
            {
                productOption.type != 1 ?

                <div className="admin-drag-box" key={setIndex} >
                        <span className="admin-drag-box-name"> {productOption.title} ({productOption.items.length}) {productOption.isRequired ? '' : "Not Required"}</span>
                    <span className="admin-drag-option-wrap">
                        <button className="admin-drag-option edit" onClick={() => handleEditItem(setIndex)}>
                            <span className="material-symbols-rounded">edit</span>
                        </button>
                        <button className="admin-drag-option delete" onClick={() => handleDeleteItem(setIndex)}>
                            <span className="material-symbols-rounded">delete</span>
                        </button>
                    </span>
                </div> : ''
            }
        </>
  );
  const moveCategory = (fromIndex, toIndex, onDrop) => {
    if (onDrop) {
      let updatedOptions = [...productOptions];
      const [movedItem] = updatedOptions.splice(fromIndex, 1);
      updatedOptions.splice(toIndex, 0, movedItem);
      updatedOptions.map((option, idx) => {
        option.order = idx;
        return option;
      });
      setProductOptions(updatedOptions);
      if (onSave) onSave(updatedOptions);
    }
  };

  const handleOptionSave = () => {
    let updatedItems = [];    
    if (editProductIndex !== null) {
      updatedItems = [...productOptions];
      updatedItems.splice(editProductIndex, 1, validateOptions(editProductOption) );
      setEditProductIndex(null);

    } else {
      editProductOption.order = productOptions.length;
      updatedItems = [...productOptions, validateOptions(editProductOption)];  
    }
    // setProductOptions(updatedItems);
    setShowOptionModal(false);
    setEditProductOption(Object.assign({}, defaultProductOption))
    if (onSave) onSave(updatedItems);
}

  const validateOptions = (productOptions) => {

      let updatedOption = {...productOptions};  
      
      switch (updatedOption.type) {
        case 3:
        case 4:
          if (!updatedOption.title || updatedOption.title.trim() === '') {
            updatedOption.title = productTypes[updatedOption.type].name; 
          }
          break;
      }
      
      return updatedOption;

  };
  
  return (
    <>
      <div className="admin-drag-box-wrap">
        <DragDropContainer
          items={productOptions.sort((a, b) => a.order - b.order)}
          moveItem={moveCategory}
          renderItem={renderOptionItem}
          isHorizontal={false}
        />
      </div>
      <div className="admin-button-wrap">
        <button onClick={() => { setShowTypeModal(true) }} className="admin-button add">Add Option</button>
      </div>
      {showTypeModal && (
        <AdminModal
          modalTitle="Select Option Type"
          show={showTypeModal}
          saveButtonText={"Next"}
          onClose={() => { setShowTypeModal(false); }}
          onSave={() => {
            setShowTypeModal(false);
            if (modalItemsType !== null) {
              setEditProductOption({
                ...editProductOption,
                type: modalItemsType
              })
              setShowOptionModal(true);
            }
          }}
        >
          <div className="admin-modal-row full" style={{ display: 'unset' }}>
            <div className="admin-modal-cell">
              {productGroups.filter(x => x.isVisible).map((optionType, idx) => (
                <label className="admin-radio-label" key={idx} htmlFor={"optionType_" + optionType.id}>
                  <input
                    className="admin-radio"
                    type="radio"
                    id={"optionType_" + optionType.id}
                    name={"selectOptionType"}
                    value={optionType.id}
                    onChange={(e) => { setModalItemsType(Number(e.target.value)) }}
                  />
                  {optionType.name}
                </label>
              ))}
            </div>
          </div>
        </AdminModal>)}
      {showOptionModal &&
        <AdminModal
          adminModalClass="wide"
          modalTitle={"Add / Edit"}
          show={showOptionModal}
          onClose={() => {
            setShowOptionModal(false);
            setEditProductOption(Object.assign({}, defaultProductOption))
          }}
          onSave={handleOptionSave}
        >
          <ProductOptionItems
            optionObject={productTypes.filter(x => x.id == modalItemsType)[0]}
            editProductOption={editProductOption}
            onChange={(optionItem) => {
              setEditProductOption(optionItem)
            }}
          />
        </AdminModal>
      }
    </>
  );
}

ProductOptions.propTypes = {
  product: PropTypes.object,
  onSave: PropTypes.func
};

export default ProductOptions;
