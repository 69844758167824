import { createSlice, createSelector } from '@reduxjs/toolkit';
import {
	getAllColorGroups,
	addColorGroup,
	editColorGroup,
	getColors,
	addColor,
	editColor,
	deleteColor,
	deleteColorGroup,
	reorderColors,
	reorderColorGroups
} from '../api/colorsapi';

const slice = createSlice({
	name: 'colors',
	initialState: {
		colorGroupsWithColors: [],
		colorGroups: [],
		colors: [],
		lastFetch: null,
		saveStatus: 'idle', // 'saving', 'succeeded', 'failed'
		saveError: null,
	},
	reducers: {
		colorsRequested: (colors) => {
			colors.isLoading = true;
		},
		colorGroupsReceived: (colors, action) => {
			colors.colorGroupsWithColors = action.payload.data;
			colors.colorGroupsWithColors.sort((a,b) => a.order - b.order);
			colors.colorGroupsWithColors.forEach((group) => {
				group.colors.sort((a, b) => a.order - b.order);
			});
			colors.lastFetch = Date.now();
		},
		colorsRequestFailed: (colors) => {
			colors.colorGroups = [];
			colors.isLoading = false;
		},
		colorGroupAdded: (colors, action) => {
			colors.colorGroups.push(action.payload.data);
		},
		colorGroupEdited: (colors, action) => {
			const index = colors.colorGroups.findIndex(
				(colorGroup) => colorGroup.id === action.payload.id
			);
			if (index >= 0) {
				colors.colorGroups[index] = action.payload;
			}
		},
		colorsReceived: (colors, action) => {
			colors.colors = action.payload;
		},
		colorAdded: (colors, action) => {
			colors.colors.push(action.payload);
		},
		colorEdited: (colors, action) => {
			const index = colors.colors.findIndex(
				(color) => color.id === action.payload.id
			);
			if (index >= 0) {
				colors.colors[index] = action.payload;
			}
		},

		saveStarted: (state) => {
			state.saveStatus = 'saving';
		},
		saveSucceeded: (state) => {
			state.saveStatus = 'succeeded';
		},
		saveFailed: (state, action) => {
			state.saveStatus = 'failed';
			state.saveError = action.payload;
		},
		resetSaveStatus: (state) => {
			state.saveStatus = 'idle';
			state.saveError = null;
		},
	},
});

const {
	colorsRequested,
	colorGroupsReceived,
	colorsRequestFailed,
	colorGroupAdded,
	colorsReceived,
	saveStarted,
	saveSucceeded,
	saveFailed,
	colorAdded,
	colorEdited,
} = slice.actions;

export default slice.reducer;

// Action Creators

export const loadColorGroups = (manufacturerId) => async (dispatch) => {
	dispatch(colorsRequested());
	try {
		const response = await getAllColorGroups(manufacturerId);
		dispatch(colorGroupsReceived(response.data));
	} catch (error) {
		dispatch(colorsRequestFailed(error.message));
	}
};

export const loadColors = (colorGroupId) => async (dispatch) => {
	dispatch(colorsRequested());
	try {
		const response = await getColors(colorGroupId);
		dispatch(colorsReceived(response.data));
	} catch (error) {
		dispatch(colorsRequestFailed(error.message));
	}
};

export const getColorGroups = createSelector(
	(state) => state.colors.colorGroups,
	(colorGroups) => colorGroups
);

export const getColor = createSelector(
	(state) => state.colors.colors,
	(colors) => colors
);

export const addColorGroups = (colorGroupData) => async (dispatch) => {
	dispatch(saveStarted());
	try {
		const response = await addColorGroup({ options: { data: colorGroupData } });
		if (response.data.success) {
			dispatch(colorGroupAdded(response.data));
			dispatch(saveSucceeded());
		} else {
			dispatch(saveFailed(response.data.message));
		}
	} catch (error) {
		console.error('There was an error adding the color group:', error);
		dispatch(saveFailed());
	}
};

export const editColorGroups = (colorGroupData) => async (dispatch) => {
	dispatch(saveStarted());

	try {
		const response = await editColorGroup({
			options: { data: colorGroupData },
		});
		if (response.data.success) {
			dispatch(saveSucceeded());
			// dispatch(colorGroupEdited(response.data));
		} else {
			dispatch(saveFailed(response.data.message));
		}
	} catch (error) {
		dispatch(saveFailed());
		console.error('There was an error editing the color group:', error);
	}
};

export const deleteColorGroups = (id) => async (dispatch) => {
	dispatch(saveStarted());
	try {
		const response = await deleteColorGroup(id);
		if (response.data.success) {
			dispatch(colorGroupAdded(response.data));
			dispatch(saveSucceeded());
		} else {
			dispatch(saveFailed(response.data.message));
		}
	} catch (error) {
		dispatch(saveFailed());
		console.error('There was an error deleting the color group:', error);
	}
};
export const addColors = (colorData) => async (dispatch) => {
	dispatch(saveStarted());
	try {
		const response = await addColor({ options: { data: colorData } });
		if (response.data.success) {
			dispatch(saveSucceeded());
			dispatch(colorAdded(response.data));
		} else {
			dispatch(saveFailed(response.data.message));
		}
	} catch (error) {
		dispatch(saveFailed(error.message));
		console.error('There was an error adding the color:', error);
	}
};

export const updateColor = (color) => async (dispatch) => {
	dispatch(saveStarted());
	try {
		const response = await editColor({ options: { data: color } });
		if (response.data.success) {
			dispatch(saveSucceeded());
			dispatch(colorEdited(response.data));
		} else {
			dispatch(saveFailed(response.data.message));
		}
	} catch (error) {
		dispatch(saveFailed(error.message));
		console.error('There was an error editing the color:', error);
	}
};

export const deletColor = (id) => async (dispatch) => {
	dispatch(saveStarted());
	try {
		const response = await deleteColor(id);
		if (response.data.success) {
			dispatch(saveSucceeded());
		} else {
			dispatch(saveFailed(response.data.message));
		}
	} catch (error) {
		dispatch(saveFailed(error.message));
		console.error('There was an error deleting the color:', error);
	}
};

export const updateColorOrder = (ids) => async (dispatch) => {
	dispatch(saveStarted());
	try {
		const response = await reorderColors({
			options: {
				params: { Ids: ids },
			},
		});
		if (response.data.success) {
			dispatch(saveSucceeded());
		} else {
			dispatch(saveFailed(response.data.message));
		}
	} catch (error) {
		dispatch(saveFailed(error.message));
		console.error('There was an error reordering the colors:', error);
	}
};

export const updateColorGroupOrder = (ids) => async (dispatch) => {
	dispatch(saveStarted());
	try {
		const response = await reorderColorGroups({
			options: {
				params: { Ids: ids },
			},
		});
		if (response.data.success) {
			dispatch(saveSucceeded());
		} else {
			dispatch(saveFailed(response.data.message));
		}
	} catch (error) {
		dispatch(saveFailed(error.message));
		console.error('There was an error reordering the color groups:', error);
	}
};

export const { resetSaveStatus } = slice.actions;
