import React from 'react';
import '../styles/footer.css';
import FooterLogo from '../assets/Facility-Standard-Main-Logo.png';

export default function Footer() {
    return (
        <div className="footer-wrap">
            <div className="footer-center">
                <div className="footer-inner">
                    <div className="footer-item">
                        <div className="footer-content-wrap">
                            <p className="footer-contact-text"><b>Have Questions?</b> <a href="tel:+1-816-768-6179" className="footer-link">(816) 768-6179</a></p>
                        </div>
                    </div>
                    <div className="footer-item">
                        <div className="footer-logo-area-wrap">
                            <p className="footer-contact-text">Powered by</p>
                            <div className="footer-logo-wrap">
                                <img className="footer-logo" src={FooterLogo} alt="Facility Standard Logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

