import api from '../utils/api';

export const getAllDistrictProducts = (id) => {
  return api({
    url: 'api/districts/products/get-all/' + id,
    method: 'GET'
  });
}

export const getAllDistrictProductOptionItems = (id) => {
    return api({
        url: 'api/districts/products/get-all-optionitems/' + id,
        method: 'GET'
    });
}

export const getAllManufacturerWithProducts = () => {
    return api({
        url: '/api/manufacturers/get-all-with-products',
        method: 'GET',
    });
};

export const addProduct = (id, productId) => {
    return api.post('api/districts/products/add-product/' + id + '/' + productId);
}

export const removeProduct = (id, productId) => {
    return api.post('api/districts/products/remove-product/' + id + '/' + productId);
}

export const addProductLine = (id, productLineId) => {
    return api.post('api/districts/products/add-productline/' + id + '/' + productLineId);
}

export const removeProductLine = (id, productLineId) => {
    return api.post('api/districts/products/remove-productline/' + id + '/' + productLineId);
}

export const addProductOptionItems = (id, optionItems) => {
    return api.post('api/districts/products/add-product-optionitems/' + id, optionItems);
}

