import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loader from '../../../components/Loader.js';
import { getProductLineDiscount, submitProductLine } from '../../../api/districtproductlinediscountapi';
import { useDispatch } from "react-redux";
import { showLoading, hideLoading } from '../../../store/loading-reducer';

export default function DistrictProductLineDiscount() {
    const { districtId } = useParams();
    const navigate = useNavigate();
    const [loadingData, setLoadingData] = useState(true);
    const [manufacturersList, setManufacturer] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        getProductLineDiscount(districtId)
            .then((response) => {
                setManufacturer(response.data.data);
                setLoadingData(false);
            });
    }, []);

    const handleProductLineSave = () => {
        dispatch(showLoading());

        submitProductLine(manufacturersList)
            .then((response) => {
                if (response.data.success) {
                    dispatch(hideLoading());
                    setManufacturer(response.data.data);
                    if (response.data.success)
                        navigate('/admin/districts/edit/' + districtId);
                } else {
                    dispatch(hideLoading());
                    alert(response.data.message);
                }
            });
    }

    const handleProductLIneUpdate = (productLineItem) => {
        if (productLineItem != null && productLineItem.districtProductLineDiscount.discount != '') {
            const manuIndex = manufacturersList.findIndex(x => x.id === productLineItem.manufacturerId);
            if (manuIndex > -1) {
                manufacturersList[manuIndex].districtId = districtId;
                manufacturersList[manuIndex].productLines.find(x => x.id === productLineItem.id).districtProductLineDiscount.discount = productLineItem.districtProductLineDiscount.discount;
                setManufacturer(manufacturersList);
            }
        }
    }

    return (
        <div className="admin-tab-content">
            <div className="admin-table-wrap">
                {loadingData ? <Loader /> :
                    <>
                        <table className="admin-table">
                            <thead>
                                <tr>
                                    <th>Manufacturer</th>
                                    <th className="admin-table-name-cell">Product Line</th>
                                    <th className="admin-table-action-cell">Discount%</th>
                                </tr>
                            </thead>
                            <tbody>
                                {manufacturersList.length > 0 &&
                                    manufacturersList
                                        .map((manufacture) =>
                                            <React.Fragment key={manufacture.id}>
                                                <tr key={manufacture.id} data-element="manufactureItem">
                                                    <td>{manufacture.name}</td>
                                                    <td className="admin-table-name-cell">&nbsp;</td>
                                                    <td className="admin-table-action-cell">
                                                        &nbsp;
                                                    </td>
                                                </tr>
                                                {manufacture.productLines != null && manufacture.productLines.length > 0 ?
                                                    manufacture.productLines
                                                        .map((productline, idx) =>
                                                            <ProductLineItem
                                                                key={'productline_' + idx}
                                                                productLineObj={productline} saveproductline={handleProductLIneUpdate}
                                                            />
                                                        ) : <></>}
                                            </React.Fragment>
                                        )
                                }
                            </tbody>
                        </table>
                    </>
                }
            </div>
            <div className="admin-floating-button-wrap">
                <button className="admin-floating-button"
                    onClick={() => navigate('/admin/districts/edit/' + districtId)}
                >Cancel</button>
                <button className="admin-floating-button power" onClick={handleProductLineSave}
                >Save Changes</button>
            </div>
        </div>
    )
}

function ProductLineItem({ productLineObj, saveproductline }) {
    if (productLineObj == null) return null;

    const [lineObj, setLineObj] = useState(productLineObj);
    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        if (/^\d*\.?\d*$/.test(inputValue)) {
            const newProductLineObj = {
                ...lineObj,  // Using the copied object
                districtProductLineDiscount: {
                    ...lineObj.districtProductLineDiscount,  // Copy the nested object
                    discount: event.target.value  // Update the discount property
                }
            };
            setLineObj(newProductLineObj);
            saveproductline(newProductLineObj)
        }
    };
    return (
        <tr className="productLineWithDiscount" id={lineObj.id}>
            <td className="no-border">&nbsp;</td>
            <td className="admin-table-name-cell">
                <p className="admin-table-text">{(lineObj.name)}</p>
            </td>
            <td className="admin-table-action-cell percentage-input">
                <input className="admin-table-input-text"
                    type="text"
                    placeholder="0"
                    onChange={handleInputChange}
                    value={lineObj.districtProductLineDiscount?.discount}
                />
                <span className="percentage-symbol">%</span>
            </td>
        </tr>
    )
}

ProductLineItem.propTypes = {
    productLineObj: PropTypes.object,
    saveproductline: PropTypes.func
}
