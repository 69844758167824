import axios from 'axios';
export const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});
export const formatUSD = (value) => {
    if (isNaN(value)) {
        return "$0";
    }
    let formatted = USDollar.format(value);
    return formatted;
}

export const calculateTotalUpcharge = (options) => {
    return options.reduce((acc, option) => {
            const optionUpcharge = option.items.reduce((innerAcc, item) => innerAcc + (item.upCharge ? item.upCharge : 0), 0);
            return acc + optionUpcharge;
    }, 0);
};

export const calculateDiscountedPrice = (price) => {
    return price - (productDiscount > 0 ? price * (productDiscount / 100) : 0);
};
//session item is being used for option items with not discount property
const productDiscount = sessionStorage.getItem('productDiscount');



export const calculateDiscountedPriceForCart = (price, discount) => {
    return price - (discount > 0 ? price * (discount / 100) : 0);
};

export const calculateFinalUnitPrice = (productItem) => {
    if (productItem == null || productItem.details == null || productItem.details == undefined) {return 0 }

    const basePrice = productItem.details.price;
    const discount = productItem.details.discount || 0;
    const totalUpcharge = productItem.options && productItem.options.length > 0 ? calculateTotalUpcharge(productItem.options) : 0;
    const totalAmount = basePrice + totalUpcharge;
    return calculateDiscountedPriceForCart(totalAmount, discount);
};

export const formatProductCode = (product) => {
    const options = product.options;
    const details = product.details;
    let productCode = details.freedomCode;

    if (options && options.length > 0) {
        options.map((option) => {
            if (option.items && option.items.length > 0) {
                option.items.map((item) => {
                    if (option.type === 2) {
                        productCode += '.' + (item?.color?.colorCode ? item?.color?.colorCode : item?.color?.name);
                    } else { 
                        if (item?.code) {
                            productCode += '.' + item?.code;
                        } else if (item?.title) {
                            productCode += '.' + item?.title;
                        }
                    }
                });
            }
        });
    }

    return productCode;  
}

export const formatPhoneNumber = (phone) => {
    var cleaned = phone.replace('+', '');
    var match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '+ ' + match[1] + ' (' + match[2] + ') ' + match[3] + '-' + match[4];
    }
    match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    match = cleaned.match(/^(\d{3})(\d{4})$/);
    if (match) {
        return match[1] + '-' + match[2];
    }
    return phone;
}

export const processUrl = (sourceData) => {
    let media = {
        id: null,
        urlval: null,
        type: null,
        success: false
    };
    if (sourceData.match(/^(http(s?)?:\/\/)?(www\.)?(youtube\.com\/(embed\/|v\/|.*[?&]v=)|youtu\.be\/)/)) {
        let youtube_id = '';
        if (sourceData.match('embed')) {
            youtube_id = sourceData.split(/embed\//)[1].split('"')[0];
        } else {
            youtube_id = sourceData.split(/v\/|v=|youtu\.be\//)[1].split(/[?&]/)[0];
        }
        media.type = "youtube";
        media.id = youtube_id;
        media.success = true;
    }

    else if (sourceData.match('http(s?)://(player.)?vimeo\\.com')) {
        var split = sourceData.split(/video\/|http(s?):\/\/vimeo\.com\//);
        let vimeo_id = split[split.length - 1];
        if (vimeo_id.indexOf('/') > -1) {
            media.id = vimeo_id.split('/')[0];
            media.urlval = 'h=' + vimeo_id.split('/')[1];
        } else {
            media.id = vimeo_id;
        }
        media.type = "vimeo";
        media.success = true;
    }
    else if (sourceData.match(/^https?:\/\/(www\.)?(imstagingstorage|blob\.net)/)) {
        media.type = "htmlVideo";
        media.success = true;
    }
    else if (sourceData.match(/^https?:\/\/(www\.)?(imlivestorage|blob\.net)/)) {
        media.type = "htmlVideo";
        media.success = true;
    }

    return media;
};


export const isVimeoVideoExists = async (id, token) => {
   
    if (token === undefined || token === null || token == '') {
        token = '5ecbdc4ba4c7ee77c7bd57f2309c7eff';
    }
    const url = `https://api.vimeo.com/videos/${id}`;
    try {
        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (response.status === 200)
            return true;
        else return false;
    } catch (error) {
        if (error.response && error.response.status === 404) {
            return false;
        }
        return false;
    }
};


