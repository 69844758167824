export function updateTitle(title, subtitle) {
    document.title = title + ' | ' + (subtitle != null ? subtitle + ' | ' : '') + 'Facility Standard';
}

export function updateMetaDescription(description) {
    let metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
        metaDescription.setAttribute('content', description);
    } else {
        metaDescription = document.createElement('meta');
        metaDescription.name = 'description';
        metaDescription.content = description;
        document.head.appendChild(metaDescription);
    }
}