import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Breadcrumbs = ({ productName, category }) => {
	const location = useLocation();
	const fromResults = location.state?.from === 'results';
	// const pathnames = location.pathname.split('/').filter((x) => x);
    if(category && category.length === 0){
		return (<Link to="/products" className="product-detail-breadcrumb"> Back to results</Link>)
    }
	return (
		<div>
			{fromResults ? (
				<div className="product-breadcrumbs-wrap">
					<p>
						<Link className="go-back" onClick={() => { window.history.go(-1); return false; } }>
							Back
						</Link>
					</p>
				</div>
			) : (
				<div className="product-breadcrumbs-wrap">
					<p>
						<Link to="/home" className="breadcrumb">
							Home
						</Link>
						<span>&nbsp;/&nbsp;</span>
						<Link to={`/products/category/${category[0].id}`} className="breadcrumb">
							{category && category[0].name}
						</Link>
						<span>&nbsp;/&nbsp;</span>

						<span>{productName}</span>
					</p>
				</div>
			)}
		</div>
	);
};

export default Breadcrumbs;
Breadcrumbs.propTypes = {
	productName: PropTypes.string,
	category: PropTypes.array,
};
