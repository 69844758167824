import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from "@reduxjs/toolkit";
import { userLogin } from '../../store/auth/actions';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getQuoteIdByReviewing } from '../../api/districtquoteapi';
import { updateTitle } from '../../helpers/titleHelper';

function FormValidationSummary({ display, messageList }) {
    if (display) {
        const listElements = messageList.map(message =>
            <li key={message}>{message}</li>
        );
        return (
            <div className="login-form-cell">
                <div className="validation-summary-valid" data-valmsg-summary="true">
                    <ul>
                        {listElements}
                    </ul>
                </div>
            </div>
        );
    }
    else return null;
}

FormValidationSummary.propTypes = {
    display: PropTypes.bool,
    messageList: PropTypes.array
}

export default function Login() {
    updateTitle('Account Login');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [displayError, setDisplayError] = useState(false);
    const [messages, setMessages] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [loaded, setLoading] = useState(false);
    const [viewPass, setViewPass] = useState(false);

    useEffect(() => {
        if (loaded == false) {
            setLoading(true);
        }
    }, [loaded]);



    const { from } = location.state || { from: { pathname: null } }; // Fallback to home if no redirect path

    const login = bindActionCreators(userLogin, dispatch);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setMessages([]);
        setDisplayError(false);

        let hasError = false;
        if (document.querySelector('.input-validation-error')) {
            document.querySelectorAll('.input-validation-error').forEach((element) => {
                element.classList.remove('input-validation-error');
            })
        }

        if (username === '' || username === null) {
            hasError = true;
            document.getElementById('login-email').classList.add('input-validation-error');
            setMessages(messages => [...messages, 'Please enter your username.']);
        }
        if (password === '' || password === null) {
            setMessages(messages => [...messages, 'Please enter your password.']);
            document.getElementById('login-password').classList.add('input-validation-error');
            hasError = true;
        }
        if (hasError) {
            setDisplayError(true);
            return;
        }

        let data = {
            "Username": username,
            "Password": password
        }

        setIsProcessing(true);
        const result = await login({ data: data }).unwrap();

        if (result.data) {
            setIsProcessing(false)
            if (result.data.success) {
                localStorage.removeItem("editTypicalLayout");
                localStorage.removeItem("typicallayout");
                var districtId = localStorage.getItem('districtId');
                if (!districtId && result.data.data && result.data.data.district) {
                    districtId = result.data.data.district.id;
                    localStorage.setItem('districtId', districtId);
                    localStorage.setItem('district', JSON.stringify(result.data.data.district));
                }
                if (districtId && !result.data.data.isAdmin) {
                    getQuoteIdByReviewing(districtId).then(response => {
                        if (response.data.success == true) {
                            //set the quoteId that the user is currently editing in place of their cart
                            localStorage.setItem('editQuoteId', response.data.data);
                            if(from && from.pathname){
                                navigate(from.pathname);
                            }
                            if (result.data.data.isAdmin) navigate('/admin');
                            else navigate("/home");
                        } else {
                            setMessages(messages => [...messages, 'Could not set the current QuoteId that this user is editing']);
                        }
                    });
                } else {
                    if (result.data.data.isAdmin) {
                        if(from && from.pathname){
                            navigate(from.pathname);
                        }else{
                            navigate('/admin');
                        }
                    }
                    else {navigate("/home");}
                }
                
            } else {
                setMessages(messages => [...messages, result.data.message]);
                setDisplayError(true);
            }
        }
    }

    useEffect(() => {
        document.querySelectorAll('.fancy-field').forEach((element) => {
            if (element.value != '') {
                element.parentNode.classList.add('input-focused');
            }
            else if (element.parentNode.classList.contains('input-focused')) {
                element.parentNode.classList.remove('input-focused');
            }
        });
    }, [username, password]);

    return (
        <form onSubmit={handleSubmit}>
            <FormValidationSummary
                display={displayError}
                messageList={messages} />
            <div className="login-form-row">
                <div className="login-form-cell">
                    <div className="login-form-field fancy-parent">
                        <label className="login-label" htmlFor="login-email">Email Address</label>
                        <input className="login-input fancy-field"
                            id="login-email"
                            name="Username"
                            onChange={(e) => setUserName(e.target.value)}
                            value={username}
                            type="text" />
                    </div>
                </div>
            </div>
            <div className="login-form-row">
                <div className="login-form-cell">
                    <div className="login-form-field fancy-parent">
                        <label className="login-label" htmlFor="login-password">Password</label>
                        <input className="login-input fancy-field"
                            id="login-password"
                            name="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type={viewPass ? 'text' : 'password'} />
                        <span className="view-pass" onClick={() => { setViewPass(!viewPass); }}>
                            <span className="material-symbols-rounded">{viewPass ? 'visibility_off' : 'visibility'}</span>
                        </span>
                    </div>
                </div>
            </div>
            <div className="login-form-row forgot-cell">
                <div className="login-form-cell">
                    <button type="button" className="login-form-forgot-link login-button-alt" onClick={() => navigate('/account/forgot-password')}>Forgot Password?</button>
                </div>
        </div>
        <div className="login-form-row">
          <div className="login-form-cell">
            {isProcessing ?
              <button type="button" className="login-submit" onClick={() => { }}>Logging in...</button> :
              <button type="submit" className="login-submit">Login</button>
            }
          </div>
        </div>
            <div className="login-form-row separator-cell">
                <div className="login-form-cell">
                    <p className="login-horizontal-line">
                        OR
                    </p>
                </div>
            </div>
            <div className="login-form-row">
                <div className="login-form-cell">
                    <button type="button" className="send-code" onClick={() => navigate('/account/forgot-one-time-login')}>Send me a one-time code</button>
                </div>
            </div>

        </form>
    )
}