import React from 'react';
import { AddSelect } from '../../components/common/Add-select';
import { ProductDimension } from '../../components/common/Product-dimension';
import { Colors } from '../../components/common/colors';
import PropTypes from 'prop-types';
export const ProductOptions = ({ options, handleOptionSelection, requiredOption, productOptionRef }) => {
    const sortedOption = options.sort((a, b) => a.order - b.order);  
    return (
        <>
            <div ref={productOptionRef}>
                {sortedOption.map((option, index) => {
                    if (!option.items || option.items.length === 0) {
                        return null;
                    }
                    if (option.type === 0 || option.type === 1) {
                        return <ProductDimension key={index} dimension={option} handleOptionSelection={handleOptionSelection} requiredOption={requiredOption} />;
                    }
                    if (option.type === 2) {
                        return <Colors key={index} colorOptions={option} handleOptionSelection={handleOptionSelection} requiredOption={requiredOption} />;
                    }
                    if (option.type === 3 || option.type === 4) {
                        return <AddSelect key={index} data={option} handleOptionSelection={handleOptionSelection} requiredOption={requiredOption} />;
                    }
                })}
            </div>
        </>
    );
};

ProductOptions.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            colorGroupId: PropTypes.number,
            title: PropTypes.string,
            items: PropTypes.array.isRequired,
            order: PropTypes.number.isRequired           
        })
    ).isRequired,
    handleOptionSelection: PropTypes.func,
    requiredOption: PropTypes.array,
    productOptionRef: PropTypes.object
};
