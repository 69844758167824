import api from '../utils/api';

export const authenticate = (userId, districtId) => {
    return api({
        url: '/api/demo-district/authenticate',
        method: 'POST',
        data: {userId:userId, districtId:districtId}
    })
}
export const registerUser = (form) => {
    return api({
        url: '/api/demo-district/register',
        method: 'POST',
        data: form
    })
}