import api from '../utils/api';

export const getProductLineDiscount = (districtid) => {
    return api({
        url: '/api/districtsProdctLineDiscount/get-all/' + districtid,
        method: 'GET',
    });
}

export const submitProductLine = (items) => {
    let url = '/api/districtsProdctLineDiscount/add';
    return api.post(url, items);
}