import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
//components
import AdminTextInput from '../../../components/admin/admin-text-input';
import AdminSelect from '../../../components/admin/admin-default-select';
import AdminPhone from '../../../components/admin/admin-phone';
import AdminError from '../../../components/admin/admin-error.js';
//reducers
import { showLoading, hideLoading } from '../../../store/loading-reducer';
//apis
import { getUserDetails, saveUserDetails } from '../../../api/adminuserapi';
//helpers
import { ValidateEmail } from '../../../helpers/validationHelper';
import { updateTitle } from '../../../helpers/titleHelper';

const availableClaims = [
    { name: 'districts', value: 'districts.edit', displayName: 'Districts' },
    { name: 'categories', value: 'categories.edit', displayName: 'Categories' },
    { name: 'manufacturers', value: 'manufacturers.edit', displayName: 'Products & Categories' },
    { name: 'quotes', value: 'quotes.edit', displayName: 'Quotes' },
    { name: 'adminusers', value: 'adminusers.edit', displayName: 'Admin Users' },
    { name: 'reports', value: 'reports.edit', displayName: 'Reports' },
]

function ClaimsList({ claims, onChange }) {
    return (
        availableClaims.map((baseClaim, idx) => {
            let isChecked = (claims.find((claim) => claim === baseClaim.value) != null);
            return (<ClaimsElement key={"claim_" + idx} claim={baseClaim} onChange={onChange} isChecked={isChecked} />)
        })
    )
}
ClaimsList.propTypes = {
    claims: PropTypes.array,
    onChange: PropTypes.func
}

function ClaimsElement({ claim, isChecked, onChange }) {

    const [checked, setChecked] = useState(isChecked);

    const handleOnChange = (e) => {
        setChecked(e.target.checked);
        onChange(e.target.checked, e.target.value);
    }

    useEffect(() => {
        setChecked(isChecked);
    }, [isChecked])

    return (
        <label key={claim.name} className="admin-inline-label" htmlFor={claim.name + "_access"}>
            <input
                className="admin-inline-checkbox"
                id={claim.name + "_access"}
                type="checkbox"
                value={claim.value}
                checked={checked}
                onChange={handleOnChange}
            />
            <span className="admin-inline-label-text">{claim.displayName}</span>
        </label>
    );
}
ClaimsElement.propTypes = {
    claim: PropTypes.object,
    isChecked: PropTypes.bool,
    onChange: PropTypes.func
}
export default function UserEditPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userId } = useParams();
    const [user, setUser] = useState({
        id: (userId == null ? null : userId),
        fullName: '',
        email: '',
        phone: '',
        password: '',
        userClaims: [],
        organization: '',
        title: '' //role
    });
    const [claims, setClaims] = useState([]);
    const [validation, setValidation] = useState({ phone: true, email: true, fullName: true, organization:true, title:true })
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        //load user info
        if (user.id !== null) {
            dispatch(showLoading());
            getUserDetails(userId)
                .then((response) => {
                    dispatch(hideLoading());
                    if (response.data.success && response.data.data) {
                        let editUser = response.data.data;
                        let claimValues = [];
                        editUser.userClaims.forEach((c) => {
                            claimValues.push(c.key + '.' + c.value)
                        })
                        setClaims(claimValues);
                        setUser({
                            ...response.data.data,
                            id: userId
                        });
                        updateTitle('Admin', 'Update User - ' + response.data.data.fullName);
                        setIsLoading(true);
                    } else {
                        navigate('/admin/users');
                    }
                })
        } else {
            setIsLoading(true);
            updateTitle('Admin', 'Add New User');
        }
    }, []);

    const handleClaimsChange = (isChecked, value) => {
        if (isChecked) setClaims([...claims, value]);
        else setClaims([...claims.filter((claim) => claim !== value)])
    }

    const handleSave = () => {
        if (!ValidateEmail(user.email)) {
            setValidation({ ...validation, email: false });
            setError('Invalid email address');
            return;
        }

        if (user.phone == '') {
            setValidation({ ...validation, phone: false });
            setError('Phone number is required');
            return;
        }

        if (user.phone != '' && validation.phone == false) {
            setValidation({ ...validation, phone: false });
            setError('Invalid phone number');
            return;
        }

        if (user.title == '') {
            setValidation({ ...validation, title: false });
            setError('Please select a role');
            return;
        }

        if (user.organization == '') {
            setValidation({ ...validation, organization: false });
            setError('Organization name is required');
            return;
        }

        dispatch(showLoading());

        let userInfo = user;
        userInfo.userClaims = [];

        if (claims.length > 0) {
            claims.forEach((claim) => {
                userInfo.userClaims.push({ key: claim.split('.')[0], value: claim.split('.')[1] })
            })
        }

        dispatch(showLoading());
        saveUserDetails(userInfo)
            .then((response) => {
                dispatch(hideLoading());
                if (response.data.success) {
                    navigate('/admin/users');
                } else {
                    setError(response.data.message);
                }
            });
    }

    return (
        <div className="admin-page-wrap">
            <div className="admin-top-bar-wrap">
                <div className="admin-top-bar-left">
                    <a className="admin-top-bar-back" href="/admin/users">Back</a>
                </div>
            </div>
            <div className="admin-page-content">
                <h1 className="admin-page-heading">{userId == null ? "Add" : "Edit"} User</h1>
                <AdminError message={error} onClose={() => setError('')}></AdminError>
                <div className="admin-section-row">
                    <div className="admin-section-cell wide">
                        <AdminTextInput
                            label="Full Name"
                            value={user.fullName ?? ''}
                            onChange={(value) => {
                                setUser({ ...user, fullName: value });
                                setValidation({ ...validation, fullName: value != '' });
                            }}
                            isRequired={true}
                        />
                    </div>
                </div>
                <div className="admin-section-row">
                    <div className="admin-section-cell wide">
                        <AdminTextInput
                            id="user_email"
                            inputType="text"
                            label="Email"
                            value={user.email ?? ''}
                            onChange={(value) => {
                                setUser({ ...user, email: value });
                                setValidation({ ...validation, email: ValidateEmail(value) });
                            }}
                            isRequired={true}
                            validated={validation.email }
                            requiredText="A valid email address is required."
                        />
                    </div>
                </div>
                <div className="admin-section-row">
                    <div className="admin-section-cell wide">
                        <AdminSelect
                            id="role"
                            label="Role"
                            value={user.title}
                            onChange={(value) => {
                                setUser({ ...user, title: value });
                                setValidation({ ...validation, title: value != '' });
                            }}
                            isRequired={true}
                            validated={validation.title}
                            requiredText="Role is required"
                            options={[
                                { value: '', label: '[Select A Role]' },
                                { value: 'Project Manager' },
                                { value: 'Account Manager' },
                                { value: 'Designer' },
                                { value: 'Project Coordinator' }
                            ]}
                        ></AdminSelect>
                    </div>
                </div>
                <div className="admin-section-row">
                    <div className="admin-section-cell wide">
                        <AdminTextInput
                            id="company-name"
                            inputType="text"
                            label="Organization"
                            value={user.organization ?? ''}
                            onChange={(value) => {
                                if (value) setUser({ ...user, organization: value });
                                setValidation({ ...validation, organization: value != '' });
                            }}
                            isRequired={true}
                            validated={validation.organization}
                            requiredText="Organization name is required."
                        />
                    </div>
                </div>
                <div className="admin-section-row">
                    <div className="admin-section-cell wide">
                        <AdminPhone
                            id="phone-number"
                            label="Phone Number"
                            type="phone"
                            placeholder=""
                            isRequired={true}
                            value={user.phone ?? ''}
                            loaded={isLoading}
                            validated={validation.phone}
                            onChange={(value) => {
                                setUser({ ...user, phone: value });
                            }}
                            onValidation={(valid) => { setValidation({ ...validation, phone: valid }); }}
                            requiredText="A valid phone number is required"
                        />
                    </div>
                </div>
                <div className="admin-section-row">
                    <div className="admin-section-cell wide">
                        <AdminTextInput
                            label="Password"
                            inputType="password"
                            placeholder={userId == null ? "A random value will be assigned to password if left blank" : "No change to password if left blank"}
                            onChange={(value) => { setUser({ ...user, password: value }) }}
                        />
                    </div>
                </div>
                <div className="admin-section-row access">
                    <div className="admin-section-cell wide">
                        <p className="admin-section-small-headline">Access</p>
                        <ClaimsList claims={claims} onChange={handleClaimsChange} />
                    </div>
                </div>
                <div className="admin-floating-button-wrap">
                    <button className="admin-floating-button"
                        onClick={() => navigate('/admin/users')}
                    >Cancel</button>
                    <button className="admin-floating-button power"
                        onClick={handleSave}>Save Changes</button>
                </div>

            </div>
        </div>
    );
}