import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SiteUserSelect from '../../../components/DistrictQuote/districtSiteUserSelector';
import AdminTextInput from '../../../components/admin/admin-text-input';
import AdminSelect from '../../../components/admin/admin-default-select';
import AdminPhone from '../../../components/admin/admin-phone';
import * as distrctSiteUserApi from '../../../api/districtsiteuserapi';

export function UserRoleModal({ show, onClose, districtId, isEditMode = false, handleAddSiteUser, existingSiteUser = null }) {

    if (!show) {
        return <></>;
    }

    const [user, setUser] = useState(isEditMode ? { siteUserId: null } : {
        siteUserId: null, role: '', companyName: '', phone: '', email: '', fullName: '', notify: false
    });
    const [error, setError] = useState(null);
    const [isExistingAdminChecked, setExistingAdminChecked] = useState(null);
    const [siteUsers, setSiteUsers] = React.useState([]);
    const [districtUsers, setDistrictUsers] = React.useState([]);
    const [createDistrictAdmin, setCreateDistrictAdmin] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [validation, setValidation] = useState({
        role: true,
        siteUserId: true,
        companyName: true
    });

    useEffect(() => {
        if (existingSiteUser != null) {
            setUser({
                siteUserId: existingSiteUser.siteUserId,
                role: existingSiteUser.role,
                fullName: existingSiteUser.siteUsers.fullName,
                email: existingSiteUser.siteUsers.email,
                phone: existingSiteUser.siteUsers.phone,
                companyName: existingSiteUser.siteUsers.organization,
                notify: existingSiteUser.notify
            });
        }
    }, [existingSiteUser]);

    useEffect(() => {
        if (isExistingAdminChecked == true) {
            //load admin users list
            if (siteUsers.length > 0) { return; }
            distrctSiteUserApi.getUsers().then(response => {
                if (response.data.success) {
                    setSiteUsers(response.data.data);

                }
            });
        } else {
            //load district users list
            if (districtUsers.length > 0) { return; }
            distrctSiteUserApi.getDistrictSiteUsers(null).then(response => {
                if (response.data.success) {
                    var users = response.data.data.map(a => {
                        return { id: a.siteUsers.id, email: a.siteUsers.email, fullName: a.siteUsers.fullName };
                    });
                    //remove duplicate accounts
                    users = users.filter((a, i, s) => { return s.map(b => b.id).indexOf(a.id) == i });
                    setDistrictUsers(users);
                }
            });
        }
    }, [isExistingAdminChecked]);

    const handleSelectingUser = (value) => {
        if (value === "ALL") {
            setUser({ ...user, siteUserId: null });
            setSelectedUser({});
        }
        else {
            setValidation({ ...validation, siteUserId: value != '' });
            setError(null);
            var selectedUser = siteUsers.filter(user => user.id === value)[0];
            setUser({ ...user, siteUserId: selectedUser.id, email:selectedUser.email });
            setSelectedUser(selectedUser);
        }
    };

    const handleSelectingDistrictUser = (value) => {
        if (value === "") {
            setSelectedUser({});
        } else if (value == 'new') {
            setCreateDistrictAdmin(true);
        } else {
            setCreateDistrictAdmin(false);
            setValidation({ ...validation, siteUserId: value != '' });
            setError(null);
            var selectedUser = districtUsers.filter(user => user.id === value)[0];
            setUser({ ...user, siteUserId: selectedUser.id, email: selectedUser.email });
            setSelectedUser(selectedUser);
        }
    };

    const handleExistingAdminChange = () => {
        setExistingAdminChecked((isExistingAdminChecked == null || isExistingAdminChecked === false) ? true : false);
    }

    const handleDistrictAdminChange = () => {
        setExistingAdminChecked((isExistingAdminChecked == null || isExistingAdminChecked === true) ? false : true);
    }

    const handleNotifyChange = () => {
        setUser({ ...user, notify: !user.notify });
    };

    const handleSave = async () => {
        var isValid = true;

        var validate = {
            role: true,
            siteUserId: true,
            fullName: true,
            email: true,
            phone: true,
            companyName: true,
            password: true,
        };
        if (user.role == null || user.role == '') {
            setError('Please select a role.');
            validate.role = false;
            isValid = false;
        }
        if (!isExistingAdminChecked && createDistrictAdmin) {
            if (user.fullName == null || user.fullName == '') {
                validate.fullName = false;
                isValid = false;
            }
            if (user.email == null || user.email == '') {
                validate.email = false;
                isValid = false;
            }
            if (user.phone == null || user.phone == '') {
                validate.phone = false;
                isValid = false;
            }
            if (user.companyName == null || user.companyName == '') {
                validate.companyName = false;
                isValid = false;
            }
        } else {
            if (!user.siteUserId) {
                setError('Please select an existing user.');
                validate.siteUserId = false;
                isValid = false;
            }
        }
        setValidation({ ...validation, ...validate });

        if (isValid == false) { return; }

        let roleData = {
            DistrictId: districtId,
            SiteUserId: user.siteUserId,
            Role: user.role,
            Email: user.email,
            FullName: user.fullName,
            Phone: user.phone,
            CompanyName: user.companyName,
            Password: user.password,
            Notify: user.notify,
        };
        handleAddSiteUser(roleData);
        onClose();
    };

    return (
        <div className="admin-modal-overlay">
            <div className="admin-modal admin-add-role">
                <div className="admin-modal-scroll">
                    <button className="admin-modal-close" onClick={onClose}>
                        <span className="material-symbols-rounded">close</span>
                    </button>
                    <h2 className="admin-modal-heading">{isEditMode ? 'Edit District Role' : 'Add District Role'}</h2>
                    {!isEditMode &&
                        <>
                        <div style={{ 'padding-bottom': '0.5rem', 'margin-left':'-20px' }}>
                                <label className="admin-inline-label" htmlFor="existing-admin">
                                    <input
                                        id="existing-admin"
                                        type="radio"
                                        checked={isExistingAdminChecked === true}
                                        onChange={handleExistingAdminChange}
                                    />
                                <span className="admin-inline-label-text">&nbsp; Select Facility Standard Admin</span>
                                </label>
                            </div>
                        <div style={{ 'padding-bottom': '1rem', 'margin-left': '-20px' } }>
                                <label className="admin-inline-label" htmlFor="district-admin">
                                    <input
                                        id="district-admin"
                                        type="radio"
                                        checked={isExistingAdminChecked === false}
                                        onChange={handleDistrictAdminChange}
                                    />
                                    <span className="admin-inline-label-text">&nbsp; Select/Create Supplier Account Manager</span>
                                </label>
                            </div>
                        </>
                    }
                    {isExistingAdminChecked === true &&
                        <div className="admin-modal-row">
                            <div className="admin-modal-cell">
                                <AdminSelect
                                    id="role"
                                    label="Role"
                                    isRequired={true}
                                    value={user.role}
                                    validated={validation.role}
                                    onChange={(value) => {
                                        setUser({ ...user, role: value });
                                        setValidation({ ...validation, role: value != '' });
                                    }}
                                    requiredText="Role is required"
                                    options={[
                                        { value: '', label: '[Select A Role]' },
                                        { value: 'Project Manager' },
                                        { value: 'Account Manager' },
                                        { value: 'Designer' },
                                        { value: 'Project Coordinator' }
                                    ]}
                                ></AdminSelect>
                            </div>
                        </div>
                    }

                    {!isEditMode && isExistingAdminChecked === true &&
                        <div className="admin-modal-row">
                            <div className={"admin-section-cell wide " + (validation.siteUserId === false ? "input-validation-error" : "")}>
                                <label className={`admin-default-label ${isEditMode ? "edit-user-role" : ''}`} htmlFor="quote-status">
                                    User {selectedUser ? '(' + selectedUser.email + ')' : ""}
                                </label>

                                <SiteUserSelect
                                    id="user-role"
                                    selectedId={user.siteUserId != null ? user.siteUserId : ''}
                                    onChange={(e) => handleSelectingUser(e.target.value)}
                                    disabled={isEditMode}
                                />
                                {error && <p className="validation-error-message">{error}</p>}
                            </div>
                        </div>
                    }
                    {isExistingAdminChecked === false && !isEditMode &&
                        <>
                        <div className="admin-modal-row">
                            <div className="admin-modal-cell">
                                <AdminSelect
                                    id="district-user-select"
                                    label="Select Account"
                                    value={createDistrictAdmin == true ? 'new' : user.siteUserId}
                                    onChange={handleSelectingDistrictUser}
                                    options={[
                                        { value: '', label: '[Select A Supplier Account Manager]' },
                                        ...districtUsers.map(a => { return { value: a.id, label: a.fullName + ' (' + a.email + ')' }; }),
                                        //{ value: '', label: '---------------------------------------------------' },
                                        {value:'new', label:'Create New Account Manager'}
                                    ]}
                                ></AdminSelect>
                            </div>
                        </div>
                        {!createDistrictAdmin &&
                            <div className="admin-modal-row">
                                <div className="admin-modal-cell">
                                    <AdminSelect
                                        id="role"
                                        label="Role"
                                        isRequired={true}
                                        value={user.role}
                                        validated={validation.role}
                                        onChange={(value) => {
                                            setUser({ ...user, role: value });
                                            setValidation({ ...validation, role: value != '' });
                                        }}
                                        requiredText="Role is required"
                                        options={[
                                            { value: '', label: '[Select A Role]' },
                                            { value: 'Project Manager' },
                                            { value: 'Account Manager' },
                                            { value: 'Designer' },
                                            { value: 'Project Coordinator' }
                                        ]}
                                    ></AdminSelect>
                                </div>
                            </div>
                        }
                        </>
                    }
                    {((isExistingAdminChecked === false && createDistrictAdmin) || isEditMode) &&
                        <>
                            <div className="admin-modal-row">
                                <div className="admin-modal-cell">
                                    <AdminTextInput
                                        id="full-name"
                                        label="First & Last Name"
                                        placeholder=""
                                        isRequired={true}
                                        value={user.fullName ?? ''}
                                        validated={validation.fullName}
                                        onChange={(value) => {
                                            setUser({ ...user, fullName: value });
                                            setValidation({ ...validation, fullName: value != '' });
                                        }}
                                        requiredText="First & Last Name is required"
                                    />
                                </div>
                            </div>
                            <div className="admin-modal-row">
                                <div className="admin-modal-cell">
                                    <AdminSelect
                                        id="role"
                                        label="Role"
                                        isRequired={true}
                                        value={user.role}
                                        validated={validation.role}
                                        onChange={(value) => {
                                            setUser({ ...user, role: value });
                                            setValidation({ ...validation, role: value != '' });
                                        }}
                                        requiredText="Role is required"
                                        options={[
                                            { value: '', label: '[Select A Role]' },
                                            { value: 'Project Manager' },
                                            { value: 'Account Manager' },
                                            { value: 'Designer' },
                                            { value: 'Project Coordinator' }
                                        ]}
                                    ></AdminSelect>
                                </div>
                            </div>
                            <div className="admin-modal-row">
                                <div className="admin-modal-cell">
                                    <AdminTextInput
                                        id="email-address"
                                        label="Email Address"
                                        placeholder=""
                                        isRequired={true}
                                        value={user.email ?? ''}
                                        validated={validation.email}
                                        onChange={(value) => {
                                            setUser({ ...user, email: value });
                                            setValidation({ ...validation, email: value != '' });
                                        }}
                                        requiredText="Email Address is required"
                                    />
                                </div>
                            </div>

                            <div className="admin-modal-row">
                                <div className="admin-modal-cell">
                                    <AdminPhone
                                        id="phone-number"
                                        label="Phone Number"
                                        type="phone"
                                        placeholder=""
                                        isRequired={true}
                                        value={user.phone ?? ''}
                                        loaded={(isEditMode == true && user.siteUserId != null) || isEditMode == false}
                                        validated={validation.phone}
                                        onChange={(value) => {
                                            setUser({ ...user, phone: value });
                                        }}
                                        onValidation={(valid) => { setValidation({ ...validation, phone: valid }); }}
                                        requiredText="A valid phone number is required"
                                    />
                                </div>
                            </div>
                            <div className="admin-modal-row">
                                <div className="admin-modal-cell">
                                    <AdminTextInput
                                        id="company-name"
                                        label="Organization"
                                        placeholder=""
                                        isRequired={true}
                                        value={user.companyName ?? ''}
                                        validated={validation.companyName}
                                        onChange={(value) => {
                                            setUser({ ...user, companyName: value });
                                            setValidation({ ...validation, companyName: value != '' });
                                        }}
                                        requiredText="Organization is required"
                                    />
                                </div>
                            </div>
                            <div className="admin-modal-row">
                                <div className="admin-modal-cell">
                                    <AdminTextInput
                                        id="password"
                                        label="Password"
                                        inputType="password"
                                        placeholder="Leave blank to let user create their own password"
                                        isRequired={false}
                                        value={user.password ?? ''}
                                        onChange={(value) => {
                                            setUser({ ...user, password: value });
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                    }


                    {isExistingAdminChecked != null &&
                        <div className="admin-modal-row">
                            <div className="admin-modal-cell full">
                                <label className="admin-inline-label" htmlFor="notify">
                                    <input
                                        id="notify"
                                        type="checkbox"
                                        checked={user.notify}
                                        onChange={handleNotifyChange}
                                    />
                                    <span className="admin-inline-label-text">Notify when RFQ&apos;s are submitted to the district</span>
                                </label>
                            </div>
                        </div>
                    }

                    <div className="admin-modal-button-wrap">
                        <button className="admin-modal-button" onClick={onClose}>Cancel</button>

                        {(isExistingAdminChecked != null || isEditMode) &&
                            <button className="admin-modal-button power" onClick={handleSave}>Save Changes</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

UserRoleModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    districtId: PropTypes.string.isRequired,
    isEditMode: PropTypes.bool,
    handleAddSiteUser: PropTypes.func.isRequired,
    existingSiteUser: PropTypes.object
};