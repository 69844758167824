import React from "react"
import { Link } from "react-router-dom"
export const ProjectConfirmation = () => {
    var district = {};
    const districtJson = localStorage.getItem('district') ?? '';
    if (districtJson != '') {
        district = JSON.parse(districtJson);
    }
    return (
        <div className="thank-you-page-wrap">
            <div className="thank-you-page-center">
                <h1 className="thank-you-heading">
                    Thank you for submitting your project!
                </h1>
                <p>
                    Your request has been submitted to management for approval.
                </p>
                {!district.isDemo && <Link to="/my-account"><a className="default-cta">View Previous Projects</a></Link>}
            </div>
        </div>
    )
}