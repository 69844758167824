import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
//import axios from 'axios';
export function ManufacturerGuides({ product, saveDocument, addDocument }) {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [error, setError] = useState('');
    const [validFilesList, setValidFilesList] = useState([]);
    const onDrop = useCallback((acceptedFiles) => {
        const validFileExtensions = ['.pdf', '.rfa', '.rvt', '.cmdrw', '.cmfav', '.dwg'];
        const validFiles = acceptedFiles.filter(
            (file) => validFileExtensions.some(ext => file.name.endsWith(ext))
        );

        if (validFiles.length < acceptedFiles.length) {
            setError('Unsupported file type. Only PDF, Revit, CET, and CAD files are allowed.');
        }else {
            setError('');
            const updatedFiles = validFiles.map((file) => ({
                fileName: file.name,
                FileSize: file.size,
                Title: file.name,
                id: Math.floor(Math.random() * 100000),
                productId: product.id
            }));
            const combinedFiles = [...uploadedFiles, ...updatedFiles];
            setUploadedFiles(combinedFiles);
            saveDocument(combinedFiles);

            setValidFilesList((prevValidFilesList) => [...prevValidFilesList, ...validFiles]);

            addDocument([...validFilesList, ...validFiles]);

        }
    }, [product.id, uploadedFiles, saveDocument]);

    const deleteFile = (index) => {
        const updatedFiles = [...uploadedFiles];     
        updatedFiles.splice(index, 1);
        setUploadedFiles(updatedFiles);
        saveDocument(updatedFiles);


        const updatedFileslist = [...validFilesList];
        updatedFileslist.splice(index, 1);
        setValidFilesList(updatedFileslist);
        addDocument(updatedFileslist);

    };
 

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: '.pdf',
        multiple: false 
    });

    useEffect(() => {
        if (product.documents !== null) {
            setUploadedFiles(product.documents);
        }
    }, [product.documents]);

    return (
        <div className="admin-sortable-wrap">
            {error && <p className="error-message">{error}</p>}
            <div className="admin-sortable-file">
                <ul>
                    {uploadedFiles.map((file, index) => (
                        <li key={index}>
                            <span className="admin-sortable-file-name">{file.fileName}</span>
                            <button
                                className="admin-sortable-file-delete"
                                onClick={() => deleteFile(index)}
                            >
                                <span className="material-symbols-rounded">close</span>
                            </button>
                        </li>
                    ))}
                </ul>
            </div>

            <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                <button className="admin-small-upload-button">Upload File</button>
            </div>
        </div>
    );
}

ManufacturerGuides.propTypes = {
    product: PropTypes.object.isRequired,
    saveDocument: PropTypes.func.isRequired,
    addDocument: PropTypes.func.isRequired
};
export default ManufacturerGuides;