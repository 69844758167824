import api from '../utils/api';


export const getById = (id) => {
    return api({
        url: '/api/typical-layouts/get/' + id,
        method: 'GET',
    });
}

export const getIdBySlug = (slug) => {
    return api({
        url: '/api/typical-layouts/get-by-slug/' + slug + '/' + localStorage.getItem('districtId'),
        method: 'GET',
    });
}

export const getByDistrictId = (districtId, filter) => {
    return api.post('/api/typical-layouts/getByDistrictId/' + districtId, filter);
}

export const getAll = () => {
    return api.post('/api/typical-layouts/get-all');
}

export const deleteTypicalLayoutById = (id) => {
    return api({
        url: '/api/typical-layouts/delete-typical-layout/' + id,
        method: 'GET',
    });
}

export const addTypicalLayout = (addTypicalLayout) => {
    let url = '/api/typical-layouts/';
    if (addTypicalLayout.id == 0) url += 'add';
    else url += 'edit';
    return api({
        url: url,
        method: 'POST',
        data: addTypicalLayout
    })
}

export const addDistrictUserTypicalLayout = (addTypicalLayout) => {
    let url = '/api/district-user/cart/add-typical-layout';
    return api({
        url: url,
        method: 'POST',
        data: addTypicalLayout
    })
}

export const copyTypicalToOtherDistrict = (typicalLayoutId, districtIds) => {
    return api.post('/api/typical-layouts/copy/' + typicalLayoutId,  districtIds);
}

export const copyToDistrict = (districtId, typicalLayoutIds) => {
    return api.post('/api/typical-layouts/copy-to/' + districtId, typicalLayoutIds);
}