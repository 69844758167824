import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import ProductImage from './ProductImage';
import nextArrow from '../../assets/arrow_next.svg';
import prevArrow from '../../assets/arrow_prev.svg';
import { isVimeoVideoExists, processUrl } from '../../helpers/formatterHelper';
import  PlayIcon  from '../../assets/play_arrow.png'
import { getVimeoToken } from '../../api/productapi';

export function ProductGallery({ images, alt }) {	
	const [nav1, setNav1] = useState(null);
	const [nav2, setNav2] = useState(null);
	const slider1Ref = useRef(null);
	const slider2Ref = useRef(null);
	const [openModal, setOpenModal] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [videoUrl, setVideoUrl] = useState("");
	const [type, setType] = useState(0);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [processedItems, setProcessedItems] = useState([]);
	const [vimeoToken, setVimeoToken] = useState("");
	const [currentSlide, setCurrentSlide] = useState(0);

	// open Video modal 
	const openVideo = () => {	
		const selectMediaData = processedItems.find((item, idx) => idx === currentSlide);

		let mediaItem = selectMediaData?.mediaItem;
		setOpenModal(!openModal)
		setPreviewImage(mediaItem?.previewsrc);
		setType(mediaItem?.type);
		if (mediaItem?.type == 1) {


			const mediaData = processUrl(mediaItem?.videoSrc);

			let videoUrl = '';
			if (mediaItem.videoType === 0) {
				videoUrl = `https://www.youtube.com/embed/${mediaData.id}?rel=0&autoplay=1`
			}
			else {
				if (mediaData.type === "vimeo") {
					videoUrl = `https://player.vimeo.com/video/${mediaData.id}?title=0&byline=0&portrait=0&${mediaData.urlval ? `${mediaData.urlval}&` : ''}autoplay=true`;
				}
				if (mediaData.type === "htmlVideo") {
					videoUrl = mediaItem?.videoSrc;
				}
			}


			setVideoUrl(videoUrl);
		}
		setCurrentIndex(currentSlide);
	}

	useEffect(() => {
		getVimeoToken().then((response) => {
			setVimeoToken(response.data);
		});
		setNav1(slider1Ref.current);
		setNav2(slider2Ref.current);
	}, []);



	useEffect(() => {
		const processItems = async () => {
			const itemsWithVideoCheck = await Promise.all(
				images.filter(item => !item.mediaItem?.videoThumbnailUrl?.includes("/static/media/")).map(async (item) => {				
					if (item.mediaItem?.videoType === 1) {
						const videoExists = await isVimeoVideoExists(item.mediaItem?.videoId, vimeoToken);
						return { ...item, videoExists };
					}
					return item;
				})
			);			
			setProcessedItems(itemsWithVideoCheck.filter(x => (x.videoExists === true && x.mediaItem?.videoType === 1) || x.mediaItem?.videoType === 0 || x.mediaItem?.type === 0));
		};

		if (images && images.length > 0) {
			processItems();
		}
	}, [images]);



	const nextSlide = () => {
		const newIndex = currentIndex === processedItems.length - 1 ? 0 : currentIndex + 1;
		const media = processedItems[newIndex];
		if (media) {
			setPreviewImage(media.mediaItem.previewsrc);
			setType(media.mediaItem.type);
			if (media.mediaItem.type == 1) {

				const mediaData = processUrl(media.mediaItem?.videoSrc);


				let videoUrl = '';
				if (media.mediaItem.videoType === 0) {
					videoUrl = `https://www.youtube.com/embed/${mediaData.id}?rel=0&autoplay=1`
				}
				else {
					if (mediaData.type === "vimeo") {
						videoUrl = `https://player.vimeo.com/video/${mediaData.id}?title=0&byline=0&portrait=0&${mediaData.urlval ? `${mediaData.urlval}&` : ''}autoplay=true`;
					}
					if (mediaData.type === "htmlVideo") {
						videoUrl = media.mediaItem?.videoSrc;
					}
				}
				setVideoUrl(videoUrl);
			}
		}
		setCurrentIndex(newIndex);
	};

	const prevSlide = () => {
		const newIndex = currentIndex === 0 ? processedItems.length - 1 : currentIndex - 1;
		const media = processedItems[newIndex];
		if (media) {
			setPreviewImage(media.mediaItem.previewsrc);
			setType(media.mediaItem.type);
			if (media.mediaItem.type == 1) {
				const mediaData = processUrl(media.mediaItem?.videoSrc);


				let videoUrl = '';
				if (media.mediaItem.videoType === 0) {
					videoUrl = `https://www.youtube.com/embed/${mediaData.id}?rel=0&autoplay=1`
				}
				else {
					if (mediaData.type === "vimeo") {
						videoUrl = `https://player.vimeo.com/video/${mediaData.id}?title=0&byline=0&portrait=0&${mediaData.urlval ? `${mediaData.urlval}&` : ''}autoplay=true`;
					}
					if (mediaData.type === "htmlVideo") {
						videoUrl = media.mediaItem?.videoSrc;
					}
				}
				setVideoUrl(videoUrl);
			}
		}
		setCurrentIndex(newIndex);
	}


	const handleBeforeChange = (oldIndex, newIndex) => {
		setCurrentSlide(newIndex);		
	};



	return (
		<>
		<div className="product-info-gallery-wrap prod-bottom">
			<div className="product-gallery-info-inner">
				{images.length > 1 &&
					<div className="product-gallery-thumb-row">
						<Slider
							asNavFor={nav1}
							ref={slider2Ref}
							slidesToShow={1}
							swipeToSlide={true}
							arrows={true}
							infinite={false}
							focusOnSelect={true}
							beforeChange={handleBeforeChange}
							responsive={[
								{
									breakpoint: 900,
									slider: {
										arrows: true,
										slidesToShow: 1,
									},
								},
								{
									breakpoint: 500,
									settings: {
										arrows: false,
										slidesToShow: 2,
									},
								},
								{
									breakpoint: 400,
									settings: {
										arrows: false,
										slidesToShow: 1,
									},
								},
							]}
						>
								{processedItems.map((item, index) => (
								<div key={index} className="product-gallery-thumb-column">
									<ProductImage
										photo={item.mediaItem.previewsrc}
										photoAlt={item.mediaItem.altText ?? alt}
										styles={''}
									/>

									{/* <img
									src={item.mediaItem.previewsrc}
									alt={item.mediaItem.altText}
								/> */}
								</div>
							))}
						</Slider>
					</div>
				}

				<div className="product-gallery-slider-img-wrap">
					<Slider
						asNavFor={nav2}
						ref={slider1Ref}
							className="product-gallery-container"
							fade={true}
						responsive={[
							{
								breakpoint: 900,
								settings: {
									arrows: false,
									slidesToShow: 1,
									dots: true,
								},
							},
						]}
					>
							{processedItems.map((item, index) => (
								
								<div className="product-gallery-slide" key={index}>
									<div className="product-gallery-image-wrap" onClick={openVideo}>
								
										{item.mediaItem?.type == 1 && (
											<div className="play-icon">
												<img src={PlayIcon} alt="play" />
											</div>
											
										)}
									<ProductImage
										photo={item.mediaItem.previewsrc}
										photoAlt={item.mediaItem.altText ?? alt}
										styles={''}
									/>
									
								</div>
								<div className="product-gallery-caption-container">
									<p className="product-gallery-caption">
										Image may not depict the actual color selected
									</p>
								</div>
							</div>
						))}
					</Slider>
				</div>
			</div>
		
		</div>
			{
		openModal && (
			<div className="video-modal">
				<div className="close-modal" onClick={openVideo}>
					<span className="material-symbols-rounded">
						close
					</span>
				</div>
				<div className="video-event-wrap news-video">
					{type == 0 && (<img src={previewImage} alt="video" className="video-box" />)}
					{type == 1 && (
						<iframe
							width="560"
							height="315"
							src={videoUrl}
							title=""
							allow="autoplay;"
							frameBorder="0"
							allowFullScreen
						>
						</iframe>
					)}

				</div>
				{processedItems.length > 1 && (
					<div className="video-arrows-wrap">
						<div className="arrow-modal prev" onClick={prevSlide}>
							<span className="arrow-wrap">
								<img src={prevArrow} alt="prev item" />
							</span>
						</div>
						<div className="arrow-modal next" onClick={nextSlide}>
							<span className="arrow-wrap">
								<img src={nextArrow} alt="next item" />
							</span>
						</div>
					</div>
				)}
			</div>
		)
			}
		</>
	);
}

ProductGallery.propTypes = {
	images: PropTypes.arrayOf(
		PropTypes.shape({
			mediaItem: PropTypes.shape({
				previewsrc: PropTypes.string,
				altText: PropTypes.string,
			}),
		})
	),
	alt: PropTypes.string
};

export default ProductGallery;
