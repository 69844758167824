import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export default function AdminSelect({
    id, value, label, onChange, onValidation, isRequired, requiredText, validated, options
}) {
    const [showError, setShowError] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);

    if (onValidation == null) { onValidation = () => { return true; }; }

    useEffect(() => {
        if (isRequired && hasChanged) setShowError(!onValidation(value));
    }, [value, hasChanged]);

    const handleOnChange = (e) => {
        setHasChanged(true);
        if (onChange) {
            if (isRequired) {
                if (!onValidation(e.target.value)) setShowError(true);
                else setShowError(false);
            }
            onChange(e.target.value);
        }
    }

    return (
        <div className={"admin-default-input-text-wrap" + (showError || validated === false ? " input-validation-error" : "")}>
            <label
                className="admin-default-label"
                htmlFor={id}
            >
                {label}{isRequired ? "*" : ""}
            </label>
            <select
                className={"admin-select"}
                id={id}
                value={value}
                onInput={handleOnChange}
                autoComplete='off'
            >
                {options && options.length > 0 && options.map(a => <option key={label + a.value} value={a.value}>{a.label != null ? a.label : a.value}</option>) }
            </select>

            {showError || validated === false ? <span className="validation-error-message">{requiredText}</span> : <></>}
        </div>
    );
}

AdminSelect.propTypes = {
    id: PropTypes.string,
    value: PropTypes.node,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onValidation: PropTypes.func,
    isRequired: PropTypes.bool,
    requiredText: PropTypes.string,
    validated: PropTypes.bool,
    options: PropTypes.array
}

AdminSelect.defaultProps = {
    isRequired: false,
    validated: true
}
