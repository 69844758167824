import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadGroups } from '../../../store/categories-reducer';
import PropTypes from 'prop-types';

export function CategoriesResults({ product, productItems, saveCategoryItems }) {
    const dispatch = useDispatch();
    const groups = useSelector(state => state.categories.groups);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        dispatch(loadGroups());
        setCategories(productItems);
    }, [dispatch, productItems]);

    const handleCategoryUpdates = (e, categoryId) => {
        const checked = e.target.checked;
        const updatedCategories = checked
            ? [...categories, { categoryId: categoryId, productId: product.id }]
            : categories.filter(category => category.categoryId !== categoryId);

        setCategories(updatedCategories);
        saveCategoryItems(updatedCategories);
    }
    return (
        <>
            {groups.filter(x => x.type===0)
                .map(group => (
                <div className="admin-column" key={group.id}>
                    <p className="admin-section-small-headline">{group.name}</p>
                    {group.categories.map(filteredCategory => (
                        <div key={filteredCategory.id}>
                            <label className="admin-inline-label" htmlFor={filteredCategory.id}>
                                <input
                                    className="admin-inline-checkbox"
                                    id={filteredCategory.id}
                                    type="checkbox"
                                    checked={categories.some(category => category.categoryId === filteredCategory.id)}
                                    onChange={e => handleCategoryUpdates(e, filteredCategory.id)}
                                />
                                <span className="admin-inline-label-text">{filteredCategory.name}</span>
                            </label>
                        </div>
                    ))}
                </div>
            ))}
        </>
    );
}

CategoriesResults.propTypes = {
    productItems: PropTypes.array,
    saveCategoryItems: PropTypes.func,
    product: PropTypes.object,
};

CategoriesResults.defaultProps = {
    productItems: [],
    saveCategoryItems: () => { },
    product: null,
};

export default CategoriesResults;
