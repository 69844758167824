export const TypicalLayoutItems = {
    id: 0,
    districtId: 0,
    title: '',
    description: '',
    isDeleted: false,
    categoryId: 0,
    mediaItems: [],
    products: [],
    IsEditCompleteCart: false
}
