import api from '../utils/api';

// Color Groups API
export const getAllColorGroups = (manufacturerId, config = {}) => {
    const { options } = config;
    return api({
        url: `/api/colorgroups/get-all/${manufacturerId}`,
        method: 'GET',
        ...options,
    });
};

export const addColorGroup = (config = {}) => {
    const { options } = config;
    return api({
        url: '/api/colorgroups/add',
        method: 'POST',
        ...options,
    });
};

export const editColorGroup = (config = {}) => {
    const { options } = config;
    return api({
        url: '/api/colorgroups/edit',
        method: 'POST',
        ...options,
    });
};

export const deleteColorGroup = (id, config = {}) => {
    const { options } = config;
    return api({
        url: `/api/colorgroups/delete/${id}`,
        method: 'DELETE',
        ...options,
    });
};

export const restoreColorGroup = (id, config = {}) => {
    const { options } = config;
    return api({
        url: `/api/colorgroups/restore/${id}`,
        method: 'GET',
        ...options,
    });
};

export const reorderColorGroups = (config = {}) => {
    const { options } = config;
    return api({
        url: '/api/colorgroups/order',
        method: 'POST',
        ...options,
    });
};

// Colors API
export const getColors = (colorGroupId, config = {}) => {
    const { options } = config;
    return api({
        url: `/api/colors/get-all/${colorGroupId}`,
        method: 'GET',
        ...options,
    });
};

export const addColor = (config = {}) => {
    const { options } = config;
    return api({
        url: '/api/colors/add',
        method: 'POST',
        ...options,
    });
};

export const editColor = (config = {}) => {
    const { options } = config;
    return api({
        url: '/api/colors/edit',
        method: 'POST',
        ...options,
    });
};

export const reorderColors = (config = {}) => {
    const { options } = config;
    return api({
        url: '/api/colors/order',
        method: 'POST',
        ...options,
    });
};

export const deleteColor = (id, config = {}) => {
    const { options } = config;
    return api({
        url: `/api/colors/delete/${id}`,
        method: 'DELETE',
        ...options,
    });
};

export const restoreColor = (id, config = {}) => {
    const { options } = config;
    return api({
        url: `/api/colors/restore/${id}`,
        method: 'GET',
        ...options,
    });
};
