import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

function AdminModalColorPicker({ onColorChange, selectedColor, label }) {
	const hexToRgb = (hex) => ({
		r: parseInt(hex.substring(1, 3), 16),
		g: parseInt(hex.substring(3, 5), 16),
		b: parseInt(hex.substring(5, 7), 16),
	});
	const [color, setColor] = useState(hexToRgb(selectedColor));
	const [hexInput, setHexInput] = useState(
		`#${color.r.toString(16).padStart(2, '0')}${color.g
			.toString(16)
			.padStart(2, '0')}${color.b.toString(16).padStart(2, '0')}`
	);

	const handleHexInputChange = (e) => {
		setHexInput(e.target.value);
	};

  const handleHexInputBlur = () => {
    let hex = hexInput.trim().toUpperCase();
    if (hex.startsWith('#')) {
      hex = hex.substring(1); // Remove the '#' symbol if present
    }
    if (hex.length === 3) {
      hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }
    if (/^[0-9A-F]{6}$/.test(hex)) {
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
      setColor({ r, g, b });
      onColorChange(`#${hex}`);
      setHexInput(hex); // Update the input with the formatted hex value (without '#')
    } else {
      // Revert to the current color if the input is invalid
      setHexInput(`${color.r.toString(16).padStart(2, '0')}${color.g.toString(16).padStart(2, '0')}${color.b.toString(16).padStart(2, '0')}`);
    }
  };
  

  useEffect(() => {
    const hex = `${color.r.toString(16).padStart(2, '0')}${color.g
      .toString(16)
      .padStart(2, '0')}${color.b.toString(16).padStart(2, '0')}`;
    setHexInput(hex); // Update hexInput with the new hex value
    onColorChange(`#${hex}`);
  }, [color, onColorChange]);
  

	const handleRGBChange = (field, e) => {
		setColor({ ...color, [field]: parseInt(e.target.value, 10) });
	};

	const colorPreviewStyle = {
		background: `rgb(${color.r}, ${color.g}, ${color.b})`,
	};

	return (
		<fieldset className="admin-modal-color-picker-wrap">
			<legend className="admin-default-label admin-legend-label">{label != null ? label : 'Select Color'}</legend>
			<div className="admin-modal-color-picker-flex">
				<div
					className="admin-modal-color-picker-preview"
					style={colorPreviewStyle}
				></div>
				<div className="admin-modal-color-picker-column">
					<div className="admin-modal-input-text-wrap inline small">
						<label className="admin-default-label" htmlFor="rgbR">
							R
						</label>
						<input
							className="admin-default-input-text"
							type="number"
							id="rgbR"
							value={color.r}
							min="0"
							max="255"
							onChange={(e) => handleRGBChange('r', e)}
						/>
					</div>
					<div className="admin-modal-input-text-wrap inline small">
						<label className="admin-default-label" htmlFor="rgbG">
							G
						</label>
						<input
							className="admin-default-input-text"
							type="number"
							id="rgbG"
							value={color.g}
							min="0"
							max="255"
							onChange={(e) => handleRGBChange('g', e)}
						/>
					</div>
					<div className="admin-modal-input-text-wrap inline small">
						<label className="admin-default-label" htmlFor="rgbB">
							B
						</label>
						<input
							className="admin-default-input-text"
							type="number"
							id="rgbB"
							value={color.b}
							min="0"
							max="255"
							onChange={(e) => handleRGBChange('b', e)}
						/>
					</div>
				</div>
			</div>
			<div className="admin-modal-input-text-wrap inline small">
				<label className="admin-default-label" htmlFor="rgbHex">
					Hex
				</label>
				<input
					className="admin-default-input-text"
					type="text"
					id="rgbHex"
					value={hexInput}
					onChange={handleHexInputChange}
					onBlur={handleHexInputBlur}
				/>
			</div>
		</fieldset>
	);
}

export default AdminModalColorPicker;
AdminModalColorPicker.propTypes = {
	onColorChange: PropTypes.func.isRequired,
	selectedColor: PropTypes.string.isRequired,
	label: PropTypes.string,
};
