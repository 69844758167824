import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as distrctSiteUserApi from '../../api/districtsiteuserapi';


function UserListSelector({ selectedId, userList, onChange, id, disabled }) {
    return (
        <select className="admin-select" id={id}
            onChange={onChange}
            value={selectedId}
            disabled={disabled}
        >
            <option value="ALL">Select user</option>
            {userList.map((user) =>
            (<option
                key={user.id}
                value={user.id}
            >{user.fullName + ' (' + user.email + ')'}</option>)
            )}
        </select>
    )
}
UserListSelector.propTypes = {
    selectedId: PropTypes.string,
    userList: PropTypes.array,
    onChange: PropTypes.func,
    id: PropTypes.string,
    disabled: PropTypes.bool
}

export default function SiteUserSelect({ selectedId, onChange, id, disabled }) {
    const [districtSiteUsers, setDistirctSiteUser] = React.useState([]);

    const getItems = async () => {
        const result = await distrctSiteUserApi.getUsers();
        if (result.data.success) {
           
            setDistirctSiteUser(result.data.data);
        }
    }

    useEffect(() => {
        getItems()
            .catch(console.error);
    }, []);

    return (
        <>
            <UserListSelector
                id={id}
                selectedId={selectedId}
                userList={districtSiteUsers}
                onChange={onChange}
                disabled={disabled}
            />
        </>
    )
}

SiteUserSelect.propTypes = {
    id: PropTypes.string,
    selectedId: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
}