import { createSlice } from '@reduxjs/toolkit';
import {update, getAll, add, remove, moveToCart } from '../api/wishlistapi'
import { addItemToMyProject } from './myproject-reducer';
const projectWishlistSlice = createSlice({
    name: 'MyWishList',
    initialState: {
        data: {
            items: [],
            totalQuantity: 0,
        },
        saveStatus: 'initial', // 'saving', 'succeeded', 'failed', 'idle
		saveError: null,
    },
    reducers: {
         loadAllWishlistProducts(state, action) {
            const newItems = action.payload;
            state.data.items = [];
            state.data.totalQuantity=0;
            newItems.map((item)=>{
                state.data.items.push(item);
                state.data.totalQuantity += item.quantity;
            } )
        },
        addItemToWishList(state, action) {
            const newItem = action.payload;
            const existingItemIndex = state.data.items.findIndex(
                item => item.cartId === newItem.cartId
            );
            if (existingItemIndex >= 0) {
                state.data.items[existingItemIndex].quantity += newItem.quantity;
            } else {
                state.data.items.push(newItem);
            }
            state.data.totalQuantity += newItem.quantity;
        },
        removeItemFromWishList(state, action) {
            const cartIdToRemove = action.payload;
        
            const itemToRemove = state.data.items.find(item => item.cartId === cartIdToRemove);
            if (itemToRemove) {
                state.data.totalQuantity -= itemToRemove.quantity;
            }
        
            state.data.items = state.data.items.filter(item => item.cartId !== cartIdToRemove);
        },
        updateItemQuantityInWishList(state, action) {
            const { cartId, quantity } = action.payload;
            const existingItemIndex = state.data.items.findIndex(item => item.cartId === cartId);
            if (existingItemIndex >= 0) {
                const item = state.data.items[existingItemIndex];
                state.data.totalQuantity += (quantity - item.quantity);
                item.quantity = quantity;
            }
        },
        saveStarted: (state) => {
			state.saveStatus = 'saving';
		},
		saveSucceeded: (state) => {
			state.saveStatus = 'succeeded';
		},
		saveFailed: (state, action) => {
			state.saveStatus = 'failed';
			state.saveError = action.payload;
		},
		resetSaveStatus: (state) => {
			state.saveStatus = 'idle';
		},
    },
});

export const {
    addItemToWishList,
    removeItemFromWishList,
    updateItemQuantityInWishList,
    saveStarted,
	saveSucceeded,
	saveFailed,
    loadAllWishlistProducts
} = projectWishlistSlice.actions;

export default projectWishlistSlice.reducer;

export const loadAllWishlistItems = () => async (dispatch) => {
    try {
        const response = await getAll();
        if (response.data.success) {
            dispatch(loadAllWishlistProducts(response.data.data));
        } else {
            dispatch(saveFailed(response.data.message));
        }
    } catch (error) {
        dispatch(saveFailed(error.message));
        console.error('Error loading all items:', error);
    }
};

export const addNewWishlistItem = (productDetails) => async (dispatch) => {
    dispatch(saveStarted());
    const { productId, quantity, optionItemIds } = productDetails;
    try {
        const response = await add(productId, quantity, optionItemIds);
        if (response.data.success) {
            dispatch(loadAllWishlistItems());
            dispatch(saveSucceeded());
            dispatch(loadAllWishlistItems());
        } else {
            dispatch(saveFailed(response.data.message));
        }
    } catch (error) {
        dispatch(saveFailed(error.message));
        console.error('Error adding new item:', error);
    }
};

export const removeItem = (cartId) => async (dispatch) => {
    dispatch(saveStarted());
    try {
        const response = await remove(cartId);
        if (response.data.success) {
            dispatch(loadAllWishlistItems());
            dispatch(saveSucceeded());
        } else {
            dispatch(saveFailed(response.data.message));
        }
    } catch (error) {
        dispatch(saveFailed(error.message));
        console.error('Error removing item:', error);
    }
};

export const moveItemToCart = (cartId) => async (dispatch) => {
    try {
        const response = await moveToCart(cartId);
        if (response.data.success) {
            dispatch(loadAllWishlistItems());
            dispatch(addItemToMyProject(response.data.data));
            dispatch(saveSucceeded());

        }
    } catch (error) {
        dispatch(saveFailed(error.message));
        console.error('Error moving item to wishlist:', error);
    }
};

export const updateMyWishlistQuantity = (productQuantity) => async (dispatch) => {
	dispatch(saveStarted());
    const{ cartId, quantity} = productQuantity;
	try {
		const response = await update(cartId, quantity);
        if (response.data.success) {
            dispatch(loadAllWishlistItems());
			dispatch(saveSucceeded());
		} else {
			dispatch(saveFailed(response.data.message));
		}
	} catch (error) {
		dispatch(saveFailed(error.message));
		console.error('There was an error editing the My Project:', error);
	}
};