import { createAsyncThunk } from '@reduxjs/toolkit';
import * as surveyApi from '../../api/surveyapi';

export const getSurveyList = createAsyncThunk('getSurveyList', async (data) => {
    const res = await surveyApi.getSurveyList({ data: data });
    return res.data;
});

export const saveSurvey = createAsyncThunk('saveSurvey', async (data) => {
    const res = await surveyApi.saveSurvey({ data: data });
    return res.data;
});
export const editSurvey = createAsyncThunk('editSurvey', async (data) => {
    const res = await surveyApi.editSurvey({ data: data });
    return res.data;
});

export const deleteSurvey = createAsyncThunk('deleteSurvey', async (data) => {
    const res = await surveyApi.deleteSurvey({ data: data });
    return res.data;
});