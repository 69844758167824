import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from "@reduxjs/toolkit";
import ScrollToTop from '../components/common/ScrollToTop';
import Logo from '../assets/facility-standard-logo.svg'
import Menu from '../assets/icons/menu.svg'
import Lock from '../assets/icons/lock.svg'
import Close from '../assets/icons/white-close.svg'
import RequestDemo from '../components/requestDemo/requestDemo';
import User from '../assets/icons/user.png'
import { getQuoteIdByReviewing } from '../api/districtquoteapi';
import { userLogin } from '../store/auth/actions';
import './HomeLayout.css';

export default function HomeLayout({children, isMainPage }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [getNavbar, setNavbar] = useState('');
    const [getHeaderModal, setHeaderModal] = useState(isMainPage);
    const [getLockModal, setLockModal] = useState('');
    const [getLoginInputs, setLoginInputs] = useState({
        username: '',
        password: ''
    });
    const [getRequest, setRequest] = useState('');
    const [loginError, setLoginError] = useState();
    const [submittingLogin, setSubmittingLogin] = useState(false);
    const { from } = location.state || { from: { pathname: null } }; // Fallback to home if no redirect path
    const [showCopyright, setShowCopyright] = useState(false);
    const currentYear = new Date().getFullYear();
    const login = bindActionCreators(userLogin, dispatch);

    const mobNavbar = () => {
        setNavbar(!getNavbar);
    };
    const toggleLoginModal = () => {
        setHeaderModal(!getHeaderModal);
    };
    const toggleLockModal = () => {
        setLockModal(!getLockModal)
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (getHeaderModal && !event.target.closest('.header-modal')) {
                setHeaderModal(false);
            }
            if (getLockModal && !event.target.closest('.header-modal')) {
                setLockModal(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [getHeaderModal, getLockModal]);

    const handleLoginInputChange = (e) => {
        const { name, value } = e.target;
        setLoginInputs({ ...getLoginInputs, [name]: value });
    };

    const requestDemo = () => {
        setRequest(!getRequest);
    };

    const submitLogin = async () => {
        var username = getLoginInputs.username;
        var password = getLoginInputs.password;

        let hasError = false;
        if (document.querySelector('.input-validation-error')) {
            document.querySelectorAll('.input-validation-error').forEach((element) => {
                element.classList.remove('input-validation-error');
            })
        }

        if (username === '' || username === null) {
            setLoginError('Please enter your username/email');
            hasError = true;
        }
        if (password === '' || password === null) {
            setLoginError('Please enter your password');
            hasError = true;
        }
        if (hasError) {
            return;
        }

        let data = {
            "Username": username,
            "Password": password
        }

        setSubmittingLogin(true);
        const result = await login({ data: data }).unwrap();

        if (result.data) {
            setSubmittingLogin(false)
            if (result.data.success) {
                localStorage.removeItem("editTypicalLayout");
                localStorage.removeItem("typicallayout");
                var districtId = localStorage.getItem('districtId');
                if (!districtId && result.data.data && result.data.data.district) {
                    districtId = result.data.data.district.id;
                    localStorage.setItem('districtId', districtId);
                    localStorage.setItem('district', JSON.stringify(result.data.data.district));
                }
                if (districtId && !result.data.data.isAdmin) {
                    getQuoteIdByReviewing(districtId).then(response => {
                        if (response.data.success == true) {
                            //set the quoteId that the user is currently editing in place of their cart
                            localStorage.setItem('editQuoteId', response.data.data);
                            if (from && from.pathname) {
                                navigate(from.pathname);
                            }
                            if (result.data.data.isAdmin) navigate('/admin');
                            else navigate("/home");
                        }
                    });
                } else {
                    if (result.data.data.isAdmin) {
                        if (from && from.pathname) {
                            navigate(from.pathname);
                        } else {
                            navigate('/admin');
                        }
                    }
                    else { navigate("/home"); }
                }

            } else {
                setLoginError(result.data.message);
            }
        }
    }

    return (
        <div className="page-content">
            <ScrollToTop />
            <section className="main-container">
                <nav className={'main-nav ' + (getHeaderModal ? 'openModalHeader' : '')}>
                    <div className="navbar-brand w-50">
                        <div className="menu-btn pointer" onClick={mobNavbar}>
                            <img src={Menu} className="w-100" alt="menu" />
                        </div>
                        <a href="/" title="Home">
                            <img src={Logo} className="w-100" alt="Facility Standard logo" />
                        </a>
                        <div className="lock-btn pointer" onClick={toggleLockModal}>
                            <img src={Lock} className="w-100" alt="lock" />
                        </div>
                    </div>

                    <div className={`menu-bar w-50 ${getNavbar ? 'mobNavbar' : ''}`}>
                        <div className="closeing-menu" onClick={mobNavbar}></div>
                        <ul className="navbar-nav d-flex">
                            <li className="navbarClose">
                                <button className="pointer" onClick={mobNavbar}>
                                    <img src={Close} alt="close" />
                                </button>
                            </li>
                            <li className="nav-item menu-icon d-none">
                                Menu
                            </li>
                            <li className="nav-item">
                                <a href="/demo" className="nav-link">Try It Live</a>
                            </li>
                            <li className="nav-item">
                                <a href="javascript:" onClick={requestDemo} className="nav-link">Contact</a>
                            </li>
                            <li className="nav-item relative userlogin-btn">
                                <a href="javascript:" onClick={toggleLoginModal} className="nav-link">Login</a>
                                {(getHeaderModal || isMainPage) &&
                                    <div className={`header-modal for-login ${getHeaderModal ? "openModal" : ''}`}>
                                        <p className="header-modal-title">
                                            <img src={User} alt="user" /> Login
                                        </p>
                                        <div className="header-modal-body modal-form">
                                            <div className="input-box relative">
                                                <input type="text" className="input-box"
                                                    id="user_id"
                                                    aria-label="User ID"
                                                    name="username"
                                                    value={getLoginInputs.username}
                                                    onChange={handleLoginInputChange} />
                                                <label htmlFor="user_id" className={`absolute ${getLoginInputs.username && "filled"}`}>User ID</label>
                                            </div>
                                            <div className="input-box relative">
                                                <input type="password"
                                                    className="input-box"
                                                    id="password"
                                                    aria-label="Password"
                                                    name="password"
                                                    value={getLoginInputs.password}
                                                    onChange={handleLoginInputChange}
                                                />
                                                <label htmlFor="password" className={`absolute ${getLoginInputs.password && "filled"}`}>Password</label>
                                            </div>
                                            {loginError &&
                                                <span className="validation-error-message">{loginError}</span>
                                            }
                                            <button
                                                className={`solid-primary-btn ${submittingLogin === true ?
                                                    "waitMessage" : ""}`}
                                                onClick={submitLogin}
                                            >
                                                {submittingLogin == false ? "Login" : "Please wait..."}
                                            </button>
                                            <div className="row forgot">
                                                <a href="/account/forgot-password">Forgot Password?</a>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </li>
                        </ul>
                    </div>
                </nav>
            </section>

            {!isMainPage &&
                <div className="banner-wrapper nav-bg"></div>
            }

            {children}

            {showCopyright &&
                <div className="admin-modal-overlay">
                    <div className={"admin-modal admin-add-role admin-user-width"}>
                        <div className="admin-modal-scroll">
                            <button className="admin-modal-close" onClick={() => { setShowCopyright(false); }}>
                                <span className="material-symbols-rounded">close</span>
                            </button>
                            <h2 className="admin-modal-heading">&copy; 2024 by Facility Standard&trade;</h2>
                            <p>All unauthorized reproduction, distribution, or displaying of materials on this site is prohibited by law</p>
                            <br/>
                            <div className="admin-modal-button-wrap">
                                <button className="admin-modal-button power" onClick={() => { setShowCopyright(false); }}>Okay</button>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <section className="footer-wrapper">
                <div className="main-container">
                    <div className="d-flex justify-between items-center footer-logo-wrap">
                        <div className="footer-logo-col">
                            <a href="/" title="Home" className="footer-logo">
                                <img src={Logo} className="w-100" alt="Facility Standard logo" />
                            </a>
                            <a href="tel:+1-816-768-6179" className="web-info">(816) 768-6179</a>
                            <a href="mailto:contact@facilitystandard.com" className="web-info">support@facilitystandard.com</a>
                        </div>
                        <div className="footer-btn">
                            <a href="/demo" className="solid-primary-btn">Try It Now</a>
                        </div>
                    </div>
                    <div className="footer-links">
                        <ul>
                            <li>
                                <a href="javascript:" onClick={() => { setShowCopyright(true); }}>&copy; {currentYear} by Facility Standard&trade;</a>
                            </li>
                            <li>
                                <a href="/privacy-policy">Privacy Policy</a>
                            </li>
                            <li>
                                <a href="/terms-of-service">Terms of Use</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            {getRequest &&
                <RequestDemo onClose={() => { setRequest(false); }}></RequestDemo>
            }
            <section className={`lock-modal-wrapper dnone ${getLockModal ? "lockModal" : ''}`}>
                <div className={`header-modal for-login ${getLockModal ? "openModal" : ''}`}>
                    <p className="header-modal-title">
                        <img src={User} alt="user" />
                        Login
                    </p>
                    <div className="header-modal-body modal-form">
                        <div className="input-box relative">
                            <input type="text" className="input-box"
                                name="username"
                                aria-label="User ID"
                                value={getLoginInputs.username}
                                onChange={handleLoginInputChange} />
                            <label className={`absolute ${getLoginInputs.username && "filled"}`}>User ID</label>
                        </div>
                        <div className="input-box relative">
                            <input type="password"
                                className="input-box"
                                aria-label="Password"
                                name="password"
                                value={getLoginInputs.password}
                                onChange={handleLoginInputChange}
                            />
                            <label className={`absolute ${getLoginInputs.password && "filled"}`}>Password</label>
                        </div>
                        {loginError &&
                            <span className="validation-error-message">{loginError}</span>
                        }
                        <button
                            className={`solid-primary-btn ${submittingLogin === true ?
                                "waitMessage" : ""}`}
                            onClick={submitLogin}
                        >
                            {submittingLogin == false ? "Login" : "Please wait..."}
                        </button>
                        <div className="row forgot">
                            <a href="/account/forgot-password">Forgot Password?</a>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}

HomeLayout.propTypes = {
    children: PropTypes.node,
    isMainPage: PropTypes.boolean
}

HomeLayout.defaultProps = {
    isMainPage: false
}
