import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import DragDropContainer from "../../../components/DragAndDropContainer";
import CurrencyInput from 'react-currency-input-field';
import MediaUploader from '../../../components/media/MediaUploader.js'
import { defaultProductOption, defaultProductOptionItem } from './productOptions';

export function DraggableOptions({ optionItems, optionType, onChange, IsChecked, productOptionData }) {

    const [productOptionItems, setProductOptionItems] = useState(optionItems.sort((a, b) => a.order - b.order));
    const [isCheckedbox, setIsChecked] = useState(true);

  const addEmptyItem = () => {
    let newOption = Object.assign({}, defaultProductOptionItem);
    newOption.order = productOptionItems.length;
    setProductOptionItems(productOptionItems => [...productOptionItems, newOption]);
  }

  const handleDeleteItem = (setIndex) => {
    let updatedOptions = [...productOptionItems];
    updatedOptions.splice(setIndex, 1);
    updatedOptions.map((item, idx) => {
      item.order = idx;
      return item;
    });
    if (updatedOptions.length == 0) updatedOptions = [Object.assign({}, defaultProductOptionItem)];
    setProductOptionItems(updatedOptions);
  }

  useEffect(() => {
      if (optionItems == null || optionItems.length == 0) setProductOptionItems([Object.assign({}, defaultProductOptionItem)]);   
      setIsChecked(productOptionData.isRequired);
  }, [])

  useEffect(() => {
      if (onChange) return onChange(productOptionItems);
  }, [productOptionItems])

  const reOrderItems = (fromIndex, toIndex, onDrop) => {
    if (onDrop) {
      const updatedOptions = [...productOptionItems];
      const [movedItem] = updatedOptions.splice(fromIndex, 1);
      updatedOptions.splice(toIndex, 0, movedItem);
      updatedOptions.map((item, idx) => {
        item.order = idx;
        return item;
      });
      setProductOptionItems(updatedOptions);
    }
  }

  const handleFieldChange = (setIndex, fieldName, value) => {
    const updatedItems = productOptionItems.map((item) => {
      if (item.order === setIndex) item[fieldName] = value;
      return item;
    })
    setProductOptionItems(updatedItems);
  }


    ////////// CheckBox
    const handleCheckboxChange = () => {
        setIsChecked(!isCheckedbox);
        IsChecked(!isCheckedbox);
    };

  const renderItems = (optionItem, setIndex) => {
    return (
      <div className={"admin-draggable-editable-option" + (setIndex > 0 ? " display-seperator" : "")} key={"data-row_" + setIndex}>
        {optionType === 3 || optionType === 4 ?
          (<div className="admin-draggable-editable-option-cell auto">

            <div className="admin-draggable-editable-option-cell auto">
              <label className="admin-default-label" htmlFor="option02-image">Image</label>
              <div className="admin-media-wrap">
                <MediaUploader
                  imageOnly={true}
                  videoOnly={false}
                  forceCrop={false}
                  allowGallery={false}
                  mediaItems={'mediaItem' in productOptionItems[setIndex] && productOptionItems[setIndex].mediaItem != null ? [productOptionItems[setIndex].mediaItem] : []}
                  saveMediaItems={(mediaItems) => {
                    handleFieldChange(setIndex, 'mediaItemId', (mediaItems.length > 0 ? mediaItems[0].id : null));
                    handleFieldChange(setIndex, 'mediaItem', mediaItems[0]);
                  }}
                  requireAltText={false}
                />
              </div>
            </div>
          </div>) : null}
        <div className="admin-draggable-editable-option-cell auto">
          <div className="admin-draggable-editable-option-form">
            <div className="admin-draggable-editable-option-form-row">
              <div className="admin-draggable-editable-option-form-cell wide">
                {optionType === 3 || optionType === 4 ?
                  <label className="admin-default-label" htmlFor="option02-title">Title</label>
                  : <label className="admin-draggable-editable-option-label" htmlFor="option02-title">Name</label>
                }
                <input type="text"
                  className="admin-draggable-editable-option-input"
                  value={productOptionItems[setIndex].title}
                  onChange={(e) => handleFieldChange(setIndex, 'title', e.target.value)}
                />
              </div>
              <div className="admin-draggable-editable-option-form-cell wide">
                {optionType === 3 || optionType === 4 ?
                  <label className="admin-default-label" htmlFor="option02-title">Code</label>
                  : <label className="admin-draggable-editable-option-label" htmlFor="option02-title">Code</label>
                }
                <input type="text"
                  value={productOptionItems[setIndex].code}
                  onChange={(e) => handleFieldChange(setIndex, 'code', e.target.value)}
                  className="admin-draggable-editable-option-input" />
              </div>
              <div className="admin-draggable-editable-option-form-cell wide">
                {optionType === 3 || optionType === 4 ?
                  <label className="admin-default-label" htmlFor="option02-title">Up Charge</label>
                  : <label className="admin-draggable-editable-option-label" htmlFor="option02-title">Up Charge</label>
                }
                <CurrencyInput
                  className="admin-default-input-text"
                  name={'adeo${setIndex}-upcharge'}
                  id={`adeo${setIndex}-upcharge`}
                  value={isNaN(productOptionItems[setIndex].upCharge) ? null : productOptionItems[setIndex].upCharge}
                  onValueChange={(value) => {
                    handleFieldChange(setIndex, 'upCharge', isNaN(value) ? null : value);
                  }}
                  decimalSeparator="."
                  groupSeparator=","
                  prefix="$"
                />
              </div>
            </div>
            {optionType === 3 || optionType === 4 ?
              <div className="admin-draggable-editable-option-form-row">
                <div className="admin-draggable-editable-option-form-cell wide">
                  <label className="admin-default-label" htmlFor="option02-title">Short Description</label>
                  <input
                    type="text"
                    className="admin-default-input-text"
                    value={productOptionItems[setIndex].description}
                    onChange={(e) => {
                      handleFieldChange(setIndex, 'description', e.target.value);
                    }}
                  />
                </div>
              </div> : null}
          </div>
        </div>
        <div className="admin-draggable-editable-option-cell actions">
          {optionType === 3 || optionType === 4 ?
            <label className="admin-default-label" htmlFor="option02-title">&nbsp;</label>
            : null
          }
          <button className="admin-draggable-editable-action red" onClick={() => handleDeleteItem(setIndex)}>
            <span className="material-symbols-rounded">delete</span>
          </button>
        </div>
      </div>
    );
  }

    return (<>

        

        <div className="admin-modal-row full wrap">
            <div className="admin-modal-required-checkbox">
                <label className="admin-inline-label">
                    <input
                        className="admin-inline-checkbox"
                        type="checkbox"
                        checked={isCheckedbox}
                        onChange={handleCheckboxChange}
                    />
                    <span className="admin-inline-label-text">Required</span>
                </label>
            </div>
      <div className="admin-draggable-editable-option-wrap">
        {optionType === 0 || optionType === 1 ?
          <div className="admin-draggable-editable-header-row">
            <div className="admin-draggable-editable-header-cell">
              <div className="admin-draggable-editable-header">Name</div>
            </div>
            <div className="admin-draggable-editable-header-cell">
              <div className="admin-draggable-editable-header">Code</div>
            </div>
            <div className="admin-draggable-editable-header-cell">
              <div className="admin-draggable-editable-header">Up Charge</div>
            </div>
            <div className="admin-draggable-editable-header-cell actions">&nbsp;</div>
          </div> : null}
        <DragDropContainer
          items={productOptionItems}
          moveItem={reOrderItems}
          renderItem={renderItems}
          isHorizontal={false}
        />
      </div>
      <button onClick={addEmptyItem} className="admin-add-option">
        <span className="material-symbols-rounded">add</span>
      </button>
    </div>
  </>);
}

DraggableOptions.propTypes = {
  optionItems: PropTypes.array,
  optionType: PropTypes.number,
    onChange: PropTypes.func,
    IsChecked: PropTypes.func,
    productOptionData: PropTypes.object
}

export function ColorOptions({ colorGroups, editColorGroup, onChange, handleChecked }) {
  const [selectedGroup, setSelectedGroup] = useState(editColorGroup.colorGroupId ?? 0);
  const [colors, setColors] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]); 
  const [isCheckedbox, setIsCheckedbox] = useState(editColorGroup && editColorGroup.isRequired);

  useEffect(() => {
    if (editColorGroup != null && editColorGroup.items != null ) {
      const selectedGroupDetails = colorGroups.filter(x => x.id === parseInt(selectedGroup))[0];
      if (selectedGroupDetails && selectedGroupDetails.colors) {
          setColors(selectedGroupDetails.colors);
      } else {
          setColors([]);
      }
        setSelectedColors(editColorGroup.items);
      }
      if (editColorGroup != null) {
          setIsCheckedbox(editColorGroup.isRequired);
      }      
  }, []);


  useEffect(() => {
    if (selectedGroup > 0 && onChange) {
      const selectedGroupDetails = colorGroups.filter(x => x.id === parseInt(selectedGroup))[0];
      if (selectedGroupDetails) {
          onChange(selectedColors, selectedGroupDetails);
      }
  }
  
    }, [selectedColors])

  const handleSelectedGroupChange = (e) => {
    setSelectedGroup(e.target.value);
    const selectedGroupDetails = colorGroups.filter(x => x.id === parseInt(e.target.value))[0];
    if (selectedGroupDetails && selectedGroupDetails.colors) {
        setColors(selectedGroupDetails.colors);
    } else {
        setColors([]);
    }    setSelectedColors([]);
  }

    const handleColorChange = (e, colorObj) => {

      if (e.target.checked) {
          setSelectedColors([...selectedColors, { colorId: colorObj.id, title: colorObj.name, order: colorObj.order}]);
    } else {
      let newColors = selectedColors.filter(x => x.colorId !== colorObj.id);
      setSelectedColors(newColors);
    }
    }

    const handleCheckboxChange = () => {
        setIsCheckedbox(!isCheckedbox);
        handleChecked(!isCheckedbox);
    };

    return (<>

        <div className="admin-modal-required-checkbox">
            <label className="admin-inline-label">
                <input
                    className="admin-inline-checkbox"
                    type="checkbox"
                    checked={isCheckedbox}
                    onChange={handleCheckboxChange}
                />
                <span className="admin-inline-label-text">Required</span>
            </label>
        </div>


    <div className="admin-modal-row full">
      <div className="admin-modal-cell wide">
        <label className="admin-default-label" htmlFor="color-set">Select Color Set</label>
        <select className="admin-default-select"
          id="color-set"
          value={selectedGroup}
          onChange={handleSelectedGroupChange}
        >
          <option value={0}>Select Color set</option>
          {colorGroups.map(group => (
            <option key={group.id} value={group.id}>{group.name}</option>
          ))}
        </select>
      </div>
    </div>
    {selectedGroup !== 0 ? (
      <>
        <div className="admin-modal-cell">
          {colors.slice(0, Math.floor(colors.length / 2)).map((color, colorIndex) => (
            <label className="admin-checkbox-label" key={colorIndex} htmlFor={"color_" + colorIndex}>
              <input className="admin-checkbox"
                id={"color_" + color.id}
                type="checkbox"
                value={color.id}
                checked={selectedColors.filter(x => x.colorId === color.id).length > 0}
                onChange={(e) => handleColorChange(e, color)}
              />
              <span>{color.name}</span>
              <span className="admin-label-preview" style={{ background: color.hex }}>
                {('colorImage' in color ? <img src={color.colorImage.previewsrc} /> : '')}
              </span>
            </label>
          ))}
        </div>
        <div className="admin-modal-cell">
          {colors.slice(Math.floor(colors.length / 2)).map((color, colorIndex) => (
            <label className="admin-checkbox-label" key={colorIndex} htmlFor={"color_" + colorIndex}>
              <input className="admin-checkbox"
                id={"color_" + color.id}
                type="checkbox"
                value={color.id}
                checked={selectedColors.filter(x => x.colorId === color.id).length > 0}
                onChange={(e) => handleColorChange(e, color)}
              />
              <span>{color.name}</span>
              <span className="admin-label-preview" style={{ background: color.hex }}>
                {('colorImage' in color ? <img src={color.colorImage.previewsrc} /> : '')}
              </span>
            </label>
          ))}
        </div>
      </>
    ) : <div className="admin-modal-cell"><p>No color group selected.</p></div> }
  </>);
}

ColorOptions.propTypes = {
  colorGroups: PropTypes.array,
  onChange: PropTypes.func,
    editColorGroup: PropTypes.object,
    handleChecked: PropTypes.func,
}

export default function ProductOptionItems({ optionObject, editProductOption, onChange }) {
  
  const ColorGroups = useSelector(state => state.colors.colorGroupsWithColors);
  const [productOption, setProductOption] = useState(editProductOption ?? Object.assign({ type: optionObject.id }, defaultProductOption));

    useEffect(() => {
        if (onChange) onChange(productOption);
    }, [productOption]);
    
    const isCheckedbox =(isChecked)=> {
        setProductOption({
            ...productOption,           
            isRequired: isChecked
        })
    }

    const handleOptionItemsChage = (productOptionItems, colorGroup) => {
        let title = productOption.title;
        switch (optionObject.id) {
          case 0:
          case 1:
            title = optionObject.name;
            break;
          case 2:
            title = colorGroup.name;
            break;
          default:
            break;
        }

        setProductOption({
          ...productOption,
          title: title,
          colorGroupId: colorGroup?.id ?? null,
          items: productOptionItems    
        });    
    }

    const handleColorCheckedbox = (isChecked) => {
        setProductOption({
            ...productOption,
            isRequired: isChecked
        });
    }

  return (<div className="admin-modal-row full">
    <div className="admin-modal-cell">
      {optionObject.id !== 2 && 
      <div className="admin-modal-row full">
        <div className="admin-modal-cell wide">
            {optionObject.id === 3 || optionObject.id === 4 ? <>
              <label className="admin-default-label" htmlFor="option-title">Options Title</label>
              <input className="admin-default-input-text" id="option-title"
                value={productOption.title}
                placeholder={optionObject.name}
                onChange={(e) => {
                  setProductOption({
                    ...productOption,
                    title:  e.target.value
                  });
                  
                }} type="text" />
            </> :
            optionObject.name}
        </div>
      </div>}
      {optionObject.id === 2 ? <ColorOptions
        colorGroups={ColorGroups}
        onChange={handleOptionItemsChage}
              editColorGroup={productOption}
              handleChecked={handleColorCheckedbox}

      /> :
        <DraggableOptions
                  optionItems={productOption.items ?? []}
                  optionType={optionObject.id}
                  onChange={handleOptionItemsChage}
                  IsChecked={isCheckedbox}
                  productOptionData={productOption}
        />}
    </div>
  </div>);
}

ProductOptionItems.propTypes = {
  optionObject: PropTypes.object,
  editProductOption: PropTypes.object,
  onChange: PropTypes.func
}
