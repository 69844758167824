import { createAsyncThunk } from "@reduxjs/toolkit";
import * as authApi from "../../api/auth.js"
import * as demoDistrictApi from "../../api/demodistrictapi.js"

export const userLogin = createAsyncThunk(
    "auth/login",
    async (data, store) => {
        const res = await authApi.login(data);
        if (res.data.success) {
            store.dispatch({
                type: "auth/login/fulfilled",
                payload: res,
            })
        }
        return res;
    })

export const demoUserLogin = createAsyncThunk(
    "auth/demo-login",
    async (data, store) => {
        var userId = localStorage.getItem('demoUserId');
        if (userId == 'undefined' || userId == 'null') { userId = null; }
        data = { ...data, userId: userId };
        const res = await demoDistrictApi.authenticate(data.userId, data.districtId);
        if (res.data.success) {
            localStorage.setItem('demoUserId', res.data.data.siteUserId);
            store.dispatch({
                type: "auth/login/fulfilled",
                payload: res,
            })
        }
        return res;
    })

export const getNewToken = createAsyncThunk(
  "auth/refresh-token",
  async (data) => {
    let config = {
      data
      }
      try {
          const res = await authApi.refreshToken(config);
          return res.data;
      } catch (ex) {
          localStorage.removeItem('FreedomInteriors/appLocalStore/persist:root');
      }
  })


export const checkAuth = createAsyncThunk(
  "auth/check-auth",
  async (data) => {
    let config = {
      data
    }
    const res = await authApi.checkAuth(config);
    return res.data;
  })


export const getForgotPassword = createAsyncThunk(
    "auth/forgot-password",
    async (data) => { 
        const res = await authApi.forgotPassword(data);
        return res.data;
    })


export const getResetPassword = createAsyncThunk(
    "auth/forgot-password",
    async (data) => {
        const res = await authApi.resetPassword(data);
        return res.data;
    })


export const setResetPassword = createAsyncThunk(
    "auth/update-password",
    async (data) => {
        const res = await authApi.updatePassword(data);
        return res.data;
    })


export const setUserData = createAsyncThunk(
    "auth/update-user",
    async (data) => {
        const res = await authApi.UpdateUserData(data);
        return res.data;
    })

export const getOneTimeLogin = createAsyncThunk(
    "auth/one-time-login",
    async (data) => {
        const res = await authApi.oneTimeLogin(data);
        return res.data;
    })

export const getEmailAuth = createAsyncThunk(
    "auth/email-auth",
    async (data, store) => {
        const res = await authApi.emailAuth(data);
        if (res.success) {
            store.dispatch({
                type: "auth/login/fulfilled",
                payload: res,
            })
        }
        return res.data;
    })

export const createUser = createAsyncThunk(
    "auth/create-user",
    async (data) => {
        const res = await authApi.createUserdata(data);
        return res.data;
    })