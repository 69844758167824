import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const LoadingOverlay = styled.div`
  align-content: center;
  align-items: center;
  backdrop-filter: blur(.5em);
  background: rgba(255, 255, 255, .7);
  display: ${(props) => (props.$show ? 'flex' : 'none')};
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 499;

  [data-filter="true"] & {
    left: 20em;
    width: calc(100% - 20em);
  }
`;
const LoadingSpinnerStyled = styled.div`
  animation: loading-spinner 1s linear infinite;
  border: 5px solid #005288;
  border-radius: 50%;
  border-top-color: transparent;
  display: block;
  height: 2.6em;
  position: absolute;
  width: 2.6em;

  @keyframes loading-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const LoadingSpinner = () => {
    const isLoading = useSelector((state) => state.loading);

    return (
        <LoadingOverlay $show={isLoading}>
            <LoadingSpinnerStyled />
        </LoadingOverlay>
    );
};


export default LoadingSpinner;
