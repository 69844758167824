import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import { MediaItem } from './MediaItem';
import './mediacomponent.css';
import './react-image-crop.css';

const defaultCropSettings = {
  unit: '%',
  x: 25,
  y: 25,
  width: 50,
  height: 50
}

function EditImageArea({ ShowCrop, imageObj, setCropImage }) {
  const cropValues = ('cropValues' in imageObj ? JSON.parse(imageObj.cropValues) : defaultCropSettings);

  const handleCropChange = (crop, percentage) => {
    setCropImage(percentage);
  }

  if (ShowCrop) {
    <ReactCrop
      crop={cropValues}
      onChange={(c, p) => handleCropChange(c, p) }
    >
      <img className="uploadImage"
        src={imageObj.previewsrc} />
    </ReactCrop>
  }
  else {
    return (<div className="uploadImageWrapper">
      <img className="uploadImage"
        src={imageObj.previewsrc} />
    </div>);
  }
}

EditImageArea.propTypes = {
  ShowCrop: PropTypes.bool,
  imageObj: PropTypes.object,
  setCropImage: PropTypes.func
}
function ShowImageArea({ ShowCrop, BaseImage, CropImage, setCropImage }) {

  const baseImageUrl = URL.createObjectURL(BaseImage);

  const handleCropChange = (crop, percentage) => {
    setCropImage(percentage);
  }

  if (ShowCrop) {
    return (
      <ReactCrop
        crop={CropImage}
        onChange={(c, p) => handleCropChange(c, p)}
      >
        <img className="" src={baseImageUrl} />
      </ReactCrop>
    );
  }
  else {
    return (<div className="uploadImageWrapper">
      <img className="uploadImage"
        src={baseImageUrl} />
    </div>);
  }
}
ShowImageArea.propTypes = {
  ShowCrop: PropTypes.bool,
  BaseImage: PropTypes.object,
  CropImage: PropTypes.object,
  setCropImage: PropTypes.func
}



export default function MediaImageModal({
  requireAltText, showModal, forceCrop, uploadImageObj, editImageObj,
  onCloseModal, onSaveModal, onCustomSaveModal }) {

  const [showCrop, setShowCrop] = useState(forceCrop);
  const [cropImage, setCropImage] = useState(defaultCropSettings);
  const [imageObj, setImageObj] = useState(editImageObj ?? MediaItem);

  useEffect(() => {
    if (editImageObj != null && 'isCropped' in editImageObj) {
      setShowCrop(editImageObj.isCropped);
    }
  }, []);

  const handleSaveModal = async () => {

    if (requireAltText && imageObj.altText === '') {
      document.getElementById('alt-text').classList.add('input-validation-error');
      return;
    }
    let mediaData = imageObj;
    mediaData.cropValues = JSON.stringify(cropImage);
    mediaData.imageFile = uploadImageObj;
    mediaData.isCropped = showCrop;

    onSaveModal(mediaData);

    setShowCrop(false);
    setCropImage(defaultCropSettings);
    setImageObj(MediaItem);
  }

  const handleCancelClick = () => {
    if (showCrop) {
      setShowCrop(false);
    }
    else
      onCloseModal();
  }

  const handleShowCropModal = () => {
    setShowCrop(true);
  }

  if (showModal) {
    return (
      <div data-section="CropImageOverlayPreview" className="admin-modal-overlay">
            <div className="admin-modal admin-add-role">
                <div className="admin-modal-scroll">
          <button className="admin-modal-close" onClick={handleCancelClick}>
            <span className="material-symbols-rounded">close</span>
          </button>
          <p className="admin-modal-heading">Image Preview</p>
          <div className="admin-modal-row">
            <div className="admin-modal-cell">
              {requireAltText ? (
                <label className="admin-default-label" htmlFor="alt-text">Alt Text (Required)</label>
              ) : (
                <label className="admin-default-label" htmlFor="alt-text">Alt Text</label>
              )}
              <input
                id="alt-text"
                type="text"
                className="admin-default-input-text"
                value={imageObj.altText}
                onChange={(e) => setImageObj({
                  ...imageObj,
                  altText: e.target.value
                })} />
            </div>
          </div>
          <div className="admin-modal-row full">
            <div className="admin-modal-cell full">
              {uploadImageObj !== null ?
                <ShowImageArea
                  ShowCrop={showCrop}
                  BaseImage={uploadImageObj}
                  CropImage={cropImage}
                  setCropImage={setCropImage}
                /> : <EditImageArea
                  imageObj={editImageObj}
                  ShowCrop={showCrop}
                  setCropImage={setCropImage}
                />}
            </div>
          </div>
          <div className="admin-modal-button-wrap">
            <button className="admin-modal-button"
              onClick={handleCancelClick}
            >Cancel</button>
            {!showCrop ?
              <button className="admin-modal-button"
                onClick={handleShowCropModal}
              >Crop</button> : <></>}
            <button className="admin-modal-button power"
              onClick={onCustomSaveModal ?? handleSaveModal}
            >Save</button>
          </div>
                </div>
        </div>
      </div>
    )
  }
  else return (<></>);
}

MediaImageModal.propTypes = {
  requireAltText: PropTypes.bool,
  mediaObj: PropTypes.object,
  showModal: PropTypes.bool,
  forceCrop: PropTypes.bool,
  uploadImageObj: PropTypes.object,
  editImageObj: PropTypes.object,
  onCloseModal: PropTypes.func,
  onSaveModal: PropTypes.func,
  onCustomSaveModal: PropTypes.func
}

MediaImageModal.defaultProps = {
  requireAltText: false,
  forceCrop: false,
  uploadImageObj: null,
  editImageObj: null,
  onCustomSaveModal: null
}
