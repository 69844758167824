import React, { useState, useEffect } from 'react';
import '../../admin/Admin.css';
import { useDispatch, useSelector } from 'react-redux';
import { loadGroups, storeLastTab, resetSaveStatus } from '../../../store/categories-reducer';
import { CategoriesContainer } from './categoriesContainer';
import { AddCategoryName, EditCategory, DeleteCategory } from './categoryActions';
import { SavingMessage } from './savingMessage';
import { Link } from 'react-router-dom';
import AdminError from '../../../components/admin/admin-error';
import { updateTitle } from '../../../helpers/titleHelper';

export function Categories() {
    updateTitle('Admin', 'Categories');
    const groups = useSelector((state) => state.categories.groups);
    const lastActiveTab = useSelector((state) => state.categories.lastTab);
    const dispatch = useDispatch();

    const [categoryList, setCategoryList] = useState([]);
    const [activeGroupId, setActiveGroupId] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null); // To track the selected category for editing or deleting
    const saveStatus = useSelector((state) => state.categories.saveStatus);
    const saveError = useSelector((state) => state.categories.saveError);
    const [error, setError] = useState('');
    const [loaded, setLoaded] = useState(false);

    const handleTabClick = (groupId) => {
        setActiveGroupId(groupId);
        dispatch(storeLastTab(groupId)); // Dispatch action to update last active tab in the state
    };

    const handleAdd = () => {
        setShowAddModal(true);
    };

    const handleEdit = (category) => {
        setSelectedCategory(category);
        setShowEditModal(true);
    };

    const handleDelete = (category) => {
        setSelectedCategory(category);
        setShowDeleteModal(true);
    };

    useEffect(() => {
        dispatch(loadGroups());
    }, [dispatch]);
    useEffect(() => {
        if (groups && groups.length > 0) {
            setActiveGroupId(groups[0].id);
        }
    }, [groups]);

    useEffect(() => {
        if (activeGroupId && groups) {
            const activeGroup = groups.find((group) => group.id === activeGroupId);
            if (
                activeGroup &&
                activeGroup.categories &&
                activeGroup.categories[0] !== null
            ) {
                setCategoryList(activeGroup.categories);
            } else {
                setCategoryList([]);
            }
        }
    }, [activeGroupId, groups]);

    useEffect(() => {
        if (groups && groups.length > 0) {
            if (!lastActiveTab) {
                setActiveGroupId(groups[0].id); // Set to first group's ID only if lastActiveTab is not available
            } else {
                setActiveGroupId(lastActiveTab); // Set to lastActiveTab if available
            }
        }
    }, [groups, lastActiveTab]);

    useEffect(() => {
        if (saveStatus === 'failed' && loaded == true) {
            setError(saveError);
        } else if (saveStatus === 'failed' && loaded == false) {
            resetSaveStatus();
        }
        setLoaded(true);
    }, [saveStatus, dispatch]);

    return (
        <div className="admin-page-wrap">
            <div className="admin-top-bar-wrap">
                <div className="admin-top-bar-left">
                    <Link className="admin-top-bar-back" to="/admin/">
                        Back
                    </Link>
                </div>
            </div>
            <div className="admin-page-content">
                <div className="admin-page-title-wrap">
                    <h1 className="admin-page-heading">Categories</h1>
                    <button className="admin-button power" onClick={() => handleAdd()}>
                        Add Category
                    </button>
                </div>
                <div className="admin-tab-wrap">
                    {groups.map((group) => (
                        <button
                            key={group.id}
                            className={`admin-tab ${activeGroupId === group.id ? 'active' : ''
                                }`}
                            onClick={() => handleTabClick(group.id)}
                            id={'tab' + group.id}
                            role="tab"
                            aria-controls={`${group.name}-tab`}
                            aria-selected={activeGroupId === group.id}
                        >
                            {group.name}
                        </button>
                    ))}
                </div>

                <AdminError message={error} onClose={() => { setError(''); }}></AdminError>

                <div className="admin-tab-content">
                    {activeGroupId && (
                        <CategoriesContainer
                            categories={categoryList || []}
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                        />
                    )}
                </div>
            </div>
            {showAddModal && (
                <AddCategoryName
                    selectedGroupId={activeGroupId}
                    onClose={() => setShowAddModal(false)}
                />
            )}
            {showEditModal && (
                <EditCategory
                    category={selectedCategory}
                    onClose={() => setShowEditModal(false)}
                />
            )}
            {showDeleteModal && (
                <DeleteCategory
                    category={selectedCategory}
                    categoryId={selectedCategory ? selectedCategory.id : null}
                    onClose={() => setShowDeleteModal(false)}
                />
            )}
            <SavingMessage />
        </div>
    );
}
