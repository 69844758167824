import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editManufacturer } from '../../../store/manufacturer-reducer';
import PropTypes from 'prop-types';
export default function EditManufacturerName({ manufacturerId, currentNotes, currentName }) {
    const dispatch = useDispatch();
    const [name, setName] = useState(currentName || '');
    const manufacturersList = useSelector((state) => state.manufacturers.list.data);
    const [errors, setErrors] = useState('');
    const handleNameChange = (event) => {
        setName(event.target.value);
        setErrors('');
    };

    const handleSave = () => {
        if (name.trim() === '') {
            setErrors('Please enter the name');
            return;
        }
        const checkName = manufacturersList.find(x => x.name === name);
        if (checkName && checkName.id !== manufacturerId) {
            setErrors('Name is already exist');
            return;
        }
        dispatch(editManufacturer({ id: manufacturerId, name, shippingNote: currentNotes }));
        if (name === '' || name === "") {
            setTimeout(() => {
                setName(currentName || '');
            }, 500);
        }
    };

    return (
        <div className="admin-tab-content" id="name-tab" role="tabpanel" aria-labelledby="tab4">
            <div className="admin-form-cell">
                <div className={'admin-default-input-text-wrap' + (errors ? ' input-validation-error' : '')}>
                    <label className={errors ? 'admin-form-label validation-error-message' :'admin-form-label'} htmlFor="shipping-install-note">Manufacturer Name</label>
                    <input className="admin-form-input" type="text" value={name} onChange={handleNameChange} />
                    {errors && <p className="validation-error-message">{errors}</p>}
                </div>
            </div>
            <div className="admin-form-cell">
                <button className="admin-form-button power" onClick={handleSave}>Save Changes</button>
            </div>
        </div>
    );
} EditManufacturerName.propTypes = {
    currentName: PropTypes.string,
    currentNotes:PropTypes.string,
    manufacturerId: PropTypes.string.isRequired,
};