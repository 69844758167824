import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './Quote.css';
import RightArrow from '../../assets/icons/left-arrow-gray.svg';
//components
import { AccordionItem } from '../../components/common/CartAccordion';
import MyProduct from '../../components/common/MyProduct';
import Banner from '../../components/common/ShowBannerDetails';
import AdminError from '../../components/admin/admin-error.js';
//apis
import {
    getDistrictQuoteProducts, editDistrictQuote, updateStatus, updateUnlockStatus, getDistrictQuoteTypicalLayouts,
    updateQuoteTypicalLayoutsLabel, updateDistrictQuoteTypicalLayoutsQuantity
} from '../../api/districtquoteapi.js';
import { getDistrictByPathId } from '../../api/districtapi.js';
//reducers
import { loadAllItems, removeMyTypicalLayout } from '../../store/myproject-reducer';
import { updatedDistrict } from '../../store/district-reducer';
//helpers
import { calculateFinalUnitPrice, formatUSD } from '../../helpers/formatterHelper';
import { updateTitle } from '../../helpers/titleHelper';

const Quote = () => {
    const { quoteId } = useParams();
    const [quote, setQuote] = useState({ cost: 0, quantity: 0, products: [], info: { districtId:0, status: 0, statusText: '' } });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);
    const [typicalLayouts, setTypicalLayouts] = useState();
    const [typicalLayoutSubtotals, setQuoteTypicalSubtotals] = useState([]);
    const [estimatedCost, setEstimatedCost] = useState(0);
    const [miscSubTotals, setMiscSubTotals] = useState(0);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [label, setLabel] = useState('My Project');

    const getQuoteProducts = () => {
        getDistrictQuoteProducts(quoteId).then(response => {
            if (response.data.success == false) {
                setError(response.data.message);
                return;
            }
            //var getuser = response.data.data.user;
            setUser(response.data.data.user);
            var products = response.data.data.products;
            var mylabel = label;
            if (products[0]?.label) {
                setLabel(products[0]?.label);
                mylabel = products[0]?.label;
            }
            var quoteInfo = {
                info: response.data.data.quote,
                products: products
            };
            setQuote(quoteInfo);
            updateTitle('Quote', mylabel + " #" + quoteInfo.info.districtId + '-' + ("000" + quoteInfo.info.id).slice(-4));

            getDistrictQuoteTypicalLayouts(quoteId).then(response => {
                if (response.data.success == false) {
                    setError(response.data.message);
                    return;
                }
                response.data.data.quoteTypicalLayouts.products = response.data.data.quoteTypicalLayoutProducts;
                setTypicalLayouts(response.data.data.quoteTypicalLayouts);

                //finally, change working district
                getDistrictByPathId(quoteInfo.info.districtId).then(response => {
                    if (response.data.success == true) {
                        localStorage.setItem('district', JSON.stringify(response.data.data));
                        localStorage.setItem('districtId', quoteInfo.info.districtId);
                        dispatch(updatedDistrict());
                    }
                });
            });

        });
    }

    useEffect(() => {
        if (quoteId == null) { navigate('/my-account'); }
        if (quote.id != quoteId) {
            getQuoteProducts();
        }
    }, [quoteId]);

    useEffect(() => {
        //get subtotals for all typical layouts
        if (typicalLayouts != null) {
            var subtotals = [];
            typicalLayouts.map((group) => {
                const typicalProducts = typicalLayouts.products.filter(item => item.typicalLayoutItemId == group.id);
                const products = quote.products.filter(a => typicalProducts.filter(b => a.quoteItemId == b.cartItemId).length > 0);
                const subtotal = products.reduce((total, product) => total + (calculateFinalUnitPrice(product) * product.quantity), 0);
                const productsQuantity = products.reduce((total, product) => total + product.quantity, 0);
                subtotals.push({ id: group.id, subtotal: subtotal * group.quantity, quantity: group.quantity, productsQuantity: productsQuantity });
            });
            setQuoteTypicalSubtotals(subtotals);
        } else {
            setQuoteTypicalSubtotals([]);
        }
    }, [typicalLayouts]);


    useEffect(() => {
        //calculate total estimate
        if (quote && quote.products) {


            //debug only: display pricing breakdown for each product & product options
            //console.log(quote.products.map(a => {
            //    var tp = typicalLayouts ? typicalLayouts.products.filter(b => b.cartItemId == a.quoteItemId)[0] : null;
            //    var tl = tp != null ? typicalLayouts.filter(a => a.id == tp.typicalLayoutItemId)[0] : null;
            //    return {
            //        id: a.id,
            //        name:a.details.name,
            //        quantity: tl ? tl.quantity * a.quantity : a.quantity,
            //        price: a.details.price,
            //        discount: a.details.discount ?? 0,
            //        contractDiscount: a.details.contractDiscount,
            //        options: a.options.map(o => o.items.map(i => i.upCharge ))
            //    }
            //}));


            //filter out any products that belong to a typical layout
            const items = typicalLayouts ? quote.products.filter(a => typicalLayouts.products.filter(b => b.cartItemId == a.quoteItemId).length == 0) : quote.products;

            //get subtotal of all typical layouts
            const typicalSubtotals = typicalLayoutSubtotals && typicalLayoutSubtotals.length > 0 ?
                typicalLayoutSubtotals.reduce((sum, item) => sum + item.subtotal, 0) : 0;

            //get subtotal of miscellaneous items
            const subtotal = items.reduce((sum, productItem) =>
                sum + calculateFinalUnitPrice(productItem) * productItem.quantity, 0);

            //get quantity of typical layout products
            var typicalQuantities = 0;
            if (typicalLayouts != null) {
                typicalLayouts.map((group) => {
                    const tItems = typicalLayouts ? quote.products.filter(a => typicalLayouts.products.filter(b => b.typicalLayoutItemId == group.id && b.cartItemId == a.quoteItemId).length > 0) : [];
                    typicalQuantities += (group.quantity * tItems.reduce((sum, item) => sum + item.quantity, 0));
                });
            }

            //get quantity of miscellaneous products
            const quantity = items.reduce((sum, item) => sum + item.quantity, 0);

            setMiscSubTotals(subtotal);
            setEstimatedCost(subtotal + typicalSubtotals);
            setTotalQuantity(typicalQuantities + quantity);
        }

    }, [quote, typicalLayoutSubtotals, typicalLayouts]);

    const handleEditProject = () => {
        editDistrictQuote(quoteId).then(response => {
            if (response.data.success == true) {
                localStorage.setItem('editQuoteId', quoteId);
                dispatch(loadAllItems());
                navigate('/my-project');
            } else {
                setError(response.data.message);
            }
        });
    };

    const handleApprove = () => {
        updateStatus(quoteId, 2).then((response) => {
            if (response.data.success == true) {
                setQuote({ ...quote, info: response.data.data });
            } else {
                setError(response.data.message);
            }
        });
    };

    const handleAdminUnlock = () => {
        updateUnlockStatus(quoteId, 0).then(() => {
            getQuoteProducts();
        });
    };

    const handleComplete = () => {
        updateStatus(quoteId, 3).then((response) => {
            if (response.data.success == true) {
                setQuote({ ...quote, info: response.data.data });
            } else {
                setError(response.data.message);
            }
        });
    };

    const handleArchive = () => {
        updateStatus(quoteId, 4).then((response) => {
            if (response.data.success == true) {
                setQuote({ ...quote, info: response.data.data });
            } else {
                setError(response.data.message);
            }
        });
    };

    const handleReject = () => {
        updateStatus(quoteId, 5).then((response) => {
            if (response.data.success == true) {
                setQuote({ ...quote, info: response.data.data });
            } else {
                setError(response.data.message);
            }
        });
    };

    const handleCloseError = () => {
        setError(null);
    }


    const handleTypicalLayoutLabelUpdate = (id, label) => {
        if (id != null && label != '') {
            updateQuoteTypicalLayoutsLabel(id, label).then((response) => {
                if (response.data.success == true) {
                    if (quote.id != quoteId) {
                        getQuoteProducts();
                    }
                }
            });
        }
    }

    const handleTypicalLayoutQuantityUpdate = (id, quantity) => {
        if (id != null && quantity != null && quantity > 0) {
            updateDistrictQuoteTypicalLayoutsQuantity(id, quantity).then((response) => {
                if (response.data.success == true) {
                    if (quote.id != quoteId) {
                        getQuoteProducts();
                    }
                }
            });
        }
    }
    const handleDeleteTypicalLayout = (id) => {
        if (id != null) {
            //remove typical layout in redux
            dispatch(removeMyTypicalLayout(id, 'Quote_Delete', quoteId));
        }
    }

    const displayFundingSource = (id, other) => {
        switch (id) {
            case 1: return 'Request district funds';
            case 2: return 'Have my own funds';
            case 3: return other;
            default: return '';
        }
    }

    const filteredQuoteProducts = quote.products.filter(item => typicalLayouts && typicalLayouts.products.filter(a => a.cartItemId == item.quoteItemId).length == 0 || !typicalLayouts);

    return (
        <div id="content">
            <Banner />
            <div className="default-outer-wrapper quote-page-wrapper">
                <div className="default-outer-wrapper-inner">
                    <div className="cart-default-center default-center">
                        <div className="cart-default-wrapper">
                            <div className="default-outer-wrapper-inner">
                                <div className="default-center">
                                    <div className="page-header-wrap">
                                        <div className="product-breadcrumbs-wrap">
                                        </div>
                                    </div>


                                    <AdminError message={error} onClose={handleCloseError}></AdminError>

                                    <div className="project-wrap">
                                        <div className="project-item">
                                            <div className="project-item-inner">
                                                <p className="project-item-title">
                                                    {quote.info.districtId > 0 &&
                                                        <>{label} #{quote.info.districtId + '-' + ("000" + quote.info.id).slice(-4)} ({totalQuantity} Items)</>
                                                    }
                                                </p>
                                                {totalQuantity > 0 ? (
                                                    <div>

                                                        {typicalLayouts && typicalLayouts.map((group) => {
                                                            const typicalProducts = typicalLayouts.products.filter(item => item.typicalLayoutItemId == group.id);
                                                            const subtotal = typicalLayoutSubtotals && typicalLayoutSubtotals.length > 0 && typicalLayoutSubtotals.filter(a => a.id == group.id).length > 0
                                                                ? typicalLayoutSubtotals.filter(a => a.id == group.id)[0].subtotal : 0;
                                                            return (
                                                                <AccordionItem
                                                                    typicalLayout={group}
                                                                    title={group.label}
                                                                    quantity={group.quantity}
                                                                    subtotal={subtotal}
                                                                    opened={true}
                                                                    key={'typical_layout ' + group.id}
                                                                    typicalLayoutItemId={group.id}
                                                                    updateLabel={handleTypicalLayoutLabelUpdate}
                                                                    updateQuantity={handleTypicalLayoutQuantityUpdate}
                                                                    deleteTypicalLayout={handleDeleteTypicalLayout}
                                                                    isQuote={true}
                                                                >
                                                                    <div className="my-product-title-table">
                                                                        <div className="my-product-title-row">
                                                                            <div className="my-product-title-coll">
                                                                                &nbsp;
                                                                            </div>
                                                                            <div className="my-product-title-coll product-details-title">
                                                                                Product Details
                                                                            </div>
                                                                            <div className="my-product-title-coll product-tag">
                                                                                Tag
                                                                            </div>
                                                                            <div className="my-product-title-coll product-quantity">
                                                                                Quantity
                                                                            </div>
                                                                            <div className="my-product-title-coll unit-price">
                                                                                <span className="table-cellprice">
                                                                                    Unit Price
                                                                                </span>
                                                                                <span className="table-cell-totalammount">
                                                                                    Total
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        {typicalProducts.map(typicalProduct => quote.products.filter(product => product.quoteItemId == typicalProduct.cartItemId).map(item => {
                                                                            return (<MyProduct key={item.quoteItemId} product={item} buttons={{ delete: false, wishlist: false }} canEdit={false} canViewProductDetail={true} isTypicalLayout={true} />);
                                                                        }))}
                                                                    </div>
                                                                </AccordionItem>
                                                            );
                                                        })}

                                                        {filteredQuoteProducts != null && filteredQuoteProducts.length > 0 &&
                                                            <>
                                                                {typicalLayouts && typicalLayouts.length > 0 ?
                                                                    <AccordionItem
                                                                        title={"Miscellaneous"}
                                                                        opened={true}
                                                                        key={'miscellaneous'}
                                                                        isMisc={true}
                                                                        subtotal={miscSubTotals}
                                                                        isQuote={true}
                                                                    >
                                                                        <div className="my-product-title-table">
                                                                            <div className="my-product-title-row">
                                                                                <div className="my-product-title-coll">
                                                                                    &nbsp;
                                                                                </div>
                                                                                <div className="my-product-title-coll product-details-title">
                                                                                    Product Details
                                                                                </div>
                                                                                <div className="my-product-title-coll product-tag">
                                                                                    Tag
                                                                                </div>
                                                                                <div className="my-product-title-coll product-quantity">
                                                                                    Quantity
                                                                                </div>
                                                                                <div className="my-product-title-coll unit-price">
                                                                                    <span className="table-cellprice">
                                                                                        Unit Price
                                                                                    </span>
                                                                                    <span className="table-cell-totalammount">
                                                                                        Total
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            {filteredQuoteProducts.map((item) => (
                                                                                <MyProduct key={item.quoteItemId} product={item} buttons={{ delete: false, wishlist: false }} canEdit={false} canViewProductDetail={true} isTypicalLayout={false} />
                                                                            ))}
                                                                        </div>
                                                                    </AccordionItem>
                                                                    :
                                                                    <div className="my-product-title-table">
                                                                        <div className="my-product-title-row">
                                                                            <div className="my-product-title-coll">
                                                                                &nbsp;
                                                                            </div>
                                                                            <div className="my-product-title-coll product-details-title">
                                                                                Product Details
                                                                            </div>
                                                                            <div className="my-product-title-coll product-tag">
                                                                                Tag
                                                                            </div>
                                                                            <div className="my-product-title-coll product-quantity">
                                                                                Quantity
                                                                            </div>
                                                                            <div className="my-product-title-coll unit-price">
                                                                                <span className="table-cellprice">
                                                                                    Unit Price
                                                                                </span>
                                                                                <span className="table-cell-totalammount">
                                                                                    Total
                                                                                </span>
                                                                            </div>
                                                                        </div>

                                                                        {filteredQuoteProducts.map((item) => (
                                                                            <MyProduct key={item.quoteItemId} product={item} buttons={{ delete: false, wishlist: false }} canEdit={false} canViewProductDetail={true} isTypicalLayout={false} />
                                                                        ))}
                                                                    </div>
                                                                }
                                                                <div className="cart-layout-wrap">
                                                                    <div className="cart-grid-row estimate">
                                                                        <div className="cart-estimate-cell">
                                                                            <p className="cart-estimate-content">
                                                                                Estimated Cost ({totalQuantity}{' '}
                                                                                items):{' '}
                                                                                <b>{formatUSD(estimatedCost)}</b>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>

                                        <div className="project-item summary-wrap">
                                            <div className="project-item-inner">
                                                <h1 className="project-item-title">Status</h1>
                                                <p className={"project-status status-color-" + quote.info.status}>{quote.info.statusText}</p>

                                                <hr />
                                                <div className="cart-side-notes for-quote">
                                                    <div className="cart-side-label">Full Name</div>
                                                    <div className="cart-side-input">
                                                        {quote.info.fullName}
                                                    </div>
                                                    <div className="cart-side-label">Email</div>
                                                    <div className="cart-side-input">
                                                        {quote.info.email}
                                                    </div>
                                                    {quote.info.phone && <>
                                                        <div className="cart-side-label">Phone</div>
                                                        <div className="cart-side-input">
                                                            {quote.info.phone}
                                                        </div>
                                                    </>}
                                                    <div className="cart-side-label">Organization</div>
                                                    <div className="cart-side-input">
                                                        {quote.info.organization != null && quote.info.organization != '' ? quote.info.organization : quote.info.districtName}
                                                    </div>
                                                    {quote.info.comments && <>
                                                    <div className="cart-side-label">Notes/Comments:</div>
                                                    <div className="cart-side-input">
                                                        {quote.info.comments ?? ''}
                                                    </div>
                                                    </>}
                                                    {quote.info.deliveryDate && <>
                                                    <div className="cart-side-label">Requested Delivery Date:</div>
                                                    <div className="cart-side-input">
                                                        {quote.info.deliveryDate != null && quote.info.deliveryDate != '' ? quote.info.deliveryDate : 'None'}
                                                        </div>
                                                    </>}
                                                        {quote.info.fundingSource && <>
                                                        <div className="cart-side-label">Funding Source:</div>
                                                        <div className="cart-side-input">
                                                            {displayFundingSource(quote.info.fundingSource, quote.info.otherFunding)}
                                                        </div>
                                                    </>}
                                                </div>

                                                {quote.info.status == 0 && (quote.info.approvedBy != undefined && quote.info.approvedBy != null && quote.info.approvedBy != user?.siteUserId) && (quote.info.userRole == 'admin') ?
                                                    <p className="project-summary-link project-status-summary">
                                                        <a onClick={handleAdminUnlock}>Unlock Quote</a>
                                                        <img
                                                            src={RightArrow}
                                                            className="project-status-arrow"
                                                            alt="arrow"
                                                        />
                                                    </p>
                                                    : <></>}

                                                {(quote.info.status == 0 || quote.info.status == 5 || quote.info.status == 2) && (quote.info.userRole == 'admin' || quote.info.userRole == 'manager' || quote.info.userRole == 'approver') ?
                                                    <p className="project-summary-link project-status-summary">
                                                        <a onClick={handleEditProject}>Edit Project</a>
                                                        <img
                                                            src={RightArrow}
                                                            className="project-status-arrow"
                                                            alt="arrow"
                                                        />
                                                    </p>
                                                    : <></>}
                                                {(quote.info.status == 3 || quote.info.status == 5) && (quote.info.userRole == 'admin' || quote.info.userRole == 'manager') ?
                                                    <p className="project-summary-link project-status-summary">
                                                        <a onClick={handleArchive}>Archive</a>
                                                        <img
                                                            src={RightArrow}
                                                            className="project-status-arrow"
                                                            alt="arrow"
                                                        />
                                                    </p>
                                                    : <></>}
                                                {(quote.info.userRole == 'admin' || quote.info.userRole == 'manager' || quote.info.userRole == 'approver') ?
                                                    <p className="project-summary-link project-status-summary">
                                                        <a href={"/report/get/" + quote.info.districtId + "/" + quoteId} rel="noreferrer" target="_blank">Download Report</a>
                                                        <img
                                                            src={RightArrow}
                                                            className="project-status-arrow"
                                                            alt="arrow"
                                                        />
                                                    </p>
                                                    : <></>}
                                                {quote.info.status <= 1 && (quote.info.userRole == 'admin' || quote.info.userRole == 'manager' || quote.info.userRole == 'approver') ?
                                                    <p className="project-summary-link project-status-summary">
                                                        <a onClick={handleReject}>Reject</a>
                                                        <img
                                                            src={RightArrow}
                                                            className="project-status-arrow"
                                                            alt="arrow"
                                                        />
                                                    </p>
                                                    : <></>}
                                                {quote.info.status <= 1 && (quote.info.userRole == 'admin' || quote.info.userRole == 'manager' || quote.info.userRole == 'approver') ?
                                                    <div className="project-summary-button">
                                                        {totalQuantity !== 0 ? (
                                                            <button
                                                                className="add-cart-cta"
                                                                onClick={handleApprove}
                                                            >
                                                                Submit To Vendor for a Detailed Quote
                                                            </button>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                    : <></>}

                                                {(quote.info.status == 2) && (quote.info.userRole == 'admin' || quote.info.userRole == 'manager') ?
                                                    <div className="project-summary-button">
                                                        <button
                                                            className="add-cart-cta"
                                                            onClick={handleComplete}
                                                        >
                                                            Complete Quote Request
                                                        </button>
                                                    </div>
                                                    : <></>}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Quote;
