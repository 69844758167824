import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
export function AddEditDistrictEmailsModal({ show, onClose, handleAddDistrictEmail, modelEmail }) {
    const [formDataEmail, setFormDataEmail] = useState({});
    const [isValidEmail, setIsValidEmail] = useState(true);

    useEffect(() => {
        setFormDataEmail(modelEmail)
    }, [modelEmail])
    const handleInputChange = (event) => {
        const { name, value } = event.target;       
        //const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const emailRegex = /^@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}|[a-zA-Z0-9.-]+\.)$/;
        const isValid = emailRegex.test(value);
        setIsValidEmail(isValid);
        setFormDataEmail({ ...formDataEmail, [name]: value });
    };

    const handleSave = () => {
        if (isValidEmail && formDataEmail.domain) {           
            handleAddDistrictEmail(formDataEmail);
            setFormDataEmail({})
            onClose(); // Close the modal  
        }
    };

    const handleOnClose = () => {
        setIsValidEmail(true);
        onClose(); // Close the modal           
    };

    if (!show) {
        return null;
    }
    return (
        <div className="admin-modal-overlay">
            <div className="admin-modal admin-add-role">
                <div className="admin-modal-scroll">
                <button className="admin-modal-close" onClick={handleOnClose}>
                    <span className="material-symbols-rounded">close</span>
                </button>
                <h2 className="admin-modal-heading">Add/Edit Email Domain</h2>
                <div className="admin-modal-row">
                    <div className="admin-modal-cell">
                        <label className="admin-default-label" htmlFor="email-domain">Email Domain</label>
                        <input
                            type="text"
                            id="email-domain"
                            label="Email Url"
                            name="domain"
                            value={formDataEmail.domain}
                            onChange={handleInputChange}
                        />
                        {isValidEmail ? null : <p style={{ color: 'red' }}>Invalid email domain</p>}
                    </div>
                </div>
                <div className="admin-modal-button-wrap">
                    <button className="admin-modal-button" onClick={handleOnClose}>Cancel</button>
                    <button className="admin-modal-button power" onClick={handleSave}>Save Changes</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

AddEditDistrictEmailsModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    handleAddDistrictEmail: PropTypes.func.isRequired,
    modelEmail: PropTypes.object
};