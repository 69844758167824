import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { getDistrictByPath } from '../../api/districtapi';
import { bindActionCreators } from "@reduxjs/toolkit";
import { demoUserLogin } from '../../store/auth/actions';
import PageNotFound from '../pagenotfound';

export default function District() {
    //check for district
    const dispatch = useDispatch();
    const [page, setPage] = useState({ notFound: false });
    var paths = window.location.pathname.split('/');

    var navigate = useNavigate();
    if (paths.length > 1) {
        if (!page.notFound) {
            getDistrictByPath(paths[1]).then(response => {
                if (response.status == 200 && response.data.success == true) {
                    var district = response.data.data;
                    if (district != null) {
                        localStorage.setItem('district', JSON.stringify(district));
                        localStorage.setItem('districtId', district.id);
                        if (paths.length > 1 && paths[2] == 'login') {
                            navigate('/account/login', { replace: true }); //redirect user to login page
                        }else if (district.isDemo == true) {
                            //authenticate user
                            if (localStorage.getItem('demoUserId') == 'undefined') {
                                localStorage.setItem('demoUserId', '');
                            }
                            const login = bindActionCreators(demoUserLogin, dispatch);
                            login({ districtId: district.id }).unwrap().then(result => {
                                if (result.data && result.data.success == true) {
                                    navigate('/home', { replace: true }); //redirect demo user to home page
                                } else {
                                    navigate('/account/login', { replace: true }); //redirect user to login page
                                }
                            });
                            
                        } else {
                            navigate('/account/login', { replace: true }); //redirect user to login page
                        }
                    } else {
                        setPage({ notFound: true });
                    }
                } else {
                    setPage({ notFound: true });
                }
            });
        }
    } else {
        setPage({ notFound: true });
    }
    return (
        <>{page.notFound == true ? <PageNotFound /> : <div></div>}</>
    )
}