import { createSlice } from "@reduxjs/toolkit";

import {
    listGroups,
    listCategories,
    addCategory,
    editCategory,
    deleteCategory,
    reorderCategories
} from '../api/categoriesapi';

const slice = createSlice({
    name: "categories",
    initialState: {
        groups: [],
        categories: [],
        isLoading: false,
        lastTab: 0,
        productType:[],
        roomType: [],
        typicalLayoutType: []
    },
    reducers: {
        categoriesRequested: (categories) => {
            categories.isLoading = true;
        },
        groupsReceived: (categories, action) => {
            categories.groups = action.payload;
            categories.groups.forEach(group => {
                group.categories.sort((a, b) => a.order - b.order);
            });

            categories.isLoading = false;
        },
        categoriesReceived: (categories, action) => {
            categories.categories = action.payload;
            categories.isLoading = false;
        },
        categoryAdded: (categories, action) => {
            categories.categories.push(action.payload);
        },
        categoryEdited: (categories, action) => {
            const index = categories.categories.findIndex(category => category.id === action.payload.id);
            categories.categories[index] = action.payload;
        },
        categoryDeleted: (categories, action) => {
            const index = categories.categories.findIndex(category => category.id === action.payload.id);
            categories.categories.splice(index, 1);
        },
        saveStarted: (state) => {
            state.saveStatus = 'saving';
        },
        saveSucceeded: (state) => {
            state.saveStatus = 'succeeded';
        },
        saveFailed: (state, action) => {
            state.saveStatus = 'failed';
            state.saveError = action.payload;
        },
        resetSaveStatus: (state) => {
            state.saveStatus = 'idle';
        },
        storeLastTab: (categories, action) => {
            categories.lastTab = action.payload;
        },
        setProductTypes: (categories, action) => {
            categories.productType = action.payload;
        },
        setRoomTypes: (categories, action) => {
            categories.roomType = action.payload;
        },
        setTypicalLayout: (categories, action) => {
            categories.typicalLayoutType = action.payload;
        }
    },
});

export const {
    categoriesRequested,
    groupsReceived,
    categoriesReceived,
    categoryAdded,
    categoryEdited,
    categoryDeleted,
    saveStarted,
    saveSucceeded,
    saveFailed,
    resetSaveStatus,
    storeLastTab,
    setProductTypes,
    setRoomTypes,
    setTypicalLayout
} = slice.actions;
export default slice.reducer;

// Action Creators


export const loadGroups = () => async (dispatch) => {
    dispatch(categoriesRequested());
    try {
        const response = await listGroups();
        dispatch(groupsReceived(response.data.data));

    } catch (error) {
        console.error("There was an error loading groups:", error);
    }
};

export const loadCategories = (categoryGroupId) => async (dispatch) => {
    dispatch(categoriesRequested());
    try {
        const response = await listCategories(categoryGroupId);
        const sortedCategories = response.data.data.sort((a, b) => a.order - b.order);
        dispatch(categoriesReceived(sortedCategories));
    } catch (error) {
        console.error("There was an error loading categories:", error);
    }
};

export const addNewCategory = (categoryData, onAdded, onError) => async (dispatch) => {
    dispatch(saveStarted());

    try {
        const response = await addCategory({ options: { data: categoryData } });
        if (response.data.success) {
            dispatch(categoryAdded(response.data));
            dispatch(saveSucceeded());
            onAdded();
        }
        else {
            dispatch(saveFailed(response.data.message));
            onError(response.data.message);
        }
    } catch (error) {
        console.error("There was an error adding the category:", error);
        dispatch(saveFailed(error.toString()));

    }
};

export const editExistingCategory = (categoryData, onEdited, onError) => async (dispatch) => {
    dispatch(saveStarted());

    try {
        const response = await editCategory({ options: { data: categoryData } });
        if (response.data.success) {
            dispatch(categoryEdited(categoryData));
            dispatch(saveSucceeded());
            onEdited();
        } else {
            dispatch(saveFailed(response.data.message));
            onError(response.data.message);
        }

    } catch (error) {
        dispatch(saveFailed(error.toString()));

    }
};

export const deleteExistingCategory = (id) => async (dispatch) => {
    dispatch(saveStarted());

    try {

        const response = await deleteCategory(id);
        if (response.data.success) {
            dispatch(categoryDeleted({ id }));
            dispatch(saveSucceeded());
        } else {
            dispatch(saveFailed(response.data.message));
        }

    } catch (error) {
        console.error("There was an error deleting the category:", error);
        dispatch(saveFailed(error.toString()));

    }
};

export const updateCategoryOrder = (ids) => async (dispatch) => {
    try {
        const response = await reorderCategories({
            options: {
                params: { Ids: ids },
            },
        });
        if (response.data.success) {
            dispatch(saveSucceeded());
        } else {
            dispatch(saveFailed(response.data.message));
        }

    } catch (error) {
        dispatch(saveFailed(error.message));
        console.error("There was an error reordering the categories:", error);
    }
};


