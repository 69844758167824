import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import AdminDefaultInputText from '../../../components/admin/admin-default-input-text.js';
import AdminModalColorPicker from '../../../components/admin/admin-modal-color-picker.js';
import { addColors, updateColor, deletColor} from '../../../store/colors-reducer.js';
import MediaUploader from '../../../components/media/MediaUploader';

export function ColorModal({ show, onClose, colorGroupId, isEditMode = false, existingColor = null, saveErrorMessage, saveStatus }) {
  const dispatch = useDispatch();
  const [colorName, setColorName] = useState(isEditMode ? existingColor.name : '');
  const [colorCode, setColorCode] = useState(isEditMode ? existingColor.colorCode : '');
  const [selectedColor, setSelectedColor] = useState(isEditMode ? existingColor.hex : '#7f7f7f');
  const [imageItem, setImageItem] = useState(isEditMode && 'colorImage' in existingColor ? existingColor.colorImage : null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleDelete = () => {
    dispatch(deletColor(existingColor.id)) 
  }
  const handleSave = () => {
    if (!colorName) {
      setErrorMessage('Color Name must be valid.');
      return;
    }
    let colorData = {
      Name: colorName,
      ColorGroupId: colorGroupId,
      ColorCode: colorCode,
      Hex: selectedColor,
      MediaItemId: (imageItem == null ? null : imageItem.id)
    };

    if (isEditMode) {
      colorData.Id = existingColor.id;
      dispatch(updateColor(colorData));
    } else {
      dispatch(addColors(colorData));
    }
  };

  useEffect(() => {
    if (saveStatus === 'succeeded') {
        onClose();
    }
}, [saveStatus,onClose]);

  if (!show) {
    return null;
  }

  return (
    <div className="admin-modal-overlay">
      <div className="admin-modal">
        <button className="admin-modal-close" onClick={onClose}>
          <span className="material-symbols-rounded">close</span>
        </button>
        <h2 className="admin-modal-heading">{isEditMode ? 'Edit Color' : 'Add Color'}</h2>
        <div className="admin-modal-row">
          <div className="admin-modal-cell">
            <AdminDefaultInputText
              id="color-name"
              label="Color Name"
              placeholder=""
              value={colorName}
              onChange={setColorName}

            />
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
          <div className="admin-modal-cell">
            <AdminDefaultInputText
              id="color-code"
              label="Manufacturer Color Code"
              placeholder=""
              value={colorCode}
              onChange={setColorCode}
            />
          </div>
        </div>
        <div className="admin-modal-row">
          <div className="admin-modal-cell">
            <AdminModalColorPicker onColorChange={setSelectedColor} selectedColor={selectedColor} />
          </div>
          <div className="admin-modal-cell flex">
            <p className="admin-modal-or">or</p>
          </div>
          <div className="admin-modal-cell">
            <MediaUploader
              imageOnly={true}
              allowGallery={false}
              mediaItems={(imageItem == null ? [] : [imageItem])}
              saveMediaItems={(item) => { setImageItem(item[0]) } }
              />
          </div>
        </div>

        {saveErrorMessage && (
					<p className="validation-error-message">{saveErrorMessage}</p>
				)}
        <div className="admin-modal-button-wrap">
          <button className="admin-modal-button" onClick={onClose}>Cancel</button>
          {!isEditMode ? '' : <button className="admin-modal-button red" onClick={handleDelete}>Delete</button>}
          <button className="admin-modal-button power" onClick={handleSave}>Save Changes</button>
        </div>
      </div>
    </div>
  );
}

ColorModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  colorGroupId: PropTypes.number,
  isEditMode: PropTypes.bool,
  existingColor: PropTypes.shape({
    id: PropTypes.number.isRequired,
    hex: PropTypes.string,
    colorCode: PropTypes.string,
    name: PropTypes.string.isRequired
  }),
  saveErrorMessage: PropTypes.string,
  saveStatus:PropTypes.string
};