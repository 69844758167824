import React from 'react';
import { updateTitle } from '../../helpers/titleHelper';
export default function TermsOfService() {
    updateTitle('Terms of Use');
    return (
        <div className="terms">
            <h1>Terms of Use</h1>
            <p>
                Facility Standard and its agents and representatives (“we,” “us,” or “our”) invites you to use our website as a resource, including school districts, colleges and universities, commercial and not-for-profit organizations with facility management needs.
            </p>
            <p>
                We provide visitors to our Website (“Visitors”) access to the Website subject to the following Terms of Use, which may be updated by us from time to time without notice to you. By browsing the public areas or by accessing and using the Website, you acknowledge that you have read, understood, and agree to be legally bound by these Terms of Use and our Privacy Policy, which is hereby incorporated by reference (collectively, this “Agreement”). If you do not agree to any of these terms, then please do not use the Website.
            </p>
            <p>
                Capitalized terms not defined in these Terms of Use shall have the meaning set forth in our Privacy Policy.
            </p>
            <h3>
                1. Website Usage
            </h3>
            <p>
                We provide Visitors with access to the Services as described below. Visitors, as the term implies, are people who do not register with us, but want to explore the Website. No login is required for Visitors. Visitors can: (i) view all publicly-available content on the Website; and (ii) email us.
            </p>
            <h3>
                2. Restrictions
            </h3>
            <p>
                The Website is only available for individuals aged 13 years or older. If you are 13 or older, but under the age of majority in your jurisdiction, you should review this Agreement with your parent or guardian to make sure that you and your parent or guardian understand it.
            </p>
            <h3>
                3. Intellectual Property
            </h3>
            <p>
                The Website contains material, such as video, photographs, text, graphics, images, and other material provided by or on behalf of Facility Standard (collectively referred to as the “Content”). The Content may be owned by us or third parties. The Content is protected under both United States and foreign laws. Unauthorized use of the Content may violate copyright, trademark, and other laws. Visitors may view all publicly-available Content for purposes or managing their facilities or consideration of our services for the purposes of becoming a customer of Facility Standard. No other use is permitted without the prior written consent of Facility Standard. Facility Standard retains all right, title, and interest, including all intellectual property rights, in and to the Content.
            </p>
            <h3>
                4. External Sites
            </h3>
            <p>
                The Website may contain links to third-party websites (“External Sites”). These links are provided solely as a convenience to you and not as an endorsement by us of the content on such External Sites. The content of such External Sites is developed and provided by others. You should contact the site administrator or webmaster for those External Sites if you have any concerns regarding such links or any content located on such External Sites. We are not responsible for the content of any linked External Sites and do not make any representations regarding the content or accuracy of materials on such External Sites. You should take precautions when downloading files from all websites to protect your computer from viruses and other destructive programs. If you decide to access linked External Sites, you do so at your own risk.
            </p>
            <h3>
                5. Compliance with Applicable Laws
            </h3>
            <p>
                The Website is based in the United States. We make no claims concerning whether the Content may be downloaded, viewed, or be appropriate for use outside of the United States. If you access the Website or the Content from outside of the United States, you do so at your own risk. Whether inside or outside of the United States, you are solely responsible for ensuring compliance with the laws of your specific jurisdiction.
            </p>
            <h3>
                6. Digital Millennium Copyright Act
            </h3>
            <p>
            Facility Standard respects the intellectual property rights of others and attempts to comply with all relevant laws. We will review all claims of copyright infringement received and remove any Content deemed to have been posted or distributed in violation of any such laws.
            </p>
        </div>
    );
}
