import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Loader from '../../../components/Loader.js';
import QuoteRequestSelect from '../../../components/DistrictQuote/districtQuoteRequest.js';
import { getDistrictQuotes, deleteQuoteById } from '../../../api/districtquoteapi.js';
import { formatDateTimeForUserTimeZone } from '../../../helpers/DateTimeFormatter';
import { updateTitle } from '../../../helpers/titleHelper';

export default function QuotesPage() {
    updateTitle('Admin', 'Quote Requests');
    const { districtId } = useParams();
    const [loadingData, setLoadingData] = useState(true);
    const [quotes, setQuotes] = useState({ total: 0, results: [] });
    const [filter, setFilter] = useState({ start: 1, length: 100, search: '', status: -1, sort: 1, isdistrictRequest: true });
    const [showDelete, setShowDelete] = useState(null);

    useEffect(() => {
        getDistrictQuotes(districtId, filter)
            .then((response) => {
                setQuotes(response.data.data);
                setLoadingData(false);
            });
    }, [filter]);

    const generatePageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(quotes.total / filter.length); i++) {
            pageNumbers.push(
                <button key={i}
                    onClick={() => { setFilter({ ...filter, start: filter.start + filter.length }); }}
                    className={Math.floor(filter.length) === i ? 'active' : ''}>{i}
                </button>
            );
        }
        return pageNumbers;
    };

    const handleShowDelete = (quote) => {
        setShowDelete(quote);
    }


    const handleDeleteQuote = () => {
        let quoteId = showDelete.id;
        deleteQuoteById(quoteId)
            .then(() => {
                // After successfully deleting the quote, call getDistrictQuotes again to refresh the data
                getDistrictQuotes(districtId, filter)
                    .then((response) => {
                        setQuotes(response.data.data);
                        setShowDelete(null);
                    })
                    .catch(error => {
                        console.error("Error fetching quotes after deletion:", error);
                        setShowDelete(null);
                    });
            })
            .catch(error => {
                console.error("Error deleting quote:", error);
            });
    };

    const onCloseDelete = () => {
        setShowDelete(null);
    }

    return (
        <>
            {showDelete != null &&
                <div className="admin-modal-overlay">
                    <div className="admin-modal">
                        <button className="admin-modal-close" onClick={onCloseDelete}>
                            <span className="material-symbols-rounded">close</span>
                        </button>
                        <h2 className="admin-modal-heading">Delete District Quote</h2>
                        <div className="admin-form-cell">
                            <p className="admin-default-input-text-wrap">
                                Are you sure you want to delete the quote made by {showDelete.email}? This cannot be undone.
                            </p>
                        </div>
                        <div className="admin-modal-button-wrap">
                            <button className="admin-modal-button" onClick={onCloseDelete}>
                                Cancel
                            </button>
                            <button className="admin-form-button power red" onClick={handleDeleteQuote}>
                                Delete Quote
                            </button>
                            
                        </div>
                    </div>
                </div>
            }
            <div className="admin-page-wrap">
                <div className="admin-top-bar-wrap">
                    <div className="admin-top-bar-left">
                        <Link className="admin-top-bar-back" to="/admin/">
                            Back
                        </Link>
                    </div>
                </div>
                <div className="admin-page-content">
                    <h1 className="admin-page-heading">Quote Requests</h1>
                    {loadingData ? <Loader /> :
                        <div>
                            <div className="admin-bottom-bar">
                                <div className="admin-bottom-bar-left">
                                    <input className="admin-default-search quoteradius"
                                        type="text"
                                        placeholder="search users"
                                        id="manufacturer-name"
                                        value={filter.search}
                                        onChange={(e) => setFilter({ ...filter, search: e.target.value })} />
                                </div>
                                <div className="admin-bottom-bar-right">
                                    <label className="admin-large-inline-label" htmlFor="quote-status">Filter Status</label>
                                    <QuoteRequestSelect id="quote-status" selectedId={filter.status}
                                        onChange={(e) => setFilter({ ...filter, status: parseInt(e.target.value) })} />
                                </div>
                            </div>


                            <div className="admin-table-wrap">
                                <table className="admin-table">
                                    <thead>
                                        <tr>
                                            <th onClick={() => setFilter({ ...filter, sort: filter.sort == 0 ? 1 : 0 })} className={filter.sort == 0 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort == 1 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>Quote</th>
                                            <th onClick={() => setFilter({ ...filter, sort: filter.sort == 8 ? 9 : 8 })} className={filter.sort == 8 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort == 9 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>District</th>

                                            <th onClick={() => setFilter({ ...filter, sort: filter.sort == 10 ? 11 : 10 })} className={filter.sort == 10 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort == 11 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>Project Name</th>

                                            <th onClick={() => setFilter({ ...filter, sort: filter.sort == 2 ? 3 : 2 })} className={filter.sort == 2 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort == 3 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>Email</th>
                                            <th onClick={() => setFilter({ ...filter, sort: filter.sort == 4 ? 5 : 4 })} className={filter.sort == 4 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort == 5 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>Submit Date</th>
                                            <th className="admin-table-name-cell">Items in Quote</th>
                                            <th onClick={() => setFilter({ ...filter, sort: filter.sort == 6 ? 7 : 6 })} className={filter.sort == 6 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort == 7 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>Status</th>
                                            <th className="admin-table-action-cell">&nbsp;</th>
                                            <th className="admin-table-action-cell">&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                       {/*.sort((a, b) => b.id - a.id)*/}
                                        {quotes.results && quotes.results.length > 0 ? (
                                            quotes.results
                                                .map(quote =>
                                                    <tr key={quote.id}>
                                                        <td className="admin-table-name-cell">{quote.districtId + '-' + ("000" + quote.id).slice(-4)}</td>
                                                        <td className="admin-table-name-cell">{quote.districtName}</td>

                                                        <td className="admin-table-name-cell">{quote.label != null ? quote.label :  'My Project'}</td>

                                                        <td className="admin-table-name-cell">{quote.email}</td>
                                                        <td className="admin-table-name-cell">{formatDateTimeForUserTimeZone(quote.created)}</td>
                                                        <td className="admin-table-name-cell">{quote.totalQuantity}</td>
                                                        <td className="admin-table-name-cell">{quote.statusText}</td>
                                                        <td className="admin-table-name-cell nowrap"><Link to={'/my-quote/' + quote.id}>View Project</Link></td>
                                                        <td>
                                                            <button className="admin-table-action" key={quote.id} onClick={() => handleShowDelete(quote)}>
                                                                <span className="material-symbols-rounded">delete</span>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )) : (
                                            <tr>
                                                <td className="admin-table-name-cell" colSpan="6">No active quotes or requests</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            {(quotes.total / filter.length) > 1 ? (
                                <div>
                                    <button
                                        onClick={() => setFilter({ ...filter, start: filter.start - filter.length })}
                                        disabled={filter.start == 1}>Previous
                                    </button>
                                    {generatePageNumbers()}
                                    <button
                                        onClick={() => setFilter({ ...filter, start: filter.start + filter.length })}
                                        disabled={filter.start - 1 + filter.length >= quotes.total}>Next
                                    </button>
                                </div>
                            ) : ""

                            }

                        </div>
                    }
                </div>
            </div>
        </>
    )
}