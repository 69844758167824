import { React } from 'react';
import PropTypes from 'prop-types';

export default function RoundedInput({ label, isRequired, placeholder, validated, value, onChange, requiredText }) {
    const handleOnChange = (e) => {
        if (onChange) {
            onChange(e.target.value);
        }
    }

    return (
        <div className={"input-box" + (!validated ? ' invalid' : '')} >
            <input
                type="text"
                value={value}
                placeholder={placeholder}
                onInput={handleOnChange}
                aria-label='Contact Form'
            />
            <label className={`absolute ${value && "filled"}`}>{label + (isRequired ? '*' : '')}</label>
            {!validated &&
                <span className="validation-error-message">{requiredText}</span>}
        </div>
    );
}
RoundedInput.propTypes = {
    value: PropTypes.node,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onValidation: PropTypes.func,
    isRequired: PropTypes.bool,
    requiredText: PropTypes.string,
    validated: PropTypes.bool
};
