

export function ValidateEmail(email) {
  let isValid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
  return isValid;
}

export function ValidateHasValue(value) {
  let isValid = value !== null && value !== undefined && value !== '';
  return isValid;
}

export function validatePhone(phone) {
    phone = phone.replace('+', '');
    if (phone.toLowerCase() !== phone.toUpperCase()) { return false; } //check for letters
    var regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (!regex.test(phone)) {
        regex = /^ ?([0-9]{1})?[ ]\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        return regex.test(phone);
    } else { return true; }
}