import api from '../utils/api';

export const getDistrictQuotes = (districtId, filter) => {
    var data = { ...filter };
    var impersonate = getImpersonationDetails();
    if (data.isMyAccountPage == true && impersonate && impersonate.userImpersonatingID) {
        data.userId = impersonate.userImpersonatingID;
    }
    return api.post('/api/district/quotes/get-all/' + districtId, data);
}

export const getTotalQuotes = (districtId, status) => {
    return api.post('/api/district/quotes/get-count/' + districtId + '/' + status);
}

export const getDistrictQuoteProducts = (quoteId) => {
    return api.post('/api/district/quotes/get-products/', { quoteId: quoteId });
}

export const getDistrictQuote = (quoteId) => {
    return api.post('/api/district/quotes/get-quote', { quoteId: quoteId });
}

export const editDistrictQuote = (quoteId) => {
    var data = { quoteId: quoteId };
    var impersonate = getImpersonationDetails();
    if (impersonate && impersonate.userImpersonatingID) {
        data.userId = impersonate.userImpersonatingID;
    }
    return api.post('/api/district/quotes/edit', data);
}

export const updateStatus = (quoteId, status) => {
    return api.post('/api/district/quotes/change-status', { quoteId: quoteId, status:status });
}

export const getQuoteIdByReviewing = (districtId) => {
    return api.post('/api/district/quotes/get-byreviewing', { districtId: districtId });
};

export const deleteQuoteById = (id) => {
    return api({
        url: '/api/district/quotes/delete-quote/' + id,
        method: 'GET',
    });
}
export const updateUnlockStatus = (quoteId, status) => {
    return api.post('/api/district/quotes/update-lock', { quoteId: quoteId, status: status });
}

export const getDistrictQuoteTypicalLayouts = (quoteId) => {
    return api.post('/api/district/quotes/get-getDistrictQuoteTypicalLayouts/', { quoteId: quoteId });
}

export const updateQuoteTypicalLayoutsLabel = (Id,Label) => {
    return api.post('/api/district/quotes/update-quote-typicallayouts/', { Id: Id, Label: Label });
}

export const updateDistrictQuoteTypicalLayoutsQuantity = (Id,Quantity) => {
    return api.post('/api/district/quotes/update-quote-typicallayouts/', { Id: Id, Quantity: Quantity });
}

export const getImpersonationDetails = () => {
    const data = sessionStorage.getItem('impersonationDetails');
    return data ? JSON.parse(data) : null;
}

export const getReportRowCount = (filter) => {
    return api.post('/api/district/quotes/report-rows', filter);
}