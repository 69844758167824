import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './myaccount.css';
import $ from 'jquery';
import { getDistrictQuotes } from '../../api/districtquoteapi.js';
import { useNavigate } from 'react-router-dom';
import { checkForDistrictId } from '../../helpers/checkForDistrictId';
import Banner from '../../components/common/ShowBannerDetails';
import { formatDateTimeForUserTimeZone } from '../../helpers/DateTimeFormatter';
import { updateTitle } from '../../helpers/titleHelper';

export default function MyAccount() {
    updateTitle('My Account');
    const navigate = useNavigate();
    $(document).ready(function () {
        $(".admin-table").parents('div.admin-table-wrap').addClass("admin-table-data");
    });
    const [quotes, setQuotes] = useState({ total: 0, results: [] });
    const [filter, setFilter] = useState({ start: 1, length: 999, search: '', status: -1, sort: 1, user: true, isMyAccountPage: true });
    const districtId = localStorage.getItem('districtId');

    useEffect(() => {
        checkForDistrictId(navigate); //redirect to admin/districts if user is not logged into district
        getDistrictQuotes(districtId, filter)
            .then((response) => {
                setQuotes(response.data.data);
            });
    }, [districtId, filter]);

    return (
        <div className="myaccount-table-center">
            <Banner/>
            <div className="product-header-wrap">
                <div>
                    <div className="product-breadcrumbs-wrap">
                        <p>
                            <a className="breadcrumb" href="/home">Home</a><span>&nbsp;/&nbsp;</span>
                            <span>My Account</span>
                        </p>
                    </div>
                </div>
                <div className="product-main-title-wrap">
                    <h1 className="product-main-title">My Account</h1>
                    <a className="product-main-logout-button" href="/account/logout">Logout</a>
                </div>

            </div>


            <div className="admin-table-wrap">
                <table className="admin-table">
                    <thead>
                        <tr>
                            <th scope="col" onClick={() => setFilter({ ...filter, sort: filter.sort == 0 ? 1 : 0 })} className={filter.sort == 0 ? 'admin-table-name-cell sortable sorted-dn account-quote' : (filter.sort == 1 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>Quote</th>
                            <th scope="col" onClick={() => setFilter({ ...filter, sort: filter.sort == 4 ? 5 : 4 })} className={filter.sort == 4 ? 'admin-table-name-cell sortable sorted-dn account-submit-date' : (filter.sort == 5 ? 'admin-table-name-cell sortable sorted-up account-submit-date' : 'admin-table-name-cell sortable account-submit-date')}>Submit Date</th>
                            <th scope="col" className="admin-table-name-cell account-in-items">Items in Quote</th>
                            <th scope="col" onClick={() => setFilter({ ...filter, sort: filter.sort == 6 ? 7 : 6 })} className={filter.sort == 6 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort == 7 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>Status</th>
                            <td scope="col" className="admin-table-action-cell">&nbsp;</td>
                        </tr>
                    </thead>
                    <tbody>
                        {quotes != undefined && quotes != null && quotes.results && quotes.results.length > 0 ? (
                            quotes.results.map(quote =>
                                <tr key={quote.id}>
                                    <td className="admin-table-name-cell">{(quote.label ?? 'My Project') + ' (' + quote.districtId + '-' + ("000" + quote.id).slice(-4) + ')'}</td>
                                    <td className="admin-table-name-cell">{formatDateTimeForUserTimeZone(quote.created)}</td>
                                    <td className="admin-table-name-cell">{quote.totalQuantity}</td>
                                    <td className="admin-table-name-cell">{quote.statusText}</td>
                                    <td className="admin-table-name-cell nowrap"><Link className="default-cta" to={"/my-quote/" + quote.id}>{quote.status == 0 ? "Edit" : "View"}</Link></td>
                                </tr>

                            )) : (


                            <tr>
                                <td className="admin-table-name-cell" colSpan="5">No active quotes or requests</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="my-account-btn">
                <a href="/my-project">Go to My Current Project</a></div>
        </div>
    );
}