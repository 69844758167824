import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function ToolTip({ title, label, tooltip }) {
    const [showInfo, setShowInfo] = useState(false);
    const handleTagInfo = () => {
		setShowInfo(true);
	}
	const onClose = () => {
		setShowInfo(false);
	}
    return (
        <>
            {showInfo &&
				<div className="admin-modal-overlay">
					<div className={"admin-modal admin-add-role admin-user-width"}>
						<div className="admin-modal-scroll">
							<button className="admin-modal-close" onClick={onClose}>
								<span className="material-symbols-rounded">close</span>
							</button>
							<h2 className="admin-modal-heading">{title}</h2>
							<p>{label}</p>
							<div className="admin-modal-button-wrap">
								<button className="admin-modal-button power" onClick={onClose}>Okay</button>
							</div>
						</div>
					</div>
				</div>    
            }
            <span className="tag-help material-symbols-rounded" onClick={handleTagInfo} title={tooltip ? label : null}>help</span>
        </>
        
    );
}
ToolTip.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	tooltip: PropTypes.boolean
};
