import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from "@reduxjs/toolkit";
import { getOneTimeLogin } from '../../store/auth/actions';
import { updateTitle } from '../../helpers/titleHelper';

export default function OneTimeLogin() {
    updateTitle('One-Time Login');
    const [username, setUserName] = useState('');
    const [show, setShow] = useState(false);
    const [checkUser, setCheckUser] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const forgotpassword = bindActionCreators(getOneTimeLogin, dispatch);

    const handleSubmit = async (event) => {
        event.preventDefault();

        let data = {
            Username: username,
            DomainName: window.location.origin
        }
        const result = await forgotpassword({ data: data }).unwrap();

        if (result.data) {
            if (result.data) {
                setShow(true);
                setCheckUser(result.data);
            } else {
                alert("Something is wrong");
            }
        }
        return;
    }

    useEffect(() => {
        document.querySelectorAll('.fancy-field').forEach((element) => {
            if (element.value != '') {
                element.parentNode.classList.add('input-focused');
            }
            else if (element.parentNode.classList.contains('input-focused')) {
                element.parentNode.classList.remove('input-focused');
            }
        });

    }, [username]);

    return (
        <div>
            {!show ? (
                <form onSubmit={handleSubmit}>
                    <div className="login-form-row">
                        <div className="login-form-cell">
                            <div className="validation-summary-valid" data-valmsg-summary="true">
                                <ul>
                                    {/* {error && <li>{error}</li>} */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="login-form-row">
                        <div className="login-form-cell">
                            <div className="login-form-field fancy-parent">
                                <label className="login-label" htmlFor="login-email">Email Address</label>
                                <input className="login-input fancy-field"
                                    id="login-email"
                                    name="Username"
                                    onChange={(e) => setUserName(e.target.value)}
                                    value={username}
                                    type="email" />
                            </div>
                        </div>
                    </div>
                    <div className="login-form-row">
                        <div className="login-form-cell">
                            <button className="login-submit" disabled={!username}>Submit</button>
                        </div>
                    </div>
                </form>
            ) : (
                    (checkUser === 'invalid' || checkUser === 'Invalid' ) ? (
                  <p>If your account exists, you will receive a one time code</p>
                ) : (
                    <p>Sign-in link sent. If your account is in our system, you should receive an email with a link to log in in the next couple of minutes.</p>
                )
            )}

            {!show ? (
                <div>
                    <button className="login-back" onClick={() => navigate('/account/login')}>Back to Login</button>
                </div>
            ) : (
                <p></p>
            )}
        </div>
    );


}

