import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../store/auth';
import { useNavigate } from 'react-router-dom';

export default function Logout() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(logoutUser());

        const districtJson = localStorage.getItem('district') ?? '';
        if (districtJson != '') {
            let district = JSON.parse(districtJson);
            if (district.isDemo == true) {
                localStorage.removeItem('district');
                localStorage.removeItem('districtId');
            }
        }

        navigate('/account/login');
    }, [])
    return (<></>)
}
