import React, { useState, useEffect } from 'react';
import { getAll } from '../../../api/typicallayoutsapi';
import PropTypes from 'prop-types';
import { formatUSD } from '../../../helpers/formatterHelper';
export function TypicalDistrictsCopyModal({ onClose, onCopy }) {
    const [districtLayouts, setDistrictLayouts] = useState([]);
    const [districts, setDistricts] = useState([]);

    useEffect(() => {
        getAll().then(response => {
            if (response.data.success == true) {
                setDistrictLayouts(response.data.data);
                var d = [];
                var di = 0;
                for (var x = 0; x < response.data.data.length; x++) {
                    var t = response.data.data[x];
                    if (t.districtId != di) {
                        d.push({ id: t.districtId, name: t.districtName });
                        di = t.districtId;
                    }
                }
                setDistricts(d);
            }
        })
    }, [onClose]);

    const handleCopyLayout = () => {
        var elems = [...document.querySelectorAll('.selected-layout')];
        var values = elems.filter(a => a.checked).map(a => a.value);
        if (values.length > 0) { onCopy(values); }
    }

    return (
        <div className="admin-modal-overlay">
            <div className="admin-modal admin-add-role" style={{'max-width':'50rem'} }>
                <div className="admin-modal-scroll">
                    <button className="admin-modal-close" onClick={onClose}>
                        <span className="material-symbols-rounded">close</span>
                    </button>
                    <h2 className="admin-modal-heading">Copy Typical Layout</h2>
                    {districts.map(district => {
                        return (
                            <>
                                <div className="admin-modal-row"  style={{padding:'0 0 20px 0', 'font-weight':'bold', 'max-width':'55rem'}}>
                                    <span style={{ 'font-size': '1.2rem' }}>{district.name}</span>
                                </div>
                                <div className="admin-modal-row" style={{'max-width':'100%'} }>
                                    <table className="admin-table" style={{ 'width': '100%', 'max-width':'none' }}>
                                        <thead>
                                            <tr>
                                                <th className="admin-table-name-cell">Category</th>
                                                <th className="admin-table-name-cell" style={{ 'width': '45%' }}>Title</th>
                                                <th className="admin-table-name-cell" style={{ 'width': '90px' }}>Products</th>
                                                <th className="admin-table-name-cell" style={{ 'width': '110px' }}>Cost</th>
                                                <th className="admin-table-action-cell">&nbsp;</th>
                                                <th className="admin-table-action-cell">Add</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {districtLayouts.filter(a => a.districtId == district.id).map(layout => {
                                                return (
                                                    <tr key={'item_' + layout.id }>
                                                        <td style={{'white-space':'nowrap'} }>{layout.categoryName}</td>
                                                        <td>{layout.title}</td>
                                                        <td>{layout.productCount}</td>
                                                        <td>{formatUSD(layout.cartTotal)}</td>
                                                        <td className="admin-table-name-cell nowrap">
                                                            <a href={'/typical-layout/' + layout.id} target="_blank" rel="noreferrer">View Layout</a>
                                                        </td>
                                                        <td className="table-action-cell">
                                                            <input type="checkbox" value={layout.id} className="selected-layout"></input>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        );
                    })}

                </div>
                <div className="admin-row" style={{'padding':'10px 40px'} }>
                    <div className="admin-modal-button-wrap">
                        <button className="admin-modal-button" onClick={onClose}>
                            Cancel
                        </button>
                        <button className="admin-modal-button power" onClick={handleCopyLayout}>
                            Add Layouts
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

TypicalDistrictsCopyModal.propTypes = {
    districtId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    onCopy: PropTypes.func.isRequired
};
