import React, { useState } from 'react';
import PropTypes from 'prop-types';

export function AccordionItem({ title, children, opened }) {
  const [isOpen, setIsOpen] = useState(opened === true);
  return (
    <div className={`prod-detail-content ${isOpen ? 'expanded' : 'collapsed'}`}>
      <div
        className="prod-detail-content-trigger"
        onClick={() => setIsOpen(!isOpen)}
      >
              {title}
      </div>
          <div className="prod-detail-content-collapse-wrap">
              <div className="prod-detail-content-collapse">
                  <div className="prod-detail-content para">
                      {children}
                  </div>
              </div>
          </div>
    </div>
  );
}

AccordionItem.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    opened: PropTypes.bool
  };
  