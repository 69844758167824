import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../../pages/cart/Cart.css';
import { Quantity } from './Quantity';
import CartButton from './CartButton';
import { useDispatch } from 'react-redux';
import { removeItem, moveItemToCart, updateMyWishlistQuantity} from '../../store/mywishlist-reducer';
import ProductImage from './ProductImage';
import {formatUSD,calculateFinalUnitPrice} from '../../helpers/formatterHelper'

const MyWishlist = ({ product }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleRemoveFromMyWishlist = () => {
		dispatch(removeItem(product.cartId));
	};
	const handleMoveToProjectList = () => {
		dispatch(moveItemToCart(product.cartId));
	};
    
	const handleQuantityChange = (event) => {
		const selectedQuantity = parseInt(event.target.value, 10);
		dispatch(updateMyWishlistQuantity({ cartId: product.cartId, quantity: selectedQuantity }));
	};

	const goToProduct = (product) => {
			navigate(`/products/${product.slug}`);
	};

	if (!product) {
		return <h1 className="loading-data">Loading...</h1>;
	}
	return (
		<>
			<div className="my-products--table-row normal-metaPro ">
				<div className="my-products--coll cart-img" style={{ 'cursor': 'pointer' }} onClick={() => { goToProduct(product); } }>
					<div className="cart-grid-col cart-image-area">
						<div className="cart-grid-image-wrap">
							{product && product.photo ? (
								<ProductImage
									photo={product.photo}
									photoAlt={product.photoAlt ?? product.details.name}
								/>
							) : (
								''
							)}
						</div>
					</div>
				</div>
				<div className="my-products--coll cart-detail cart-grid-col" style={{ 'cursor': 'pointer' }} onClick={() => { goToProduct(product); }}>
					<div className="cart-grid-desc-wrap">
						<p className="cart-desc-title">
							{product && product.details && product.details.name}
						</p>
						{product.options &&
							product.options.length > 0 &&
							product.options.filter(x => x.type !== 2).map((option) => (
								<React.Fragment key={option.id}>
									<p className="cart-desc-options">{option.title}&nbsp;:&nbsp;
										{option.items &&
											option.items.map((item) => (
												<React.Fragment key={item.id}>
													{option.type === 2 ? item.color.name : item.title}
												</React.Fragment>
											))}
										{/*{idx + 1 === product.options.filter(x => x.type !== 2).length ? '' : ' / '}*/}
									</p>
								</React.Fragment>
							))}
						{product.options &&
							product.options.length > 0 &&
							product.options.filter(x => x.type === 2).map((option) => (
								<React.Fragment key={option.id}>
									<p className="cart-desc-color">{option.title}:&nbsp;
										{option.items.map((item) => <span
											key={item.color.id}
											className="color-dots large color-select"
											style={
												item.color.colorImage
													? {
														backgroundImage: `url(${item.color.colorImage.previewsrc})`,
													}
													: { backgroundColor: item.color.hex }
											}
											htmlFor={item.color.id}
										></span>)}
									</p>
								</React.Fragment>
							))}
					</div>
				</div>

				<div className="my-products--coll cart-quantity">
					<Quantity
						cartId={product.cartId}
						currentQuantity={product.quantity}
                        setQuantity={handleQuantityChange}
					/>
				</div>
				<div className="my-products--coll cart-price " colSpan="2">
					<div className="cart-grid-header-wrap price-label">
						<div className="cart-grid-header-title">Unit Price</div>
						<div className="cart-grid-header-title header-right">Total</div>
					</div>
					<div className="cart-total-wrap">
						<div className="cart-datawrap">
							<div className="cart-grid-total-price-wrap">
								<div className="cart-grid-inner">
									<p className="mobile-price-label">Unit Price</p>
									<p className="cart-grid-total left-unit-price">{product?.details && formatUSD(calculateFinalUnitPrice(product))}</p>
								</div>
							</div>

							<div className="cart-grid-unit-price-wrap">
								<div className="cart-grid-inner">
									<p className="mobile-price-label">Total Price</p>
									<p className="cart-grid-total">
										{
											(formatUSD(calculateFinalUnitPrice(product) * product.quantity))}
									</p>
								</div>
							</div>
						</div>
						<div className="cart-grid-unit-buttons">
							<CartButton
								message={'Move to Cart'}
								action={handleMoveToProjectList} cellClass={'myproduct-icon'}
							/>
							<CartButton message={'Delete'} action={handleRemoveFromMyWishlist} cellClass={'delete-cell'} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default MyWishlist;

MyWishlist.propTypes = {
	product: PropTypes.object.isRequired,
};
