import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'banner',
    initialState: {
        isVisible: false, 
        message: '',
        isButtonShown: false,
        isTypicalButtonShow: false
    },
    reducers: {
        showBanner: (state) => {
            state.isVisible = true;
        },
        hideBanner: (state) => {
            state.isVisible = false;
        },
        showBannerWithButton:(state) => {
            state.isVisible=true;
            state.isButtonShown = true;
        },
        showTypicalCancelBannerButton: (state) => {
            state.isVisible = true;
            state.isTypicalButtonShow = true;
        },

        setMessage: (state, action) => {
            state.message = action.payload;
        },
        controlBannerWithButtonAndMessage: (state, action) => {
            state.message = action.payload.message;
            state.isVisible=action.payload.isVisible;
            state.isButtonShown = action.payload.isButtonShown;
        }

    }
    
});

export const { showBanner, hideBanner, setMessage, showBannerWithButton, controlBannerWithButtonAndMessage, showTypicalCancelBannerButton } = slice.actions;
export default slice.reducer;

export const selectBannerVisible = (state) => state.banner.isVisible;
export const selectBannerMessage = (state) => state.banner.message;
export const selectIsBtnShown = (state) => state.banner.isButtonShown;

