import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Loader from '../../../components/Loader.js';
import QuoteRequestSelect from '../../../components/DistrictQuote/districtQuoteRequest.js';
import { getDistrictQuotes } from '../../../api/districtquoteapi.js';
import { formatDateTimeForUserTimeZone } from '../../../helpers/DateTimeFormatter';
export default function DistrictQuoteRequests() {
    const { districtId } = useParams();
    const [loadingData, setLoadingData] = useState(true);
    const [quotes, setQuotes] = useState({ total: 0, results: [] });
    const [filter, setFilter] = useState({ start: 1, length: 100, search: '', status: -1, sort: 1, isdistrictRequest: true });

    useEffect(() => {
        getDistrictQuotes(districtId, filter)
            .then((response) => {
                setQuotes(response.data.data);
                setLoadingData(false);
            });
    }, [filter]);

    const generatePageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(quotes.total / filter.length); i++) {
            pageNumbers.push(
                <button key={i}
                    onClick={() => { setFilter({ ...filter, start: filter.start + filter.length }); } }
                    className={Math.floor(filter.length) === i ? 'active' : ''}>{i}
                </button>
            );
        }
        return pageNumbers;
    };

    return (
        <div>
            {loadingData ? <Loader /> :
                <div className="admin-tab-content">
                    <div className="admin-bottom-bar">
                        <div className="admin-bottom-bar-left">
                            <input className="admin-default-search quoteradius"
                                type="text"
                                placeholder="search users"
                                id="manufacturer-name"
                                value={filter.search}
                                onChange={(e) => setFilter({ ...filter, search: e.target.value })} />
                        </div>
                        <div className="admin-bottom-bar-right">
                            <label className="admin-large-inline-label" htmlFor="quote-status">Filter Status</label>
                            <QuoteRequestSelect id="quote-status" selectedId={filter.status}
                                onChange={(e) => setFilter({ ...filter, status: parseInt(e.target.value) })} />
                        </div>
                    </div>

                    <div className="admin-table-wrap">
                        <table className="admin-table">
                            <tbody>
                            <tr>
                               <th onClick={() => setFilter({ ...filter, sort: filter.sort == 0 ? 1 : 0 })} className={filter.sort == 0 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort == 1 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>Quote</th>

                                <th onClick={() => setFilter({ ...filter, sort: filter.sort == 10 ? 11 : 10 })} className={filter.sort == 10 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort == 11 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>Project Name</th>

                                <th onClick={() => setFilter({ ...filter, sort: filter.sort == 2 ? 3 : 2 })} className={filter.sort == 2 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort == 3 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>User</th>
                                <th onClick={() => setFilter({ ...filter, sort: filter.sort == 4 ? 5 : 4 })} className={filter.sort == 4 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort == 5 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>Submit Date</th>
                                <th className="admin-table-name-cell">Items in Quote</th>
                                <th onClick={() => setFilter({ ...filter, sort: filter.sort == 6 ? 7 : 6 })} className={filter.sort == 6 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort == 7 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>Status</th>
                                <th className="admin-table-action-cell">&nbsp;</th>
                            </tr>
                            {quotes.results && quotes.results.length > 0 ? (
                                quotes.results.map(quote =>
                                    <tr key={quote.id}>
                                        <td className="admin-table-name-cell">{quote.districtId + '-' + ("000" + quote.id).slice(-4)}</td>
                                        <td className="admin-table-name-cell">{quote.label != null ? quote.label : "My Project"}</td>
                                        <td className="admin-table-name-cell">{quote.email}</td>
                                        <td className="admin-table-name-cell">{formatDateTimeForUserTimeZone(quote.created)}</td>
                                        <td className="admin-table-name-cell">{quote.totalQuantity}</td>
                                        <td className="admin-table-name-cell">{quote.statusText}</td>
                                        <td className="admin-table-name-cell nowrap"><Link to={'/my-quote/' + quote.id} target="_blank" rel="noopener noreferrer">View/Edit Project</Link></td>
                                    </tr>
                                )) : (
                                <tr>
                                    <td className="admin-table-name-cell" colSpan="6">No active quotes or requests</td>
                                </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    {(quotes.total / filter.length) > 1 ? (
                        <div>
                            <button
                                onClick={() => setFilter({ ...filter, start: filter.start - filter.length })}
                                disabled={filter.start == 1}>Previous
                            </button>
                            {generatePageNumbers()}
                            <button
                                onClick={() => setFilter({ ...filter, start: filter.start + filter.length })}
                                disabled={filter.start - 1 + filter.length >= quotes.total}>Next
                            </button>
                        </div>
                    ) : ""

                    }

                    </div>
            }
        </div>
    )
}