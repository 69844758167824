import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getById, edit } from '../../../api/districtapi';
import DistrictSettings from './districtsettings';
import DistrictQuoteRequests from "./quoteRequests";
import DistrictProductLineDiscount from "./districtPrdLIneDis";
import DistrictTypicalLayouts from "./districtTypicalLayouts";
import DistrictSurvey from "./districtSurvey";
import DistrictUsers from './districtusers'
import DistrictProducts from './products/DistrictProducts';
import { useDispatch } from 'react-redux';
import { showLoading, hideLoading } from '../../../store/loading-reducer';
import { getDistrictQuotes } from '../../../api/districtquoteapi.js';
import { updateTitle } from '../../../helpers/titleHelper';

const districtObj = {
    id: 0,
    name: '',
    hexColor: '#000000',
    path: '',
    mediaItemId: null,
    schoolLogo: null
}

function EditDistrictDetail() {
    const [activeTab, setActiveTab] = useState('settings');
    const [district, setDistrict] = useState(districtObj);

    const [filter] = useState({ start: 1, length: 100, search: '', status: -1, sort: 0, isdistrictRequest: true });
    const [districtcount, setDistrictcount] = useState(0);

    const { districtId, openTab } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (openTab !== undefined) setActiveTab(openTab);
        if (districtId == null) navigate('/admin/districts');

        getDistrictQuotes(districtId, filter)
            .then((response) => {
                handleDistrictCount(response?.data?.data?.total);
            });

        dispatch(showLoading());
        if (!district || district.id != districtId) {
            getById(districtId)
                .then((response) => {
                    dispatch(hideLoading());
                    if (response.data.success) {
                        setDistrict({
                            ...response.data.data
                            , isDistrictUser: response.data.isDistrictUser
                            , isDistrictAdmin: response.data.isDistrictAdmin
                        });
                        updateTitle('Admin', response.data.data.name);
                    } else navigate('/admin/districts');
                })
                .catch(ex => {
                    console.log(ex);
                });
        }
    }, []);

    const handleDistrictEdit = (districtData) => {
        if (districtData != undefined && districtData != null) {
            dispatch(showLoading());
            edit({ options: { data: districtData } })
                .then((response) => {
                    dispatch(hideLoading());
                    setDistrict(response.data.data);
                })
        }
    }

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        window.history.replaceState(null, null, '/admin/districts/edit/' + districtId + '/' + tab);
    }

    const handleDistrictCount = (count) => {
        setDistrictcount(count);
    }

    return (
        <div className="admin-page-wrap">
            <div className="admin-top-bar-wrap">
                <div className="admin-top-bar-left">
                    <a className="admin-top-bar-back" href="/admin/districts">Back</a>
                </div>
            </div>
            <div className="admin-page-content">
                <h1 className="admin-page-heading">{district.name}</h1>
                <div className="admin-tab-wrap">
                    <button className={`admin-tab ${activeTab === 'settings' ? 'active' : ''}`} onClick={() => handleTabChange('settings')} id="tab1" role="tab" aria-controls="settings-tab" aria-selected="true">Settings</button>
                    <button className={`admin-tab ${activeTab === 'users' ? 'active' : ''}`} id="tab2" onClick={() => handleTabChange('users')} role="tab" aria-controls="users-tab" aria-selected="true">End Users</button>
                    <button className={`admin-tab ${activeTab === 'quoterequests' ? 'active' : ''}`} id="tab3" role="tab" onClick={() => handleTabChange('quoterequests')} aria-controls="quoterequests-tab" aria-selected="true">Quote Requests {districtcount > 0 ? "(" + districtcount + ")" : "(" + 0 + ")"}</button>
                    <button className={`admin-tab ${activeTab === 'products' ? 'active' : ''}`} id="tab4" role="tab" aria-controls="products-tab" onClick={() => handleTabChange('products')} aria-selected="true">Products</button>
                    <button className={`admin-tab ${activeTab === 'pricing' ? 'active' : ''}`} id="tab5" role="tab" aria-controls="pricing-tab" onClick={() => handleTabChange('pricing')} aria-selected="true">Pricing</button>
                    <button className={`admin-tab ${activeTab === 'layouts' ? 'active' : ''}`} id="tab6" role="tab" aria-controls="pricing-tab" onClick={() => handleTabChange('layouts')} aria-selected="true">Typical Layouts</button>
                    <button className={`admin-tab ${activeTab === 'surveys' ? 'active' : ''}`} id="tab7" role="tab" aria-controls="surveys-tab" onClick={() => handleTabChange('surveys')} aria-selected="true">Surveys</button>
                </div>
                {activeTab === 'settings' && <DistrictSettings district={district} onDistrictEdit={handleDistrictEdit} />}
                {activeTab === 'users' && <DistrictUsers />}
                {activeTab === 'quoterequests' && <DistrictQuoteRequests district={district} /*handleDistrictCount={handleDistrictCount}*/ />}
                {activeTab === 'pricing' && <DistrictProductLineDiscount />}
                {activeTab === 'layouts' && <DistrictTypicalLayouts />}
                {activeTab === 'products' && <DistrictProducts districtId={districtId} />}
                {activeTab === 'surveys' && <DistrictSurvey />}

            </div>
        </div>
    );
}

export default EditDistrictDetail;