import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { checkLogin } from '../api/checkuserapi';
import HomeLayout from '../layouts/HomeLayout';
import DefaultLayout from '../layouts/DefaultLayout';

export default function EndUserLayout({ children }) {
    //determine whether or not to display the logged-in user header & footer (DefaultLayout)
    //or the anonymous user header & footer (HomeLayout)
    const [loginUser, setLoginUser] = useState({ id: -1 });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (loading == true) {
            setLoading(false);
            checkLogin().then((response) => {
                if (response.data.success == true) {
                    setLoginUser(response.data.data);
                } else {
                    setLoginUser({ id: 0 });
                }
            });
        }

    }, [loginUser]);

    if (loginUser.id == 0) {
        return (<HomeLayout isMainPage={false}>{children}</HomeLayout>);
    } else if (typeof loginUser.id == 'string') {
        return (<DefaultLayout>{children}</DefaultLayout>);
    }
}


EndUserLayout.propTypes = {
    children: PropTypes.any,
};
