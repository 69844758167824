import React from 'react';
import PropTypes from 'prop-types';
import DragDropContainer from '../../../components/DragAndDropContainer';
import { useDispatch } from 'react-redux';
import {
	updateColorOrder,
	updateColorGroupOrder,
} from '../../../store/colors-reducer';
export const ColorGroupSection = ({
	colorGroups,
	openAddColorModal,
	openEditColorModal,
	openEditColorGroupModal,
	openDeleteColorGroupModal,
}) => {
	const dispatch = useDispatch();

	const moveColor = (groupId, fromIndex, toIndex, makeApiCall) => {
		// Find the index of the group that matches the provided groupId
		const groupIndex = colorGroups.findIndex((g) => g.id === groupId);
		if (groupIndex !== -1) {
			// Clone the colors array for the specific group to avoid mutating state
			const updatedColors = [...colorGroups[groupIndex].colors];

			// Check if the color id exists at the specified fromIndex
			if (!updatedColors[fromIndex]) {
				console.error(
					`No color found at index ${fromIndex} in group ${groupId}`
				);
				return;
			}

			const [movedColor] = updatedColors.splice(fromIndex, 1);
			updatedColors.splice(toIndex, 0, movedColor);

			const ids = updatedColors.map((color) => color.id).join(',');
			if (makeApiCall) {
				dispatch(updateColorOrder(ids));
			}
		}
	};

	const renderColor = (color) => (
		<div key={color.id} className="admin-round-swatch">
			<div className="admin-round-swatch-preview">
				<div
					className="admin-round-swatch-preview-background"
					style={{
						background: color.hex,
					}}
				>
					{('colorImage' in color ? <img src={color.colorImage.previewsrc} /> : '') }
				</div>
				<button
					className="admin-round-swatch-edit"
					onClick={() => openEditColorModal(color)}
				>
					<span className="material-symbols-rounded">edit</span>
				</button>
			</div>
			<p className="admin-round-swatch-name">{color.name}</p>
		</div>
	);

	const moveColorGroup = (currentGroupId, direction) => {
		const currentGroupIndex = colorGroups.findIndex(
			(group) => group.id === currentGroupId
		);

		if (currentGroupIndex < 0) return;

		if (
			(direction === 'up' && currentGroupIndex === 0) ||
			(direction === 'down' && currentGroupIndex === colorGroups.length - 1)
		)
			return;

		const newColorGroups = [...colorGroups];
		const targetIndex =
			direction === 'up' ? currentGroupIndex - 1 : currentGroupIndex + 1;

		const temp = newColorGroups[currentGroupIndex];
		newColorGroups[currentGroupIndex] = newColorGroups[targetIndex];
		newColorGroups[targetIndex] = temp;

		const ids = newColorGroups.map((group) => group.id).join(',');
		dispatch(updateColorGroupOrder(ids));
	};

	return (
		<div>
			{colorGroups.map((colorGroup, index) => {
				const currentColorGroupId = colorGroup.id;
				return (
					<div key={colorGroup.id} className="admin-round-swatch-section">
						<h2 className="admin-round-swatch-title">
							<span className="admin-round-swatch-sort-arrow-wrap">
								<button className="admin-round-swatch-sort-arrow"
									onClick={() => moveColorGroup(colorGroup.id, 'up')}
									style={{ display: index === 0 ? 'none' : 'inline-block' }}>
									<span className="material-symbols-rounded">arrow_upward</span>
								</button>
								<button className="admin-round-swatch-sort-arrow"
									onClick={() => moveColorGroup(colorGroup.id, 'down')}
									style={{ display: index === colorGroups.length - 1 ? 'none' : 'inline-block' }} >
									<span className="material-symbols-rounded"
									>arrow_downward</span>
								</button>
							</span>
							<span className="admin-round-swatch-title-text">
								{colorGroup.name}
							</span>
							<span className="admin-round-swatch-title-action-wrap">
								<button
									className="admin-round-swatch-title-action edit"
									onClick={() => openEditColorGroupModal(colorGroup)}
								>
									<span className="material-symbols-rounded">edit</span>
								</button>
								<button
									className="admin-round-swatch-title-action delete"
									onClick={() => openDeleteColorGroupModal(colorGroup)}
								>
									<span className="material-symbols-rounded">delete</span>
								</button>
							</span>								
							<span className="admin-round-swatch-name">
								(Up Charge: {colorGroup && colorGroup.upcharge !== undefined
									? colorGroup.upcharge.toLocaleString('en-US', {
										style: 'currency',
										currency: 'USD',
									})
									: '$0'})
							</span>

						</h2>
						<div className="admin-round-swatch-wrap">
							<DragDropContainer
								items={colorGroup.colors}
								moveItem={(fromIndex, toIndex, makeApiCall) =>
									moveColor(colorGroup.id, fromIndex, toIndex, makeApiCall)
								}
								renderItem={renderColor}
								isHorizontal={true}
								colorGroupId={colorGroup.id}
								canDropItem={(item) => {
									return item.colorGroupId === currentColorGroupId;
								}}
							/>

							<div className="admin-round-swatch">
								<button
									onClick={() => openAddColorModal(colorGroup.id)}
									className="admin-round-swatch-add"
								>
									<span className="material-symbols-rounded">add</span>
								</button>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

ColorGroupSection.propTypes = {
	colorGroups: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			manufacturerId: PropTypes.number.isRequired,
			manufacturer: PropTypes.shape({
				id: PropTypes.number.isRequired,
				name: PropTypes.string,
				shippingNote: PropTypes.string,
				isDeleted: PropTypes.bool.isRequired,
				productLines: PropTypes.array,
				colorGroups: PropTypes.array,
			}).isRequired,
			name: PropTypes.string.isRequired,
			colors: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.number.isRequired,
					/*color: PropTypes.string.isRequired,*/
					hex: PropTypes.string,
					colorCode: PropTypes.string,
					name: PropTypes.string.isRequired,
					colorImage: PropTypes.object,
				})
			).isRequired,
		})
	).isRequired,
	openAddColorModal: PropTypes.func.isRequired,
	openEditColorModal: PropTypes.func.isRequired,
	openEditColorGroupModal: PropTypes.func.isRequired,
	openDeleteColorGroupModal: PropTypes.func.isRequired,
	loadColorGroups: PropTypes.func.isRequired,
};
