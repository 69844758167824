import React, { useEffect, useState } from 'react';
import { updateCategoryOrder } from '../../../store/categories-reducer';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import DragDropContainer from '../../../components/DragAndDropContainer';

export const CategoriesContainer = ({ categories, onEdit, onDelete }) => {
	const dispatch = useDispatch();

	if (!categories) {
		return <h2>no categories exist</h2>;
	}

	const [localCategories, setLocalCategories] = useState(categories);
    const [trackOrderCategories, setTrackOrderCategories] = useState(categories);

	useEffect(() => {
		if (categories) {
			setLocalCategories(categories);
		}
	}, [categories]);

	const moveCategory = (fromIndex, toIndex, makeApiCall) => {
		const updatedCategories = [...localCategories];
		const [movedItem] = updatedCategories.splice(fromIndex, 1);
		updatedCategories.splice(toIndex, 0, movedItem);

		const newUpdatedCategories = updatedCategories.map((category, index) => ({
			...category,
			order: index,
		}));

		setLocalCategories(newUpdatedCategories);
        setTrackOrderCategories(newUpdatedCategories);
		if (makeApiCall) {
            setLocalCategories(trackOrderCategories);

			const ids = trackOrderCategories.map((category) => category.id).join(',');
			dispatch(updateCategoryOrder(ids));
		}
	};

	const renderCategoryItem = (category) => (
		<div className="admin-drag-box" key={category.id}>
			<span className="admin-drag-box-name">{category.name}</span>
			<span className="admin-drag-option-wrap">
				<button
					className="admin-drag-option edit"
					onClick={() => onEdit(category)}
				>
					<span className="material-symbols-rounded">edit</span>
				</button>
				<button
					className="admin-drag-option delete"
					onClick={() => onDelete(category)}
				>
					<span className="material-symbols-rounded">delete</span>
				</button>
			</span>
		</div>
	);

	return (
		<DragDropContainer
			items={localCategories}
			moveItem={moveCategory}
			renderItem={renderCategoryItem}
			isHorizontal={false}
		/>
	);
};
CategoriesContainer.propTypes = {
	categories: PropTypes.array.isRequired,
	onEdit: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
};
