import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import MediaUploader from '../../../components/media/MediaUploader.js';
import ProductLineSelect from '../../../components/products/ProductLines.js';
import AdminTextInput from '../../../components/admin/admin-text-input.js';
import { ProductItem } from './ProductItem.js';
import { useDispatch } from 'react-redux';
import { showLoading, hideLoading } from '../../../store/loading-reducer';
import { getProduct, submitProduct, getHistory } from '../../../api/productapi';
import { CategoriesResults } from './categories.js';
import ProductOptions from './productOptions.js';
import { ManufacturerGuides } from './documents.js';
import axios from 'axios';
import AdminError from '../../../components/admin/admin-error';
import { loadColorGroups } from '../../../store/colors-reducer';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import CustomerEditor from '@ckeditor/ckeditor5-build-classic';
import SimpleBar from 'simplebar-react';
import CurrencyInput from 'react-currency-input-field';
import { updateTitle } from '../../../helpers/titleHelper';

const ckEditorConfig = {
	toolbar: [
		'Bold',
		'Italic',
		'Link',
		'Unlink',
		'NumberedList',
		'BulletedList',
		'Indent',
		'Outdent',
	],
};
export function Products() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { manufacturerId } = useParams();
	const { productId } = useParams();
	const { versionDate } = useParams();

	const [product, setProduct] = useState(ProductItem);
	const [mediaItems, setMediaItems] = useState([]);
	const [productLineId, setProductLineId] = useState(null);
	const [documentItems, setDocumentItems] = useState(new FormData());
	const [error, setError] = useState('');
	const [versionItems, setVersionItems] = useState();

	const [validation, setValidation] = useState({
		productLineId: true,
		productName: true,
		productPrice: true,
		productPriceError: '',
		shortDescription: true,
		description: true,
	});
	const [isInitialLoad, setIsInitialLoad] = useState(true);
	const handleProductSave = () => {
		product.productLineId = productLineId;

		//validate product
		var isValid = true;
		var validate = {
			productLineId: true,
			productName: true,
			productPrice: true,
			productPriceError: '',
			shortDescription: true,
			description: true,
		};
		if (productLineId <= 0) {
			validate.productLineId = false;
			isValid = false;
		}
		if (
			product.details == null ||
			product.details.name == null ||
			product.details.name == ''
		) {
			validate.productName = false;
			isValid = false;
		}
		if (
			product.details == null ||
			product.details.price == null ||
			product.details.price <= 0
		) {
			validate.productPrice = false;
			validate.productPriceError = 'Price must be greater than 0';
			isValid = false;
		}
		setValidation({ ...validation, ...validate });
		if (isValid == false) {
			return;
		}
		dispatch(showLoading());

		//submit product
		//product.details.name = ''; //debugging only
		//setProduct(product); //debugging only
		submitProduct(product)
			.then((response) => {
				if (response.data.success == false) {
					dispatch(hideLoading());
					setError(response.data.message);
					return;
				}
				if (documentItems.getAll("pdfFile").length > 0) {
					if (product.id == 0 || product.id == null) {
						documentItems.append('productsId', response.data.data.id);
					} else {
						documentItems.append('productsId', product.id);
					}
					handleDocumentAdd(documentItems, () => {
						dispatch(hideLoading());
						navigate('/admin/manufacturers-products/edit/' + manufacturerId);
					});
				} else if (response.data.success) {
					dispatch(hideLoading());
					navigate('/admin/manufacturers-products/edit/' + manufacturerId);
				} else dispatch(hideLoading());
			})
			.catch((error) => {
				dispatch(hideLoading());
				if (error.response.data && error.response.data.message) {
					setError(error.response.data.message);
				} else {
					setError(error.response.responseText);
				}
			});
	};

	const handleMediaUpdates = (mediaItems) => {
		setProduct({
			...product,
			media: mediaItems,
		});
	};

	const handleCategoryUpdates = (categoryItems) => {
		setProduct({
			...product,
			categories: categoryItems,
		});
	};

	const handleProductOptionsUpdates = (Options) => {
		setProduct({
			...product,
			options: Options,
		});
	};

	const handleDocumentUpdates = (Documents) => {
		setProduct({
			...product,
			documents: Documents,
		});
	};

	const handleDocumentAdd = (items, onComplete) => {
		axios({
			url: '/api/media/addfile',
			method: 'POST',
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			data: items,
		})
			.then((response) => {
				if (onComplete) {
					onComplete();
				}
				if (response.data.success == false) {
					setError(response.data.message);
					return;
				}
			})
			.catch((error) => {
				if (onComplete) {
					onComplete();
				}
				if (error.response.data && error.response.data.message) {
					setError(error.response.data.message);
				} else {
					setError(error.response.responseText);
				}
			});
	};

	const formData = (data) => {
		const formData = new FormData();
		data.forEach((file) => {
			formData.append('pdfFile', file);
		});
		setDocumentItems(formData);
	};

	useEffect(() => {
		dispatch(loadColorGroups(manufacturerId));
		if (productId > 0) {
			dispatch(showLoading());
			getProduct(productId, null, versionDate ? versionDate.replace('_', '.') : null).then((response) => {
				let productResponse = response.data.data;
				if (productResponse.details == null)
					productResponse.details = { name: '' };
				setProduct(productResponse);
				updateTitle('Admin', 'Update Product - ' + productResponse.details.name);
				setProductLineId(productResponse.productLineId);
				if (
					productResponse.media !== null &&
					productResponse.media.length > 0
				) {
					var getMediaItems = [];
					productResponse.media.forEach((item) => {
						item.mediaItem.mediaItemId = item.mediaItemId;
						getMediaItems.push(item.mediaItem);
					});
					setMediaItems(getMediaItems);
				}
				dispatch(hideLoading());

				//get product versions
				getHistory(productId).then((history) => {
					setVersionItems(history.data.data);
				});
			});
			
		} else {
			setProductLineId(0);
			updateTitle('Admin', 'Add New Product');
		}
	}, []);

	function formatAMPM(date) {
		var hours = date.getHours();
		var minutes = date.getMinutes();
		var ampm = hours >= 12 ? 'pm' : 'am';
		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'
		minutes = minutes < 10 ? '0' + minutes : minutes;
		var strTime = hours + ':' + minutes + ' ' + ampm;
		return strTime;
	}

	const calculateNetPrice = () => {
		const listPrice = parseFloat(product.details.price) || 0;
		const contractDiscount = parseFloat(product.details.contractDiscount) || 0;
		const netPrice = listPrice - (listPrice * contractDiscount) / 100;
		return netPrice.toFixed(2);
	};

	useEffect(() => {
		const netPrice = calculateNetPrice();
		setProduct({
			...product,
			details: {
				...product.details,
				netPrice: netPrice,
			},
		});
	}, [product.details.price, product.details.contractDiscount]);

	return (
		<div className="admin-page-wrap">
			<div className="admin-top-bar-wrap">
				<div className="admin-top-bar-left">
					<Link
						className="admin-top-bar-back"
						to={'/admin/manufacturers-products/edit/' + manufacturerId}
					>
						Back
					</Link>
				</div>
				<div className="admin-top-bar-right">
					{!(product.id == 0 || product.id == null) && versionItems && versionItems.length > 0 && <>
						<div className="rollbackWrap">
							<div className="topBarLink"><span className="fa fa-mail-reply"></span>Rollback</div>
							<div className="rollbackDropdown">
								<SimpleBar style={{ maxHeight: 175 }}>
									{versionItems.map(version => {
										var date = new Date(Date.parse(version.Version + 'Z'));
										return (
											<a key={version.Version} href={`/admin/products/${manufacturerId}/edit/${product.id}/${encodeURI(version.Version).replace(/\./g, '_')}`}>
												{date.toLocaleDateString("en-us", { year: "numeric", month: "long", day: "numeric" }) + ' ' + formatAMPM(date)}
											</a>);
									})}
								</SimpleBar>
							</div>
						</div>
					</>}
				</div>
			</div>
			<div className="admin-page-content">
		
				<h1 className="admin-page-heading">Add / Edit Product</h1>

				<AdminError
					message={error}
					onClose={() => {
						setError('');
					}}
				></AdminError>

				<div className="admin-section">
					<div className="admin-section-title-bar">
						<p className="admin-section-headline">Description &amp; Photos</p>
					</div>
					<div className="admin-section-row">
						<div className="admin-section-cell wide">
							<AdminTextInput
								label="Product Name"
								id="product-name"
								value={product.details.name ?? ''}
								onChange={(value) => {
									setProduct({
										...product,
										details: {
											...product.details,
											name: value,
										},
									});
									setValidation({ ...validation, productName: value != '' });
								}}
								validated={validation.productName}
							/>
						</div>
						<div className="admin-section-cell">
							<AdminTextInput
								label="Mfg Part #"
								id="mfg-part-num"
								value={product.details.mfgPartNumber ?? ''}
								onChange={(value) => {
									setProduct({
										...product,
										details: {
											...product.details,
											mfgPartNumber: value,
										},
									});
								}}
							/>
						</div>
						<div className="admin-section-cell">
							<AdminTextInput
								label="Freedom Code #"
								id="freedom-code-num"
								value={product.details.freedomCode ?? ''}
								onChange={(value) => {
									setProduct({
										...product,
										details: {
											...product.details,
											freedomCode: value,
										},
									});
								}}
							/>
						</div>
					</div>
					<div className="admin-section-row">
						<div
							className={
								'admin-section-cell wide' +
								(validation.productLineId == false
									? ' input-validation-error'
									: '')
							}
						>
							<label className="admin-default-label" htmlFor="product-line">
								Select Product Line
							</label>
							<ProductLineSelect
								id="product-line"
								manufacturerId={parseInt(manufacturerId)}
								selectedId={productLineId}
								onChange={(e) => {
									setProductLineId(Number(e.target.value));
									setValidation({
										...validation,
										productLineId: e.target.value != '0',
									});
								}}
							/>
						</div>
					</div>
					<div className="admin-section-row">
						<div className="admin-section-cell">
							<div
								className={
									'admin-default-input-text-wrap' +
									((product.details.price === 0 || product.details.price === "0.00")  ? ' input-validation-error' : '')
								}
							>
								<label className="admin-default-label">List Price </label>
								{/*<AdminTextInput*/}
								{/*	inputType="currency"*/}
								{/*	id="list-price"*/}
								{/*	value={product.details.price}*/}
								{/*	onChange={(value) => {*/}
								{/*		value = Number(value.replace('$', '').replace(/,/g, ''));*/}
								{/*		setProduct({*/}
								{/*			...product,*/}
								{/*			details: {*/}
								{/*				...product.details,*/}
								{/*				price: value,*/}
								{/*			},*/}
								{/*		});*/}
								{/*		setValidation({ ...validation, productPrice: value });*/}
								{/*	}}*/}
								{/*	validated={validation.productPrice}*/}
								{/*	requiredText={validation.productPriceError}*/}
								{/*/>*/}

								<CurrencyInput maxLength={12}
									className={"admin-default-input-text"}
									id="list-price"
									value={product.details.price}
									onValueChange={(value) => {
										if (value == undefined) {
											value = "0.00";
										}
										setProduct({
											...product,
											details: {
												...product.details,
												price: value,
											},
										});
										setValidation({ ...validation, productPrice: value });
									}}
									decimalSeparator="."
									groupSeparator=","
									decimalScale={2}
									prefix="$"
								/>
							</div>
						</div>

						{/*after client approval*/}
						{/*<div className="admin-section-cell">*/}
						{/*	<label className="admin-default-label">Contract Discount(%) </label>*/}
						{/*	<CurrencyInput maxLength={10}*/}
						{/*		className={"admin-default-input-text"}*/}
						{/*		id="contract-discount"*/}
						{/*		value={product.details.contractDiscount}*/}
						{/*		onValueChange={(value) => {*/}
						{/*			if (value == undefined) {*/}
						{/*				value = "0.00";*/}
						{/*			}*/}
						{/*			setProduct({*/}
						{/*				...product,*/}
						{/*				details: {*/}
						{/*					...product.details,*/}
						{/*					contractDiscount: value,*/}
						{/*				},*/}
						{/*			});*/}
						{/*		}}*/}
						{/*		decimalSeparator="."*/}
						{/*		groupSeparator=","*/}
						{/*		decimalScale={2}*/}
						{/*	/>*/}
						{/*</div>*/}

						{/*<div className="admin-section-cell">*/}
						{/*	<label className="admin-default-label">Net Price </label>*/}
						{/*	<input className="admin-default-input-text"*/}
						{/*		type="text"*/}
						{/*		id="net-price"*/}
						{/*		placeholder="0"*/}
						{/*		disabled={true}*/}
						{/*		value={product.details.netPrice ?? ''}*/}
						{/*	/>*/}
							
						{/*</div>*/}

					</div>
					<div className="admin-section-row">
						<div
							className={
								'admin-section-cell extra-wide' +
								(validation.shortDescription == false
									? ' input-validation-error'
									: '')
							}
						>
							<label className="admin-default-label" htmlFor="short-desc">
								Short Description
							</label>
							<CKEditor
								editor={CustomerEditor}
								config={ckEditorConfig}
								data={product.details.shortDescription || ''}
								onChange={(event, editor) => {
									const data = editor.getData();
									if (!isInitialLoad) {
										setProduct({
											...product,
											details: {
												...product.details,
												shortDescription: data,
											},
										});
									}
									setIsInitialLoad(false);
								}}
							/>
						</div>
					</div>
					<div className="admin-section-row">
						<div className="admin-section-cell full">
							<p className="admin-section-small-headline">Images / Videos</p>
							<div className="admin-media-wrap product-media-model">
								<MediaUploader
									imageOnly={false}
									videoOnly={false}
									forceCrop={false}
									allowGallery={true}
									mediaItems={mediaItems}
									saveMediaItems={handleMediaUpdates}
									requireAltText={false}
								/>
							</div>
						</div>
					</div>
					<div className="admin-section-row">
						<div
							className={
								'admin-section-cell extra-wide' +
								(validation.description == false
									? ' input-validation-error'
									: '')
							}
						>
							<label className="admin-default-label" htmlFor="long-desc">
								Long Description
							</label>
							<CKEditor
								editor={CustomerEditor}
								config={ckEditorConfig}
								data={product.details.description || ''}
								onChange={(event, editor) => {
									const data = editor.getData();
									if (!isInitialLoad) {
										setProduct({
											...product,
											details: {
												...product.details,
												description: data,
											},
										});
									}
									setIsInitialLoad(false);
								}}
							/>
						</div>
					</div>
					<div className="admin-section-row">
						<div className="admin-section-cell extra-wide">
							<label className="admin-default-label" htmlFor="size-spec">
								Size &amp; Specifications
							</label>
							<CKEditor
								editor={CustomerEditor}
								config={ckEditorConfig}
								data={product.details.specifications || ''}
								onChange={(event, editor) => {
									const data = editor.getData();
									if (!isInitialLoad) {
										setProduct({
											...product,
											details: {
												...product.details,
												specifications: data,
											},
										});
									}
									setIsInitialLoad(false);
								}}
							/>
						</div>
					</div>
					<div className="admin-section-row">
						<div className="admin-section-cell full">
							<p className="admin-section-small-headline">
								Manufacturer Guides
							</p>
							<ManufacturerGuides
								product={product}
								saveDocument={handleDocumentUpdates}
								addDocument={formData}
							/>
						</div>
					</div>
					<div className="admin-section-separator">&nbsp;</div>
					<div className="admin-section-row">
						<div className="admin-section-cell full">
							<p className="admin-section-headline">Select Categories</p>
							<div className="admin-column-wrap">
								<CategoriesResults
									product={product}
									productItems={product.categories}
									setProductItems={product.categories}
									saveCategoryItems={handleCategoryUpdates}
								/>
							</div>
						</div>
					</div>
					<div className="admin-section-separator">&nbsp;</div>
					<div className="admin-section-row">
						<div className="admin-section-cell full">
							<p className="admin-section-headline">Options</p>
							<ProductOptions
								product={product}
								onSave={handleProductOptionsUpdates}
							/>
						</div>
					</div>
				</div>
				<div className="admin-floating-button-wrap">
					<button
						className="admin-floating-button"
						onClick={() =>
							navigate('/admin/manufacturers-products/edit/' + manufacturerId)
						}
					>
						Cancel
					</button>
					<button
						className="admin-floating-button power"
						onClick={handleProductSave}
					>
						Save Changes
					</button>
				</div>
			</div>
			{/*< />*/}
		</div>
	);
}
