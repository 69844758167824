// Layout
import { DefaultLayout } from '../layouts';
import EndUserLayout from '../layouts/EndUserLayout';

// EUI Pages
import Products from '../pages/products/productindex.js';
import ProductDetails from '../pages/products/productdetails.js';
import Cart from '../pages/cart';
import Quote from '../pages/quote';
import Contact from '../pages/contact/contact.js';
import MyAccount from '../pages/my-account/myaccountindex.js';
import { ProjectConfirmation } from '../pages/cart/ProjectConfirmation';
import AllQuotes from '../pages/all-quotes/allquotesindex.js';
import Typical from '../pages/typical';
import TypicalLayouts from '../pages/typical-layouts/typicalindex.js';
import SurveyDetail from '../pages/survey/surveyindex';
import PrivacyPolicy from '../pages/legal/privacy-policy';
import Terms from '../pages/legal/terms';
import Home from '../pages/home';
import Main from '../pages/main/main';

export const appRoutes = [
	{
		path: '/',
		element: Main,
		secure:false
	},
	{
		path: '/home',
		element: Home,
		layout: DefaultLayout,
		secure: true
	},
	{
		path: '/products',
		element: Products,
		layout: DefaultLayout,
		secure: true
	},
	{
		path: '/products/category/:slugs/:sort?',
		element: Products,
		layout: DefaultLayout,
		secure: true
	},
	{
		path: '/products/sort/:sort',
		element: Products,
		layout: DefaultLayout,
		secure: true
	},
	{
		path: '/products/:slug',
		element: ProductDetails,
		layout: DefaultLayout,
		secure: true
	},
	{
		path: '/products/:slug/preview',
		element: ProductDetails,
		layout: DefaultLayout,
		secure: true
	},
	{
		path: '/my-project',
		element: Cart,
		layout: DefaultLayout,
		secure: true
	},
	{
		path: '/my-quote/:quoteId?',
		element: Quote,
		layout: DefaultLayout,
		secure: true
	},
	{
		path: '/contact',
		element: Contact,
		layout: DefaultLayout,
		secure: false
	},
	{
		path: '/my-account',
		element: MyAccount,
		layout: DefaultLayout,
		secure: true
	},
	{
		path: '/all-quotes',
		element: AllQuotes,
		layout: DefaultLayout,
		secure: true
	},
	{
		path: '/confirmation',
		element: ProjectConfirmation,
		layout: DefaultLayout,
		secure: true
	},
	{
		path: '/typical-layout/:slug',
		element: Typical,
		layout: DefaultLayout,
		secure: true
	},
	{
		path: '/typical-layout/:category/:slug',
		element: Typical,
		layout: DefaultLayout,
		secure: true
	},
	{
		path: '/typical-layouts',
		element: TypicalLayouts,
		layout: DefaultLayout,
		secure: true
	},
	{
		path: '/typical-layouts/category/:slug',
		element: TypicalLayouts,
		layout: DefaultLayout,
		secure: true
	},
	{
		path: '/surveys',
		element: SurveyDetail,
		layout: DefaultLayout,
		secure: true
	},
	{
		path: '/privacy-policy',
		element: PrivacyPolicy,
		layout: EndUserLayout,
		secure: false
	},
	{
		path: '/terms-of-service',
		element: Terms,
		layout: EndUserLayout,
		secure: false
	},
];
