import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './Cart.css';
//components
import MyProduct from '../../components/common/MyProduct';
import MyWishlist from '../../components/common/MyWishlist';
import Banner from '../../components/common/ShowBannerDetails';
import ToolTip from '../../components/common/ToolTip';
import RoundedInput from '../../components/common/Rounded-Input';
import { AccordionItem } from '../../components/common/CartAccordion';
import PageLoadingSpinner from '../../components/FullPageLoader';
//images
import CloseBtn from '../../assets/icons/close.svg'
//apis
import {
    getImpersonationCheck, updateQuote,
    GetUserCartLabel, updateUserCartLabel,
    GetUserCartNotes, updateUserCartNotes
} from '../../api/cartapi';
import { getDistrictQuote, updateStatus } from '../../api/districtquoteapi.js';
import { registerUser } from '../../api/demodistrictapi.js';
//reducers
import {
    loadAllItems, completeCart, updateMyTypicalLayoutLabel,
    updateMyTypicalLayoutQuantity, removeMyTypicalLayout
} from '../../store/myproject-reducer';
import { hideBanner } from '../../store/layout-banner-reducer';
//helpers
import { checkForDistrictId, isDemoDistrict } from '../../helpers/checkForDistrictId';
import { calculateFinalUnitPrice, formatUSD } from '../../helpers/formatterHelper';
import { updateTitle } from '../../helpers/titleHelper';
import { formatPhoneNumber } from '../../helpers/formatterHelper';
import { ValidateEmail, validatePhone } from '../../helpers/validationHelper';


const Cart = () => {
    updateTitle('My Project');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const quoteId = localStorage.getItem('editQuoteId');
    const [quote, setQuote] = useState(null);
    const myProjectItems = useSelector((state) => state.myProject.data.items);
    const myTypicalLayouts = useSelector((state) => state.myProject.data.typicalLayouts);
    const wishListItems = useSelector((state) => state.myWishlist.data.items);
    const totalMyProjectQuantity = useSelector((state) => state.myProject.data.totalQuantity);
    const totalWishListQuantity = useSelector((state) => state.myWishlist.data.totalQuantity);
    const isImpersonation = useSelector((state) => state.districtSiteUsers.isImpersonation)
    const [miscSubTotals, setMiscSubTotals] = useState(0);
    const [typicalLayoutSubtotals, setTypicalSubtotals] = useState([]);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [bannerUpdate, setBannerUpdate] = useState(0);
    const [estimatedCost, setEstimatedCost] = useState(0);
    let typicalProductDetails = JSON.parse(localStorage.getItem('editTypicalLayout')) || [];
    const [editLabel, setEditLabel] = useState(false);
    const [label, setLabel] = useState('My Project');
    const [notes, setNotes] = useState({ comments: '', deliveryDate: null, fundingSource: null, otherFunding: null });
    const [hideFundingOption, setFundingOption] = useState(false);
    const [submitError, setSubmitError] = useState('');
    const [showRegistrationFrom, setRegistrationForm] = useState(false);
    const [customerInfo, setCustomerInfo] = useState({ name: '', email: '', phone: '', company: '', zipcode: '' });
    const [validationRegForm, setValidationRegForm] = useState({ name: true, email: true, phone: true, company: true, zipcode: true });
    const [submittingRegForm, setSubmittingRegForm] = useState(false);
    const [regError, setRegError] = useState(null);
    const [loading, setLoading] = useState(true);
    const today = (new Date());
    var tomorrow = today;
    tomorrow.setDate(today.getDate() + 1);

    //get cart label & cart notes
    useEffect(() => {
        //redirect to admin/districts if user is not logged into district
        checkForDistrictId(navigate);

        GetUserCartLabel().then(response => {
            if (response.data.success == true && response.data.data) {
                setLabel(response.data.data);
            }
        });
        GetUserCartNotes().then(response => {
            if (response.data.success) {
                var data = response.data.data;
                setNotes(data);
            }
        });
    }, [myProjectItems]);

    //get subtotals for all typical layouts
    useEffect(() => {
        if (myTypicalLayouts != null) {
            var subtotals = [];
            myTypicalLayouts.typicalLayouts.map((group) => {
                const typicalProducts = myTypicalLayouts.products.filter(item => item.typicalLayoutItemId == group.id);
                const products = myProjectItems.filter(a => typicalProducts.filter(b => a.cartId == b.cartItemId).length > 0);
                const subtotal = products.reduce((total, product) => total + (calculateFinalUnitPrice(product) * product.quantity), 0);
                const productsQuantity = products.reduce((total, product) => total + product.quantity, 0);
                subtotals.push({ id: group.id, subtotal: subtotal * group.quantity, quantity: group.quantity, productsQuantity: productsQuantity });
            });
            setTypicalSubtotals(subtotals);
        } else {
            setTypicalSubtotals([]);
        }

    }, [myTypicalLayouts]);

    //calculate total estimate
    useEffect(() => {
        if (myProjectItems) {
            //filter out any products that belong to a typical layout
            const items = myTypicalLayouts ? myProjectItems.filter(a => myTypicalLayouts.products.filter(b => b.cartItemId == a.cartId).length == 0) : myProjectItems;

            //get subtotal of all typical layouts
            const typicalSubtotals = typicalLayoutSubtotals && typicalLayoutSubtotals.length > 0 ?
                typicalLayoutSubtotals.reduce((sum, item) => sum + item.subtotal, 0) : 0;

            //get subtotal of miscellaneous items
            const subtotal = items.reduce((sum, productItem) =>
                sum + calculateFinalUnitPrice(productItem) * productItem.quantity, 0);

            //get quantity of typical layout products
            var typicalQuantities = 0;
            if (myTypicalLayouts != null) {
                myTypicalLayouts.typicalLayouts.map((group) => {
                    const tItems = myTypicalLayouts ? myProjectItems.filter(a => myTypicalLayouts.products.filter(b => b.typicalLayoutItemId == group.id && b.cartItemId == a.cartId).length > 0) : [];
                    typicalQuantities += (group.quantity * tItems.reduce((sum, item) => sum + item.quantity, 0));
                });
            }

            //get quantity of miscellaneous products
            const quantity = items.reduce((sum, item) => sum + item.quantity, 0);

            setMiscSubTotals(subtotal);
            setEstimatedCost(subtotal + typicalSubtotals);
            setTotalQuantity(typicalQuantities + quantity);
            setLoading(false);
        }

    }, [myProjectItems, typicalLayoutSubtotals]);

    //get quote if quoteId exists
    useEffect(() => {
        if (quoteId != null && quoteId > 0) {
            getDistrictQuote(quoteId).then(response => {
                setQuote(response.data.data.quote);
                setLoading(false);
            });
        }

        if (window.location.hash.indexOf('wishlist') >= 0) {
            document.getElementById('wishlist').scrollIntoView({ behavoir: 'smooth' });
        }
    }, [quoteId]);

    const goBackOrToProducts = () => {
        navigate('/products');
    };

    const handleSubmitQuote = () => {
        setLoading(true);
        if (localStorage.typicallayout === 'true') {
            dispatch(completeCart(navigate));
            return;
        }
        if (notes.fundingSource == null || notes.fundingSource == '') {
            setSubmitError('Please select a funding source to continue');
            setLoading(false);
            return;
        }
        if (notes.fundingSource == 3 && (notes.otherFunding == null || notes.otherFunding == '')) {
            setSubmitError('Please describe your other funding source to continue');
            setLoading(false);
            return;
        }

        if (isDemoDistrict()) {
            //show registration form if user is in a demo district
            setRegistrationForm(true);
            setLoading(false);
            return;
        }
        dispatch(completeCart(navigate));
    };

    const handleSaveQuote = () => {
        updateQuote().then((response) => {
            if (response.data.success == true) {
                var id = quoteId;
                localStorage.setItem('editQuoteId', null);
                localStorage.removeItem('editQuoteId');
                dispatch(hideBanner());
                dispatch(loadAllItems());
                navigate('/my-quote/' + id);
            }
        });
    }

    const handleCancelEditing = () => {
        if (typicalProductDetails.products != undefined && typicalProductDetails?.products[0]?.id > 0) {
            let districtId = typicalProductDetails.districtId;
            let typicalLayoutId = typicalProductDetails?.id;
            dispatch(hideBanner());
            navigate(`/admin/districts/edit/${districtId}/typical-layout/${typicalLayoutId}`);
            localStorage.removeItem("typicallayout");
        } else {
            updateStatus(quoteId, 0).then(() => {
                var id = quoteId;
                localStorage.setItem('editQuoteId', null);
                localStorage.removeItem('editQuoteId');
                dispatch(loadAllItems());
                dispatch(hideBanner());
                setBannerUpdate(bannerUpdate + 1);
                navigate('/my-quote/' + id);
            });
        }
    }

    const handleTypicalLayoutLabelUpdate = (id, label) => {
        if (id != null && label != '') {
            //update typical layout label in redux
            dispatch(updateMyTypicalLayoutLabel(id, label));
        }
    }

    const handleTypicalLayoutQuantityUpdate = (id, quantity) => {
        if (id != null && quantity != null && quantity > 0) {
            //update typical layout quantity in redux
            dispatch(updateMyTypicalLayoutQuantity(id, quantity));
        }
    }

    const handleDeleteTypicalLayout = (id) => {
        if (id != null) {
            //remove typical layout in redux
            dispatch(removeMyTypicalLayout(id, 'Cart'));
        }
    }

    const handleEdit = (e) => {
        if (e.key !== "Enter") {
            e.stopPropagation();
        }
        setEditLabel(true);
        if (editLabel == true) {
            updateUserCartLabel(label);
        }
        setEditLabel(!editLabel);
    }

    const TagInfo = () => {
        return <ToolTip
            title="Product Tag"
            tooltip={true}
            label="You can assign a custom tag to each product. This can be used to tag rooms, areas, or categories."
        ></ToolTip>
    }

    const handleCommentsChange = (e) => {
        var target = e.target;
        if (window.notesTimer != null) {
            clearInterval(window.notesTimer);
        }
        var data = { ...notes, comments: target.value };
        setNotes(data);
        window.notesTimer = setTimeout(() => {
            updateUserCartNotes(data)
        }, 2000);
    }

    const handleDeliveryDateChange = (e) => {
        var target = e.target;
        if (window.notesTimer != null) {
            clearInterval(window.notesTimer);
        }
        var data = { ...notes, deliveryDate: target.value == '' ? null : target.value };
        setNotes(data);
        window.notesTimer = setTimeout(() => {
            updateUserCartNotes(data)
        }, 2000);
    }

    const handleFundingSourceChange = (e) => {
        var target = e.target;
        if (window.notesTimer != null) {
            clearInterval(window.notesTimer);
        }
        var data = { ...notes, fundingSource: target.value != '' ? parseInt(target.value) : null };
        setNotes(data);
        window.notesTimer = setTimeout(() => {
            updateUserCartNotes(data)
        }, 2000);
    }

    const handleOtherFundingChange = (e) => {
        var target = e.target;
        if (window.notesTimer != null) {
            clearInterval(window.notesTimer);
        }
        var data = { ...notes, otherFunding: target.value };
        setNotes(data);
        window.notesTimer = setTimeout(() => {
            updateUserCartNotes(data)
        }, 2000);
    }

    const onCloseRegForm = () => {
        setRegistrationForm(false);
    }

    const handleSaveRegForm = (e) => {
        e.preventDefault();
        setSubmittingRegForm(true);
        var validated = true;
        var valid = validationRegForm;
        if (customerInfo.name == '') {
            valid = { ...valid, name: false };
            validated = false;
        } else {
            valid = { ...valid, name: true };
        }
        if (customerInfo.company == '') {
            valid = { ...valid, company: false };
            validated = false;
        } else {
            valid = { ...valid, company: true };
        }
        if (!ValidateEmail(customerInfo.email)) {
            valid = { ...valid, email: false };
            validated = false;
        } else {
            valid = { ...valid, email: true };
        }
        var phone = formatPhoneNumber(customerInfo.phone).replace('+', '');
        if (!validatePhone(phone)) {
            valid = { ...valid, phone: false };
            validated = false;
        } else {
            valid = { ...valid, phone: true };
        }
        //if (customerInfo.zipcode == '') {
        //    valid = { ...valid, zipcode: false };
        //    validated = false;
        //} else {
        //    valid = { ...valid, zipcode: true };
        //}
        setValidationRegForm(valid);
        if (validated == false) {
            setSubmittingRegForm(false);
            return;
        }
        var info = {
            Id: localStorage.getItem('demoUserId'),
            FullName: customerInfo.name,
            Organization: customerInfo.company,
            Email: customerInfo.email,
            Phone: phone,
            //Zipcode: customerInfo.zipcode
        };

        registerUser(info).then(response => {
            if (response.data.success == false) {
                setRegError(response.data.data);
                setSubmittingRegForm(false);
            } else {
                setRegistrationForm(false);
                dispatch(completeCart(navigate));
            }
        });
    }

    //exclude all products that belong to a typical layout
    const filteredProductItems = myProjectItems.filter(item => myTypicalLayouts && myTypicalLayouts.products.filter(a => a.cartItemId == item.cartId).length == 0 || !myTypicalLayouts);

    return (
        <div id="content">
            <Banner update={bannerUpdate} />
            {loading == true && <PageLoadingSpinner show={true}></PageLoadingSpinner> }
            <div className="default-outer-wrapper">
                <div className="default-outer-wrapper-inner">
                    <div className="cart-default-center default-center">
                        <div className="cart-default-wrapper">
                            <div className="default-outer-wrapper-inner">
                                <div className="default-center">
                                    <div className="page-header-wrap">
                                        <div className="product-breadcrumbs-wrap">
                                            <p>
                                                <span
                                                    onClick={goBackOrToProducts}
                                                    className="breadcrumb shopping normal-metaPro"
                                                >
                                                    {localStorage.typicallayout === 'true' ? 'Back' : 'Continue Shopping'}
                                                </span>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="project-wrap">
                                        <div className="project-item">
                                            <div className="project-item-inner">
                                                <p className="project-item-title">
                                                    {localStorage.typicallayout === 'true' && (
                                                        <div>
                                                            <p className="project-item-title">
                                                                {typicalProductDetails ? `${typicalProductDetails.title} (${totalQuantity} Items)` : `(${totalQuantity} Items)`}
                                                            </p>
                                                        </div>
                                                    )}

                                                    {(localStorage.typicallayout !== 'true' || localStorage.typicallayout == undefined || localStorage.typicallayout == null) && (
                                                        <div className="flex-between">
                                                            <div >
                                                                {!editLabel ? quote != null ? (
                                                                    `${label} #${quote.districtId}-${("000" + quote.id).slice(-4)}`
                                                                ) : (
                                                                    `${label}`
                                                                ) :
                                                                    <>
                                                                        <input type="text" className="admin-default-input-text-wrap project-title-input" maxLength="64" value={label}
                                                                            onChange={(e) => setLabel(e.target.value)}
                                                                            onClick={(e) => { e.stopPropagation(); }}
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === "Enter") {
                                                                                    handleEdit(e);
                                                                                }
                                                                            }}
                                                                        >
                                                                        </input>

                                                                        {quote != null ? (
                                                                            ` #${quote.districtId}-${("000" + quote.id).slice(-4)}`
                                                                        ) : (
                                                                            ` `
                                                                        )}

                                                                    </>
                                                                }
                                                                <button className="admin-table-action" data-id="7" title="edit" onClick={handleEdit}>
                                                                    {editLabel == false ? <span className="material-icons">edit</span> : <span className="material-symbols-rounded check-mark-icon">check</span>}
                                                                </button>

                                                            </div>
                                                            <div className="total-quantity-box">
                                                                {quote != null ? (
                                                                    ` (${totalQuantity} items)`
                                                                ) : (
                                                                    ` (${totalQuantity} items)`
                                                                )}
                                                            </div>

                                                        </div>
                                                    )}

                                                </p>
                                                {totalMyProjectQuantity !== 0 ? (
                                                    <div>
                                                        {myTypicalLayouts && myTypicalLayouts.typicalLayouts.map((group) => {
                                                            const typicalProducts = myTypicalLayouts.products.filter(item => item.typicalLayoutItemId == group.id);
                                                            const products = myProjectItems.filter(a => typicalProducts.filter(b => a.cartId == b.cartItemId).length > 0);
                                                            const subtotal = typicalLayoutSubtotals && typicalLayoutSubtotals.length > 0 && typicalLayoutSubtotals.filter(a => a.id == group.id).length > 0
                                                                ? typicalLayoutSubtotals.filter(a => a.id == group.id)[0].subtotal : 0;
                                                            return (
                                                                <AccordionItem
                                                                    typicalLayout={group}
                                                                    title={group.label}
                                                                    quantity={group.quantity}
                                                                    subtotal={subtotal}
                                                                    opened={true}
                                                                    key={'typical_layout ' + group.id}
                                                                    typicalLayoutItemId={group.id}
                                                                    updateLabel={handleTypicalLayoutLabelUpdate}
                                                                    updateQuantity={handleTypicalLayoutQuantityUpdate}
                                                                    deleteTypicalLayout={handleDeleteTypicalLayout}
                                                                >
                                                                    <div className="my-product-title-table">
                                                                        <div className="my-product-title-row normal-metaPro">
                                                                            <div className="my-product-title-coll">
                                                                                &nbsp;
                                                                            </div>
                                                                            <div className="my-product-title-coll product-details-title">
                                                                                Product Details
                                                                            </div>
                                                                            {localStorage.typicallayout != 'true' &&
                                                                                <div className="my-product-title-coll">
                                                                                    Tag
                                                                                    <TagInfo></TagInfo>
                                                                                </div>
                                                                            }
                                                                            <div className="my-product-title-coll product-quantity">
                                                                                Quantity
                                                                            </div>
                                                                            <div className="my-product-title-coll unit-price">
                                                                                <span className="table-cellprice">
                                                                                    Unit Price
                                                                                </span>
                                                                                <span className="table-cell-totalammount">
                                                                                    Total
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        {products.map(item => {
                                                                            return (<MyProduct key={item.cartId} product={item} buttons={{ delete: true, wishlist: quote == null }} canViewProductDetail={true} isTypicalLayout={true} />);
                                                                        })}
                                                                    </div>
                                                                </AccordionItem>
                                                            );
                                                        })}
                                                        {filteredProductItems && filteredProductItems.length > 0 &&
                                                            <>
                                                                {myTypicalLayouts && myTypicalLayouts.products.length > 0 ?
                                                                    <AccordionItem
                                                                        title={"Miscellaneous"}
                                                                        opened={true}
                                                                        key={'miscellaneous'}
                                                                        isMisc={true}
                                                                        subtotal={miscSubTotals}
                                                                    >
                                                                        <div className="my-product-title-table">
                                                                            <div className="my-product-title-row normal-metaPro">
                                                                                <div className="my-product-title-coll">
                                                                                    &nbsp;
                                                                                </div>
                                                                                <div className="my-product-title-coll product-details-title">
                                                                                    Product Details
                                                                                </div>

                                                                                {localStorage.typicallayout != 'true' &&
                                                                                    <div className="my-product-title-coll">
                                                                                        Tag
                                                                                        <TagInfo></TagInfo>
                                                                                    </div>
                                                                                }
                                                                                <div className="my-product-title-coll product-quantity">
                                                                                    Quantity
                                                                                </div>
                                                                                <div className="my-product-title-coll unit-price">
                                                                                    <span className="table-cellprice">
                                                                                        Unit Price
                                                                                    </span>
                                                                                    <span className="table-cell-totalammount">
                                                                                        Total
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            {filteredProductItems.map((item) => (
                                                                                <MyProduct key={item.cartId} product={item} buttons={{ delete: true, wishlist: quote == null }} canViewProductDetail={true} isTypicalLayout={false} />
                                                                            ))}
                                                                        </div>
                                                                    </AccordionItem>
                                                                    :
                                                                    <div className="my-product-title-table">
                                                                        <div className="my-product-title-row normal-metaPro">
                                                                            <div className="my-product-title-coll">
                                                                                &nbsp;
                                                                            </div>
                                                                            <div className="my-product-title-coll product-details-title">
                                                                                Product Details
                                                                            </div>
                                                                            {localStorage.typicallayout != 'true' &&
                                                                                <div className="my-product-title-coll">
                                                                                    Tag
                                                                                    <TagInfo></TagInfo>
                                                                                </div>
                                                                            }
                                                                            <div className="my-product-title-coll product-quantity">
                                                                                Quantity
                                                                            </div>
                                                                            <div className="my-product-title-coll unit-price">
                                                                                <span className="table-cellprice">
                                                                                    Unit Price
                                                                                </span>
                                                                                <span className="table-cell-totalammount">
                                                                                    Total
                                                                                </span>
                                                                            </div>
                                                                        </div>

                                                                        {filteredProductItems.map((item) => (
                                                                            <MyProduct key={item.cartId} product={item} buttons={{ delete: true, wishlist: quote == null }} canViewProductDetail={true} isTypicalLayout={false} />
                                                                        ))}
                                                                    </div>
                                                                }
                                                                <div className="cart-layout-wrap">
                                                                    <div className="cart-grid-row estimate normal-metaPro">
                                                                        <div className="cart-estimate-cell">
                                                                            <p className="cart-estimate-content">
                                                                                ({totalQuantity}{' '}
                                                                                items) Subtotal<b>:</b> {' '}
                                                                                <b className="cart-estimate-cost">{formatUSD(estimatedCost)}</b>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            {!getImpersonationCheck() && localStorage.typicallayout != 'true' ? <div className="project-item-inner">
                                                <div id="wishlist" style={{'position':'relative', 'top':'-5rem', 'height':0} }></div>
                                                <p className="project-item-title flex-between">
                                                    My Wishlist <span className="total-quantity-box">({totalWishListQuantity} Items)</span>
                                                </p>
                                                {totalWishListQuantity !== 0 && !isImpersonation ? (
                                                    <div className="my-product-title-table wishlist-table">
                                                        <div className="my-product-title-row normal-metaPro ">
                                                            <div className="my-product-title-coll">
                                                                &nbsp;
                                                            </div>
                                                            <div className="my-product-title-coll product-details-title">
                                                                Product Details
                                                            </div>
                                                            <div className="my-product-title-coll product-quantity">
                                                                Quantity
                                                            </div>
                                                            <div className="my-product-title-coll unit-price">
                                                                <span className="table-cellprice">
                                                                    Unit Price
                                                                </span>
                                                                <span className="table-cell-totalammount">
                                                                    Total
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {wishListItems.map((item) => (
                                                            <MyWishlist key={item.cartId} product={item} />
                                                        ))}
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div> : ''}

                                        </div>

                                        <div className="project-item summary-wrap normal-metaPro ">
                                            <div className="project-item-inner">
                                                {quote == null ?
                                                    <>

                                                        <h1 className="project-item-title">
                                                            {localStorage.typicallayout != 'true' ? 'Summary' : 'Typical Layout'}
                                                        </h1>
                                                        {totalMyProjectQuantity !== 0 ? <div className="summary-est-wrap">
                                                            <p className="summary-est-subhead">Estimate:</p>
                                                            <p className="summary-est-subhead">{formatUSD(estimatedCost)}</p>
                                                        </div> : ''}
                                                        {localStorage?.typicallayout != 'true' ?
                                                            <div>
                                                                <p className="summary-est-desc">
                                                                    Shipping (usually 10%) and install (usually 8-10%)
                                                                    will be added to the final quote
                                                                </p>
                                                                <p className="summary-est-desc">
                                                                    Pricing is an estimate and is within 10% of shown
                                                                    total.
                                                                </p>
                                                                <hr />
                                                                <div className="cart-side-notes">
                                                                    <label className="cart-side-label">Notes/Comments</label>
                                                                    <div className="cart-side-input">
                                                                        <textarea rows="2" aria-label="Notes/Comments" onInput={handleCommentsChange} value={notes.comments ?? ''}></textarea>
                                                                    </div>
                                                                    <label className="cart-side-label">Requested Delivery Date</label>
                                                                    <div className="cart-side-input">
                                                                        <input type="text" aria-label="Requested Delivery Date" onInput={handleDeliveryDateChange} value={notes.deliveryDate}></input>
                                                                    </div>
                                                                    <label className="cart-side-label">Funding Source*</label>
                                                                    <div className="cart-side-input cart-select-wrapper arrow-button">
                                                                        <select onMouseDown={() => { setFundingOption(true); }} onInput={handleFundingSourceChange} onClick={handleFundingSourceChange} value={notes.fundingSource} className="cart-grid-select cart-select-arrow">
                                                                            {!hideFundingOption && <option value="">Select Funding Source</option>}
                                                                            <option value="1">Request district funds</option>
                                                                            <option value="2">Have my own funds</option>
                                                                            <option value="3">Other</option>
                                                                        </select>
                                                                    </div>
                                                                    {notes.fundingSource == 3 &&
                                                                        <>
                                                                            <div className="cart-side-label">Other Funding Source*</div>
                                                                            <div className="cart-side-input">
                                                                            <input type="text" aria-label="Other Funding Source" onInput={handleOtherFundingChange} value={notes.otherFunding != null ? notes.otherFunding : ''}></input>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                    {submitError != '' &&
                                                                        <span className="validation-error-message">{submitError}</span>
                                                                    }
                                                                </div>

                                                            </div>
                                                            : ''}
                                                        {totalMyProjectQuantity !== 0 ? (
                                                            <div>
                                                                {typicalProductDetails?.id > 0 &&
                                                                    <p className="project-summary-link">
                                                                        <a onClick={handleCancelEditing}>Cancel Editing</a>
                                                                    </p>
                                                                }
                                                                <button
                                                                    className="add-cart-cta"
                                                                    onClick={handleSubmitQuote}
                                                                >
                                                                    {localStorage.typicallayout === 'true' ?
                                                                        (typicalProductDetails?.id > 0 ? 'Update Products for Typical Layout' : 'Save Products')
                                                                        : 'Submit for Final Quote'
                                                                    }
                                                                </button>
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </>
                                                    :
                                                    <>
                                                        <p className="project-item-title">Status</p>
                                                        <p className={"project-status status-color-" + quote.status}>{quote.statusText}</p>

                                                        <hr />
                                                        <div className="cart-side-notes">
                                                            <div className="cart-side-label">Notes/Comments</div>
                                                            <div className="cart-side-input">
                                                                <textarea rows="2" onInput={handleCommentsChange} value={notes.comments ?? ''}></textarea>
                                                            </div>
                                                            <div className="cart-side-label">Requested Delivery Date</div>
                                                            <div className="cart-side-input">
                                                                <input type="text" onInput={handleDeliveryDateChange} value={notes.deliveryDate}></input>
                                                            </div>
                                                            <div className="cart-side-label">Funding Source*</div>
                                                            <div className="cart-side-input cart-select-wrapper arrow-button">
                                                                <select onMouseDown={() => { setFundingOption(true); }} onInput={handleFundingSourceChange} value={notes.fundingSource} className="cart-grid-select cart-select-arrow">
                                                                    {!hideFundingOption && <option value="" disabled>Select Funding Source</option>}
                                                                    <option value="1">Request District fund</option>
                                                                    <option value="2">Have my own fund</option>
                                                                    <option value="3">Other</option>
                                                                </select>
                                                            </div>
                                                            {notes.fundingSource == 3 &&
                                                                <>
                                                                    <div className="cart-side-label">Other Funding Source*</div>
                                                                    <div className="cart-side-input">
                                                                        <input type="text" onInput={handleOtherFundingChange} value={notes.otherFunding != null ? notes.otherFunding : ''}></input>
                                                                    </div>
                                                                </>
                                                            }
                                                            {submitError != '' &&
                                                                <span className="validation-error-message">{submitError}</span>
                                                            }
                                                        </div>

                                                        <p className="project-summary-link">
                                                            <a onClick={handleSaveQuote}>Save Quote</a>
                                                        </p>
                                                        {quote.status < 4 ?
                                                            <p className="project-summary-link">
                                                                <a onClick={handleCancelEditing}>Cancel Editing</a>
                                                            </p>
                                                            : <></>}
                                                    </>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showRegistrationFrom &&
                <section className="main-page-wrapper request-demo demo-cart-popup">
                    <div className="modal-wrapper">
                        <div onClick={onCloseRegForm} className="sales-popup-close"></div>
                        <div className="modal-body">
                            <div className="close-btn flex-center" onClick={onCloseRegForm}>
                                <img src={CloseBtn} alt="close" />
                            </div>
                            <div className="modal-content">
                                <p className="modal-title">Register Account</p>
                                <p className="modal-discription">
                                    Please fill out the information below to receive detailed quote you requested.
                                    If you need immediate assistance with budgeting for a project please contact us directly at &nbsp;
                                    <a href="tel:816-768-6179" className="support-number">816-768-6179</a> or email&nbsp;
                                    <a href="mailto:garik@facilitystandard.com">garik@facilitystandard.com</a>.
                                </p>

                                {regError != null &&
                                    <div className="internal-error">
                                        {regError}
                                        <span className="close-button" onClick={() => { setRegError(null); } }>
                                            <span className="material-symbols-rounded">close</span>
                                        </span>
                                    </div>
                                }
                                <form onSubmit={handleSaveRegForm} className="modal-form" id="demoform">
                                    <RoundedInput
                                        label="Name"
                                        placeholder=""
                                        isRequired={true}
                                        value={customerInfo.name}
                                        validated={validationRegForm.name}
                                        onChange={(value) => {
                                            setCustomerInfo({ ...customerInfo, name: value });
                                            setValidationRegForm({ ...validationRegForm, name: value != '' });
                                        }}
                                        requiredText="Your name is required"
                                    />
                                    <RoundedInput
                                        id="customer-company"
                                        label="Organization"
                                        placeholder=""
                                        isRequired={true}
                                        value={customerInfo.company}
                                        validated={validationRegForm.company}
                                        onChange={(value) => {
                                            setCustomerInfo({ ...customerInfo, company: value });
                                            setValidationRegForm({ ...validationRegForm, company: value != '' });
                                        }}
                                        requiredText="Your organization is required"
                                    />
                                    <RoundedInput
                                        id="customer-email"
                                        label="Email Address"
                                        placeholder=""
                                        isRequired={true}
                                        value={customerInfo.email}
                                        validated={validationRegForm.email}
                                        onChange={(value) => {
                                            setCustomerInfo({ ...customerInfo, email: value });
                                        }}
                                        requiredText="A valid email address is required"
                                    />
                                    <RoundedInput
                                        id="customer-phone"
                                        label="Phone"
                                        placeholder=""
                                        isRequired={true}
                                        value={customerInfo.phone}
                                        validated={validationRegForm.phone}
                                        onChange={(value) => {
                                            setCustomerInfo({ ...customerInfo, phone: value });
                                        }}
                                        requiredText="A valid phone number is required"
                                    />
                                    <RoundedInput
                                        id="customer-zip"
                                        label="Zipcode"
                                        placeholder=""
                                        isRequired={true}
                                        value={customerInfo.zipcode}
                                        validated={validationRegForm.zipcode}
                                        onChange={(value) => {
                                            setCustomerInfo({ ...customerInfo, zipcode: value });
                                        }}
                                        requiredText="A valid zipcode is required"
                                    />

                                    <div className="form-btn">
                                        <button type="submit"
                                            className={`solid-primary-btn ${submittingRegForm ? 'submitReqBtn' : " "} `}>
                                            {submittingRegForm ? "Submitting registration..." : "Submit"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </div>
    );
};

export default Cart;
