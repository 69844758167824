import React, { useEffect, useState } from 'react';
import { updateTitle } from '../../helpers/titleHelper';
import { checkLogin } from '../../api/checkuserapi';
import HomeLayout from '../../layouts/HomeLayout';
import DefaultLayout from '../../layouts/DefaultLayout';
export default function PageNotFound() {
    updateTitle('Page Not Found', '404');
    const [loginUser, setLoginUser] = useState({ id: -1 });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (loading == true) {
            setLoading(false);
            checkLogin().then((response) => {
                if (response.data.success == true) {
                    setLoginUser(response.data.data);
                } else {
                    setLoginUser({ id: 0 });
                }
            });
        }
        
    }, [loginUser]);

    const Error404 = () => {
        return (
            <div className="pages-not-found">
                <div className="pages-not-found-wrap">
                    <div className="pages-not-found-center">
                        <div className="pages-not-found-outer">
                            <h1> 404</h1>
                        </div>
                        <p className="pages-not-found-title">OOPS! PAGE NOT FOUND</p>
                        <p className="pages-not-found-content">The page you are looking for may have moved</p>
                    </div>
                </div>
            </div>
        );
    }
    if (loginUser.id == 0) {
        return (<HomeLayout><Error404 /></HomeLayout>);
    } else if (typeof loginUser.id == 'string') {
        return (<DefaultLayout><Error404 /></DefaultLayout>);
    }
}
