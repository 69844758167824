import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
	addColorGroups,
	editColorGroups,
	deleteColorGroups,
} from '../../../store/colors-reducer';
import { AdminModal } from '../../../components/admin/admin-modal';
import PropTypes from 'prop-types';
import CurrencyInput from 'react-currency-input-field';

export const ColorGroupModal = ({
	onClose,
	manufacturerId,
	isEditMode = false,
	existingGroup = null,
	isDeleteMode = false,
	saveErrorMessage,
	saveStatus,
}) => {
	const dispatch = useDispatch();
	const [newColorGroup, setNewColorGroup] = useState(
		isEditMode || isDeleteMode ? existingGroup.name : ''
	);
	const [upcharge, setupcharge] = useState(
		isEditMode || isDeleteMode ? existingGroup.upcharge : null
	);
	const [errors, setErrors] = useState('');
	const inputRef = useRef(null);

	const handleColorGroupName = (event) => {
		setNewColorGroup(event.target.value);
	};

	const handleDelete = () => {
		dispatch(deleteColorGroups(existingGroup.id));
	};

	const handleSave = () => {
		if (!newColorGroup) {
			setErrors('Color Group Name must be valid.');
			return;
		}	
		const colorGroupData = {
			ManufacturerId: manufacturerId,
			Name: newColorGroup,
			Upcharge: upcharge
		};

		if (isEditMode) {
			colorGroupData.Id = existingGroup.id;
			dispatch(editColorGroups(colorGroupData));
		} else {
			dispatch(addColorGroups(colorGroupData));
		}
	};

	useEffect(() => {
		if (saveStatus === 'succeeded') {
			onClose();
		}
	}, [saveStatus, onClose]);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, []);

	if (isDeleteMode) {
		return (
			<div className="admin-modal-overlay">
				<div className="admin-modal">
					<button className="admin-modal-close" onClick={onClose}>
						<span className="material-symbols-rounded">close</span>
					</button>
					<h2 className="admin-modal-heading">Delete Color Group</h2>
					<div className="admin-form-cell">
						<p
							className="admin-default-input-text-wrap"
							htmlFor="add-color-group"
						>
							Are you sure you wish to delete the Color Group
						</p>
						<p>{newColorGroup}</p>
					</div>
					{saveErrorMessage && (
						<p className="input-validation-error validation-error-message">{saveErrorMessage}</p>
					)}
					<div className="admin-form-cell">
						<button className="admin-modal-button red" onClick={handleDelete}>
							Delete
						</button>
						<button className="admin-form-button power" onClick={onClose}>
							Cancel
						</button>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<AdminModal
				show={true}
				onClose={onClose}
				onSave={handleSave}
				modalTitle={isEditMode ? 'Edit Color Group' : 'Add / Edit Color Group'}
			>
				<div className="admin-modal-row full">
					<div className="admin-modal-cell">
						<div
							className={
								'admin-default-input-text-wrap admin-default-popup-style' +
								(errors ? ' input-validation-error' : '')
							}
						>
						<div>
							<label className="admin-default-label">Color Group</label>
							<input
								className="admin-default-input-text"
								type="text"
								value={newColorGroup}
								onChange={handleColorGroupName}
								ref={inputRef}
								/>

								{errors && <p className="validation-error-message">{errors}</p>}
							</div>
							<span>
								{saveErrorMessage && (
									<p className="validation-error-message">{saveErrorMessage}</p>
								)}
							</span>							
						</div>
						
						<label className="admin-default-label">Up Charge</label>						
							<CurrencyInput
								className={"admin-default-input-text"}
								value={upcharge}
								onValueChange={(value) => {
									setupcharge(value);
								}}
								decimalSeparator="."
								groupSeparator=","
								prefix="$"
							/>
						
					</div>
				</div>
			</AdminModal>
		);
	}
};
ColorGroupModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	manufacturerId: PropTypes.string.isRequired,
	isEditMode: PropTypes.bool,
	existingGroup: PropTypes.shape({
		id: PropTypes.number.isRequired,
		name: PropTypes.string.isRequired,
		upcharge: PropTypes.number.isRequired
	}),
	isDeleteMode: PropTypes.bool,
	saveErrorMessage: PropTypes.string,
	saveStatus: PropTypes.string,
};
