import { createSlice } from '@reduxjs/toolkit';
import * as actions from './actions';

const initialState = {
    isSurveyLoaded: false,
    surveyList: [],
    isSaving: false
}

const surveySlice = createSlice({
    name: 'surveys',
    initialState,
    extraReducers: (builder) => {
        // Get Survey
        builder.addCase(actions.getSurveyList.pending, (state) => {
            state.isSurveyLoaded = false;
        })
        builder.addCase(actions.getSurveyList.fulfilled, (state, action) => {
            if (action.payload.success) {
                state.surveyList = action.payload.data;
            } else {
                state.surveyList = [];
            }
            state.isSurveyLoaded = true;
        })
        builder.addCase(actions.getSurveyList.rejected, (state) => {
            state.isSurveyLoaded = false;
        });

        // Save survey
        builder.addCase(actions.saveSurvey.pending, (state) => {
            state.isSaving = true;
        })
        builder.addCase(actions.saveSurvey.fulfilled, (state, action) => {
            if (action.payload.success) {
                const returnObj = action.payload.data;
                state.surveyList.unshift(returnObj);
            }
            state.isSaving = false;
        })

        builder.addCase(actions.saveSurvey.rejected, (state) => {
            state.isSaving = false;
        });

        // Edit Survey
        builder.addCase(actions.editSurvey.pending, (state) => {
            state.isSaving = true;
        })
        builder.addCase(actions.editSurvey.fulfilled, (state, action) => {
            if (action.payload.success) {
                const returnObj = action.payload.data;
                const surveyObj = state.surveyList.find(x => x.id === returnObj.id);
                if (surveyObj !== undefined && surveyObj !== null) {
                    surveyObj.title = returnObj.title;
                    surveyObj.links = returnObj.links;
                    surveyObj.description = returnObj.description;
                    surveyObj.isActive = returnObj.isActive;
                }
            }
            state.isSaving = false;
        })

        builder.addCase(actions.editSurvey.rejected, (state) => {
            state.isSaving = false;
        });

        // Delete survey
        builder.addCase(actions.deleteSurvey.pending, (state) => {
            state.saving = true;
        })
        builder.addCase(actions.deleteSurvey.fulfilled, (state, action) => {
            if (action.payload.success) {
                const id = action.payload.data;
                const enityIndex = state.surveyList.findIndex(x => x.id === id);
                if (enityIndex > -1) {
                    state.surveyList.splice(enityIndex, 1);
                }
            }
            state.isSaving = false;
        })
        builder.addCase(actions.deleteSurvey.rejected, (state) => {
            state.isSaving = false;
        });
    }
});

export default surveySlice.reducer;
