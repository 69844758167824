import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Quantity } from './Quantity';
import { formatUSD } from '../../helpers/formatterHelper';

export function AccordionItem({
    title, quantity, subtotal, children, opened, typicalLayoutItemId,
    updateLabel, updateQuantity, deleteTypicalLayout, isQuote, isMisc
}) {
    const [isOpen, setIsOpen] = useState(opened === true);
    const [editLabel, setEditLabel] = useState(false);
    const [label, setLabel] = useState(title);
    const [quantityValue, setQuantity] = useState(quantity);
    const handleEdit = (e) => {
        if (e.key !== "Enter") {
            e.stopPropagation(); 
        }             
        setEditLabel(true);
        if (editLabel == true) {
            updateLabel(typicalLayoutItemId, label);
        }
        setEditLabel(!editLabel);
    }

    const handleQuantity = (e) => {
        e.stopPropagation();
        const q = parseInt(e.target.value);
        updateQuantity(typicalLayoutItemId, q);
        setQuantity(q);
    }

    const handleDelete = (e) => {
        e.stopPropagation();
        deleteTypicalLayout(typicalLayoutItemId);
    }
    return (
        <div className={`prod-detail-content cart-accordion ${isOpen ? 'expanded' : 'collapsed'}`}>
            <div
                className="prod-detail-content-trigger cart-accordion-bar"
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className={"accordion-title-input" + (isQuote || isMisc ? " label-only" : "")}>
                    {!isQuote && !isMisc ?
                        <>
                            <span className="accordion-title">
                                {!editLabel ? title :
                                    <input type="text" className="admin-default-input-text" maxLength="32" value={label}
                                        onChange={(e) => setLabel(e.target.value)}
                                        onClick={(e) => { e.stopPropagation(); }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleEdit(e);
                                            }
                                        }}
                                    >
                                    </input>
                                }
                            </span>
                            <button className="admin-table-action" data-id="7" title="edit" onClick={handleEdit}>
                                {editLabel == false ? <span className="material-icons">edit</span> : <span className="material-symbols-rounded check-mark-icon">check</span>}
                            </button>
                        </>
                        :
                        <span className="accordion-title">
                            {title}
                        </span>
                    }
                    
                </div>
            </div>
            <div className="prod-detail-content-collapse-wrap">
                <div className="prod-detail-content-collapse">
                    <div className="prod-detail-content para">
                        {children}
                    </div>
                </div>
            </div>

             <div  className="prod-detail-content-trigger cart-accordion-bar cart-accordion-footer">
               
                {!isQuote && !isMisc &&
                    <>
                        <div className="accordion-delete">
                            <div className="icon-cta delete-cell wishlist-cell" onClick={handleDelete}><span className="cta-cell-wrap"><span className="cell-text">Delete</span></span></div>
                        </div>
                    </>
                }

                {!isMisc &&
                    <span className={"accordion-quantity" + (isQuote === true ? " label-only" : "")}>
                        <span>Grouped Quantity</span>
                        {isQuote === true ? quantityValue :
                            <Quantity
                                currentQuantity={quantityValue}
                                setQuantity={handleQuantity}
                                maxQuantity={100}
                            >
                            </Quantity>
                        }
                    </span>
                }
                {isMisc && <><span></span><span></span>
                </>}
                <span className="accordion-subtotal">
                    <span className="subtotal">Subtotal:</span>
                    <span className="grand-total">{formatUSD(subtotal)}</span>
                </span>
            </div>
        </div>
    );
}

AccordionItem.propTypes = {
    title: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    subtotal: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
    opened: PropTypes.bool,
    typicalLayoutItemId: PropTypes.number.isRequired,
    updateLabel: PropTypes.func.isRequired,
    updateQuantity: PropTypes.func.isRequired,
    deleteTypicalLayout: PropTypes.func.isRequired,
    isQuote: PropTypes.bool,
    isMisc: PropTypes.bool,
};
