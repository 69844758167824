import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loader from '../../../components/Loader.js';
import {
    getProductLineWithProducts, updateProductLine, copyProductLine,
    deleteProductLine, copyProduct, deleteProduct, updateProductOrder
} from '../../../api/productapi.js';
import { AddEditProudctLineModal } from "./productLineModal"
function ProductTable({ productLines, onChange }) {

    const [models, setModels] = useState({
        copyModel: false, deleteModel: false, productLine: null,
        copyProductModel: false, deleteProductModel: false, product: null
    });

    return (
        <>
            <table className="admin-table product-table">
                <thead>
                    <tr>
                        <th>Product Line</th>
                        <th className="admin-table-name-cell">Product Name</th>
                        <th className="admin-table-action-cell">&nbsp;</th>
                        <th className="admin-table-action-cell">&nbsp;</th>
                        <th className="admin-table-action-cell">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {productLines.length > 0 &&
                        productLines
                            .sort((a, b) => a.order - b.order)
                            .map((productLine, idx) =>
                                <ProductLineItem
                                    key={'product_line' + idx}
                                    productLineObj={productLine}
                                    onCopy={() => {
                                        setModels({ ...models, copyModel: true, productLine: productLine });
                                    }}
                                    onDelete={() => {
                                        setModels({ ...models, deleteModel: true, productLine: productLine });
                                    }}
                                    onCopyProduct={(...args) => {
                                        setModels({ ...models, copyProductModel: true, product: args[0] });
                                    }}
                                    onDeleteProduct={(...args) => {
                                        setModels({ ...models, deleteProductModel: true, product: args[0] });
                                    }}
                                />
                            )
                    }
                </tbody>
            </table>
            {models.copyModel == true ?
                <ProductLineCopyModel id={models.productLine.id} name={models.productLine.name} onClose={() => {
                    setModels({ ...models, copyModel: false });
                }} onUpdate={onChange}></ProductLineCopyModel> : <></>
            }
            {models.deleteModel == true ?
                <ProductLineDeleteModel id={models.productLine.id} name={models.productLine.name} onClose={() => {
                    setModels({ ...models, deleteModel: false });
                }} onUpdate={onChange}></ProductLineDeleteModel> : <></>
            }
            {models.copyProductModel == true ?
                <ProductCopyModel id={models.product.id} name={models.product.details?.name} onClose={() => {
                    setModels({ ...models, copyProductModel: false });
                }} onUpdate={onChange}></ProductCopyModel> : <></>
            }
            {models.deleteProductModel == true ?
                <ProductDeleteModel id={models.product.id} name={models.product.details?.name} onClose={() => {
                    setModels({ ...models, deleteProductModel: false });
                }} onUpdate={onChange}></ProductDeleteModel> : <></>
            }
        </>

    )
}
ProductTable.propTypes = {
    productLines: PropTypes.array,
    onChange: PropTypes.func.isRequired
}

function ProductLineItem({ productLineObj, onCopy, onDelete, onCopyProduct, onDeleteProduct }) {
    const [lineObj, setLineObj] = useState(productLineObj);
    const [showEditProductLineModal, setShowAddModal] = useState(false);
    const [timer, setTimer] = useState(null);
    const [existingProductLine, setEditingProductLine] = useState(null);
    
    const handleAddLine = (manufacturerData, onSuccess, onError) => {
        console.log("onError", onError);

        if (timer) {
            clearTimeout(timer);
        }

        const newTimer = setTimeout(() => {
            updateProductLine(manufacturerData)
                .then(() => {
                    setShowAddModal(false);
                    onSuccess();
                    setLineObj(manufacturerData);
                    //setEditingProductLine(manufacturerData);

                    getProductLineWithProducts(productLineObj.manufacturerId).then((response) => {
                        const allProductLines = response.data.data;
                        const productLine= allProductLines.find(x => x.id === manufacturerData.id && x.manufacturerId === productLineObj.manufacturerId);
                        setLineObj(productLine);
                    });
                })
        }, 500);

        setTimer(newTimer);
    }

    const openEditProductLineModal = (productLineObj) => {
        setEditingProductLine(productLineObj);
        setShowAddModal(true);
    };

    const onMoveProduct = (id, dir) => {
        updateProductOrder(id, dir).then(response => {
            if (response.data.success == true) {
                getProductLineWithProducts(productLineObj.manufacturerId).then((response) => {
                    const allProductLines = response.data.data;
                    const productLine = allProductLines.find(x => x.id == productLineObj.id && x.manufacturerId == productLineObj.manufacturerId);
                    setLineObj(productLine);
                });
            }
        });
    };

    return (
        <>
            <AddEditProudctLineModal
                show={showEditProductLineModal}
                onClose={() => setShowAddModal(false)}
                handleAddProductLine={handleAddLine}
                manufacturerId={parseInt(productLineObj.manufacturerId)}
                isEdit={true}
                existingProductLine={existingProductLine}
            />

            <tr data-element="productLineItem">
                <td>
                    <label>{lineObj.name}</label>
                </td>
                <td className="admin-table-name-cell">&nbsp;</td>
                <td className="admin-table-action-cell">
                    <button className="admin-table-action" onClick={() => { onCopy(lineObj) }}>
                        <span className="material-symbols-rounded">content_copy</span>
                    </button>
                </td>
                <td className="admin-table-action-cell">
                    <button className="admin-table-action" onClick={() => openEditProductLineModal(lineObj)} >
                        <span className="material-symbols-rounded">edit</span>
                    </button>

                </td>
                <td className="admin-table-action-cell">{
                    lineObj.products == null || lineObj.products.length == 0 || lineObj.products[0] == null ?
                        <button className="admin-table-action" title="Delete Product Line" onClick={onDelete}>
                            <span className="material-symbols-rounded">delete</span>
                        </button>
                        : <></>
                }</td>
            </tr>
            {lineObj.products != null && lineObj.products.length > 0 ?
                lineObj.products
                    .sort((a, b) => a.order - b.order)
                    .map((product, idx) =>
                        <ProductItem
                            key={'product_' + idx}
                            productObj={product}
                            manufacturerId={lineObj.manufacturerId}
                            onCopy={() => { onCopyProduct(product); }}
                            onDelete={() => { onDeleteProduct(product); }}
                            moveProduct={onMoveProduct }
                        />
                    ) : <></>}
        </>
    );
}
ProductLineItem.propTypes = {
    productLineObj: PropTypes.object,
    onCopy: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onCopyProduct: PropTypes.func.isRequired,
    onDeleteProduct: PropTypes.func.isRequired
}


function ProductItem({ productObj, manufacturerId, onCopy, onDelete, moveProduct }) {
    const navigate = useNavigate();
    if (productObj == null) return null;
    return (
        <tr>
            <td className="no-border" style={{textAlign:"right"}}>
                <span className="admin-round-swatch-sort-arrow-wrap" style={{display:"inline-flex"} }>
                    <button className="admin-round-swatch-sort-arrow"
                        onClick={() => moveProduct(productObj.id, 'up')}
                        style={{display:'inline-block'}}>
                        <span className="material-symbols-rounded">arrow_upward</span>
                    </button>
                    <button className="admin-round-swatch-sort-arrow"
                        onClick={() => moveProduct(productObj.id, 'down')}
                        style={{display:'inline-block'}}>
                        <span className="material-symbols-rounded"
                        >arrow_downward</span>
                    </button>
                </span>
            </td>
            <td className="admin-table-name-cell">
                <p className="admin-table-text">{(productObj.details != null ? productObj.details.freedomCode : '')} {(productObj.details != null ? productObj.details.name : '')}</p>
            </td>
            <td className="admin-table-action-cell">
                <button className="admin-table-action" onClick={onCopy}>
                    <span className="material-symbols-rounded">content_copy</span>
                </button>
            </td>
            <td className="admin-table-action-cell">
                <button className="admin-table-action" onClick={() => navigate('/admin/products/' + manufacturerId + '/edit/' + productObj.id)} >
                    <span className="material-symbols-rounded">edit</span>
                </button>
            </td>
            <td className="admin-table-action-cell">
                <button className="admin-table-action" onClick={onDelete}>
                    <span className="material-symbols-rounded">delete</span>
                </button>
            </td>
        </tr>
    )
}
ProductItem.propTypes = {
    productObj: PropTypes.object,
    manufacturerId: PropTypes.number,
    onCopy: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    moveProduct: PropTypes.func.isRequired,
}

function ProductLineCopyModel({ id, name, onClose, onUpdate }) {
    return (
        <>
            <div className="admin-modal-overlay">
                <div className="admin-modal">
                    <button className="admin-modal-close" onClick={onClose}>
                        <span className="material-symbols-rounded">close</span>
                    </button>
                    <h2 className="admin-modal-heading">Copy Product Line</h2>
                    <div className="admin-form-cell">
                        <p className="admin-default-input-text-wrap">
                            Are you sure you make a copy of the product line &quot;{name}&quot;?
                        </p>
                    </div>
                    <div className="admin-form-cell">
                        <button className="admin-modal-button red" onClick={() => { copyProductLine(id).then(() => { onUpdate(); onClose(); }); }}>
                            Copy Product Line
                        </button>
                        <button className="admin-form-button power" onClick={onClose}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
ProductLineCopyModel.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired
}

function ProductLineDeleteModel({ id, name, onClose, onUpdate }) {
    return (
        <>
            <div className="admin-modal-overlay">
                <div className="admin-modal">
                    <button className="admin-modal-close" onClick={onClose}>
                        <span className="material-symbols-rounded">close</span>
                    </button>
                    <h2 className="admin-modal-heading">Delete Product Line</h2>
                    <div className="admin-form-cell">
                        <p className="admin-default-input-text-wrap">
                            Are you sure you delete product line &quot;{name}&quot;? This cannot be undone!
                        </p>
                    </div>
                    <div className="admin-form-cell">
                        <button className="admin-modal-button red" onClick={() => { deleteProductLine(id).then(() => { onUpdate(); onClose(); }); }}>
                            Delete Product Line
                        </button>
                        <button className="admin-form-button power" onClick={onClose}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
ProductLineDeleteModel.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired
}

function ProductCopyModel({ id, name, onClose, onUpdate }) {
    return (
        <>
            <div className="admin-modal-overlay">
                <div className="admin-modal">
                    <button className="admin-modal-close" onClick={onClose}>
                        <span className="material-symbols-rounded">close</span>
                    </button>
                    <h2 className="admin-modal-heading">Copy Product</h2>
                    <div className="admin-form-cell">
                        <p className="admin-default-input-text-wrap">
                            Are you sure you make a copy of the product &quot;{name}&quot;?
                        </p>
                    </div>
                    <div className="admin-form-cell">
                        <button className="admin-modal-button red" onClick={() => { copyProduct(id).then(() => { onUpdate(); onClose(); }); }}>
                            Copy Product
                        </button>
                        <button className="admin-form-button power" onClick={onClose}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
ProductCopyModel.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired
}

function ProductDeleteModel({ id, name, onClose, onUpdate }) {
    return (
        <>
            <div className="admin-modal-overlay delete-product-modal">
                <div className="admin-modal edit-delete-product">
                    <button className="admin-modal-close" onClick={onClose}>
                        <span className="material-symbols-rounded">close</span>
                    </button>
                    <h2 className="admin-modal-heading">Delete Product</h2>
                    <div className="admin-form-cell">
                        <p className="admin-default-input-text-wrap">
                            Are you sure you delete product &quot;{name}&quot;? This cannot be undone!
                        </p>
                    </div>
                    <div className="admin-form-cell delete-product-modal">
                        <button className="admin-form-button power" onClick={onClose}>
                            Cancel
                        </button>
                        <button className="admin-modal-button red" onClick={() => { deleteProduct(id).then(() => { onUpdate(); onClose(); }); }}>
                            Delete Product
                        </button>
                        
                    </div>
                </div>
            </div>
        </>
    );
}
ProductDeleteModel.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired
}

export default function EditProducts() {
    const productChange = useRef();
    const [showAddModal, setShowAddModal] = useState(false);
    const navigate = useNavigate();
    const { manufacturerId } = useParams();
    const [timer, setTimer] = useState(null);

    const [loadingData, setLoadingData] = useState(true);
    const [productLines, setProductLines] = useState([]);

    useEffect(() => {
        getProductLineWithProducts(manufacturerId)
            .then((response) => {
                setProductLines(response.data.data);
                setLoadingData(false);
            });
    }, []);

    useEffect(() => {
        if (productChange.current) {
            let nodes = document.querySelectorAll('[data-element="productLineItem"]');
            let target = nodes[nodes.length - 1];
            target.scrollIntoView({ behavoir: 'smooth' });
            target.querySelector('label').focus();
        }

    }, [productLines])

    const handleAddLine = (manufacturerData, onSuccess, onError) => {
        console.log("onError", onError);
        if (timer) {
            clearTimeout(timer);
        }

        const newTimer = setTimeout(() => {
            updateProductLine(manufacturerData)
                .then(() => {
                    productChange.current = manufacturerData;
                    setShowAddModal(false);
                    onSuccess();

                    setLoadingData(true);
                    getProductLineWithProducts(manufacturerId).then((response) => {
                        setProductLines(response.data.data);
                        setLoadingData(false);
                    });
                })
        }, 500);

        setTimer(newTimer);
    }

    const updateList = () => {
        setLoadingData(true);
        getProductLineWithProducts(manufacturerId).then((response) => {
            setProductLines(response.data.data);
            setLoadingData(false);
        });
    }

    return (
        <div className="admin-tab-content">
            <div className="admin-button-wrap">
                <button className="admin-button"
                    onClick={() => setShowAddModal(true)}>
                    <span className="admin-button-text">Add Product Line</span>
                    <span className="admin-button-icon material-symbols-rounded" aria-hidden="true">add</span>
                </button>

                <AddEditProudctLineModal
                    show={showAddModal}
                    onClose={() => setShowAddModal(false)}
                    handleAddProductLine={handleAddLine}
                    manufacturerId={parseInt(manufacturerId)}
                    isEdit={false}
                    existingProductLine={null}
                />

                {productLines == null || productLines.length < 1 ? <></> :
                    <button className="admin-button" onClick={() => navigate('/admin/products/' + manufacturerId + '/create')}>
                        <span className="admin-button-text">Add Product</span>
                        <span className="admin-button-icon material-symbols-rounded" aria-hidden="true">add</span>
                    </button>
                }
            </div>
            <div className="admin-table-wrap">
                {loadingData ? <Loader /> :
                    <ProductTable
                        productLines={productLines}
                        onChange={updateList}
                    />
                }
            </div></div>

    )
}
