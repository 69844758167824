
import AccountLayout from '../layouts/AccountLayout.jsx';
import Login from '../pages/account/login.js';
import Logout from '../pages/account/logout.js';
import ForgotPassword from '../pages/account/forgotpassword.js';
import CreateAccount from '../pages/account/createaccount.js';
import TestToken from '../pages/account/testtoken.js';
import ResetPassword from '../pages/account/resetpassword.js';
import OneTimeLogin from '../pages/account/onetimelogin.js';
import EmailAuth  from '../pages/account/emailAuth.js';

export const accountRoutes = [
  {
    path: '/account/login',
    element: Login,
    layout: AccountLayout,
    secure: false
  },
  {
    path: '/account/logout',
    element: Logout,
    layout: AccountLayout,
    secure: false
  },
  {
    path: '/account/forgot-password',
    element: ForgotPassword,
    layout: AccountLayout,
    secure: false
  },
  {
    path: '/account/create',
    element: CreateAccount,
    layout: AccountLayout,
    secure: false
  },
  {
    path: '/account/test',
    element: TestToken,
    layout: AccountLayout,
    secure: false
    },
    {
        path: '/account/reset-password/:hash',
        element: ResetPassword,
        layout: AccountLayout,
        secure: false
    },
    {
        path: '/account/accept-invite/:hash',
        element: ResetPassword,
        layout: AccountLayout,
        secure: false
    },
    {
        path: '/account/forgot-one-time-login',
        element: OneTimeLogin,
        layout: AccountLayout,
        secure: false
    },
    {
        path: '/account/email-auth/:hash',
        element: EmailAuth,
        layout: AccountLayout,
        secure: false
    }
];
