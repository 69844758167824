import React, { useState, useEffect } from 'react';
import './Admin.css';
import AdminLinkTile from '../../components/admin/admin-link-tile';
import { getTotalQuotes } from '../../api/districtquoteapi.js';
import { getLoginUserDetails } from '../../api/adminuserapi';

import { useDispatch } from "react-redux";
import { showLoading, hideLoading, delayedHideLoading } from '../../store/loading-reducer';
import { updateTitle } from '../../helpers/titleHelper';

function Admin() {
    updateTitle('Admin');
    const dispatch = useDispatch();
    const [quotes, setQuotes] = useState(0);
    const [loginUser, setLoginUser] = useState(null);

    useEffect(() => {
        dispatch(showLoading());
        getTotalQuotes(0, 0) //districtId:0 = all districts, status:0 = open quotes
            .then((response) => {
                setQuotes(response.data.data);
                dispatch(delayedHideLoading());
            })
            .catch(() => {
                dispatch(hideLoading());
            });
    }, [dispatch]);

    useEffect(() => {
        dispatch(showLoading());
        getLoginUserDetails()
            .then((response) => {
                setLoginUser(response?.data);
                dispatch(delayedHideLoading());
            }).catch(() => {
                dispatch(hideLoading());
            });
    }, []);


    const adminRole = loginUser?.data?.userRoles?.filter(x => x.siteRole.name === 'admin');
    const isAdmin = adminRole?.length > 0;
    const districtManager = loginUser?.data?.isDistrictManager ?? false;

    const adminClaim = loginUser?.data?.userClaims;
    var isDistrictAccess = adminClaim?.filter(x => x.key === 'districts')?.length > 0;
    var isCategories = adminClaim?.filter(x => x.key === 'categories')?.length > 0;
    var isManufactures = adminClaim?.filter(x => x.key === 'manufacturers')?.length > 0;
    var isQuoteAccess = adminClaim?.filter(x => x.key === 'quotes')?.length > 0;
    var isAdminUsersAccess = adminClaim?.filter(x => x.key === 'adminusers')?.length > 0;
    var isAdminReportsAccess = adminClaim?.filter(x => x.key === 'reports')?.length > 0;



    return (
        <div className="admin-page-wrap">
            <div className="admin-top-bar-wrap">
                &nbsp;
            </div>
            <div className="admin-page-content">
                <div className="admin-link-tile-wrap">
                    {(districtManager || (isAdmin && isDistrictAccess)) &&
                        <AdminLinkTile
                            href="/admin/districts"
                            icon="school"
                            name="Districts"
                        />
                    }
                    {isAdmin && isCategories &&
                        <AdminLinkTile
                            href="/admin/categories"
                            icon="list"
                            name="Categories"
                        />
                    }

                    {isAdmin && isManufactures &&
                        <AdminLinkTile
                            href="/admin/manufacturers"
                            icon="inventory_2"
                            name="Manufacturers & Products"
                        />
                    }

                    {isAdmin && isQuoteAccess &&
                        <AdminLinkTile
                            badge={quotes == undefined ? 0 : quotes}
                            href="/admin/quotes"
                            icon="format_quote"
                            name="Quotes"
                        />
                    }
                    {isAdmin && isAdminUsersAccess &&
                        <AdminLinkTile
                            href="/admin/users"
                            icon="settings"
                            name="Admin Settings"
                        />
                    }
                    {isAdmin && isAdminReportsAccess &&
                        <AdminLinkTile
                            href="/admin/reports"
                            icon="monitoring"
                            name="Reports"
                        />
                    }
                </div>
            </div>
        </div>
    );
}

export default Admin;