import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { resetSaveStatus, loadGroups } from '../../../store/categories-reducer';
import { showLoading, hideLoading } from '../../../store/loading-reducer';
export const SavingMessage = () => {
    const saveStatus = useSelector((state) => state.categories.saveStatus);
    const saveError = useSelector((state) => state.categories.saveError);
    const dispatch = useDispatch();

    useEffect(() => {
        if(saveStatus === 'saving'){
            dispatch(showLoading());
        }
        if (saveStatus === 'succeeded') {
            const timer = setTimeout(() => {
                dispatch(resetSaveStatus());
                dispatch(loadGroups());
                dispatch(hideLoading());
            }, 1000);

            return () => clearTimeout(timer);
        } else if (saveStatus === 'failed') {
            dispatch(resetSaveStatus());
            dispatch(hideLoading());
        }
    }, [saveStatus, dispatch]);

    return (
        <div>
            {saveStatus === 'saving' && <p>Saving...</p>}
            {saveStatus === 'succeeded' && <p>Save successful!</p>}
            {saveStatus === 'failed' && <p>Error saving: {saveError}</p>}
        </div>
    );
};
