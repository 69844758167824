import api from '../utils/api';

const getDistrictId = () => {
    return localStorage.getItem('districtId');
};


export const listGroups = (config = {}) => {
    const { options } = config;
    return api({
        url: '/api/categorygroups/get-all-with-categories',
        method: 'GET',
        ...options,
    });
};

export const listCategories = (categoryGroupId, config = {}) => {
    const { options } = config;
    return api({
        url: `/api/categories/get-all/${categoryGroupId}`,
        method: 'GET',
        ...options,
    });
};

export const allCategories = (categoryIds,type,config = {}) => {
    const { options } = config;
    return api({
        url: '/api/categories/get-all-groups/' + getDistrictId() + '/' + type + '/' + categoryIds,
        method: 'GET',
        ...options,
    });
};

export const addCategory = (config = {}) => {
    const { options } = config;
    return api({
        url: '/api/categories/add',
        method: 'POST',
        ...options,
    });
};

export const editCategory = (config = {}) => {
    const { options } = config;
    return api({
        url: '/api/categories/edit',
        method: 'POST',
        ...options,
    });
};

export const deleteCategory = (id, config = {}) => {
    const { options } = config;
    return api({
        url: `/api/categories/delete/${id}`,
        method: 'DELETE',
        ...options,
    });
};

export const restoreCategory = (id, config = {}) => {
    const { options } = config;
    return api({
        url: `/api/categories/restore/${id}`,
        method: 'GET',
        ...options,
    });
};

export const reorderCategories = (config = {}) => {
    const { options } = config;
    return api({
        url: '/api/categories/order',
        method: 'POST',
        ...options,
    });
};

export const listCategoriesProductNav = (categoryGroupId, type, config = {}) => {
    const { options } = config;
    return api({
        url: '/api/categories/get-all-by-group/' + categoryGroupId + '/' + getDistrictId() + '/' + type,
        method: 'GET',
        ...options,
    });
};

export const getBySlug = (slug, type, config = {}) => {
    const { options } = config;
    return api({
        url: `/api/categories/get-by-slug/${slug}/${type}`,
        method: 'GET',
        ...options,
    });
};

export const getAllBySlugs = (slugs, type, config = {}) => {
    const { options } = config;
    return api({
        url: '/api/categories/get-all-by-slugs/' + slugs + '/' + type,
        method: 'GET',
        ...options,
    });
};

