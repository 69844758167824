import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../../../components/Loader.js';
import { UserRoleModal } from './addRoleModal';
import { loadSiteUserAccount, getAllSiteUsersList, addNewSiteUsers, editDistrictSiteUserDetail, deleteExistingSiteUser, resetSaveStatus } from '../../../store/district-account-reducer';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export default function DistrictSiteUsers() {
    const dispatch = useDispatch();
    const { districtId } = useParams();
    const [loadingData, setLoadingData] = useState(true);
    const [showAddRoleModal, setShowAddRoleModal] = useState(false);
    const districtSiteUser = useSelector(getAllSiteUsersList);
    const [showEditRoleModal, setEditRoleModal] = useState(false);
    const [modelSiteUser, setmodal] = useState('');
    const [showDelete, setShowDelete] = useState(null);
    const saveStatus = useSelector((state) => state.districtSiteUsers.saveStatus);
    const saveError = useSelector((state) => state.districtSiteUsers.saveError);
    const [error, setError] = useState();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (districtId !== null) {
            dispatch(loadSiteUserAccount(districtId));
            setLoadingData(false);
            setLoaded(true);
        }
    }, [districtId, dispatch]);
    const handleDeleteSiteUserDistrict = (email, userId) => {
        setShowDelete({ email: email, userId: userId });
    };

    const handleConfirmRemoveUser = () => {
        dispatch(deleteExistingSiteUser(districtId, showDelete.userId));
        setShowDelete(null);
    }



    useEffect(() => {
        if (saveStatus === 'failed' && loaded == true) {
            setError(saveError);
        } else if (saveStatus === 'failed' && loaded == false) {
            resetSaveStatus();
        }
    }, [saveStatus, dispatch]);

    //handleEditSiteUser
    const handleEditSiteUser = (districtuser) => {
        setError(null);
        setmodal(districtuser);
        setEditRoleModal(true);
    };
    const handleAddSiteUserManager = (roleData) => {
        dispatch(addNewSiteUsers(roleData));
    };
    const handleEditSiteUserManager = (roleData) => {
        dispatch(editDistrictSiteUserDetail(roleData));
    };

    return (
        <div className="admin-new-section">
            {loadingData ? <Loader /> :
                <>
                    {showAddRoleModal && (
                        <UserRoleModal
                            show={showAddRoleModal}
                            onClose={() => setShowAddRoleModal(false)}
                            districtId={districtId}
                            handleAddSiteUser={handleAddSiteUserManager}
                        />
                    )}

                    {showEditRoleModal && (
                        <UserRoleModal
                            show={showEditRoleModal}
                            onClose={() => setEditRoleModal(false)}
                            districtId={districtId}
                            isEditMode={true}
                            handleAddSiteUser={handleEditSiteUserManager}
                            existingSiteUser={modelSiteUser}
                        />
                    )}

                    {showDelete != null &&
                        <div className="admin-modal-overlay">
                            <div className="admin-modal">
                                <button className="admin-modal-close" onClick={() => { setShowDelete(null); setError(null); }}>
                                    <span className="material-symbols-rounded">close</span>
                                </button>
                                <h2 className="admin-modal-heading">Remove User</h2>
                                <div className="admin-form-cell">
                                    <p className="admin-default-input-text-wrap">
                                        Are you sure you want to remove the user {showDelete.email} from this districts account manager role?
                                    </p>
                                </div>
                                <div className="admin-modal-button-wrap">
                                    <button className="admin-modal-button" onClick={() => { setShowDelete(null); setError(null); }}>
                                        Cancel
                                    </button>
                                    <button className="admin-form-button power red" onClick={handleConfirmRemoveUser}>
                                        Remove User
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="admin-section">
                        <div className="admin-section-title-bar">
                            <p className="admin-section-headline">Supplier Account Managers</p>
                            <div className="admin-section-title-action-wrap">
                                <button className="admin-button" onClick={() => { setShowAddRoleModal(true); setError(null); }}>
                                    <span className="admin-button-text">Add Role</span>
                                    <span className="material-symbols-rounded">add</span>
                                </button>
                            </div>
                        </div>
                        {error &&
                            <div className="validation-error-message" style={{ 'padding': '5px 0' }}>{error}</div>
                        }
                        <div className="admin-table-wrap">
                            <table className="admin-table">
                                <tbody>
                                    <tr>
                                        <th className="admin-table-name-cell">Role</th>

                                        <th className="admin-table-name-cell">Organization</th>

                                        <th className="admin-table-name-cell">Notify</th>
                                        <th className="admin-table-name-cell">Name</th>
                                        <th className="admin-table-name-cell">Email</th>
                                        <th className="admin-table-name-cell">Phone</th>
                                        <th className="admin-table-action-cell">&nbsp;</th>
                                        <th className="admin-table-action-cell">&nbsp;</th>
                                    </tr>
                                    {districtSiteUser && districtSiteUser.length > 0 ? (
                                        districtSiteUser.map(districtuser =>
                                            <tr key={Math.floor(Math.random() * 100000)}>
                                                <td className="admin-table-name-cell">{districtuser.role}</td>
                                                <td className="admin-table-name-cell">{districtuser.companyName}</td>
                                                <td className="admin-table-name-cell">{districtuser.notify === false ? "" : <span className='admin-table-check material-symbols-rounded'>check</span>}</td>
                                                <td className="admin-table-name-cell">{districtuser?.siteUsers?.fullName}</td>
                                                <td className="admin-table-name-cell">{districtuser?.siteUsers?.email}</td>
                                                <td className="admin-table-name-cell">{districtuser?.siteUsers?.phone}</td>
                                                <td className="admin-table-action-cell account-manager">
                                                    <button className="admin-table-action" onClick={() => handleEditSiteUser(districtuser)}>
                                                        <span className="material-symbols-rounded">edit</span>
                                                    </button>
                                                </td>
                                                <td className="admin-table-action-cell account-manager">
                                                    <button className="admin-table-action"
                                                        onClick={() => handleDeleteSiteUserDistrict(districtuser?.siteUsers?.email, districtuser.siteUserId)}>
                                                        <span className="material-symbols-rounded">delete</span>
                                                    </button>
                                                </td>
                                            </tr>
                                        )) : (
                                        <></>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}
