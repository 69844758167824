import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { getTotalQuotes } from '../../api/districtquoteapi.js';
import { getLoginUserDetails } from '../../api/adminuserapi';

import { useDispatch } from 'react-redux';
import {
    showLoading,
    hideLoading,
    delayedHideLoading,
} from '../../store/loading-reducer';
function Drawer() {
    const dispatch = useDispatch();
    const [quotes, setQuotes] = useState(0);
    const [loginUser, setLoginUser] = useState(null);
    const [district, setDistrict] = useState(null);

    const { displayName } = useSelector((state) => state.auth);
    const location = useLocation();
    const match = location.pathname.match(/\/admin\/products\/\d+\/edit\/(\d+)$/);
    const productId = match ? match[1] : null;

    const typicalId = location.pathname.match(/\/admin\/districts\/edit\/\d+\/typical-layout\/(\d+)$/);
    const distictTypicalId = typicalId ? typicalId[1] : null;
    useEffect(() => {
        dispatch(showLoading());
        getTotalQuotes(0, 0)
            .then((response) => {
                setQuotes(response?.data?.data);
                dispatch(delayedHideLoading());
            })
            .catch(() => {
                dispatch(hideLoading());
            });
    }, []);

    useEffect(() => {
        dispatch(showLoading());
        getLoginUserDetails()
            .then((response) => {
                if (response.data.success == true) {
                    setLoginUser(response?.data);
                    setDistrict(response.data.data.district);
                }
                dispatch(delayedHideLoading());
            })
            .catch(() => {
                dispatch(hideLoading());
            });
    }, []);

    const userRoles = loginUser?.data?.userRoles || [];
    const adminRole = loginUser?.data?.userRoles?.filter(
        (x) => x.siteRole.name === 'admin'
    );
    const isAdmin = adminRole?.length > 0;
    const districtManager = loginUser?.data?.isDistrictManager ?? false;
    const canImpersonate = userRoles.some((role) =>
        ['admin', 'manager', 'approver'].includes(role.siteRole.name)
    );
    sessionStorage.setItem('canImpersonate', canImpersonate);
    const adminClaim = loginUser?.data?.userClaims;
    var isDistrictAccess =
        adminClaim?.filter((x) => x.key === 'districts')?.length > 0;
    var isCategories =
        adminClaim?.filter((x) => x.key === 'categories')?.length > 0;
    var isManufactures =
        adminClaim?.filter((x) => x.key === 'manufacturers')?.length > 0;
    var isQuoteAccess = adminClaim?.filter((x) => x.key === 'quotes')?.length > 0;
    var isAdminUsersAccess =
        adminClaim?.filter((x) => x.key === 'adminusers')?.length > 0;
    var isAdminReportsAccess =
        adminClaim?.filter((x) => x.key === 'reports')?.length > 0;

    return (
        <nav className="admin-drawer-wrap">
            {
                (productId || distictTypicalId) && (
                    <Link className="admin-drawer-view-page" to={distictTypicalId != null && distictTypicalId > 0 ? `/typical-layout/${distictTypicalId}` : (productId != null && productId > 0 ? `/products/${productId}/preview` : '')}>
                        View Page
                    </Link>
                )
            }

            <div className="admin-drawer-info-wrap">
                <Link className="admin-drawer-info-title decoration" to="/admin">
                    {district ? district.name : 'Facility Standard'}
                </Link>
                <p className="admin-drawer-info-user">{displayName}</p>
            </div>
            <div className="admin-drawer-link-wrap">
                {districtManager && (
                    <Link className="admin-drawer-link" to="/admin/districts">
                        <span
                            className="admin-drawer-link-icon material-symbols-rounded"
                            aria-hidden="true"
                        >
                            school
                        </span>
                        <span className="admin-drawer-link-text">Districts</span>
                    </Link>
                )}
                {isAdmin && !districtManager && (
                    <>
                        {isDistrictAccess && (
                            <Link className="admin-drawer-link" to="/admin/districts">
                                <span
                                    className="admin-drawer-link-icon material-symbols-rounded"
                                    aria-hidden="true"
                                >
                                    school
                                </span>
                                <span className="admin-drawer-link-text">Districts</span>
                            </Link>
                        )}
                        {isCategories && (
                            <Link className="admin-drawer-link" to="/admin/categories">
                                <span
                                    className="admin-drawer-link-icon material-symbols-rounded"
                                    aria-hidden="true"
                                >
                                    list
                                </span>
                                <span className="admin-drawer-link-text">Categories</span>
                            </Link>
                        )}
                        {isManufactures && (
                            <Link className="admin-drawer-link" to="/admin/manufacturers">
                                <span
                                    className="admin-drawer-link-icon material-symbols-rounded"
                                    aria-hidden="true"
                                >
                                    inventory_2
                                </span>
                                <span className="admin-drawer-link-text">
                                    Manufacturers &amp; Products
                                </span>
                            </Link>
                        )}
                        {isQuoteAccess && (
                            <Link className="admin-drawer-link" to="/admin/quotes">
                                <span
                                    className="admin-drawer-link-icon material-symbols-rounded"
                                    aria-hidden="true"
                                >
                                    format_quote
                                </span>
                                <span className="admin-drawer-link-text">Quotes</span>
                                <span className="admin-link-tile-badge admin-quotes-style">
                                    {quotes == null ? 0 : quotes}
                                </span>
                            </Link>
                        )}

                        {isAdminReportsAccess && (
                            <Link className="admin-drawer-link" to="/admin/reports">
                                <span
                                    className="admin-drawer-link-icon material-symbols-rounded"
                                    aria-hidden="true"
                                >
                                    monitoring
                                </span>
                                <span className="admin-drawer-link-text">Reports</span>
                            </Link>
                        )}
                        {isAdminUsersAccess && (
                            <Link className="admin-drawer-link" to="/admin/users">
                                <span
                                    className="admin-drawer-link-icon material-symbols-rounded"
                                    aria-hidden="true"
                                >
                                    settings
                                </span>
                                <span className="admin-drawer-link-text">Admin Settings</span>
                            </Link>
                        )}
                    </>
                )}

                {/*<Link className="admin-drawer-link" to="/broken">*/}
                {/*  <span className="admin-drawer-link-icon material-symbols-rounded" aria-hidden="true">notifications</span>*/}
                {/*  <span className="admin-drawer-link-text">Notifications</span>*/}
                {/*</Link>*/}
                <Link className="admin-drawer-link" to="/account/logout">
                    <span
                        className="admin-drawer-link-icon material-symbols-rounded"
                        aria-hidden="true"
                    >
                        logout
                    </span>
                    <span className="admin-drawer-link-text">Logout</span>
                </Link>
            </div>
        </nav>
    );
}

export default Drawer;
