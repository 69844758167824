import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { MediaItem } from './MediaItem';
import MediaImageModal from './MediaImageModal';
import PageLoadingSpinner from '../FullPageLoader';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import VideoProcessing from "../../assets/video-processing.jpg";
//function ShowVideoThumbnail({ thumbnailSrc }) {
//    console.log("thumbnailSrc", thumbnailSrc)
//    //if (thumbnailSrc !== undefined && thumbnailSrc !== '') {
//    //  return (<div className="videoThumbWrap">
//    //    <img className="videoThumbPreview" src={thumbnailSrc} />
//    //  </div>);
//    //} else return null;
//}
//ShowVideoThumbnail.propTypes = {
//    thumbnailSrc: PropTypes.string
//}

const getVideoImageFromUrl = async (url) => {
    const youtubeUrlRegex = /^(https?:\/\/)?(www\.)?\s*(youtube\.com\/watch\?v=|youtu\.be\/)\s*([a-zA-Z0-9_-]{11})/i;
    const vimeoUrlRegex = /^(https?:\/\/)?(www\.)?\s*vimeo\.com\/\s*(\d+)/i;

    let videoObj = {
        videoId: "",
        videoThumbnail: "",
        videoType: null,
        isValid: false,
    };

    if (!url) return videoObj;

    url = url.trim();

    const matchYoutube = url.match(youtubeUrlRegex);
    const matchVimeo = url.match(vimeoUrlRegex);

    if (matchYoutube && matchYoutube[4]) {
        videoObj.videoId = matchYoutube[4];
        videoObj.videoThumbnail = `https://img.youtube.com/vi/${videoObj.videoId}/maxresdefault.jpg`;
        videoObj.videoType = "YouTube";
        videoObj.isValid = true;
    } else if (matchVimeo && matchVimeo[3]) {
        videoObj.videoId = matchVimeo[3];
        videoObj.videoType = "Vimeo";
        try {
            const response = await fetch(`https://vimeo.com/api/v2/video/${videoObj.videoId}.json`);
            const data = await response.json();
            videoObj.videoThumbnail = data[0]?.thumbnail_large || data[0]?.thumbnail_medium;
            videoObj.isValid = true;
        } catch (error) {
            console.error("Error fetching Vimeo thumbnail:", error);
            videoObj.isValid = false;
        }
    } else {
        videoObj.isValid = false;
    }

    return videoObj;
};



export default function MediaVideoModal({ requireAltText, showModal, onCloseModal, onSaveModal }) {
    const [videoObj, setVideoObj] = useState(MediaItem);
    const [selectVideoSourceError, setSelectVideoSourceError] = useState('');
    const [videoSource, setVideoSource] = useState(null);
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [validationError, setValidationError] = useState(false);
    const fileInputRef = useRef(null);
    const [showImageModal, setShowImageModal] = useState(false);
    const [ogImageSrc, setOgImageSrc] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [processingMessage, setProcessingMessage] = useState('');
    const [isLargeVideo, setIsLargeVideo] = useState(false);
    const [showSaving, setShowSaving] = useState(false);
    const [validationErrorMsg, setValidationErrorMsg] = useState('');

    const handleSaveModal = async () => {      
        setValidationErrorMsg("");
        if (videoSource === null || videoSource === 'Select Video Source') {
            setValidationError(true);
            setSelectVideoSourceError("Please select a video source.");
            return false;
        }

        if ((uploadProgress > 0 && uploadProgress < 100) || (processingMessage !== '' && uploadProgress >= 100)) {
            setValidationError(true);
            setValidationErrorMsg("Video is in process.");
            return false;
        }

        if (!videoObj?.videoSrc && videoSource === "videoLink") {
            setValidationError(true);
            setValidationErrorMsg("URL is required.");
            return false;
        }
        if (!videoObj?.videoSrc && videoSource === "uploadVideo") {
            setValidationError(true);
            setValidationErrorMsg("Video is required.");
            return false;
        }

        setValidationError(false);
        if (videoObj) {
            let mediaData = videoObj;
            mediaData.type = 'Video';
            mediaData.imageFile = null;
            //save video and return as media object
            onSaveModal(mediaData);
            setVideoObj(MediaItem);
        }
    }

    const handleCloseModal = async () => {
        setVideoObj({});
        setIsPlaying(false);
        onCloseModal();
    }
    const fetchVideoThumbnailFromWeb = async (e) => {
        let videoUrl = e.target.value;
        let newVideo = await getVideoImageFromUrl(videoUrl);
        if (newVideo) {
            setVideoObj({
                ...videoObj,
                videoId: newVideo.videoId,
                videoSrc: document.getElementById('video-url').value,
                videoThumbnailUrl: newVideo.videoThumbnail,
                videoType: newVideo.videoType,
                type: 'Video',
                imageFile: null,
                selectedVideoSrc: videoSource
            })
        }
    }

    const SelectedVideoSrc = async (e) => {
        let VideoSrc = e.target.value;
        if (VideoSrc != '') {
            setSelectVideoSourceError('');
        }
        setVideoSource(VideoSrc);
        setVideoObj({
            ...videoObj,
            videoId: null,
            videoSrc: null,
            videoThumbnailUrl: null,
            videoType: null,
            selectedVideoSrc: VideoSrc
        });

    }
    const handleDeleteMedia = () => {
        setVideoObj(undefined);
    }
    const handleMediaPlay = () => {
            setIsPlaying(!isPlaying);
    };
    const handleThumbnailImageChange = (e) => {
        setShowImageModal(true);
        setOgImageSrc(e.target.files[0]);
    };
    const removeCustomThumbnail = async () => {
        setVideoObj({
            ...videoObj,
            videoCustomThumbnailUrl: undefined,
            isCropped: false,
            photoId: undefined,
            photo: undefined,
            cropPhotoId: undefined,
            cropPhoto: undefined,
            cropValues: undefined
        });
    }
    const uploadCustomThumbnail = async (mediaObj) => {
        console.log(mediaObj);
    }
    const handleVideoUploadChange = (e) => {
        setIsLargeVideo(false);
        //to do
        const maxFileSize = 1 * 1024 * 1024 * 1024; // 1 GB
        //const maxFileSize = 10 * 1024 * 1024; // 10MB in bytes
        const selectedFile = e.target.files[0];

        //todo
        if (selectedFile != undefined && selectedFile != null) {
            if (selectedFile.size > maxFileSize) {
                setIsLargeVideo(true);
                return;
            }
            handleChunkedUpload(selectedFile);
        }
    };
    const handleChunkedUpload = (file) => {
        const chunkSize = 5 * 1024 * 1024; // 5 MB chunk size
        const totalChunks = Math.ceil(file.size / chunkSize);
        let currentChunk = 0;
        let extension = file.name.split('.').pop(); // Get file extension
        const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.'); // Handle multiple periods
        const uuid = uuidv4(); // Generate UUIDv4
        const newFileName = `${fileNameWithoutExtension}_${uuid}.${extension}`;
        const uploadFileName = newFileName;
        let lastProgress = 0;
        const uploadNextChunk = async () => {
            const start = currentChunk * chunkSize;
            const end = Math.min(start + chunkSize, file.size);
            const chunk = file.slice(start, end);

            const formData = new FormData();
            formData.append('videoChunk', chunk);
            formData.append('chunkNumber', currentChunk);
            formData.append('fileName', file.name);
            formData.append('uploadFileName', uploadFileName);
            try {
                await axios.post('/api/media/uploadChunk', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    //onUploadProgress: (progressEvent) => {
                    //    // Calculate overall progress
                    //    const chunkProgress = (progressEvent.loaded / progressEvent.total) * (1 / totalChunks);
                    //    const overallProgress = ((currentChunk + chunkProgress) / totalChunks) * 100;
                    //    setUploadProgress(overallProgress);
                    //}
                    onUploadProgress: (progressEvent) => {
                        // Calculate overall progress
                        const chunkProgress = (progressEvent.loaded / progressEvent.total) * (1 / totalChunks);
                        const overallProgress = Math.floor(((currentChunk + chunkProgress) / totalChunks) * 100);

                        // Only update state if the new progress is greater than the last progress
                        if (overallProgress > lastProgress) {
                            const updateProgress = (start, end) => {
                                if (start <= end) {
                                    setUploadProgress(start);
                                    requestAnimationFrame(() => updateProgress(start + 1, end));
                                }
                            };
                            updateProgress(lastProgress + 1, overallProgress);
                            lastProgress = overallProgress;
                        }
                    }
                });

                currentChunk++;

                if (currentChunk < totalChunks) {
                    uploadNextChunk();
                } else {

                    setUploadProgress(100);
                    finalizeUpload(file.name, totalChunks, uploadFileName);
                }
            } catch (error) {
                console.error('Error uploading chunk', error);
                // Handle error as needed
            }
        };

        uploadNextChunk();
    };
    // Function to finalize upload after all chunks are uploaded
    const finalizeUpload = async (fileName, totalChunks, uploadFileName) => {
        setProcessingMessage('Processing...');
        try {

            const response = await axios.post('/api/media/finalizeUpload', { fileName, totalChunks, uploadFileName });
            const result = response;

            if (result.data?.success) {
                setProcessingMessage('Video uploaded successfully');
                setIsPlaying(false);
                setTimeout(function () {
                    setVideoObj(prevMediaData => ({
                        ...prevMediaData,
                        videoId: result?.data?.data?.videoId,
                        videoSrc: result?.data?.data?.videoSrc,
                        videoThumbnailUrl: result?.data?.data?.videoThumbnailUrl === '/images/video-processing.jpg' ? VideoProcessing : result?.data?.data?.videoThumbnailUrl,
                        videoType: result?.data?.data?.videoType,
                        vimeoVideoFileName: result?.data?.data?.vimeoVideoFileName,
                        type: 'Video',
                        imageFile: null,
                        selectedVideoSrc: videoSource
                    }));

                    setShowSaving(false);
                    setProcessingMessage('');
                    setUploadProgress(0);
                    setValidationErrorMsg('');
                }, 2000);
            }

            // Handle response from server, e.g., update UI
            console.log('Media added successfully:', result);

        } catch (error) {
            console.error('Error finalizing upload', error);
            // Handle error as needed
        }
    };
    console.log(requireAltText);
    if (showModal) {
        return (
            <div data-section="CropImageOverlayPreview" className="admin-modal-overlay video-modal-wrapper">
                <div className="admin-modal">
                    <button className="admin-modal-close" onClick={handleCloseModal}>
                        <span className="material-symbols-rounded">close</span>
                    </button>
                    <p className="admin-modal-heading">Add Video</p>
                    <div className="admin-modal-row full">
                        <div className="admin-modal-cell full">

                            <div className="financial-skill-tile">
                                <select className="admin-default-select" onChange={SelectedVideoSrc} value={videoSource ? videoSource : null}>
                                    <option value={null}>Select Video Source</option>
                                    <option value="uploadVideo">Upload Video</option>
                                    <option value="videoLink">Enter Link</option>
                                </select>
                                {selectVideoSourceError != '' && < p className="error-messagess">{selectVideoSourceError}</p>}
                            </div>
                            {(videoObj?.videoSrc) ? (
                                <>
                                    <div className="financial-skill-tile">
                                        <label className="admin-default-label" htmlFor="video-url">
                                            {videoSource === 'videoLink' ? 'URL Link' : 'Video'}
                                        </label>
                                        {videoSource === 'videoLink' && (
                                            <>
                                                <span>{videoObj?.videoSrc.replace('https://', '')}</span>
                                                <button className="media-thumbnail-action" onClick={handleDeleteMedia}>
                                                    <span className="material-symbols-rounded">delete</span>
                                                </button>
                                            </>

                                        )}
                                        {videoSource === 'uploadVideo' && (
                                            <div className="video-priew-section">
                                                <div className="video-priew">
                                                    {isPlaying ? <>
                                                        <iframe
                                                            ref={videoRef}
                                                            src={videoObj.videoSrc}
                                                            title=""
                                                            frameBorder="0"
                                                            allow="autoplay;fullscreen"
                                                        >
                                                        </iframe>
                                                        <button className="media-thumbnail-action" onClick={handleDeleteMedia}>
                                                            <span className="material-symbols-rounded">close</span>
                                                        </button>
                                                    </>
                                                        : <>
                                                            <img
                                                                src={videoObj?.videoCustomThumbnailUrl ? videoObj?.videoCustomThumbnailUrl : videoObj?.videoThumbnailUrl}
                                                                alt="Video Thumbnail"
                                                            />


                                                            <button className="media-thumbnail-action" onClick={handleDeleteMedia}>
                                                                <span className="material-symbols-rounded">close</span>
                                                            </button>
                                                            {!isPlaying && videoObj?.videoSrc.includes('vimeo.com') ?
                                                                <button className="media-thumbnail-action play-icon" onClick={handleMediaPlay} >
                                                                    <span className="material-symbols-rounded">
                                                                        {isPlaying ? 'pause' : 'play_arrow'}
                                                                    </span>
                                                                </button>
                                                                :
                                                                <button className="media-thumbnail-action play-icon" >
                                                                    <span className="material-symbols-rounded">
                                                                        {isPlaying ? 'pause' : 'play_arrow'}
                                                                    </span>
                                                                </button>

                                                            }
                                                        </>

                                                    }

                                                </div>
                                            </div>
                                        )}

                                    </div>

                                    <div className="admin-modal-cell full video-thumbnail-cell">
                                        <label className="admin-default-label" htmlFor="video-url">Thumbnail</label>
                                        <div className="videoThumbWrap video-thumbnail">
                                            <img
                                                className="videoThumbPreview video-thumbnail-image"
                                                src={videoObj?.videoCustomThumbnailUrl ? videoObj?.videoCustomThumbnailUrl : videoObj?.videoThumbnailUrl}
                                                alt="Thumbnail"
                                            />
                                            <div className={`media-thumbnail-action-wrap ${validationError ? ' input-validation-error' : ''}`}>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleThumbnailImageChange}
                                                    style={{ display: 'none' }}
                                                    ref={fileInputRef}
                                                />
                                                <button
                                                    className="media-thumbnail-action red margin-bt-edit-image"
                                                    onClick={() => fileInputRef.current.click()}
                                                >
                                                    <span className="material-symbols-rounded">edit</span>
                                                </button>
                                                {videoObj?.videoCustomThumbnailUrl && (
                                                    <button className="media-thumbnail-action red" onClick={removeCustomThumbnail}>
                                                        <span className="material-symbols-rounded">delete</span>
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <MediaImageModal
                                        showModal={showImageModal}
                                        forceCrop={false}
                                        uploadImageObj={ogImageSrc}
                                        editImageObj={null}
                                        onCloseModal={() => setShowImageModal(false)}
                                        onSaveModal={uploadCustomThumbnail}
                                    />
                                </>
                            ) : (
                                <>
                                    {videoSource == 'videoLink' && (
                                        <div className="financial-skill-tile">
                                            <label className="admin-default-label" htmlFor="video-url">Enter URL*</label>
                                            <input
                                                id="video-url"
                                                data-imvideosection="videoUrl"
                                                className={`admin-default-input-text ${validationError ? ' input-validation-error' : ''}`}
                                                type="text"
                                                    onChange={fetchVideoThumbnailFromWeb}
                                                    defaultValue={videoObj?.videoSrc}
                                            />
                                        </div>
                                    )}
                                    {videoSource == 'uploadVideo' && (
                                        <div className="financial-skill-tile">
                                            {uploadProgress === 0 && processingMessage == '' && (
                                                <button className="admin-modal-button">
                                                    Upload Video
                                                    <input
                                                        type="file"
                                                        accept="video/*"
                                                        onChange={handleVideoUploadChange}
                                                    />
                                                </button>
                                            )}
                                            <div>
                                                <div>
                                                    {uploadProgress > 0 && uploadProgress < 100 && (
                                                        <span>Uploading file: {Math.round(uploadProgress)}%</span>
                                                    )}
                                                    {processingMessage && uploadProgress >= 100 && (
                                                        <span>{processingMessage}</span>
                                                    )}
                                                </div>
                                            </div>



                                            {isLargeVideo && < p className="error-messagess"> File size exceeds the 1GB limit.</p>}                                        </div>
                                    )}
                                </>
                            )}


                            <div className="upload-video-loader">
                                <PageLoadingSpinner show={showSaving} />
                            </div>
                        </div>
                    </div>

                    <div className="admin-modal-button-wrap">
                        <p className="error-messagess">
                            {validationErrorMsg || ''}
                        </p>
                        <button className="admin-modal-button"
                            data-action="closeOverlay"
                            onClick={handleCloseModal}
                        >Close</button>
                        <button className="admin-modal-button power"
                            onClick={handleSaveModal}
                        >Save Changes</button>
                    </div>
                </div>
            </div>
        )
    }
    else return (<></>);
}


MediaVideoModal.propTypes = {
    requireAltText: PropTypes.bool,
    mediaObj: PropTypes.object,
    showModal: PropTypes.bool,
    onCloseModal: PropTypes.func,
    onSaveModal: PropTypes.func
}

MediaVideoModal.defaultProps = {
    requireAltText: false
}
