import { createSlice } from '@reduxjs/toolkit';
import * as actions from './actions';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: false,
    loading: false,
    token: null,
    refreshToken: null,
    displayName: '',
    email: '',
    activeDistrict: null,
    revalidatingToken: false,
    isAdmin: false
  },
  reducers: {
    logoutUser(state) {
      state.isAuthenticated = false;
      state.loading = false;
      state.token = null;
      state.refreshToken = null;
      state.displayName = '';
      state.email = '';
    }
  },
  extraReducers: (builder) => {
    // User Login
    builder.addCase(actions.userLogin.pending, state => {
      state.loading = true;
    })
    builder.addCase(actions.userLogin.fulfilled, (state, action) => {
      const { token, refreshToken, displayName, email, isAdmin } = action.payload?.data.data || {};
      state.isAuthenticated = true;
      state.token = token;
      state.refreshToken = refreshToken;
      state.displayName = displayName;
      state.email = email
      state.isAdmin = isAdmin;
      state.loading = false;
    })

    builder.addCase(actions.userLogin.rejected, state => {
      state.loading = false;
    })

    //Refresh token
    builder.addCase(actions.getNewToken.pending, state => {
      state.loading = true;
      state.revalidatingToken = true;
    })
    builder.addCase(actions.getNewToken.fulfilled, (state, action) => {
      const { token } = action.payload?.data || {};
      if (action.payload?.success) {
        state.token = token,
          state.loading = false;
      } else {
        state.loading = false;
        state.isAuthenticated = false,
          state.token = null,
          state.refreshToken = null,
          state.name = '',
          state.email = '';
      }
      state.revalidatingToken = false;
    })
    builder.addCase(actions.getNewToken.rejected, state => {
      if (!state.revalidatingToken) {
        state.loading = false;
        state.isAuthenticated = false,
          state.token = null,
          state.refreshToken = null,
          state.name = '',
          state.email = '';
      }
    })

    //Check if authenticated
    builder.addCase(actions.checkAuth.pending, state => {
      state.loading = true;
    });
    builder.addCase(actions.checkAuth.fulfilled, (state, action) => {
      state.loading = false;
      const { success } = action.payload?.data || {};
      if (success === false && state.refreshToken !== null) {
        console.log('not authenticated, may need to refesh token');
      }
    })

      // One time email Login
      builder.addCase(actions.getEmailAuth.pending, state => {
          state.loading = true;
      })
      builder.addCase(actions.getEmailAuth.fulfilled, (state, action) => {          
          const { token, refreshToken, displayName, email, isAdmin } = action.payload?.data || {};
          state.isAuthenticated = true;
          state.token = token;
          state.refreshToken = refreshToken;
          state.displayName = displayName;
          state.email = email
          state.isAdmin = isAdmin;
          state.loading = false;
      })
      builder.addCase(actions.getEmailAuth.rejected, state => {
          state.loading = false;
      })

  }
});

export const { logoutUser } = authSlice.actions

export default authSlice.reducer;
