import React, { useState, useEffect } from "react";
import "../../pages/typical/typical.css";
import { useNavigate } from 'react-router-dom';
import { Quantity } from "./Quantity";
import TypicalProductImage from './TypicalProductImage'
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { formatProductCode, formatUSD, calculateFinalUnitPrice } from '../../helpers/formatterHelper'
import { loadAllItems } from '../../store/myproject-reducer';
export function TypicalProductDetail(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { typicalDetail, handleTypicalQuantityChange,handTypicalProdcutAdded } = props;
    const [product, setProduct] = useState({});
    const [typicalLayout, setTypicalLayout] = useState({});
    useEffect(() => {
        setProduct(typicalDetail?.product);
        setTypicalLayout(typicalDetail);
    }, [typicalDetail]);

    const quantityChange = (event) => {
        const selectedQuantity = parseInt(event.target.value, 10);
        const quantityData = {
            id: typicalLayout.id,
            TypicalLayoutId: typicalLayout.typicalLayoutId,
            quantity: selectedQuantity
        };
        handleTypicalQuantityChange(quantityData);
    };
    const typicalProductAdded = (id, typicalId,isAdded) => {
        const productAdded = {
            id: id,
            TypicalLayoutId: typicalId,
            isAdded: isAdded == true ? false : true
        };
        handTypicalProdcutAdded(productAdded);
    }

    const getProductDetails = (productId, typicalproductid, typicalLayoutId) => {
        var typicalLayoutList = [];
        typicalLayoutList.push(typicalLayout);
        localStorage.setItem('typicalLayoutProductDetail', JSON.stringify(typicalLayoutList));
        dispatch(loadAllItems("typicalProductDetailView"));

        navigate('/products/' + productId + '?cartid=' + typicalproductid + '&typicalid=' + typicalLayoutId);
    }

    return (
        <div className={`prod-grid-row ${typicalLayout?.isAdded ? 'checked' : ''} row-section`}>
            <div className="typical-products--coll cart-img">

                {/*<div className="prod-grid-image-wrap">*/}
                {/*    <img src="https://placehold.jp/128x134.png" alt="product placeholder" />*/}
                {/*</div>*/}
                {product && product.photo ? (
                    <TypicalProductImage
                        photo={product.photo}
                        photoAlt={product.photoAlt ?? product.details.name}
                    />
                ) : (
                    <div className="prod-grid-image-wrap">
                    <span className="errorImage">Image Coming Soon</span>
                    </div>
                )}

            </div>
            <div className="typical-products--coll">
                <div className="prod-grid-col prod-desc typical-prod-desc">
                    <p className="prod-desc-title">{product && product?.details && product.details?.name}</p>
                    <p className="prod-desc-content">{product && product?.details && formatProductCode(product)}</p>
                    {/*<span className="prod-desc-color">*/}
                    {/*    <span className="teal-bak">*/}
                    {/*        Color: <span className="prod-desc-color-label">Teal Blue</span>*/}
                    {/*    </span>*/}
                    {/*</span>*/}

                    {typicalLayout.options &&
                        typicalLayout.options.length > 0 &&
                        typicalLayout.options.filter(x => x.type !== 2).map((option) => (
                            <React.Fragment key={option?.id}>
                              <p className="prod-desc-color">
                                {option?.title}&nbsp;:&nbsp;
                                    {option.optionItems &&
                                            option.optionItems.map((item) => (
                                            <React.Fragment key={item?.id}>
                                                {option.type === 2 ? item.color?.name : item?.title}
                                            </React.Fragment>
                                        ))}
                                    {/*{idx + 1 === product.options.filter(x => x.type !== 2).length ? '' : ' / '}*/}
                                   
                                </p>
                            </React.Fragment>
                        ))}
                    {typicalLayout.options &&
                        typicalLayout.options.length > 0 &&
                        typicalLayout.options.filter(x => x.type === 2).map((option) => (
                            <React.Fragment key={option?.id}>
                                <p className="prod-desc-color">{option?.title}:&nbsp;
                                    {option?.optionItems.map((item) => <span
                                        key={item.color?.id}
                                        className="color-icons color-dots large color-select"
                                        style={
                                            item.color?.colorImage
                                                ? {
                                                    backgroundImage: `url(${item.color?.colorImage?.previewsrc})`,
                                                }
                                                : { backgroundColor: item.color?.hex }
                                        }
                                        htmlFor={item.color?.id}
                                    ></span>)}
                                </p>
                            </React.Fragment>
                        ))}

                </div>
            </div>
            <div className="typical-products--coll">
                {/*<Quantity />*/}
                <Quantity
                    typicalId={typicalLayout?.id}
                    currentQuantity={typicalLayout?.quantity}
                    setQuantity={quantityChange}
                /> 
            </div>
            <div className="typical-products--coll desktop-gallery">
                <div className="prod-grid-col prod-total-price">
                    <div className="prod-grid-price-wrap">
                        <p className="prod-grid-total"> {product?.details && formatUSD(calculateFinalUnitPrice(product))}</p>
                    </div>
                </div>
            </div>

            <div className="typical-products--coll desktop-gallery">
                <div className="prod-grid-col prod-unit-price">
                    <div className="prod-grid-total-wrap">

                        <p className="prod-grid-total">{formatUSD(
                            calculateFinalUnitPrice(product) * typicalLayout.quantity
                        )}</p>
                    </div>

                </div>
            </div>

            <div className="typical-products--coll desktop-gallery">
                <div className="subtotal-cart-item subtotal-buttons-wrap">
                    <a href="javascript:;" onClick={() => getProductDetails(product.id, typicalLayout.id, typicalLayout.typicalLayoutId)} className="add-cart-cta typical-add-button">
                        View Details
                    </a>
                </div>
            </div>

            <div className="typical-products--coll">
                <div className="prod-grid-col icon-wrap">
                    <div className="prod-grid-action-wrap" onClick={() => typicalProductAdded(typicalLayout.id, typicalLayout.typicalLayoutId, typicalLayout.isAdded)}>
                        {typicalLayout?.isAdded === true ? (
                            <span className="prod-grid-icon checked">
                                <span className="material-symbols-rounded">check</span>
                            </span>
                        ) : (<span className="prod-grid-icon">
                            <span className="material-symbols-rounded">add</span>
                        </span>)
                        }
                    </div>
                </div>
            </div>
            <div className="cart-total-wrap mobile-gallery typical-cart-total-wrap">
                <div className="cart-datawrap">
                    <div className="cart-grid-total-price-wrap">
                        <div className="cart-grid-inner">
                            <p className="mobile-price-label">Unit Price</p>
                            <p className="cart-grid-total left-unit-price">{product?.details && formatUSD(calculateFinalUnitPrice(product))}</p>
                        </div>
                    </div>
                    <div className="cart-grid-unit-price-wrap">
                        <div className="cart-grid-inner">
                            <p className="mobile-price-label">Total Price</p>
                            <p className="cart-grid-total">{formatUSD(
                                calculateFinalUnitPrice(product) * typicalLayout.quantity
                            )}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


TypicalProductDetail.propTypes = {
    typicalDetail: PropTypes.object.isRequired,
    handleTypicalQuantityChange: PropTypes.func.isRequired,
    handTypicalProdcutAdded: PropTypes.func.isRequired
};

export default TypicalProductDetail;