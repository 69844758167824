import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//import { useNavigate } from "react-router-dom";
import { loadDistricts, getDistricts, addDistrict, deleteDistricts } from "../../../store/districts-reducer";
import { showLoading, hideLoading, delayedHideLoading } from '../../../store/loading-reducer';
import { AddDistrictModal } from "./addDistrictModal"
import { Link } from 'react-router-dom';
import { getDistrictByPath } from '../../../api/districtapi';
import { updateTitle } from '../../../helpers/titleHelper';
import { DeleteConfrimationModal } from '../../../components/admin/DeleteConfirmationModal'

export function Districts() {
    updateTitle('Admin', 'Districts');
    const model = {
        id: 0,
        name: '',
        hexcolor: '#000000',
        path: '',
        mediaItemId: null,
        schoolLogo: null
    };
    const dispatch = useDispatch();
    const districts = useSelector(getDistricts);
    const [showAddModal, setShowAddModal] = useState(false);
    //const navigate = useNavigate();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [districtModel, setDistrictModel] = useState({ id: 0, title: '' });

    useEffect(() => {
        dispatch(showLoading());
        dispatch(loadDistricts())
            .then(() => {
                dispatch(delayedHideLoading());
            })
            .catch(() => {
                dispatch(hideLoading());
            });

    }, [dispatch]);


    const handleAddDistrict = (districtData) => {
        dispatch(addDistrict(districtData));
    };

    const handleDeleteTrigger = (id, name) => {
        setDistrictModel({ id: id, title: name });
        setShowDeleteModal(true);
    }

    const handleDeleteDistrict = (districtData) => {
        dispatch(deleteDistricts(districtData.id));
    };

    const setActiveDistrict = (e) => {
        getDistrictByPath(e.target.dataset.districturl.replace('/', '')).then(response => {
            if (response.status == 200 && response.data.success == true) {
                var district = response.data.data;
                if (district != null) {
                    localStorage.setItem('district', JSON.stringify(district));
                    localStorage.setItem('districtId', district.id);
                }
            }
        });
    }

    return (
        <div className="admin-page-wrap">
            <div className="admin-top-bar-wrap">
                <Link className="admin-top-bar-back" to="/admin/">
                    Back
                </Link>
            </div>
            <div className="admin-page-content">
                <div className="admin-page-title-wrap">
                    <h1 className="admin-page-heading">Districts</h1>
                    {(!districts.isDistrictUser || districts.isDistrictAdmin === true) &&
                        <button className="admin-button power" onClick={() => setShowAddModal(true)}>
                            Add District <span className="material-symbols-rounded">add</span>
                        </button>
                    }
                </div>

                <DeleteConfrimationModal
                    show={showDeleteModal}
                    onClose={() => setShowDeleteModal(false)}
                    handleDeleteEvent={handleDeleteDistrict}
                    model={districtModel}
                    label={"district"}
                />

                <AddDistrictModal
                    show={showAddModal}
                    onClose={() => setShowAddModal(false)}
                    handleAddDistrict={handleAddDistrict}
                    model={model}
                />

                <div className="admin-section">
                    {districts.data && districts.data.length > 0 ? (
                        districts.data.map((district) => (
                            <div className="admin-tall-list-item" key={district.id}>
                                <p className="admin-tall-list-name">{district.name}</p>
                                <div className="admin-tall-list-options">
                                    {district.path && district.path !== '' &&
                                        <a
                                            href="/home"
                                            target="_blank" rel="noopener noreferrer"
                                            className="admin-tall-list-edit"
                                            data-districturl={district.path}
                                            onClick={setActiveDistrict}
                                            title="Set as Active District"
                                        >
                                            <span className="material-symbols-rounded">visibility</span>
                                        </a>}
                                    <Link to={`/admin/districts/edit/${district.id}`} className="admin-tall-list-edit">
                                        <span className="material-symbols-rounded">edit</span>
                                    </Link>
                                    <button
                                        className="admin-tall-list-edit"
                                        key={district.id}
                                        onClick={() => handleDeleteTrigger(district.id, district.name)}
                                    >
                                        <span className="material-symbols-rounded">delete</span>
                                    </button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="admin-tall-list-item">
                            <p className="admin-tall-list-name">There are not any active Districts.</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
