import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
	addNewCategory,
	editExistingCategory,
	deleteExistingCategory,
} from '../../../store/categories-reducer';
import { AdminModal } from '../../../components/admin/admin-modal';
import PropTypes from 'prop-types';
import MediaUploader from '../../../components/media/MediaUploader';

export const AddCategoryName = ({ selectedGroupId, onClose }) => {
	const dispatch = useDispatch();
	const [newCategoryName, setNewCategoryName] = useState('');
	const [imageItem, setImageItem] = useState(null);
	const [errors, setErrors] = useState('');

	const handleAddCategory = (groupId, categoryName) => {
		if (newCategoryName.trim() === '') {
			setErrors('Name field cannot be empty');
			return;
		}
		const categoryData = {
			CategoryGroupId: groupId,
			Name: categoryName,
			MediaItemId: (imageItem != null ? imageItem.id : null)
		};
		dispatch(addNewCategory(categoryData,
			() => { onClose(); }, //success
			(err) => { //error
			setErrors(err);
		}));
	};

	const onCancel = () => {
		setNewCategoryName('');
		setErrors('');
		onClose();
	};
	const handleCategoryNameChange = (e) => {
		setNewCategoryName(e.target.value);
	};
	const inputRef = useRef(null);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, []);

	return (
		<AdminModal
			show={true}
			onClose={onCancel}
			onSave={() => handleAddCategory(selectedGroupId, newCategoryName)}
			modalTitle="Add Category"
			errors={errors}
		>
			<div className="admin-modal-row full">
				<div className="admin-modal-cell">
					<div
						className={
							'admin-default-input-text-wrap' +
							(errors ? ' input-validation-error' : '')
						}
					>
						<label className="admin-default-label">Category</label>
						<input
							className="admin-default-input-text"
							type="text"
							value={newCategoryName}
							onChange={handleCategoryNameChange}
							placeholder="Enter new category name"
							ref={inputRef}
						/>
						{errors && <p className="validation-error-message">{errors}</p>}
					</div>
				</div>
			</div>
			<div className="admin-modal-row full">
				<div className="admin-modal-cell">
					<MediaUploader
						imageOnly={true}
						allowGallery={false}
						mediaItems={imageItem == null ? [] : [imageItem]}
						saveMediaItems={(item) => { setImageItem(item[0]) } }
					/>
				</div>
			</div>
		</AdminModal>
	);
};
export const EditCategory = ({ category, onClose }) => {
	const dispatch = useDispatch();
	const [categoryName, setCategoryName] = useState(category.name);
	const [imageItem, setImageItem] = useState('mediaItem' in category && category.mediaItem ? category.mediaItem : null);
	const [errors, setErrors] = useState('');

	const handleSaveEdit = () => {
		if (categoryName.trim() === '') {
			setErrors('Name field cannot be empty' );
			return;
		}
		dispatch(editExistingCategory({ ...category, Name: categoryName, MediaItemId: (imageItem != null ? imageItem.id : null) },
			() => { onClose(); }, //success
			(err) => { //error
				setErrors(err);
			}));
	};
	const onCancel = () => {
		setErrors('');
		onClose();
	};
	const inputRef = useRef(null);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, []);

	return (
		<AdminModal
			show={true}
			onClose={onCancel}
			onSave={handleSaveEdit}
			modalTitle="Edit Category"
		>
			<div className="admin-modal-row full">
				<div className="admin-modal-cell">
					<div
						className={
							'admin-default-input-text-wrap' +
							(errors ? ' input-validation-error' : '')
						}
					>
						<label className="admin-default-label">Category</label>
						<input
							className="admin-default-input-text"
							type="text"
							ref={inputRef}
							value={categoryName}
							onChange={(e) => setCategoryName(e.target.value)}
						/>
						{errors && <p className="validation-error-message">{errors}</p>}
					</div>
				</div>
				</div>
				<div className="admin-modal-row full">
					<div className="admin-modal-cell">
						<MediaUploader
							imageOnly={true}
							allowGallery={false}
							mediaItems={imageItem == null ? [] : [imageItem]}
							saveMediaItems={(item) => { setImageItem(item[0]) }}
						/>
					</div>
				</div>
		</AdminModal>
	);
};
export const DeleteCategory = ({ category, categoryId, onClose }) => {
	const dispatch = useDispatch();

	const handleConfirmDelete = () => {
		dispatch(deleteExistingCategory(categoryId));
		onClose();
	};

	return (
		<div className="admin-modal-overlay">
			<div className="admin-modal">
				<h2 className="admin-modal-heading">
					Are you sure you want to delete this category?
				</h2>
				<div className="admin-modal-row full">
					<div className="admin-modal-cell">{category.name}</div>
				</div>
				<div className="admin-modal-button-wrap">
					<button className="admin-modal-button" onClick={onClose}>
						Cancel
					</button>
					<button
						className="admin-form-button power red"
						onClick={handleConfirmDelete}
					>
						Delete
					</button>
				</div>
			</div>
		</div>
	);
};

AddCategoryName.propTypes = {
	selectedGroupId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
		.isRequired,
	onClose: PropTypes.func.isRequired,
};

EditCategory.propTypes = {
	category: PropTypes.shape({
		id: PropTypes.number.isRequired,
		name: PropTypes.string.isRequired,
		categoryGroupId: PropTypes.number.isRequired,
		mediaItemId: PropTypes.number,
		mediaItem: PropTypes.object
	}).isRequired,
	onClose: PropTypes.func.isRequired,
};

DeleteCategory.propTypes = {
	category: PropTypes.shape({
		id: PropTypes.number.isRequired,
		name: PropTypes.string.isRequired,
	}).isRequired,
	categoryId: PropTypes.number.isRequired,
	onClose: PropTypes.func.isRequired,
};
