import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { loadDistricts, getDistricts } from "../../../store/districts-reducer";
import { showLoading, hideLoading, delayedHideLoading } from '../../../store/loading-reducer';
import { formatDateForUserTimeZone } from '../../../helpers/DateTimeFormatter';
import { getReportRowCount } from '../../../api/districtquoteapi';

export function Reports() {
    const dispatch = useDispatch();
    const districts = useSelector(getDistricts);
    const [filter, setFilter] = useState({ districtIds: [], startDate: null, endDate: null });
    const [err, setErr] = useState(null);
    const [rowcount, setRowCount] = useState(0);

    useEffect(() => {
        dispatch(showLoading());
        dispatch(loadDistricts())
            .then(() => {
                dispatch(delayedHideLoading());
            })
            .catch(() => {
                dispatch(hideLoading());
            });

    }, [dispatch]);

    useEffect(() => {
        getReportRowCount(filter).then(response => {
            if (response.data.success == true) {
                setRowCount(response.data.data);
            }
        });
    }, [filter]);

    const handleDistrictChecked = (e) => {
        var id = parseInt(e.target.value);
        var districts = filter.districtIds;
        if (districts.indexOf(id) < 0) {
            districts.push(id);
        } else {
            const i = districts.indexOf(id);
            if (i >= 0) {
                districts.splice(i, 1);
            }
        }
        if (districts.length > 0) {
            setErr(null);
        }
        setFilter({ ...filter, districtIds: districts });
    }

    const handleStartDateChange = (e) => {
        var input = e.target;
        if (!input.value) {
            setFilter({ ...filter, startDate: null });
            return;
        }
        setFilter({ ...filter, startDate: input.value });
    }

    const handleEndDateChange = (e) => {
        var input = e.target;
        if (!input.value) {
            setFilter({ ...filter, endDate: null });
            return;
        }
        setFilter({ ...filter, endDate: input.value });
    }

    const handleDistrictCheckAll = () => {
        setFilter({ ...filter, districtIds: districts.data.length == filter.districtIds.length ? [] : districts.data.map(a => a.id) });
    }

    const showError = () => {
        if (rowcount == 0) {
            setErr('The filter you\'ve selected does not contain any rows of data');
        } else {
            setErr('You must select at least one district');
        }
    }

    return (
        <div className="admin-page-wrap">
            <div className="admin-page-content">
                <h1 className="admin-page-heading">Reports</h1>
                <div className="report-table-wrapper">
                    <div className="report-districts">
                        <div className="select-district-wrapper">
                            <p className="admin-section-small-headline">Choose District</p>

                            <div>
                                <label className="admin-inline-label" >
                                    <input className="admin-inline-checkbox" type="checkbox" onChange={handleDistrictCheckAll} checked={districts.data && districts.data.length == filter.districtIds.length}></input>
                                    <span className="admin-inline-label-text">{districts.data && districts.data.length == filter.districtIds.length ? 'Clear All' : 'Select All'}
                                    </span>
                                </label>
                            </div>
                            <hr/>
                           
                            {districts.data && districts.data.length > 0 ? (
                                districts.data.map((district) => (
                                    <div className="district-checkbox" key={district.id}>
                                        <label className="admin-inline-label" >
                                            <input type="checkbox"
                                                className="admin-inline-checkbox"
                                                value={district.id}
                                                onInput={handleDistrictChecked}
                                                checked={filter.districtIds.indexOf(district.id) >= 0}></input>
                                            <span className="admin-inline-label-text">{district.name}</span>
                                        </label>
                                    </div>
                                ))
                            ) : (
                                <div className="admin-tall-list-item">
                                    <p className="admin-tall-list-name">There are not any active Districts.</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        <div className="report-dates">
                            <div className="col date-picker-wrap">
                                <div className="date-picker-label">Start Date</div>
                                <div className="date-picker-box">
                                    <input type="date" onInput={handleStartDateChange} max={(new Date()).toISOString().split('T')[0] }></input>
                                </div>
                            </div>
                            <div className="col date-picker-wrap">
                                <div className="date-picker-label">End Date</div>
                                <label className="date-picker-box" htmlFor="endDate">
                                    <input type="date" id="endDate" onInput={handleEndDateChange} max={(new Date()).toISOString().split('T')[0]}></input>
                                </label>
                            </div>
                            <div className="col date-picker-wrap">

                                {filter.districtIds.length > 0 &&
                                    <div className="date-picker-label">Filtered Records: {rowcount}</div>
                                }
                                {filter.districtIds.length > 0 && rowcount > 0 ?
                                    <a className="admin-button" href={"/report/get/" + (filter.districtIds.join(',')) + '/0/' + (filter.startDate ? formatDateForUserTimeZone(filter.startDate, 'yyyy-MM-dd') : '') + '/' + (filter.endDate ? formatDateForUserTimeZone(filter.endDate, 'yyyy-MM-dd') : '')} target="_blank" rel="noreferrer">
                                        Download Report
                                        <span className="material-symbols-rounded">
                                            download
                                        </span>
                                    </a>
                                    :
                                    <a className="admin-button" href="javascript:" onClick={showError}>
                                        Download Report
                                        <span className="material-symbols-rounded">
                                            download
                                        </span>
                                    </a>
                                }
                            </div>
                        </div>
                        {err &&
                            <div className="validation-error-message" style={{ 'padding':'30px 0 0 0' }}>{err}</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

