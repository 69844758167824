import React, { useState, useEffect, useRef } from 'react';
import { createUser } from '../../store/auth/actions';
import { bindActionCreators } from "@reduxjs/toolkit";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import './login.css'
import './../../styles/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import { updateTitle } from '../../helpers/titleHelper';

function CreateAccountFormValidationSummary({ display, messageList }) {
    if (display) {
        const listElements = messageList.map(message =>
            <li key={message}>{message}</li>
        );
        return (
            <div className="login-form-cell">
                <div className="validation-summary-valid" data-valmsg-summary="true">
                    <ul>
                        {listElements}
                    </ul>
                </div>
            </div>
        );
    }
    else return null;
}

CreateAccountFormValidationSummary.propTypes = {
    display: PropTypes.bool,
    messageList: PropTypes.array
}

export default function CreateAccount() {
    updateTitle('Create Account');
    const dispatch = useDispatch();
    const setcreateUser = bindActionCreators(createUser, dispatch);
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [viewPass1, setViewPass1] = useState(false);
    const [viewPass2, setViewPass2] = useState(false);

    const [displayError, setDisplayError] = useState(false);
    const [messages, setMessages] = useState([]);

    const [isProcessing, setIsProcessing] = useState(false);
    const districtId = localStorage.getItem('districtId');
    const phoneInputRef = useRef(null);

    useEffect(() => {
        setIsProcessing(false);
    }, [])
    

    const handleSubmit = async (event) => {
        event.preventDefault();
        setMessages([]);
        setDisplayError(false);
        let hasError = false;
        if (document.querySelector('.input-validation-error')) {
            document.querySelectorAll('.input-validation-error').forEach((element) => {
                element.classList.remove('input-validation-error');
            })
        }
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (email === '' || email === null ) {
            hasError = true;
            document.getElementById('email').classList.add('input-validation-error');
            setMessages(messages => [...messages, 'Please enter your Email.']);

        }
        if (!emailPattern.test(email) && email !== null) {
            hasError = true;
            document.getElementById('email').classList.add('input-validation-error');
            setMessages(messages => [...messages, 'Please enter valid Email.']);
        }
        if (fullName === '' || fullName === null) {
            hasError = true;
            document.getElementById('full-name').classList.add('input-validation-error');
            setMessages(messages => [...messages, 'Please enter your FullName.']);
        }
        if (phone === '' || phone === null) {
            hasError = true;
            document.getElementById('phone').classList.add('input-validation-error');
            setMessages(messages => [...messages, 'Please enter your Phone.']);
        }
        if (password === '' || password === null) {
            hasError = true;
            document.getElementById('password').classList.add('input-validation-error');
            setMessages(messages => [...messages, 'Please enter your Password.']);
        }
        if (confirmPassword === '' || confirmPassword === null) {
            hasError = true;
            document.getElementById('confirmPassword').classList.add('input-validation-error');
            setMessages(messages => [...messages, 'Please enter your ConfirmPassword.']);
        }
        
        if (password !== '' && confirmPassword !== '' && confirmPassword !== password) {
            hasError = true;
            document.getElementById('confirmPassword').classList.add('input-validation-error');
            setMessages(messages => [...messages, 'Please enter valid ConfirmPassword.']);
            
        }

        if (hasError) {
            setDisplayError(true);
            return;
        }

        let data = {
            DistrictId: districtId ,
            Email: email,
            FullName: fullName,
            Phone: phone,
            Password: password,
            ConfirmPassword: confirmPassword
        };

        setIsProcessing(true);

        const result = await setcreateUser({ data: data }).unwrap();

        if (result.success) {
            navigate('/account/login');
        } else { 
            document.getElementById('email').classList.add('input-validation-error');
            setMessages(messages => [...messages, 'Your email does not match our records as a valid signup domain.']);
            setDisplayError(true);
            setIsProcessing(false);
            return;
        }
    }


    useEffect(() => {
        document.querySelectorAll('.fancy-field').forEach((element) => {
            if (element.value != '') {
                element.parentNode.classList.add('input-focused');
            }
            else if (element.parentNode.classList.contains('input-focused')) {
                element.parentNode.classList.remove('input-focused');
            }
        });

    }, [email,fullName,phone,password,confirmPassword]);




    useEffect(() => {
        
            const iti = intlTelInput(phoneInputRef.current, {
                initialCountry: 'us',
                separateDialCode: true,
                utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js"
            });

        const defaultPhoneNumber = phone;
            iti.setNumber(defaultPhoneNumber);

            phoneInputRef.current.addEventListener('input', () => {
                const newPhoneNumber = iti.getNumber();
                setPhone(newPhoneNumber);
            });

            phoneInputRef.current.addEventListener('countrychange', () => {
                const newPhoneNumber = iti.getNumber();
                setPhone(newPhoneNumber);
            });
            return () => {
                iti.destroy();
            };
        
    }, []);



    return (
        <form onSubmit={handleSubmit}>
            <CreateAccountFormValidationSummary
                display={displayError}
                messageList={messages} />
            <div className="login-form-row">
                <div className="login-form-cell">
                    <div className="login-form-field fancy-parent">
                        <label className="login-label" htmlFor="email">Email Address</label>
                        <input className="login-input fancy-field"
                            type="text"
                            id="email"
                            name="email"
                            aria-label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}                              
                        />
                    </div>
                </div>
            </div>
            <div className="login-form-row">
                <div className="login-form-cell">
                    <div className="login-form-field fancy-parent">
                        <label className="login-label" htmlFor="fullName">Full Name</label>
                        <input className="login-input fancy-field"
                            id="full-name"
                            name="fullName"
                            aria-label="Full Name"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}     
                            type="text"
                             />
                    </div>
                </div>
            </div>

            <div className="login-form-row">
                <div className="login-form-cell">
                    <div className="login-form-field fancy-parent">                       
                        <input id="phone" className="admin-default-input-text" aria-label="Phone" ref={phoneInputRef} type="tel" autoComplete="off" />
                    </div>
                </div>
            </div>

            <div className="login-form-row">
                <div className="login-form-cell">
                    <div className="login-form-field fancy-parent">
                        <label className="login-label" htmlFor="password">Password</label>
                        <input className="login-input fancy-field"
                            id="password"
                            name="password"
                            value={password}
                            aria-label="Password"
                            onChange={(e) => setPassword(e.target.value)}  
                            type={viewPass1 ? 'text' : 'password'}
                        />    
                        <span className="view-pass" onClick={() => { setViewPass1(!viewPass1); }}>
                            <span className="material-symbols-rounded">{viewPass1 ? 'visibility_off' : 'visibility'}</span>
                        </span>
                    </div>
                </div>
            </div>
            <div className="login-form-row">
                <div className="login-form-cell">
                    <div className="login-form-field fancy-parent">
                        <label className="login-label" htmlFor="confirmPassword">Confirm Password</label>
                        <input className="login-input fancy-field"
                            id="confirmPassword"
                            name="confirmPassword"
                            value={confirmPassword}
                            aria-label="Confirm Password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            type={viewPass2 ? 'text' : 'password'}
                        />
                        <span className="view-pass" onClick={() => { setViewPass2(!viewPass2); }}>
                            <span className="material-symbols-rounded">{viewPass2 ? 'visibility_off' : 'visibility'}</span>
                        </span>               
                    </div>
                </div>
            </div>
            <div className="login-form-row">
                <div className="login-form-cell">
                    {isProcessing ?
                        <button type="button" className="login-submit" onClick={() => { }}>Creating Account...</button> :
                        <button type="submit" className="login-submit">Create Account</button>
                    }
                </div>
            </div>
        </form>
    )
}