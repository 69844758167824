import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { editManufacturer } from '../../../store/manufacturer-reducer';
import PropTypes from 'prop-types';

export default function EditShippingNote({ manufacturerId, currentNotes, currentName,  }) {
    const dispatch = useDispatch();

    const [notes, setNotes] = useState(currentNotes || '');


    const handleNotesChange = (event) => {
        setNotes(event.target.value);
    };


    const handleSave = () => {
        dispatch(editManufacturer({ id: manufacturerId, name: currentName, shippingNote: notes }));
    };

    return (
        <div className="admin-tab-content" id="shipping-tab" role="tabpanel" aria-labelledby="tab3">
            <div className="admin-form-cell">
                <label className="admin-form-label" htmlFor="shipping-install-note">Shipping &amp; Install Note</label>
                <textarea className="admin-form-textarea" id="shipping-install-note" rows="3" value={notes} onChange={handleNotesChange} />
            </div>
            <div className="admin-form-cell">
                <button className="admin-form-button power" onClick={handleSave}>Save Changes</button>
            </div>
        </div>
    );
}

EditShippingNote.propTypes = {
    currentNotes: PropTypes.string,
    currentName: PropTypes.string,
    manufacturerId: PropTypes.string.isRequired
};