import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import CustomerEditor from '@ckeditor/ckeditor5-build-classic';
//import IconCustomSelect from '../../../components/IconCustomSelect';

import './typicalLayout.css'

const ckEditorConfig = {
    toolbar: [
        'Bold',
        'Italic',
        'Link',
        'Unlink',
        'NumberedList',
        'BulletedList'
    ],
};

const iconList = [
    { icon: 'chair_alt', label: 'Chair' },
    { icon: 'table_restaurant', label: 'Table' },
    { icon: 'table_bar', label: 'Table Alternative' },
    { icon: 'widgets', label: 'Arrange' },
    { icon: 'badge_critical_battery', label: 'Battery ' },
    { icon: 'weekend', label: 'Couch' },
    { icon: 'workspaces', label: 'Workspace' },
    { icon: 'atr', label: 'Workspace Alternative' },
    { icon: 'safety_divider', label: 'Space divider' },
    { icon: 'airline_seat_recline_normal', label: 'Sitting' },
    { icon: 'airline_seat_legroom_extra', label: 'Leg Space' },
    { icon: 'social_distance', label: 'Social Distancing' },
    { icon: 'align_space_even', label: 'Even Alignment' },
    { icon: 'workspace_premium', label: 'Premium' },
    { icon: 'computer', label: 'Computer' },
    { icon: 'school', label: 'Education' },
    { icon: 'square_foot', label: 'Square Foot' },
    { icon: 'star', label: 'Star' },
    { icon: 'done', label: 'Checkmark' },
    { icon: 'favorite', label: 'Favorite' },
    { icon: 'key', label: 'Key' },
    { icon: 'fullscreen', label: 'Fullscreen' },
    { icon: 'accessible', label: 'Accesible' },
    { icon: 'delete', label: 'Trash' },
    { icon: 'recycling', label: 'Recycle' },
    { icon: 'light', label: 'Light' },
    { icon: 'soap', label: 'Wash Hands' },
    { icon: 'battery_charging_full', label: 'Charging' },
    { icon: 'lightning_stand', label: 'Wireless Charging' },
    { icon: 'swap_horiz', label: 'Exchange' },
    { icon: 'expand', label: 'Expand' },
    { icon: 'view_comfy_alt', label: 'Alignment' },
    { icon: 'celebration', label: 'Celebrate' },
    { icon: 'build', label: 'Build' },
    { icon: 'arrows_outward', label: 'Arrows Outward' },
    { icon: 'psychiatry', label: 'Plant' },
    { icon: 'volume_up', label: 'Audio' },
    { icon: 'headphones', label: 'Headphones' },
    { icon: 'notifications_active', label: 'Alert' },
    { icon: 'earthquake', label: 'Earthquake' },
    { icon: 'shield', label: 'Shield' },
    { icon: 'lock', label: 'Lock' },
    { icon: 'verified', label: 'Verified' },
    { icon: 'touch_app', label: 'Touch Screen' },
    { icon: 'power_settings_new', label: 'Power' },
    { icon: 'alarm', label: 'Alarm' },
    { icon: 'support', label: 'Support' },
    { icon: 'hub', label: 'Hub' },
    { icon: 'wifi', label: 'Wifi' },
    { icon: 'local_shipping', label: 'Delivery Truck' },
    { icon: 'power', label: 'Plug' },
    { icon: 'sensors', label: 'Sensors' },
    { icon: 'tune', label: 'Custom' },
    { icon: 'precision_manufacturing', label: 'Precision' },
    { icon: 'factory', label: 'Factory' },
    { icon: 'warehouse', label: 'Warehouse' },
    { icon: 'manufacturing', label: 'Manufacturing' }
];

export function AddEditDescription(props) {
    const [formData, setFormData] = useState(null);
    const { show, onClose, handleAddDescription, modelDescription } = props;
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [selectedIcon,setSelectedIcon] = useState({});

    useEffect(() => {
        setIsInitialLoad(true);
        if (modelDescription?.icon != undefined && modelDescription.icon != null && modelDescription.icon != '') {
            const selected = iconList.find(item => item.icon === modelDescription.icon);
            setSelectedIcon({ icon: selected.icon, label: selected.label });
        }
        else { setSelectedIcon({ icon: 'accessible', label: 'Accesible' }); }
        setFormData(modelDescription);
    }, [modelDescription, show]);

    const handleSave = () => {
        if (formData.description) {
            if (formData.icon == '') {
                formData.icon = selectedIcon.icon;
            }
            handleAddDescription(formData);
            setFormData(null);
            setSelectedIcon({ icon: 'accessible', label: 'Accesible' }); 
            onClose(); 
        }
    }

    /*
    const iconsave = (icondata) => {
        if (icondata != undefined && icondata != null) {
            setFormData({
                ...formData, icon: icondata
            });
        }
    }*/

    const handleOnClose = () => {
        setFormData(null);
        setSelectedIcon({ icon: 'accessible', label: 'Accesible' }); 
        onClose();         
    };


    if (!show) {
        return null;
    }

    return (
        <div className="admin-modal-overlay">
            <div className="admin-modal admin-add-role">
                <div className="admin-modal-scroll">
                    <button className="admin-modal-close" onClick={handleOnClose}>
                        <span className="material-symbols-rounded">close</span>
                    </button>
                    <h2 className="admin-modal-heading">Add/Edit Description</h2>

                    {/*<div className="admin-modal-row">

                        <IconCustomSelect options={iconList} selected={selectedIcon} handleIconSave={ iconsave} />

                    </div>*/}

                    <div className="admin-modal-row">
                        <div
                            className='admin-section-cell extra-wide'>
                            <label className="admin-default-label" htmlFor="title">
                                Title
                            </label>
                            <input
                                className="admin-default-input-text"
                                type="text"
                                id="manufacturer-name"
                                value={formData.title || ''}
                                onChange={(e) => setFormData({
                                    ...formData, title: e.target.value
                                })}
                            />
                        </div>
                    </div>

                    <div className="admin-modal-row">
                        <div
                            className='admin-section-cell extra-wide'>
                            <label className="admin-default-label" htmlFor="descriptions">
                                Descriptions
                            </label>
                            <CKEditor
                                editor={CustomerEditor}
                                config={ckEditorConfig}
                                data={formData.description || ''}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    if (!isInitialLoad) {
                                        setFormData({
                                            ...formData, description: data
                                        });
                                    }
                                    setIsInitialLoad(false);
                                }}
                            />
                        </div>
                    </div>
                    <div className="admin-modal-button-wrap">
                        <button className="admin-modal-button" onClick={handleOnClose}>Cancel</button>
                        <button className="admin-modal-button power" onClick={handleSave}>Save Changes</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

AddEditDescription.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    handleAddDescription: PropTypes.func.isRequired,
    modelDescription: PropTypes.object
};
