import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

const ProductImageSlider = ({ photo, photoAlt, styles }) => {
    const [hasError, setHasError] = useState(false);
    useEffect(() => {
        if (photo) {
            const image = new Image();
            image.src = photo;
            image.onload = () => {
                setHasError(false);
            };
            image.onerror = () => {
                setHasError(true);
            };
        } else {
            setHasError(true);
        }
    }, [photo]);

    return (

        <span className="product-item-image bought-together-tile-image-wrap">
            {hasError ? (
                <span className="errorImage">Image Coming Soon</span>
            ) : (
                <img src={photo} alt={photoAlt} className={styles} />
            )}
            <span className="product-item-reaction">
                <span className="product-item-icon">
                    <span className="material-symbols-rounded">favorite</span>
                </span>
            </span>
        </span>

    );
};

ProductImageSlider.propTypes = {
    photo: PropTypes.string,
    photoAlt: PropTypes.string,
    styles: PropTypes.string
};

export default ProductImageSlider;