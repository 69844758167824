import api from '../utils/api';

export const getAllUsers = (districtId) => {
  return api({
    url: '/api/districts/users/get-all-users/' + districtId,
    method: 'GET'
  })
}

export const getDistrictUser = (districtId, userId) => {
  return api({
    url: '/api/districts/users/get-user/' + districtId + '/' + userId,
    method: 'GET'
  })
}

export const updateUser = (districtId, user) => {
  let url = '/api/districts/users/';
  if (user.id == null || user.id == 0) url += 'add-user';
  else url += 'edit-user';
  url += '/' + districtId;
  return api.post(url, user)
}

export const deleteUser = (districtId, userId) => {
  return api.delete('/api/districts/users/delete/'+districtId+'/'+userId);
}