import { createSlice, createSelector } from '@reduxjs/toolkit';
import { getAll, edit, add } from '../api/manufacturersapi';
/*import moment from "moment";*/

const slice = createSlice({
	name: 'manufacturers',
	initialState: {
		list: [],
		isLoading: false,
		lastFetch: null,
		saveStatus: 'idle', // 'saving', 'succeeded', 'failed'
		saveError: null,
	},
	reducers: {
		manufacturersRequested: (manufacturers) => {
			manufacturers.isLoading = true;
		},
		manufacturersReceived: (manufacturers, action) => {
			manufacturers.list = action.payload;
			manufacturers.isLoading = false;
			manufacturers.lastFetch = Date.now();
		},
		manufacturersRequestFailed: (manufacturers) => {
			manufacturers.list = [];
			manufacturers.isLoading = false;
		},
		manufacturerAdded: (manufacturers, action) => {
			manufacturers.list.data.push(action.payload);
		},
		manufacturerEdited: (manufacturers, action) => {
			const index = manufacturers.list.data.findIndex(
				(manufacturer) => manufacturer.id === action.payload.id
			);
			if (index >= 0) {
				manufacturers.list.data[index] = action.payload;
			}
		},
		saveStarted: (state) => {
			state.saveStatus = 'saving';
		},
		saveSucceeded: (state) => {
			state.saveStatus = 'succeeded';
		},
		saveFailed: (state, action) => {
			state.saveStatus = 'failed';
			state.saveError = action.payload;
		},
		resetSaveStatus: (state) => {
			state.saveStatus = 'idle';
		},
	},
});

const {
	manufacturersRequested,
	manufacturersReceived,
	manufacturersRequestFailed,
	manufacturerAdded,
	manufacturerEdited,
	saveStarted,
	saveSucceeded,
	saveFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators

export const loadManufacturers = () => async (dispatch) => {
	dispatch(manufacturersRequested());
	try {
		const response = await getAll();
		dispatch(manufacturersReceived(response.data));
	} catch (error) {
		dispatch(manufacturersRequestFailed(error.message));
	}
};

export const getManufacturers = createSelector(
	(state) => state.manufacturers.list,
	(manufacturers) => manufacturers
);

export const resetStatus = () => async (dispatch) => {
	dispatch(resetSaveStatus());
};

export const addManufacturer = (manufacturerData, onSuccess, onError) => async (dispatch) => {
	dispatch(saveStarted());
	try {
		const response = await add({ options: { data: manufacturerData } });
		if (response.data.success) {
			dispatch(manufacturerAdded(response.data.data));
			dispatch(saveSucceeded());
			if (onSuccess) { onSuccess(); }
		} else {
			dispatch(saveFailed(response.data.message));
			if (onError) { onError(response.data.message); }
		}
	} catch (error) {
		console.error('There was an error adding the manufacturer:', error);
	}
};

export const editManufacturer = (manufacturerData) => async (dispatch) => {
	dispatch(saveStarted());

	try {
		const response = await edit({ options: { data: manufacturerData } });
		if (response.data.success) {
			dispatch(manufacturerEdited(response.data));
			dispatch(saveSucceeded());
		} else {
			dispatch(saveFailed(response.data.message));
		}
	} catch (error) {
		dispatch(saveFailed(error.message));
		console.error('There was an error editing the manufacturer:', error);
	}
};

export const { resetSaveStatus } = slice.actions;
