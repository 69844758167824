import { createSlice, createSelector } from '@reduxjs/toolkit';
import {
	getAll,
	edit,
	add,
	deleteDistrictById,
	addDistrictsEmail,
	getAllDistrictEmail,
	deleteDistrictEmailById,
	editDistrictEmail,
} from '../api/districtapi'; // Make sure the path is correct
/*import moment from "moment";*/

const slice = createSlice({
	name: 'districts',
	initialState: {
		list: [],
		isLoading: false,
		lastFetch: null,
		saveStatus: 'idle', // 'saving', 'succeeded', 'failed'
		saveError: null,
		districtEmailList: [],
	},
	reducers: {
		// Getting All Districts
		districtRequested: (districts) => {
			districts.isLoading = true;
		},
		districtReceived: (districts, action) => {
			districts.list = action.payload;
			districts.isLoading = false;
			/* districts.lastFetch = Date.now();*/
		},
		districtRequestFailed: (districts) => {
			districts.list = [];
			districts.isLoading = false;
		},
		districtAdded: (districts, action) => {
			districts.list.data.push(action.payload);
		},
		districtEdited: (districts, action) => {
			const index = districts.list.data.findIndex(
				(districts) => districts.id === action.payload.data.id
			);
			districts.list.data[index] = action.payload.data;
		},
		deleteDistrict: (districts, action) => {
			const index = districts.list.data.findIndex(
				(districts) => districts.id === action.payload.data
			);
			if (index > -1) {
				districts.list.data.splice(index, 1);
			}
		},
		saveStarted: (state) => {
			state.saveStatus = 'saving';
		},
		saveSucceeded: (state) => {
			state.saveStatus = 'succeeded';
		},
		saveFailed: (state, action) => {
			state.saveStatus = 'failed';
			state.saveError = action.payload;
		},
		resetSaveStatus: (state) => {
			state.saveStatus = 'idle';
		},
		districtEmailAdded: (districtEmails, action) => {
			districtEmails.districtEmailList.push(action.payload);
		},
		// Getting District Emails
		districtEmailRequested: (districtEmails) => {
			districtEmails.isLoading = true;
		},

		districtEmailReceived: (districtEmails, action) => {
			districtEmails.districtEmailList = action.payload;
			districtEmails.isLoading = false;
		},
		districtEmailRequestFailed: (districtEmails) => {
			districtEmails.districtEmailList = [];
			districtEmails.isLoading = false;
		},
		deleteDistrictEmail: (districtEmails, action) => {
			const index = districtEmails.districtEmailList.findIndex(
				(districtEmails) => districtEmails.id === action.payload.data
			);
			if (index > -1) {
				districtEmails.districtEmailList.splice(index, 1);
			}
		},
		districtEmailEdited: (districtEmails, action) => {
			const index = districtEmails.districtEmailList.findIndex(
				(districtEmails) => districtEmails.id === action.payload.data.id
			);
			districtEmails.districtEmailList[index] = action.payload.data;
		},
	},
});

const {
	districtRequested,
	districtReceived,
	districtRequestFailed,
	districtAdded,
	districtEdited,
	deleteDistrict,
	saveStarted,
	saveSucceeded,
	saveFailed,
	districtEmailAdded,
	districtEmailRequested,
	districtEmailReceived,
	districtEmailRequestFailed,
	deleteDistrictEmail,
	districtEmailEdited,
} = slice.actions;
export default slice.reducer;

// Action Creators

export const loadDistricts = () => async (dispatch) => {
	dispatch(districtRequested());

	try {
		const response = await getAll();
		dispatch(districtReceived(response.data));
	} catch (error) {
		dispatch(districtRequestFailed(error.message));
	}
};

export const getDistricts = createSelector(
	(state) => state.districts.list,
	(districts) => districts
);
export const addDistrict = (districtData) => async (dispatch) => {
	dispatch(saveStarted());
	try {
		const response = await add({ options: { data: districtData } });
		if (response.data.success) {
			dispatch(districtAdded(response.data.data));
			dispatch(saveSucceeded());
		} else {
			dispatch(saveFailed(response.data.message));
		}
	} catch (error) {
		console.error('There was an error adding the district:', error);
	}
};

export const editDistrict = (districtData) => async (dispatch) => {
	dispatch(saveStarted());

	try {
		const response = await edit({ options: { data: districtData } });
		if (response.data.success) {
			dispatch(saveSucceeded());

			dispatch(districtEdited(response.data));
		} else {
			dispatch(saveFailed(response.data.message));
		}
	} catch (error) {
		dispatch(saveFailed(error.message));
		console.error('There was an error editing the district:', error);
	}
};

export const deleteDistricts = (districtId) => async (dispatch) => {
	try {
		const response = await deleteDistrictById(districtId);
		if (response.data.success) {
			dispatch(deleteDistrict(response.data));
			dispatch(saveSucceeded());
		} else {
			dispatch(saveFailed(response.data.message));
		}
	} catch (error) {
		dispatch(saveFailed(error.message));
		console.error('There was an error editing the district:', error);
	}
};

export const addDistrictEmail = (districtEmailData) => async (dispatch) => {
	try {
		dispatch(saveStarted());
		const response = await addDistrictsEmail({
			options: { data: districtEmailData },
		});
		if (response.data.success) {
			dispatch(districtEmailAdded(response.data.data));
			dispatch(saveSucceeded());
		} else {
			dispatch(saveFailed(response.data.message));
		}
	} catch (error) {
		console.error('There was an error adding the district:', error);
	}
};

export const loadDistrictEmails = (districtId) => async (dispatch) => {
	dispatch(districtEmailRequested());

	try {
		const response = await getAllDistrictEmail(districtId);
		dispatch(districtEmailReceived(response.data.data));
	} catch (error) {
		dispatch(districtEmailRequestFailed(error.message));
	}
};
export const getDistrictsEmail = createSelector(
	(state) => state.districts.districtEmailList,
	(districts) => districts
);

export const deleteDistrictsEmail = (districtemailid) => async (dispatch) => {
	dispatch(saveStarted());
	try {
		const response = await deleteDistrictEmailById(districtemailid);
		if (response.data.success) {
			dispatch(deleteDistrictEmail(response.data));
			dispatch(saveSucceeded());
		} else {
			dispatch(saveFailed(response.data.message));
		}
	} catch (error) {
		dispatch(saveFailed(error.message));
		console.error('There was an error editing the district:', error);
	}
};

export const editDistrictEmailReducer =
	(districtEmailData) => async (dispatch) => {
		dispatch(saveStarted());

		try {
			const response = await editDistrictEmail({
				options: { data: districtEmailData },
			});
			if (response.data.success) {
				dispatch(saveSucceeded());
				dispatch(districtEmailEdited(response.data));
			} else {
				dispatch(saveFailed(response.data.message));
			}
		} catch (error) {
			dispatch(saveFailed(error.message));
			console.error('There was an error editing the district email:', error);
		}
	};

export const { resetSaveStatus } = slice.actions;
