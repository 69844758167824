import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AdminModal } from '../../../components/admin/admin-modal';
import AdminTextInput from '../../../components/admin/admin-text-input';
import AdminModalColorPicker from '../../../components/admin/admin-modal-color-picker.js';
import MediaUploader from '../../../components/media/MediaUploader';

export function AddDistrictModal({ show, onClose, handleAddDistrict, model, title }) {

    const [formData, setFormData] = useState(model);
    const [selectedColor, setSelectedColor] = useState(model.hexcolor);
    const [validation, setValidation] = useState({
        name: true,
        path: true,
        pathError:''
    });

    const handleSave = () => {
        var isValid = true;
        var validate = {
            name: true,
            path: true,
            pathError: ''
        };
        if (formData.name == null || formData.name == '') {
            validate.name = false;
            isValid = false;
        }
        if (formData.path == null || formData.path == '') {
            validate.path = false;
            isValid = false;
            validate.pathError = 'District URL is required';
        } else if ((/\W|_/g).test(formData.path) == true || formData.path.indexOf('/') >= 0) {
            validate.path = false;
            isValid = false;
            validate.pathError = 'No slashes, spaces, or special characters allowed in path';
        }
        setValidation({ ...validation, ...validate });
        if (isValid == false) { return; }
        handleAddDistrict(formData);
        onClose(); // Close the modal    
    };

    useEffect(() => {
        setFormData(model);
        setSelectedColor(model.hexcolor);
    }, [model]);

    useEffect(() => {
        setFormData({ ...formData, hexcolor: selectedColor });
    }, [selectedColor]);

    if (!show) {
        return null; // Don't render the modal if it's not being shown
    }
    if (!title) { title = 'Add District'; }

    return (
        <AdminModal
            modalTitle="Add District"
            show={show}
            onClose={onClose}
            onSave={handleSave}
        >
            <div className="admin-modal-row full">
                <div className="admin-modal-cell full">
                    <AdminTextInput
                        id="district-name"
                        label="District Name"
                        isRequired={true}
                        value={formData.name}
                        validated={validation.name }
                        onChange={(value) => {
                            setFormData({ ...formData, name: value });
                            setValidation({ ...validation, name: value != '' });
                        }}
                        requiredText="District Name is required"
                    />
                </div>
            </div>
            <div className="admin-modal-row full">
                <div className="admin-modal-cell full">
                    <AdminTextInput
                        id="district-url"
                        label="District URL"
                        isRequired={true}
                        value={formData.path}
                        validated={validation.path}
                        onChange={(value) => {
                            setFormData({ ...formData, path: value });
                            setValidation({ ...validation, path: value != '' });
                        }}
                        requiredText={validation.pathError }
                    />
                </div>
            </div>
            <div className="admin-modal-row">
                <div className="admin-modal-cell">
                    <AdminModalColorPicker
                        onColorChange={setSelectedColor}
                        selectedColor={selectedColor}
                        label="Main School Color"
                    />
                </div>
                <div className="admin-modal-cell">
                    <label className="admin-default-label admin-legend-label">School Logo</label>
                    <MediaUploader
                        imageOnly={true}
                        allowGallery={false}
                        forceCrop={false}
                        mediaItems={(formData.schoolLogo != null ? [formData.schoolLogo] : [])}
                        saveMediaItems={(mediaItems) => setFormData({
                            ...formData,
                            schoolLogo: mediaItems[0],
                            mediaItemId: mediaItems[0] != null ? mediaItems[0].id : null
                        })}
                    />
                </div>
            </div>
        </AdminModal>);
}

AddDistrictModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    handleAddDistrict: PropTypes.func.isRequired,
    model: PropTypes.object,
    title: PropTypes.string
};