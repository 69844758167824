import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AdminModal } from '../../../components/admin/admin-modal';

export function AddManufacturerModal({ show, onClose, handleAddManufacturer }) {
	const [manufacturerName, setManufacturerName] = useState('');
	const [errors, setErrors] = useState('');
	const [displayError, setDisplayError] = useState('');
	const inputRef = useRef(null);
	const handleSave = () => {
		if (!manufacturerName) {
			setErrors('Manufacturer Name must be valid.');
			return;
		}
		setErrors('');
		//handleAddManufacturer({ name: manufacturerName }, onClose, (err) => {
		//	setDisplayError(err);
		//});

		handleAddManufacturer({ name: manufacturerName },() => {
				setManufacturerName('');
				onClose();
			},
			(err) => {
				setDisplayError(err);
			}
		);

	};

	const onCancel = () => {
		setManufacturerName('');
		setErrors('');
		onClose();
	}
	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, [show]);

	if (!show) {
		return null; // Don't render the modal if it's not being shown
	}

	return (
		<AdminModal
			show={true}
			onClose={onCancel}
			onSave={handleSave}
			modalTitle="Add Manufacturer"
			displayError={displayError}
			onHideError={() => { setDisplayError(''); }}
			label="Manufacturer Name"
		>
			<div className="admin-modal-row full">
				<div className="admin-modal-cell">
					<div
						className={
							'admin-default-input-text-wrap' +
							(errors ? ' input-validation-error' : '')
						}
					>
						<label className="admin-default-label">Manufacturer</label>
						<input
							className="admin-default-input-text"
							type="text"
							id="manufacturer-name"
							value={manufacturerName}
							onChange={(e) => setManufacturerName(e.target.value)}
							ref={inputRef}
						/>
						{errors && <p className="validation-error-message">{errors}</p>}
					</div>
				</div>
			</div>
		</AdminModal>
	);
}

AddManufacturerModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	handleAddManufacturer: PropTypes.func.isRequired,
};
