import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'district',
    initialState: {
        updated: 0
    },
    reducers: {
        updatedDistrict: (state) => {
            state.updated += 1;
        }
    }
});

export const { updatedDistrict } = slice.actions;
export default slice.reducer;

export const selectUpdated = (state) => state.district.updated;

