import React from 'react';
import { updateTitle } from '../../helpers/titleHelper';
export default function PrivacyPolicy() {
    updateTitle('Privacy Polcy');
    return (
        <div className="privacy-policy">
            <h1>Privacy Policy</h1>
            <p>
                We at Facility Standard have created this privacy policy because we know that you care about how information you provide to us is used and shared. This Privacy Policy applies to our information collection and use practices: (i) online when you visit our website, without limitation, facilitystandard.com; and (ii) offline when you provide information to us.
            </p>
            <h2>
                Acceptance of Terms
            </h2>
            <p>
                By visiting our Website, you are agreeing to the terms of this Privacy Policy and the accompanying Terms of Use, both of which govern your use of the Websites. By providing us information offline, you are also agreeing to the terms of this Privacy Policy.
            </p>
            <h2>
                The Information We Collect
            </h2>
            <p>
                In the course of operating the Website and/or interacting with you, we will collect (and/or receive) the following types of information.
            </p>
            <h3>
                1. Personal Information.
            </h3>
            <p>
                When you sign up to receive a response to request for quote, respond to a survey, apply for a grant, or complete any form-like submissions, you may be required to provide us with personal information about yourself and/or your organization, such as your name, organization’s name, physical address, email address, and/or phone number. We do not collect any personal information from Visitors when they use the Website unless they provide such information voluntarily, such as by registering, submitting a form, or sending us an email or signing up for a newsletter. All information we collect and/or receive under this section is collectively called “Personal Information.”
            </p>
            <h3>
                2. Other Information.
            </h3>
            <p>
                In addition to the information noted above, we may collect additional information (collectively, the “Other Information”). Such Other Information may include: From You. Additional information about yourself, your company or your project that you voluntarily provide to us.
            </p>
            <ul>
                <li>
                    From Your Activity. Information that we automatically collect when you use the Website, including, without limitation:
                    <ul>
                        <li>
                            IP addresses, which may consist of a static or dynamic IP address and will sometimes point to a specific identifiable computer or device; browser type and language; referring and exit pages and URLs; date and time; amount of time spent on particular pages; what sections of the Websites you visit; and similar data; and
                        </li>
                        <li>
                            Information about your device, including the type of device; universally unique ID (“UUID”); advertising identifier (“IDFA”); MAC address; operating system and version (e.g., iOS, Android or Windows); carrier and country location; hardware and processor information (e.g., storage, chip speed, camera resolution, NFC enabled); network type (WiFi, 3G, 4G, LTE); and similar data.
                        </li>
                    </ul>
                </li>
                <li>From Cookies. Information that we collect using “cookie” technology. Cookies are small packets of data that a website stores on your computer’s or mobile device’s hard drive so that your computer will “remember” information about your visit. We may use both session cookies (which expire once you close your web browser) and persistent cookies (which stay on your computer until you delete them) to help us collect Other Information and to enhance your experience using the Websites. If you do not want us to place a cookie on your hard drive, you may be able to turn that feature off on your computer or mobile device. Please consult your Internet browser’s documentation for information on how to do this and how to delete persistent cookies. However, if you decide not to accept cookies from us, the Websites may not function properly.</li>
                <li>Third-Party Analytics. We use third-party analytics services (such as Google Analytics) to evaluate your use of the Websites, compile reports on activity, collect demographic data, analyze performance metrics, and collect and evaluate other information relating to the Websites and mobile and Internet usage. These third parties use cookies and other technologies to help analyze and provide us the data. By accessing and using the Websites, you consent to the processing of data about you by these analytics providers in the manner and for the purposes set out in this Privacy Policy.</li>
                <li>For more information on Google Analytics, including how to opt out from certain data collection, please visit https://www.google.com/analytics. Please be advised that if you opt out of any service, you may not be able to use the full functionality of the Websites.</li>
                <li>From Other Sources. We also may collect or receive information from third parties, such as Facebook and/or other third-party social media sites.</li>
            </ul>

            <h3>
                External Websites
            </h3>
            <p>
                The Website may contain links to third-party websites. We have no control over the privacy practices or the content of any of our business partners, advertisers, sponsors, or other websites to which we provide links. As such, we are not responsible for the content or the privacy policies of those third-party websites. You should check the applicable third-party privacy policy and terms of use when visiting any other websites.
            </p>

            <p>
                Microsoft serves as Facility Standard’s email service provider. Please visit their legal policy to learn more about opt-outs and data collection: Policy
            </p>
            <h3>
                Changes to This Privacy Policy
            </h3>

            <p>
                This Privacy Policy is effective as of the date stated at the top of this Privacy Policy. We may change this Privacy Policy from time to time. By accessing the Website and/or using our services after we make any such changes to this Privacy Policy, you are deemed to have accepted such changes. Please be aware that, to the extent permitted by applicable law, our use of the Information is governed by the Privacy Policy in effect at the time we collect the Information. Please refer back to this Privacy Policy on a regular basis.
            </p>
            <h3>How to Contact Us</h3>
            <p>
                If you have questions about this Privacy Policy, please contact us via email at <a href="mailto:support@facilitystandard.com">support@facilitystandard.com</a>&nbsp;
                with “Privacy Policy” in the subject line.
            </p>
        </div>
    );
}
