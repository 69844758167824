import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Loader from '../../../components/Loader';
import { AdminModal } from '../../../components/admin/admin-modal.js';
import AdminTextInput from '../../../components/admin/admin-text-input';
import {
	ValidateEmail,
	ValidateHasValue,
} from '../../../helpers/validationHelper';
import PropTypes from 'prop-types';
import {
	getAllUsers,
	updateUser,
	getDistrictUser,
	deleteUser,
} from '../../../api/districtuserapi';
import { useDispatch } from 'react-redux';
import { showLoading, hideLoading } from '../../../store/loading-reducer';
import { DateTimeForUserTimeZone } from '../../../helpers/DateTimeFormatter';
import { handleImpersonation } from '../../../store/district-account-reducer.js';
import { controlBannerWithButtonAndMessage } from '../../../store/layout-banner-reducer.js';
import { getAll } from '../../../api/cartapi';
import { loadAllProducts } from '../../../store/myproject-reducer.js';
import { getById } from '../../../api/districtapi';

function TableData({ updateTable, onUpdated, onEditUser, onRemoveUser }) {
	const [loading, setLoading] = useState(true);
	const [users, setUsers] = useState([]); // [user, setUser]
	const { districtId } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const canImpersonate = sessionStorage.getItem('canImpersonate');
	useEffect(() => {
		if (updateTable) {
			setLoading(true);
			getAllUsers(districtId).then((response) => {
				if (response.data.success) {
					setLoading(false);
					setUsers(response.data.data);
					onUpdated();
				}
			});
		}
	}, [updateTable]);
	const setActiveDistrict = (districtId) => {
		const obj = {
			id: '',
			name: '',
			color: '',
			logo: '',
		};
		getById(districtId).then((response) => {
			if (response.data.success) {
				var district = response.data.data;
				obj.id = district.id;
				obj.name = district.name;
				obj.color = district.hexColor;
				obj.logo = district.schoolLogo.previewsrc;
				if (district != null) {
					localStorage.setItem('district', JSON.stringify(obj));
					localStorage.setItem('districtId', district.id);
					navigate('/products', { replace: true });
				}
			}
		});
	};

	const onClickHandleImpersonation = (userId, name, email) => {
		const impersonationDetails = {
			isImpersonating: true,
			userImpersonatingID: userId,
			userImpersonatingName: name,
			originalURL: location.pathname,
			email: email,
			message: `You are currently impersonating ${email}`,
		};

		dispatch(handleImpersonation(impersonationDetails));
		sessionStorage.setItem(
			'impersonationDetails',
			JSON.stringify(impersonationDetails)
		);
		dispatch(
			controlBannerWithButtonAndMessage({
				message: `You are currently impersonating ${email}`,
				isVisible: true,
				isButtonShown: true,
			})
		);
		getAll(userId).then((response) => {
			if (response.data.success) {
				dispatch(loadAllProducts(response.data.data));
				setActiveDistrict(districtId);
			}
		});
	};

	return loading ? (
		<tr>
			<td colSpan="9">
				<Loader />
			</td>
		</tr>
	) : (
		<>
			{users == null || users.length < 1 ? (
				<tr>
					<td colSpan="7">No users assigned to district</td>
				</tr>
			) : (
				users.map((user, idx) => (
					<tr key={'user_' + idx}>
						<td>{user.fullName}</td>
						<td>{user.email}</td>
						<td>
							{user.lastLogin == null
								? '--'
								: DateTimeForUserTimeZone(user.lastLogin)}
						</td>
						<td>{user.wishListItems}</td>
						<td>
							{user.quotes} ({user.quoteItems})
						</td>
						<td>{user.isApprover ? 'Approver' : 'User'}</td>
						{/* ToDo: Add in impersonate user functionality */}
						<td className="table-action-cell">
							{canImpersonate === 'true' ? (
								<button
									className="admin-table-action"
									data-id={user.id}
									onClick={() => {
										onClickHandleImpersonation(user.id, user.fullName, user.email);
									}}
									title="edit user's live project"
								>
									<span className="material-symbols-rounded">
										supervised_user_circle
									</span>
								</button>
							) : (
								''
							)}
						</td>
						<td className="table-action-cell">
							<button
								className="admin-table-action"
								data-id={user.id}
								onClick={(e) => onEditUser(e.target.dataset.id)}
								title="edit"
							>
								<span className="material-symbols-rounded">edit</span>
							</button>
						</td>
						<td className="table-action-cell">
							<button
								className="admin-table-action"
								data-id={user.id}
								onClick={() => onRemoveUser(user.id, user.email)}
								title="delete"
							>
								<span className="material-symbols-rounded">delete</span>
							</button>
						</td>
					</tr>
				))
			)}
		</>
	);
}

TableData.propTypes = {
	updateTable: PropTypes.bool,
	onUpdated: PropTypes.func,
	onEditUser: PropTypes.func,
	onRemoveUser: PropTypes.func,
};

const newUser = {
	id: null,
	isApprover: false,
	fullName: '',
	email: '',
	password: '',
};

function AdminModalDetails({ userId, show, onClose, onSave }) {
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState(newUser);
	const [errorMessage, setErrorMessage] = useState('');
	const dispatch = useDispatch();
	const { districtId } = useParams();

	useEffect(() => {
		if (show) document.getElementById('user_fullname').focus();
	}, [show]);

	useEffect(() => {
		if (userId == null) {
			setLoading(false);
			setUser(newUser);
		} else {
			setLoading(true);
			getDistrictUser(districtId, userId)
				.then((response) => {
					if (response.data.success) setUser(response.data.data);
					else setErrorMessage(response.data.message);
					setLoading(false);
				})
				.catch(() => {
					setLoading(false);
					setErrorMessage('An unknown error occurred getting user data.');
				});
		}
	}, [userId]);

	const handleSave = () => {
		dispatch(showLoading());
		updateUser(districtId, {
			...user, domainName: window.location.origin}).then((response) => {
			dispatch(hideLoading());
			if (response.data.success) {
				onSave(true);
				setUser(newUser);
			} else setErrorMessage(response.data.message);
		});
	};

	const handleClose = () => {
		setUser(newUser);
		onClose();
	};

	return (
		<AdminModal
			show={show}
			onClose={handleClose}
			onSave={handleSave}
			displayError={errorMessage}
			modalTitle="Add / Edit User"
			adminModalClass="wide"
		>
			{loading ? (
				<Loader />
			) : (
				<>
					<div className="admin-modal-row full">
						<div className="admin-modal-cell wide">
							<label className="admin-inline-label">
								<input
									type="checkbox"
									className="admin-checkbox"
									checked={user.isApprover}
									onChange={(e) => {
										setUser({ ...user, isApprover: e.target.checked });
									}}
								/>
								<span className="admin-inline-label-text">Approver</span>
							</label>
						</div>
					</div>
					<div className="admin-modal-row full">
						<div className="admin-modal-cell">
							<AdminTextInput
								id="user_fullname"
								label="Name (First and Last)"
								onValidation={(value) => ValidateHasValue(value)}
								value={user.fullName}
								onChange={(value) => {
									setUser({ ...user, fullName: value });
								}}
							/>
						</div>
						<div className="admin-modal-cell wide">
							<AdminTextInput
								label="Email"
								isRequired={true}
								value={user.email}
								onChange={(value) => setUser({ ...user, email: value })}
								onValidation={(value) => ValidateEmail(value)}
							/>
						</div>
					</div>
					<div className="admin-modal-row full">
						<div className="admin-modal-cell wide">
							<AdminTextInput
								label="Reset Password"
								placeholder="Leave blank to keep current password"
								isRequired={false}
								onChange={(value) => setUser({ ...user, password: value })}
							/>
						</div>
					</div>
				</>
			)}
		</AdminModal>
	);
}

AdminModalDetails.propTypes = {
	userId: PropTypes.string,
	show: PropTypes.bool,
	onClose: PropTypes.func,
	onSave: PropTypes.func,
};

AdminModalDetails.defaultProps = {
	userId: 0,
	show: false,
};

export default function DistrictUsers() {
	const [loading, setLoading] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [userId, setUserId] = useState(null);
	const [showDelete, setShowDelete] = useState(null);

	const { districtId } = useParams();

	useEffect(() => {}, [loading]);

	const handleAddNewUser = () => {
		setUserId(null);
		setShowModal(true);
	};

	const handleEditUser = (userId) => {
		setUserId(userId);
		setShowModal(true);
	};

	const handleSaveUser = () => {
		setShowModal(false);
		setLoading(true);
	};

	const handleRemoveUser = (userId, email) => {
		setShowDelete({ email: email, userId: userId });
	};

	const handleConfirmRemoveUser = () => {
		var userId = showDelete.userId;
		setShowDelete(null);
		deleteUser(districtId, userId).then(() => {
			setLoading(true);
		});
	}

	return (
		<div className="admin-tab-content">
			{showDelete != null && 
				<div className="admin-modal-overlay">
					<div className="admin-modal">
						<button className="admin-modal-close" onClick={() => { setShowDelete(null); }}>
							<span className="material-symbols-rounded">close</span>
						</button>
						<h2 className="admin-modal-heading">Remove User</h2>
						<div className="admin-form-cell">
							<p className="admin-default-input-text-wrap">
								Are you sure you want to remove the user {showDelete.email } from this districts user roles?
							</p>
						</div>
						<div className="admin-modal-button-wrap">
							<button className="admin-modal-button" onClick={() => { setShowDelete(null); }}>
								Cancel
							</button>
							<button className="admin-form-button power red" onClick={handleConfirmRemoveUser}>
								Remove User
							</button>
							
						</div>
					</div>
				</div>
			}
			<div className="admin-button-wrap">
				<button className="admin-button" onClick={handleAddNewUser}>
					<span className="admin-button-text">Add User</span>
					<span
						className="admin-button-icon material-symbols-rounded"
						aria-hidden="true"
					>
						add
					</span>
				</button>
			</div>
			<div className="admin-table-wrap">
				<table className="admin-table">
					<thead>
						<tr>
							<th className="admin-table-name-cell">Name</th>
							<th className="admin-table-name-cell">Email</th>
							<th className="admin-table-name-cell">Last Login</th>
							<th className="admin-table-name-cell">Wishlist</th>
							<th className="admin-table-name-cell">Quotes</th>
							<th className="admin-table-name-cell">Roles</th>
							<th className="admin-table-action-cell">&nbsp;</th>
							<th className="admin-table-action-cell">&nbsp;</th>
							<th className="admin-table-action-cell">&nbsp;</th>
						</tr>
					</thead>
					<tbody>
						<TableData
							updateTable={loading}
							onUpdated={() => {
								setLoading(false);
							}}
							onEditUser={handleEditUser}
							onRemoveUser={handleRemoveUser}
						/>
					</tbody>
				</table>
			</div>
			<AdminModalDetails
				show={showModal}
				userId={userId}
				onClose={() => {
					setShowModal(false);
				}}
				onSave={handleSaveUser}
			/>
		</div>
	);
}
