export const checkForDistrictId = (navigate) => {
    if (!localStorage.getItem('districtId')) { navigate('/admin/districts'); } //redirect if user is not logged into a district
}

export const isDemoDistrict = () => {
    const districtJson = localStorage.getItem('district') ?? '';
    if (districtJson != '') {
        let district = JSON.parse(districtJson);
        return district.isDemo;
    }
    return false;
}

export const getDistrictPath = () => {
    const districtJson = localStorage.getItem('district') ?? '';
    if (districtJson != '') {
        let district = JSON.parse(districtJson);
        return district.path;
    }
    return '';
}