import React from 'react';
import PropTypes from 'prop-types';

export default function QuoteRequestSelect({ selectedId, onChange, id }) {
    const statusGroups = [
        {
            label: 'Open',
            statuses: [
                { value: -1, text: 'All Open Status Types' },
                { value: 0, text: 'Pending District Approval' },
                { value: 1, text: 'Reviewing' },
                { value: 2, text: 'Sent to Vendor for Quote' },
            ],
        },
        {
            label: 'Closed',
            statuses: [
                { value: -2, text: 'All Closed Status Types' },
                { value: 3, text: 'Completed' },
                { value: 4, text: 'Archived' },
            ],
        },
    ];

    return (
        <>
            <select className="admin-default-select" id={id} onChange={onChange} defaultValue={selectedId}>
               {/* <option value="-1">All Status Types</option>*/}
                {statusGroups.map((group, groupIndex) => (
                    <optgroup label={group.label} key={`group_${groupIndex}`}>
                        {group.statuses.map((status) => (
                            <option key={'status_' + status.value} value={status.value}>{status.text}</option>
                        ))}
                    </optgroup>
                ))}
            </select>
        </>
    );

}

QuoteRequestSelect.propTypes = {
    id: PropTypes.string,
    selectedId: PropTypes.number,
    onChange: PropTypes.func
}