import api from '../utils/api';

export const getByUrl = (urlVal) => {
  return api({
    url: '/api/districts/get-by-url/' + urlVal,
    method: 'GET'
  });
}

export const getAll = (config = {}) => {
    const { options } = config;
    return api({
        url: '/api/districts/get-all',
        method: 'GET',
        ...options,
    });
};
export const getById = (districtId, imageSize) => {
  if (imageSize == null) imageSize = 'NoCrop_1000X1000';
  return api({
    url: '/api/districts/get/' + districtId + '/' + imageSize,
    method: 'GET'
  });
}
export const edit = (config = {}) => {
    const { options } = config;
    return api({
        url: '/api/districts/edit',
        method: 'POST',
        ...options,
    });
};
export const add = (config = {}) => {
    const { options } = config;
    return api({
        url: '/api/districts/add',
        method: 'POST',
        ...options,
    });
};

export const deleteDistrictById = (id) => {
    return api({
        url: '/api/districts/delete/' + id,
        method: 'Delete'
    });
};


export const addDistrictsEmail = (config = {}) => {
    const { options } = config;
    return api({
        url: '/api/districtsemail/add',
        method: 'POST',
        ...options,
    });
};


export const getAllDistrictEmail = (id) => {
    return api({
        url: '/api/districtsemail/get-all/' + id,
        method: 'GET'
    });
};

export const deleteDistrictEmailById = (id) => {
    return api({
        url: '/api/districtsemail/delete/' + id,
        method: 'Delete'
    });
};

export const editDistrictEmail = (config = {}) => {
    const { options } = config;
    return api({
        url: '/api/districtsemail/edit',
        method: 'POST',
        ...options,
    });
};

export const updateDistrictPath = (id, path) => {
    return api({
        url: '/api/districts/update-path',
        method: 'POST',
        config: { data: { id: id, path: path } }
    });
};

export const getDistrictByPath = (path) => {
    return api({
        url: '/api/districts-path/get/' + encodeURI(path),
        method: 'GET'
    });
};

export const getDistrictByPathId = (id) => {
    return api({
        url: '/api/districts-path/by-id/' + id,
        method: 'GET'
    });
};