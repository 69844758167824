import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CurrencyInput from 'react-currency-input-field';

export default function AdminTextInput({
    id, value, placeholder, label, inputType,
    onChange, onValidation, isRequired, requiredText, validated
}) {
    const [showError, setShowError] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);
    const [viewPass, setViewPass] = useState(false);
   
    if (onValidation == null) { onValidation = () => { return true; }; }

    useEffect(() => {
        if (isRequired && hasChanged) setShowError(!onValidation(value));
    }, [value, hasChanged]);

    const handleOnChange = (e) => {
        setHasChanged(true);
        if (onChange) {
            if (isRequired) {
                if (!onValidation(e.target.value)) setShowError(true);
                else setShowError(false);
            }
            onChange(e.target.value);
        }
    }

    const handleCurrencyChange = (value) => {
        if (value && value.length <= 12) {
            const cleanedValue = value.replace(/[^\d.]/g, '');
            let [integerPart, decimalPart] = cleanedValue.split('.');
            if (integerPart.length > 8) {
                integerPart = integerPart.slice(0, 8);
            }
            if (decimalPart && decimalPart.length > 2) {
                decimalPart = decimalPart.slice(0, 2);
            }
            let truncatedValue = decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
            if (!truncatedValue) {
                truncatedValue = "$0";
            }
            handleOnChange({ target: { value: truncatedValue } });
        }
        else {
            handleOnChange({ target: { value: '$0' } });
        }
    };


    return (
        <div className={"admin-default-input-text-wrap" + (showError || validated === false ? " input-validation-error" : "")}>
            <label
                className="admin-default-label"
                htmlFor={id}
            >
                {label}{isRequired ? "*" : ""}
            </label>
            {inputType == 'currency' ?
                <CurrencyInput maxLength={12} 
                    className={"admin-default-input-text"}
                    id={id}
                    value={value}
                    placeholder={placeholder}
                    onValueChange={handleCurrencyChange}
                    decimalSeparator="."
                    groupSeparator=","
                    prefix="$"
                /> : 
                <>
                    <input
                        className={"admin-default-input-text"}
                        type={inputType == 'password' && viewPass == true ? 'text' : inputType }
                        id={id}
                        value={value}
                        placeholder={placeholder}
                        onInput={handleOnChange}
                        autoComplete='off'
                    />
                    {inputType == 'password' &&
                        <span className="admin-view-pass" onClick={() => { setViewPass(!viewPass); }}>
                            <span className="material-symbols-rounded">{viewPass ? 'visibility_off' : 'visibility'}</span>
                        </span>
                    }
                </>
            }
            
            {showError || validated === false ? <span className="validation-error-message">{requiredText}</span> : <></>}
        </div>
    );
}

AdminTextInput.propTypes = {
    id: PropTypes.string,
    value: PropTypes.node,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    inputType: PropTypes.string,
    onValidation: PropTypes.func,
    isRequired: PropTypes.bool,
    requiredText: PropTypes.string,
    validated: PropTypes.bool
}

AdminTextInput.defaultProps = {
    inputType: 'text',
    isRequired: false,
    validated: true
}
