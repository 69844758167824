import React from 'react';
import '../../pages/cart/Cart.css';
import PropTypes from 'prop-types';

const CartButton = ({message, action, cellClass}) => {
	return (
		<div>
			<div className={"icon-cta wishlist-cell " + cellClass} onClick={action}>
				<span className="cta-cell-wrap"><span className="cell-text">{message}</span></span>
			</div>
			{/* <a href="/broken" className="icon-cta delete-cell"><span className="wording">Delete</span><span className="mobile-icon"></span></a> */}
		</div>
	);
};
export default CartButton;

CartButton.propTypes = {
    message:PropTypes.string,
	action: PropTypes.func,
	cellClass: PropTypes.string
}