import api from '../utils/api';

export const getProductLines = (manufacturerid) => {
    return api({
        url: '/api/productlines/get-all/' + manufacturerid,
        method: 'GET',
    });
}

export const getProductLineWithProducts = (manufacturerid) => {
  return api({
    url: '/api/productlines/get-all-with-products/' + manufacturerid,
    method: 'GET',
  });
}

export const updateProductLine = (productLine) => {
  let url = '/api/productlines/';
  if (productLine.id == 0) url += 'add';
  else url += 'edit';
  return api.post(url, productLine);
}

export const copyProductLine = (id) => {
    return api({
        url: '/api/productlines/copy-product-line/' + id,
        method: 'GET',
    });
}

export const deleteProductLine = (id) => {
    return api({
        url: '/api/productlines/delete-product-line/' + id,
        method: 'GET',
    });
}

export const copyProduct = (id) => {
    return api({
        url: '/api/products/copy-product/' + id,
        method: 'GET',
    });
}

export const deleteProduct = (id) => {
    return api({
        url: '/api/products/delete-product/' + id,
        method: 'GET',
    });
}

export const updateProductOrder = (id, dir) => {
    return api({
        url: '/api/products/update-product-order/' + dir + '/' + id,
        method: 'GET',
    });
}

export const getProduct = (productid, imagesize, version) => {
  if (imagesize == null) imagesize = 'NoCrop_1000x1000';
  return api.get('/api/products/get/' + productid + '/' + imagesize + (version != null ? '/' + version : ''));
}

export const submitProduct = (product) => {
  let url = '/api/products/';
  if (product.id == 0) url += 'add';
    else url += 'edit';
    return api({
        url: url,
        method: 'POST',
        data: product       
    })
}

export const getOptionType = () => {
    return api({
        url: '/api/products/get-optiontype',
        method: 'GET',
    });
}

export const filterProductsByDistrict = (districtId, start, length, orderby, categoryIds) => {
    let url = '/api/products/filter-bydistrict/' + districtId + '/' + start + '/' + length + '/' + orderby;
    return api({
        url: url,
        method: 'POST',
        data: categoryIds
    })
}

export const getProductByDistrict = (districtId, productId) => {
    return api({
      url: '/api/products/get-bydistrict/' + districtId+ '/' + productId,
      method: 'GET',
    });
}

export const getrelatedproducts = (productId, districtId) => {
    return api({
        url: '/api/products/get-related-products/' + productId + '/' + districtId,
        method: 'GET',
    });
}

export const getHistory = (productId) => {
    return api({
        url: '/api/product-history/get/' + productId,
        method: 'GET',
    });
}

export const getIdBySlug = (slug) => {
    return api.get('/api/products/get-by-slug/' + slug);
}

export const getVimeoToken = ()=> {
    return api({
        url: '/api/media/getVimeoToken',
        method: 'GET',
    });
}
