import React, { useState } from 'react';
import PropTypes from 'prop-types';


export function DropDown({ children, onChange, defaultValue }) {
    var defaultChild = defaultValue != null && children ? children.filter(a => a.props.value == defaultValue) : null;
    const [value, setValue] = useState(defaultChild != null && defaultChild.length > 0 ? defaultChild[0].props.title : '');

    var listener = null;
    var parent = null;
    const changed = (option) => {
        setValue(option.props.title);
        onChange(option.props.value);
        if (listener != null) {
            document.body.removeEventListener('click', listener);
        }
        if (parent != null) {
            parent.classList.remove('expanded');
        }
        
    };

    const toggleDropDown = (e) => {
        parent = e.target.parentNode;
        if (parent.className.indexOf('expanded') < 0) {
            listener = document.body.addEventListener('click', (e2) => {
                var node = e2.target;
                while (node != null) {
                    if (node.className && node.className.indexOf('choices') >= 0) {
                        return;
                    }
                    node = node.parentNode;
                }
                parent.classList.remove('expanded');
                document.body.removeEventListener('click', listener);
            });
            parent.classList.add('expanded');
        } else {
            parent.classList.remove('expanded');
            document.body.removeEventListener('click', listener);
        }
    }
    return (
        <div className="choices">
            <div className="choices__inner">
                <div className="button choices__list--single" onClick={toggleDropDown}>{value}</div>
                <div className="content choices__list--dropdown">
                    {children ? children.map((option, i) => (
                        <div key={i} className="choices__item" onClick={() => { changed(option) }}>{option.props.title}</div>
                    )) : <></>}
                </div>
            </div>
        </div>
    )
}

DropDown.propTypes = {
    children: PropTypes.node.isRequired,
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.node
};

export function Option(title, value, onClick) {
    return (<div data-value={value} className="choices__item" onClick={onClick}>{title}</div>);
}

Option.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.node.isRequired,
    onClick: PropTypes.func
};