import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AdminModal } from '../../../components/admin/admin-modal';

export function AddEditProudctLineModal({ show, onClose, handleAddProductLine, manufacturerId, isEdit = false, existingProductLine = null }) {
	const [productLineName, setProductLIneName] = useState('');
	
	const [errors, setErrors] = useState('');
	const [displayError, setDisplayError] = useState('');
	const inputRef = useRef(null);
	const handleSave = () => {
		if (!productLineName) {
			setErrors('Product Line must be valid.');
			return;
		}
		setErrors('');
		//handleAddProductLine({ name: productLineName, id: (isEdit && existingProductLine ? existingProductLine.id :0 ), manufacturerId: manufacturerId }, onClose, (err) => {
		//	setDisplayError(err);
		//	if (!err) {
		//		setProductLIneName('');
		//		setErrors('');
		//		onClose();
		//	}
		//});

		handleAddProductLine({ name: productLineName, id: (isEdit && existingProductLine ? existingProductLine.id : 0), manufacturerId: manufacturerId }, () => {
			setProductLIneName('');
			onClose();
		},
			(err) => {
				setDisplayError(err);
			}
		);

	};

	const onCancel = () => {

		if (isEdit && existingProductLine) {
			setProductLIneName(existingProductLine.name);
		} else {
			setProductLIneName('');
		}
		setErrors('');
		onClose();
	}

	useEffect(() => {
		if (isEdit && existingProductLine) {
			setProductLIneName(existingProductLine.name);
		}
	}, [isEdit, existingProductLine]);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, [show]);

	if (!show) {
		return null; // Don't render the modal if it's not being shown
	}

	return (
		<AdminModal
			show={true}
			onClose={onCancel}
			onSave={handleSave}
			modalTitle={isEdit ? 'Edit Product Line' : 'Add Product Line'}
			displayError={displayError}
			onHideError={() => { setDisplayError(''); }}
			label="Product Line"
		>
			<div className="admin-modal-row full">
				<div className="admin-modal-cell">
					<div
						className={
							'admin-default-input-text-wrap' +
							(errors ? ' input-validation-error' : '')
						}
					>
						<label className="admin-default-label">Product Line</label>
						<input
							className="admin-default-input-text"
							type="text"
							id="productline-name"
							value={productLineName}
							onChange={(e) => setProductLIneName(e.target.value)}
							ref={inputRef}
						/>
						{errors && <p className="validation-error-message">{errors}</p>}
					</div>
				</div>
			</div>
		</AdminModal>
	);
}

AddEditProudctLineModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	handleAddProductLine: PropTypes.func.isRequired,
	manufacturerId: PropTypes.number.isRequired,
	isEdit: PropTypes.bool.isRequired,
	existingProductLine: PropTypes.object
};
