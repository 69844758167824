import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import "../../styles/slick-them.css";
import "../../styles/Slick.css";
import PropTypes from 'prop-types';
import { getrelatedproducts } from '../../api/productapi';
import { formatUSD} from '../../helpers/formatterHelper';
import ProductImageSlider from '../../components/common/ProductImageSlider';
import { Link } from "react-router-dom";
export function ProductSlider({ productId }) {
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    useEffect(() => {
        getRelatedProducts(productId);
    }, []);

    const getRelatedProducts = (productId) => {
        const districtID = localStorage.getItem('districtId');
        getrelatedproducts(productId, districtID)
            .then((result) => {
                if (result.data.success == true) {
                    setProducts(result.data.data);
          
                } else {
                    console.log('error when getting related products');
                }

            })
            .catch((err) => {
                console.log(err);
                setProducts([]);
            });
    };

    const handleProductClick = (product) => {
        navigate("/products/" + product.slug);
    };

    var settings = {
        infinite: false,
        speed: 500,
        // slidesToShow: 4.5,
        // slidesToScroll: 1,
        initialSlide: 0,

        // autoWidth: true,
        autoWidth: true,
        dots: false,
        arrows: true,
        slidesToShow: 4.29,
        slidesToScroll: 4,
        // centerMode: true,
        centerPadding: "40px",
        // cssEase: "linear",
        // autoplay: true,
        // autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3.5,
                    slidesToScroll: 1,
                    dots: false,
                    autoplay: true,
                    cssEase: "linear",
                    autoplaySpeed: 2000,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    dots: false,
                    autoplay: true,
                    cssEase: "linear",
                    autoplaySpeed: 2000,
                },
            },
            {
                breakpoint: 900,
                settings: {
                    arrows: false,
                    slidesToShow: 2.5,
                    slidesToScroll: 1,
                    dots: false,
                    cssEase: "linear",
                    autoplay: true,
                    autoplaySpeed: 2000,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    arrows: false,
                    slidesToShow: 1.8,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    cssEase: "linear",
                },
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    slidesToShow: 1.75,
                    slidesToScroll: 1,
                },
            },
        ],
    };



    return (
        <>      
            <Slider {...settings}>    
                {products.length > 0 ? (                   
                    products
                        .filter((product) => product.details && !product?.photo?.includes("/static/media/"))
                        .map((p) => (
                            <div key={p.id}> {/* 'key' should be an attribute, not a child element */}
                                <Link to={"/products/" + p.slug} className="product-gallery-item bought-together-tile product-slick-slider" onClick={() => handleProductClick(p)}>
                                    <span className="bought-together-tile-inner">
                                        <ProductImageSlider
                                            photo={p.photo}
                                            photoAlt={p.photoAlt ?? p.details.name}
                                            styles="nav-block-cover-thumb"
                                        />                                     
                                        <span className="bought-together-content">
                                            <span className="bought-together-title-amount-wrap">
                                                <span className="bought-together-tile-title">{p.details.name}</span>
                                                <span className="bought-together-amount">
                                                    {formatUSD(
                                                        p.details.price -
                                                        (p.details.discount > 0
                                                            ? p.details.price * (p.details.discount / 100)
                                                            : 0)
                                                    )}
                                                </span>
                                            </span>
                                            <span className="bought-together-color-wrap">

                                                {p.options &&
                                                    p.options.length > 0 &&
                                                    p.options.filter((a) => a.type == 2).length > 0 &&
                                                    p.options
                                                        .filter((a) => a.type == 2)[0]
                                                        .items.filter((a) => a.color)
                                                        .map((a) => (
                                                            <span
                                                                key={p.id + '_' + a.id}
                                                                className="color-dots"
                                                                title={a.color.name}
                                                                style={{ background: a.color.hex }}
                                                            >
                                                                {a.color.colorImage ? (
                                                                    <img
                                                                        src={a.color.colorImage.previewsrc}
                                                                        style={{ height: '100%' }}
                                                                        alt={a.color.name}
                                                                    />
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </span>
                                                        ))}
                                               
                                            </span>
                                        </span>
                                    </span>
                                </Link>
                            </div>
                        ))
                ) : (
                        <div className="no-results">No Results Found</div>
                )}
            </Slider>
        </>
    );
}

ProductSlider.propTypes = {
    productId: PropTypes.number.isRequired
};
