import React, { useEffect, useState } from 'react';
import { getDistrictQuote } from '../../api/districtquoteapi.js';
import { showBanner, hideBanner, setMessage, showBannerWithButton, showTypicalCancelBannerButton } from '../../store/layout-banner-reducer';
import { useDispatch } from 'react-redux';

export default function Banner(update) {
    const dispatch = useDispatch();
    const [checkBanner, setCheckBanner] = useState(false);
    const [isPreview, setIsPreview] = useState(false);
    useEffect(() => {
        if (checkBanner == false) {
            setCheckBanner(true);
            const quoteId = localStorage.getItem('editQuoteId');
            if (quoteId != null && quoteId > 0) {
                getDistrictQuote(quoteId).then(response => {
                    var getuser = response?.data?.data?.user;
                    var quote = response?.data?.data?.quote;
                    if (
                        getuser != undefined &&
                        getuser != null &&
                        quote.status === 1
                    ) {
                        dispatch(
                            setMessage('You are editing the quote request "' + (quote.label ?? 'My Project') + '" for ' + quote.email)
                        );
                        dispatch(showBanner());
                        dispatch(showBannerWithButton());
                    }
                });
                return;
            }

            let typicalDetailList = JSON.parse(localStorage.getItem('editTypicalLayout')) || [];
            if (localStorage.typicallayout === 'true' && typicalDetailList.id >= 0) {
                dispatch(setMessage('You are currently editing a typical layout'));
                dispatch(showBanner());
                dispatch(showTypicalCancelBannerButton());
                return;
            }
            const data = sessionStorage.getItem('impersonationDetails');
            if (data) {
                var imp = JSON.parse(data);
                dispatch(setMessage('You are currently impersonating ' + imp.email));
                dispatch(showBanner());
                dispatch(showTypicalCancelBannerButton());
                return;
            }
        }
        
    }, [update]);

    useEffect(() => {
        if (window.location.pathname.indexOf('products/') >= 0 && window.location.pathname.indexOf('/preview') > 0) {
            setIsPreview(true);
            dispatch(setMessage('Viewing product details.'));
            dispatch(showBanner());
            return;
        } else if (isPreview == true) {
            dispatch(hideBanner());
            setIsPreview(false);
            return;
        }
    }, []);
    return <></>
}