import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteConfrimationModal } from '../../../components/admin/DeleteConfirmationModal'
import { getSurveyList, deleteSurvey } from '../../../store/survey/actions';

export default function DistrictSurvey() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { districtId } = useParams();
    const [filter, setFilter] = useState({ sort: 0, districtId: districtId });
    const { surveyList } = useSelector(state => state.surveyList);
    const defaultSurveyObj = { id: 0, name: '', Deleted: false }
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [surveyModel, setSurveyModel] = useState(defaultSurveyObj);

    useEffect(() => {
        dispatch(getSurveyList(filter));
    }, [filter]);

    const handleDeleteTrigger = (id, name) => {
        setSurveyModel({ id: id, title: name });
        setShowDeleteModal(true);
    }

    const handleDeleteSurvey = (data) => {
        dispatch(deleteSurvey(data.id));
    }
    const handleEditSurvey = (id) => {
        navigate('/admin/districts/edit/' + districtId + '/survey/' + id)
    };

    return (
        <>

            <DeleteConfrimationModal
                show={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                handleDeleteEvent={handleDeleteSurvey}
                model={surveyModel}
            />

            <div className="admin-tab-content">
                <button className="admin-button" onClick={() => {
                    navigate(`/admin/districts/edit/${districtId}/survey/create`);
                }}>
                    <span className="admin-button-text">Add Survey</span>
                    <span className="admin-button-icon material-symbols-rounded" aria-hidden="true">add</span>
                </button>
                <div className="admin-table-wrap">
                    <table className="admin-table">
                        <thead>
                            <tr>
                                <th onClick={() => setFilter({ ...filter, sort: filter.sort == 0 ? 1 : 0 })} className={filter.sort == 0 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort == 1 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>Title</th>
                                <th onClick={() => setFilter({ ...filter, sort: filter.sort == 2 ? 3 : 2 })} className={filter.sort == 2 ? 'admin-table-name-cell sortable sorted-dn' : (filter.sort == 3 ? 'admin-table-name-cell sortable sorted-up' : 'admin-table-name-cell sortable')}>Status</th>
                                <th className="admin-table-name-cell">Link</th>
                                <th className="admin-table-action-cell">&nbsp;</th>
                                <th className="admin-table-action-cell">&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {surveyList && surveyList.length > 0 ? (
                                surveyList.map(item =>
                                    <tr key={'survey_' + item.id}>
                                        <td>{item.title}</td>
                                        <td>{(item.isActive == false ? "Active" : "InActive")}</td>
                                        <td>
                                            <a href={item.links} target="_blank" rel="noopener noreferrer">{item.links}</a>
                                        </td>
                                        <td className="table-action-cell">
                                            <button
                                                className="admin-table-action"
                                                title="edit"
                                                data-id={item.id}
                                                onClick={(e) => handleEditSurvey(e.target.dataset.id)}
                                            >
                                                <span className="material-symbols-rounded">edit</span>
                                            </button>
                                        </td>
                                        <td className="table-action-cell">
                                            <button
                                                className="admin-table-action"
                                                title="delete"
                                                data-id={item.id}
                                                onClick={(e) => handleDeleteTrigger(e.target.dataset.id, item.title)}
                                            >
                                                <span className="material-symbols-rounded">delete</span>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            ) : ""}

                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}