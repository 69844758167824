import api from '../utils/api';

export const getUsers = () => {
  return api({
    url: '/api/adminusers/get-all',
    method: 'GET',
  })
}

export const getUserDetails = (userId) => {
  return api({
    url: '/api/adminusers/get/' + userId,
    method: 'GET'
  })
}

export const saveUserDetails = (user) => {
  let url = '/api/adminusers/';
  if (user.id === null) url += 'add';
  else url += 'edit';
  return api.post(url, user);
}

export const getLoginUserDetails = () => {
    return api({
        url: '/api/adminusers/get-login-user/' + localStorage.getItem('districtId'),
        method: 'GET'
    })
}

export const deleteUser = (id) => {
    return api({
        url: '/api/adminusers/delete/' + id,
        method: 'DELETE',
    })
}