import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Footer from '../components/footer';
import '../styles/account-layout.css';
import { defaultDistrict } from '../store/default-district';
import { useNavigate } from 'react-router-dom';
import MainLogo from '../assets/Facility-Standard-Main-Logo.png';
export default function DefaultLayout({ children }) {
    const navigate = useNavigate();
    const [loaded, setLoading] = useState(false);
    const [district, setDistrict] = useState({ isDemo: false });

    useEffect(() => {
        if (loaded == false) {
            const districtJson = localStorage.getItem('district') ?? '';
            if (districtJson != '') {
                setDistrict(JSON.parse(districtJson));
            } else {
                setDistrict(defaultDistrict);
            }
            setLoading(true);
        }
    }, [loaded]);
    return (
        <div className="page-content">
            <div className="login-page-wrap" style={{ background: district.color }}>
                <div className="login-form-wrap">
                    <div className="login-form-inner">
                        <div className="login-form-row">
                            <div className="login-logo-cell">
                                <img className="login-form-logo" src={district.logo ?? MainLogo} alt={district.name} />
                                <h1 className="logo-title" style={{ color: district.color }}>{district.isDemo ? 'Demo' : 'Approved Furniture'}</h1>
                            </div>
                        </div>
                        {children}
                    </div>
                    {district != null && district.id > 0 ?
                        <div className="login-form-inner bottom-wrap">
                            <div className="login-form-row">
                                <div className="login-form-cell">
                                    <button className="outline-submit" onClick={() => navigate('/account/create')}>Create an Account</button>
                                    <p className="login-form-disclaimer">{district.name} employees get immediate access</p>
                                </div>
                            </div>
                        </div>
                        : <></>}
                </div>
            </div>
            <Footer />
        </div>
    );
}

DefaultLayout.propTypes = {
    children: PropTypes.any,
};
